import classNames from "classnames";
import { Field, Filter, SearchField, DateRange } from "components/commons";
import { SelectDepot } from "components/field";
import locale from "localization";
import React, { useCallback } from "react";
import styles from "./wholesale-price-filter.module.scss";
import { Button } from "../../../components/commons";

const WholesalePriceFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onDateRangeChange,
  onSearch,
  onFieldChange,
  resetFilter,
  submitFilter,
  filterCount,
  exportReport,
  exporting,
}) => {
  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  const selected = { label: filterState.depot, value: filterState.depotCode };

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <div
          style={{
            margin: "0px 20px",
          }}
        >
          <Field
            className={styles.date}
            label={locale.effectiveDate}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={onDateRangeChangeCb}
            />
          </Field>

          <Field
            className={styles.productCode}
            label={locale.depot}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <SelectDepot {...selected} onChange={onFieldChange} />
          </Field>
        </div>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={locale.wppid}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>

      <div className={styles.downloadDiv}>
        <Button
          className={classNames(styles.button)}
          onClick={() => exportReport({ fileType: "csv" })}
          loading={exporting}
        >
          <div className={styles.text}>
            <span className={styles.filterIcon}>
              <span className={`icon icon-download`} />
            </span>

            {locale.export}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default WholesalePriceFilter;
