import locale from "localization";

export const initialState = (state = {}) => {
  const {
    accountType = {
      value: "",
    },
  } = state;
  return {
    accountType: {
      name: "accountType",
      label: locale.accountType,
      options: [],
      horizontal: true,
      ...accountType,
    },
  };
};
