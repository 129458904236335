import Big from "big.js";
import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";

import { getFleet, updateFleetPricing, updatePricingValue } from "apis";
import { getStationPricing } from "apis/station.api";
import { Intro, Loader, Text, Field, Button, ActionButton } from "components/commons";
import { useMount, useApi, useForm, useRouter, useModal } from "hooks";
import { SuccessPurchase } from "images";
import locale from "localization";
import { formatNumber, parseLiter, parseNumber } from "utils";

import PreviousPricingInfo from "./previous-pricing-info.module";
import PricingForm from "./pricing-form.module";
import { initialState as formState } from "./pricing.state";
import { matchPumpPriceModalState } from "./match-pump-price-modal.state";
import Validation from "utils/validation.utils";

import { ConfirmModal, SuccessModal } from "components/modals";

import styles from "./pricing.module.scss";
import AccumulatedSpecificModal from "./accumulated-modal-form";
import MatchPumpPriceModal from "./match-pump-price-modal-form";
import { EditRounded } from "@material-ui/icons";
import { UserContext } from "contexts";
import { UserAccess } from "enums";
export const GasType = {
  diesel: "Diesel",
  gas91: "Gas91",
  gas95: "Gas95",
  gas97: "Gas97",
};

Object.freeze(GasType);

export const computeSpecificPrice = (currentPrice, newPumpPrice) =>
  Big(parseNumber(currentPrice) || 0)
    .add(Big(parseNumber(newPumpPrice) || 0))
    .toNumber();

export const matchPumpPrice = (refSPrice, currentPumpPrice) =>
  Big(parseNumber(refSPrice) || 0)
    .minus(Big(parseNumber(currentPumpPrice)) || 0)
    .toNumber();

const ViewFleetPricingModule = (props) => {
  const { userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.FleetAccounts;
  });

  const [isEdit, setIsEdit] = useState(false);
  const { query } = useRouter();
  const [initialFormState, setInitialFormState] = useState({});
  const fleetId = query?.id ?? "";

  const confirmModal = useModal();
  const accumulatedSpecificModal = useModal();
  const matchPriceModal = useModal();
  const successModal = useModal();

  const updatePricingValueForm = useApi({
    api: updatePricingValue,
    modalError: true,
    params: { fleetId },
  });

  const {
    request: getFleetRequest,
    loading: loadingFleet,
    result: getFleetResult,
  } = useApi({
    api: getFleet,
    modalError: true,
    params: {
      fleetId,
    },
  });

  const updatePricing = useApi({
    api: updateFleetPricing,
    modalError: true,
    params: { fleetId },
  });

  const getCurrentInfo = useApi({
    api: getStationPricing,
    modalError: true,
  });

  const getOnboardingInfo = useApi({
    api: getStationPricing,
    modalError: true,
  });

  const computeContractPrice = useCallback((contractPrice, value, discount) => {
    return Big(contractPrice || 0)
      .add(Big(value || 0))
      .minus(Big(discount || 0))
      .toNumber();
  }, []);

  // form initialization
  const form = useMemo(() => {
    let initialState = {};
    if (fleetId) {
      if (getFleetResult) {
        const updateReferenceStation = updatePricing?.result?.data?.referenceStation;
        const updateFleetstations = updatePricing?.result?.data?.fleetStations;
        const updateDiscounts = updatePricing?.result?.data?.fleetDiscounts;
        // const updateAccumulatedAccounSpecificMovement =
        //   updatePricing?.result?.data?.virtualStationPriceTools;

        const {
          estimatedVolume,
          existingVolume,
          discounts,
          // accumulatedPriceMovement,
          fleetStations,
          referenceStation,
          accumulatedAccountSpecificPriceMovement,
          currentPricing,
          accountType,
          inventoryLimit,
        } = getFleetResult;

        let fleets = updateFleetstations ?? fleetStations;
        let fleetDiscounts = updateDiscounts ?? discounts;
        let specificMovement = accumulatedAccountSpecificPriceMovement;

        // updateAccumulatedAccounSpecificMovement ??
        const newPriceMovement = specificMovement.map((accPriceMove) => ({
          [`accuSpecific${GasType[accPriceMove.productCode]}`]: {
            value: formatNumber(
              Number(
                Big(
                  initialFormState?.[`accuSpecific${GasType[accPriceMove.productCode]}`]?.value ?? 0
                ).add(accPriceMove.movement ?? 0)
              ),
              2
            ),
          },
        }));

        // const newAccuMovement = accumulatedPriceMovement.map((cPrice) => ({
        //   [`accuMovement${GasType[cPrice.productCode]}`]: {
        //     value: formatNumber(cPrice.movement, 2),
        //   },
        // }));

        const newCurrentPrice = currentPricing.map((cPrice, key) => ({
          [`current${GasType[cPrice.productCode]}Price`]: {
            value: formatNumber(cPrice?.price, 2),
          },
          [`contractPrice${GasType[cPrice.productCode]}`]: {
            value: formatNumber(Number(Big(cPrice?.price).minus(fleetDiscounts[key].discount)), 2),
          },
          [`newPumpPrice${GasType[cPrice.productCode]}`]: {
            value: computeSpecificPrice(cPrice?.price, 0),
          },
        }));

        const newDiscount = fleetDiscounts.map((discount) => ({
          [`discount${GasType[discount.productCode]}`]: {
            value: formatNumber(discount.discount, 2),
            isUpdate: false,
          },
        }));

        const newExistingVolume = existingVolume.map((exstVolume) => ({
          [`existing${GasType[exstVolume.productCode]}Volume`]: {
            value: formatNumber(exstVolume.existingVolume),
          },
        }));

        const newEstimatedVolume = estimatedVolume.map((estdVolume) => ({
          [`estimated${GasType[estdVolume.productCode]}Volume`]: {
            value: formatNumber(estdVolume.estimatedVolume),
          },
        }));

        const newFleetStations =
          fleets?.length !== 0
            ? {
                value: fleets.map((fleet) => ({
                  label: fleet?.name,
                  value: fleet?.stationId,
                })),
              }
            : { isAll: true, value: [] };

        initialState = {
          redemptionStation: {
            isAll: false,
            ...newFleetStations,
            isUpdate: false,
          },
          referenceStation: {
            value: updateReferenceStation
              ? updateReferenceStation.stationId
              : referenceStation?.stationId,
            label: updateReferenceStation ? updateReferenceStation.name : referenceStation?.name,
            isUpdate: false,
          },
          inventoryLimit: {
            value: inventoryLimit,
          },
          ...Object.assign({}, ...newPriceMovement),
          // ...Object.assign({}, ...newAccuMovement),
          ...Object.assign({}, ...newCurrentPrice),
          ...Object.assign({}, ...newDiscount),
          ...Object.assign({}, ...newExistingVolume),
          ...Object.assign({}, ...newEstimatedVolume),
          accountType: {
            value: accountType,
          },
        };
      }
    }

    setInitialFormState({ ...initialFormState, ...initialState, initialFormState: true });
    return formState(initialState);

    //eslint-disable-next-line
  }, [fleetId, getFleetResult, updatePricing?.result]);

  const matchPumpPriceFormState = useMemo(() => {
    let initialState = {};
    if (fleetId) {
      if (getFleetResult) {
        const { currentPricing } = getFleetResult;

        const newCurrentPricing = currentPricing.map((cPrice) => ({
          [`matchCurrent${GasType[cPrice.productCode]}Price`]: {
            value: formatNumber(cPrice.price, 2),
          },
        }));

        initialState = {
          ...Object.assign({}, ...newCurrentPricing),
        };
      }
    }

    if (initialFormState?.initialFormState) {
      setInitialFormState({ ...initialFormState, ...initialState, initialFormState: false });
    }
    return matchPumpPriceModalState(initialState);
    //eslint-disable-next-line
  }, [fleetId, getFleetResult, initialFormState?.initialFormState]);

  const { fields, modifyField, submitForm, modifyForm, isFormSubmittable } = useForm({
    initialState: form,
  });

  const matchPumpPriceForm = useForm({
    initialState: matchPumpPriceFormState,
  });
  // end of form initialization
  const getFleetDetails = useCallback(async () => {
    const resp = await getFleetRequest();
    // for the form

    // for onboarding details
    await getOnboardingInfo?.request({
      stationId: resp?.oldReferenceStation?.stationId ?? resp?.referenceStation?.stationId,
      effectiveDate: resp?.priceAssessmentDate,
    });

    await getCurrentInfo?.request({
      stationId: resp?.referenceStation?.stationId,
      effectiveDate: resp?.priceAssessmentDate,
    });
  }, [getFleetRequest, getCurrentInfo, getOnboardingInfo]);

  useMount(async () => {
    getFleetDetails();
  });

  const updateMatchPumpPrice = useCallback(() => {
    if (getCurrentInfo?.result) {
      const currentInfo = getCurrentInfo?.result;
      const newInitialState = currentInfo?.map((info) => ({
        [`matchRefSPumpPrice${GasType[info.productCode]}`]: {
          value: formatNumber(info?.latestStationPricingTool?.pumpPrice, 2),
        },
        [`matchAccountSpecific${GasType[info.productCode]}`]: {
          value: "0",
          isUpdate: false,
          validations: [
            Validation?.differentiate({
              initialValue: formatNumber(
                matchPumpPrice(
                  info?.latestStationPricingTool?.pumpPrice,
                  matchPumpPriceForm?.fields?.[`matchCurrent${GasType[info.productCode]}Price`]
                    ?.value
                ),
                2
              ),
            }),
            Validation.priceChange(),
          ],
        },
        [`isPriceDifference${GasType[info.productCode]}`]: {
          value: matchPumpPrice(
            info?.latestStationPricingTool?.pumpPrice,
            matchPumpPriceForm?.fields?.[`matchCurrent${GasType[info.productCode]}Price`]?.value
          ),
          showUpdate:
            matchPumpPrice(
              info?.latestStationPricingTool?.pumpPrice,
              matchPumpPriceForm?.fields?.[`matchCurrent${GasType[info.productCode]}Price`]?.value
            ) !== 0,
        },
      }));
      const newInfo = Object.assign({}, { ...Object.assign({}, ...newInitialState) });

      return newInfo;
    }
    // eslint-disable-next-line
  }, [getCurrentInfo?.result]);

  const comparePumpPrice = useCallback(() => {
    const isEdit = Object.keys(GasType)
      .map((gas) => {
        const refSPumpPrice = matchPumpPriceForm?.fields[`matchCurrent${GasType[gas]}Price`]?.value;
        const currentPrice = matchPumpPriceForm?.fields[`matchRefSPumpPrice${GasType[gas]}`]?.value;
        return refSPumpPrice !== currentPrice ? true : null;
      })
      .filter((data) => data);
    return isEdit?.length > 0;

    //eslint-disable-next-line
  }, [matchPumpPriceForm?.fields]);

  // const updateBasePrice = useCallback(() => {
  //   if (getOnboardingInfo?.result) {
  //     const onboardingInfo = getOnboardingInfo?.result || [];
  //     const newCurrentPrice = onboardingInfo.map((cPrice, key) => ({
  //       [`base${GasType[cPrice.productCode]}Price`]: {
  //         value: cPrice.pumpPrice,
  //       },
  //       [`accuMovement${GasType[cPrice.productCode]}`]: {
  //         value: Big(basePrices[key].price).minus(cPrice.pumpPrice),
  //       },
  //     }));

  //     const newAccuMovement = accumulatedPriceMovement.map((cPrice) => ({
  //       [`accuMovement${GasType[cPrice.productCode]}`]: {
  //         value: formatNumber(cPrice.movement, 2),
  //       },
  //     }));

  //     return Object.assign({}, ...newCurrentPrice);
  //   }
  // }, [getOnboardingInfo]);

  const updateCurrentInfo = useCallback(() => {
    if (getCurrentInfo?.loading) {
      // const basePrice = updateBasePrice();
      const currentInfo = getCurrentInfo?.result || [];
      const currentPricing = getFleetResult?.currentPricing || [];
      // const newAccuMovement = accumulatedPriceMovement.map((cPrice) => ({
      //   [`accuMovement${GasType[cPrice.productCode]}`]: {
      //     value: formatNumber(cPrice.movement, 2),
      //   },
      // }));

      const newInitialState = currentInfo?.map((info, key) => ({
        [`freight${GasType[info.productCode]}`]: {
          validations: [
            Validation?.differentiate({
              initialValue: formatNumber(info?.latestStationPricingTool.freight, 4),
            }),
          ],
          hasValidation: true,
          value: formatNumber(currentPricing[key].freight, 4),
        },
        [`accuMovement${GasType[info.productCode]}`]: {
          value: formatNumber(info.accumulatedMovement, 2),
        },
        [`sdum${GasType[info.productCode]}`]: {
          validations: [
            Validation?.differentiate({
              initialValue: formatNumber(info?.latestStationPricingTool.sdum, 2),
            }),
          ],
          hasValidation: true,
          value: formatNumber(currentPricing[key].sdum, 2),
        },
        [`accuMovement${GasType[info.productCode]}`]: {
          value: formatNumber(info?.accumulatedMovement),
        },
        [`base${GasType[info.productCode]}Price`]: {
          value: formatNumber(getOnboardingInfo?.result[key].pumpPrice, 2),
        },
        [`stationMovement${GasType[info.productCode]}`]: {
          value: formatNumber(
            Number(
              Big(getOnboardingInfo?.result[key].latestPumpPrice || 0)
                .minus(getOnboardingInfo?.result[key].pumpPrice || 0)
                .minus(info?.accumulatedMovement || 0),
              2
            )
          ),
        },
        // [`contractPrice${GasType[info.productCode]}`]: {
        //   value: formatNumber(
        //     Number(
        //       Big(info?.latestPumpPrice).minus(
        //         fields?.[`discount${GasType[info.productCode]}`].value
        //       )
        //     )
        //   ),
        // },
      }));

      const newSelectState = {
        referenceStationType: {
          value: currentInfo[0]?.stationType,
          label: currentInfo[0]?.stationType,
        },
        depot: {
          value: currentInfo[0]?.depot.id,
          label: currentInfo[0]?.depot.name,
        },
      };

      const newInfo = Object.assign(
        {},
        {
          ...Object.assign({}, ...newInitialState),
          ...newSelectState,
          // ...basePrice,
        }
      );

      return newInfo;
    }
    // eslint-disable-next-line
  }, [getCurrentInfo?.result, fields?.redemptionStaiton?.value]);

  useEffect(() => {
    if (getCurrentInfo?.result) {
      const currentInfo = updateCurrentInfo();
      const matchPumpPrice = updateMatchPumpPrice();
      modifyForm(currentInfo);
      matchPumpPriceForm?.modifyForm(matchPumpPrice);
      setInitialFormState({
        ...initialFormState,
        ...(!initialFormState?.initialState ? currentInfo : {}),
        ...(!initialFormState?.initialMatchState ? matchPumpPrice : {}),
        initialMatchState: true,
        initialState: true,
      });
    }
    // eslint-disable-next-line
  }, [getCurrentInfo?.result]);

  const prepareDieselForm = useCallback(() => {
    const obj = Object.values(GasType).map((gasType) => ({
      ...(fields?.[`discount${gasType}`].isUpdate
        ? { [`discount${gasType}`]: parseFloat(fields?.[`discount${gasType}`].value) }
        : {}),
    }));

    return Object.assign({}, ...obj);
  }, [fields]);

  const prepareEstimatedVolume = useCallback(() => {
    const obj = Object.values(GasType).map((gasType) => ({
      ...(fields?.[`estimated${gasType}Volume`].isUpdate
        ? { [`estimated${gasType}Volume`]: parseLiter(fields?.[`estimated${gasType}Volume`].value) }
        : {}),
    }));

    return Object.assign({}, ...obj);
  }, [fields]);

  const prepareExistingVolume = useCallback(() => {
    const obj = Object.values(GasType).map((gasType) => ({
      ...(fields?.[`existing${gasType}Volume`].isUpdate
        ? { [`existing${gasType}Volume`]: parseLiter(fields?.[`existing${gasType}Volume`].value) }
        : {}),
    }));

    return Object.assign({}, ...obj);
  }, [fields]);

  const prepareMovementValue = useCallback(() => {
    const movementData = Object.values(GasType)
      .map((gasType) => ({
        ...(matchPumpPriceForm?.fields?.[`matchAccountSpecific${gasType}`].isUpdate
          ? {
              [`movement${gasType}`]: parseFloat(
                matchPumpPriceForm?.fields?.[`matchAccountSpecific${gasType}`].value
              ),
            }
          : null),
      }))
      .filter((data) => data);

    return Object.assign({}, ...movementData);
  }, [matchPumpPriceForm?.fields]);

  const prepareFleetStations = useCallback(() => {
    if (fields?.redemptionStation?.isUpdate) {
      const fleetStations = fields?.redemptionStation?.value?.map(({ value }) => value);
      return {
        fleetStations,
      };
    }

    return {};
  }, [fields]);

  const checkIfUpdate = useCallback(() => {
    const fleetDetailFields = {};
    Object.assign(fleetDetailFields, fields);

    const hasUpdate = Object.values({
      ...fleetDetailFields,
    })?.filter((data) => data?.isUpdate);

    return hasUpdate?.length !== 0 ? false : true;
  }, [fields]);

  const checkIfMatchPumpPriceUpdate = useCallback(() => {
    const hasUpdate = Object.values({
      ...matchPumpPriceForm?.fields,
    })?.filter((data) => data?.isUpdate);
    return hasUpdate?.length !== 0 ? false : true;
  }, [matchPumpPriceForm?.fields]);

  const updatePricingForm = async (params) => {
    const value = await updatePricingValueForm?.request(params);
    if (value) {
      accumulatedSpecificModal.close();
      successModal.show({
        title: locale.exclamatedSuccess,
        content: locale.detailHaveBeenSuccessfullyUpdated,
        primary: {
          onClick: () => {
            setInitialFormState({});
            getFleetDetails();
            setIsEdit(!isEdit);
            successModal.close();
          },
        },
      });
    }
  };

  const updatePriceRequest = async (isRefSPriceChange = false) => {
    let params = {
      businessId: getFleetResult?.shortName,
    };

    if (fields.accountType.isUpdate) {
      params = {
        ...params,
        ...{
          accountType: fields?.accountType?.value,
        },
      };
    }

    if (isRefSPriceChange) {
      params = {
        ...params,
        ...{ referenceStationId: fields?.referenceStation?.value },
      };
    } else {
      params = {
        ...params,
        ...prepareDieselForm(),
        ...prepareEstimatedVolume(),
        ...prepareExistingVolume(),
        ...prepareFleetStations(),
        ...prepareMovementValue(),
      };
    }

    const resp = await updatePricing?.request(params);
    return resp;
  };

  const showSuccessModal = () => {
    successModal.show({
      title: locale.exclamatedSuccess,
      content: locale.detailHaveBeenSuccessfullyUpdated,
      primary: {
        onClick: () => {
          setInitialFormState({}); // remove all state
          getFleetDetails();
          setIsEdit(!isEdit);
          successModal.close();
        },
      },
    });
  };

  const showSaveModal = () => {
    confirmModal.show({
      title: locale.areYouSure,
      content: (
        <>
          <Text>{locale.updatedReferenceStationOnly}</Text>
          <div style={{ padding: `20px 0` }}>
            {fields?.referenceStation?.isUpdate && (
              <Field
                className={styles.fieldTextLabel}
                label={locale.referenceStation}
                labelClassName={styles.fieldTextInfo}
                labelPosition="left"
                childrenClassName={styles.fieldText}
              >
                <Text>{fields?.referenceStation?.label}</Text>
              </Field>
            )}
            {fields?.redemptionStation?.isUpdate && (
              <Field
                className={styles.fieldTextLabel}
                label={locale.redemptionStation}
                labelClassName={styles.fieldTextInfo}
                labelPosition="left"
                childrenClassName={styles.fieldTextContentLong}
              >
                <Text>
                  {fields.redemptionStation?.value?.length === 0
                    ? "All Stations"
                    : fields.redemptionStation?.value.map((station) => station?.label).join(", ")}
                </Text>
              </Field>
            )}
            {(getFleetResult?.referenceStation?.name !== fields?.referenceStation?.label ||
              comparePumpPrice()) && (
              <Field
                className={styles.fieldTextLabel}
                label={locale.pumpPrice}
                labelClassName={styles.fieldTextInfo}
                labelPosition="left"
                childrenClassName={styles.fieldTextContentLong}
              >
                <Button
                  outline
                  primary
                  disabled={updatePricing?.loading}
                  onClick={() => {
                    confirmModal?.close();
                    matchPriceModal?.show({
                      title: locale.matchRefSPumpPrice,
                      isShowSaveModal: true,
                    });
                  }}
                >
                  {locale.update}
                </Button>
              </Field>
            )}
          </div>
        </>
      ),
      secondary: {
        text: locale.cancel,
        onClick: () => confirmModal?.close(),
      },
      primary: {
        text: locale.yesProceed,
        onClick: async () => {
          const resp = await updatePriceRequest();
          if (resp) {
            confirmModal.close();
            showSuccessModal();
          }
        },
      },
    });
  };

  const onApplyUpdates = async () => {
    // const movements = Object.values(GasType)
    //   .map((gasType) => ({
    //     ...(fields?.[`accuMovement${gasType}`].initialFormValue !==
    //       initialFormState[`accuMovement${gasType}`].value ||
    //     fields?.[`accuMovement${gasType}`].value !==
    //       initialFormState[`accuMovement${gasType}`].value
    //       ? {
    //           [`accuMovement${gasType}`]: {
    //             value: parseFloat(fields?.[`accuMovement${gasType}`].initialFormValue),
    //             isUpdate: true,
    //           },
    //         }
    //       : null),
    //   }))
    //   .filter((data) => data);

    const resp = await updatePriceRequest(true);
    if (resp) {
      confirmModal.close();
      successModal.show({
        title: locale.exclamatedSuccess,
        content: locale.detailHaveBeenSuccessfullyUpdated,
        primary: {
          onClick: () => {
            setInitialFormState({});
            getFleetDetails();
            successModal.close();
          },
        },
      });
    }
  };

  // const onApplyUpdatePrices = useCallback(() => {
  //   const depot =
  //     fields?.depot?.initialFormValue?.value !== initialFormState?.depot?.value
  //       ? fields?.depot?.initialFormValue
  //       : initialFormState?.depot;

  //   const sdum = Object.values(GasType)
  //     .map((gasType) => ({
  //       ...(fields?.[`sdum${gasType}`].initialFormValue !==
  //         initialFormState[`sdum${gasType}`].value ||
  //       fields?.[`sdum${gasType}`].value !== initialFormState[`sdum${gasType}`].value
  //         ? {
  //             [`sdum${gasType}`]: {
  //               value: parseFloat(fields?.[`sdum${gasType}`].initialFormValue),
  //               isUpdate: true,
  //             },
  //           }
  //         : null),
  //     }))
  //     .filter((data) => data);

  //   const freight = Object.values(GasType)
  //     .map((gasType) => ({
  //       ...(fields?.[`freight${gasType}`].initialFormValue !==
  //         initialFormState[`freight${gasType}`].value ||
  //       fields?.[`freight${gasType}`].value !== initialFormState[`freight${gasType}`].value
  //         ? {
  //             [`freight${gasType}`]: {
  //               value: parseFloat(fields?.[`freight${gasType}`].initialFormValue),
  //               isUpdate: true,
  //             },
  //           }
  //         : null),
  //     }))
  //     .filter((data) => data);

  //   const formUpdate = Object.assign({}, { ...Object.assign({}, ...sdum, ...freight), depot });

  //   modifyForm(formUpdate);
  // }, [fields, initialFormState, modifyForm]);

  const onCancelEdit = useCallback(() => {
    const formObj = Object.assign(
      {},
      ...Object.values(GasType)
        .map((gasType) =>
          matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`]?.isUpdate
            ? {
                ...(fields[`accuSpecific${gasType}`]?.isUpdate
                  ? {
                      [fields[`accuSpecific${gasType}`].name]: {
                        ...fields[`accuSpecific${gasType}`],
                        value: Big(fields[`accuSpecific${gasType}`].value).minus(
                          matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].value
                        ),
                        isUpdate: false,
                      },
                    }
                  : null),
                [fields[`contractPrice${gasType}`]?.name]: {
                  value: initialFormState[`contractPrice${gasType}`].value,
                },
              }
            : null
        )
        .filter((data) => data)
    );

    const obj = Object.values(GasType)
      .map((gasType) => ({
        ...(matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`]?.isUpdate
          ? {
              [matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].name]: {
                ...initialFormState[`matchAccountSpecific${gasType}`],
                value: "0",
                isUpdate: false,
                fontColor: "black",
              },
              [matchPumpPriceForm?.fields[`isPriceDifference${gasType}`].name]: {
                ...initialFormState[`isPriceDifference${gasType}`],
                showUpdate: true,
              },
            }
          : null),
        [matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].name]: {
          ...initialFormState[`matchAccountSpecific${gasType}`],
        },
        [matchPumpPriceForm?.fields[`matchRefSPumpPrice${gasType}`].name]: {
          ...initialFormState[`matchRefSPumpPrice${gasType}`],
        },
      }))
      .filter((data) => data);

    const initialValueState = Object.assign(
      {},
      ...Object.values(fields)
        .map((field) =>
          field?.validations.length !== 0
            ? {
                [field.name]: {
                  ...initialFormState[field?.name],
                  hasValidation: false,
                },
              }
            : null
        )
        .filter((data) => data)
    );

    modifyForm(Object.assign({}, { ...initialFormState, ...formObj, ...initialValueState }));
    matchPumpPriceForm?.modifyForm(Object.assign({}, ...obj));
  }, [modifyForm, initialFormState, matchPumpPriceForm, fields]);

  const stateForm = {
    fields,
    loadingFleet,
    modifyField,
    submitForm,
    modifyForm,
    updateCurrentInfo,
    computeContractPrice,
    getCurrentInfo,
    comparePumpPrice,
  };
  return (
    <>
      <ConfirmModal
        {...confirmModal}
        loading={updatePricing?.loading}
        disabled={updatePricing?.loading}
        closable={!updatePricing?.loading}
      />
      <AccumulatedSpecificModal
        closable={false}
        updatePricingForm={updatePricingForm}
        disabled={updatePricingValueForm?.loading}
        {...accumulatedSpecificModal}
        {...stateForm}
      />
      <MatchPumpPriceModal
        computeContractPrice={computeContractPrice}
        updatePriceRequest={updatePriceRequest}
        disabled={updatePricing?.loading}
        showSaveModal={showSaveModal}
        showSuccessModal={showSuccessModal}
        isShowSaveModal={false}
        matchPumpPriceForm={matchPumpPriceForm}
        initialFormState={initialFormState}
        currentInfo={getCurrentInfo?.result}
        {...matchPriceModal}
        {...stateForm}
      />
      <SuccessModal {...successModal} image={SuccessPurchase} closable={false} />
      <div style={{ marginTop: 60 }}>
        {loadingFleet || updatePricing?.loading ? (
          <Loader open />
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <Intro
                style={{ minWidth: "500px" }}
                title={`${isEdit ? locale.editPricingDetails : locale.pricingDetails} - ${
                  getFleetResult?.businessName
                }`}
              />
              {isEdit ? (
                <ActionButton
                  right
                  style={{
                    marginTop: "0px",
                  }}
                  items={[
                    {
                      onClick: () => {
                        onCancelEdit();
                        setIsEdit(!isEdit);
                      },
                      text: locale.cancel,
                      primary: false,
                    },
                    {
                      text: locale.save,
                      disabled: !isFormSubmittable || checkIfUpdate(),
                      onClick: async () => {
                        if (!checkIfMatchPumpPriceUpdate() && !checkIfUpdate()) {
                          const resp = await updatePriceRequest();
                          if (resp) {
                            confirmModal.close();
                            setInitialFormState({}); // remove all state
                            showSuccessModal();
                          }
                        } else {
                          showSaveModal();
                        }
                      },
                      primary: true,
                    },
                  ]}
                />
              ) : (
                <ActionButton
                  right
                  style={{
                    marginTop: "0px",
                  }}
                  items={
                    getUserAccess?.fullAccess || getUserAccess?.modifyAccess
                      ? [
                          {
                            onClick: () => {
                              updateCurrentInfo();
                              setIsEdit(!isEdit);
                            },
                            text: locale.edit,
                            startIcon: <EditRounded />,
                            exportButton: true,
                            primary: true,
                          },
                        ]
                      : []
                  }
                />
              )}
            </div>
            <PreviousPricingInfo
              onboardingInfo={getOnboardingInfo?.result}
              isLoading={getOnboardingInfo?.loading}
              getFleetResult={getFleetResult}
              computeContractPrice={computeContractPrice}
            />
            <PricingForm
              onApplyUpdates={onApplyUpdates}
              isEdit={isEdit}
              isLoading={getCurrentInfo?.loading ?? true}
              initialFormLoading={loadingFleet}
              initialFormState={initialFormState}
              matchPriceModal={matchPriceModal}
              isFormSubmittable={isFormSubmittable}
              onboardingInfo={getOnboardingInfo?.result}
              priceAssessmentDate={getFleetResult?.priceAssessmentDate}
              matchPumpPriceFields={matchPumpPriceForm?.fields}
              showMatchPumpPriceModal={
                getFleetResult?.referenceStation?.name !== fields?.referenceStation?.label
              }
              accumulatedSpecificModal={accumulatedSpecificModal}
              computeContractPrice={computeContractPrice}
              {...stateForm}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ViewFleetPricingModule;
