import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NoPage } from "../components/commons";
const PricelocqConsumerPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PricelocqConsumer} component={NoPage} />
      <Redirect to={Path.PricelocqConsumer} />
    </Switch>
  );
};

export default PricelocqConsumerPage;
