import { ApiPath } from "enums";
import { Get, Post } from "utils";
import moment from "moment";

export const getWholesalePrice = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetWholesalePrice}`, query);

  return res.data;
};

export const uploadWholesalePriceFile = async ({ params }) => {
  let formData = new FormData();
  const file = params.fileInfo;
  const effectiveDate = params.effectiveAt && moment(params.effectiveAt).format();

  formData.append("data", file);
  formData.append("effectiveAt", effectiveDate);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    return await Post(`${ApiPath.UploadWholesalePrice}`, formData, config);
  } catch (error) {
    return error;
  }
};
