import { CircularProgress, Grid } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Intro, Text, TextField } from "components/commons";
import { locale } from "localization/en";
import React, { useEffect, useState } from "react";
import { debounced } from "utils";
import { shortDateTime } from "utils/date.utils";
import style from "./inbox.module.scss";

const InboxItems = ({
  data,
  selectedMessage,
  setSelectedMessage,
  setRead,
  initializing,
  onSearch,
  loading,
  error,
  searchKey,
  setSearchKey,
}) => {
  const [typing, setTyping] = useState(false);
  const [submittedSearchKey, setSubmittedSearchKey] = useState("");

  //eslint-disable-next-line
  useEffect(
    debounced(async () => {
      setTyping(false);
      if (!error && !loading && !initializing && submittedSearchKey !== searchKey) {
        onSearch(searchKey);
        setSelectedMessage(null);
        setSubmittedSearchKey(searchKey);
      }
    }, 1000),
    [searchKey]
  );

  return (
    <Grid item xs={3}>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <TextField
          prefix={<SearchOutlined style={{ color: "#a1aeb7" }} />}
          placeholder={locale.search}
          value={searchKey}
          suffix={typing ? <CircularProgress size={12} /> : null}
          onChange={(_, { value }) => {
            if (!loading) {
              setTyping(true);
            }
            setSearchKey(value);
          }}
        />
      </div>
      <div className={style.items}>
        {initializing || (loading && !data.length && !searchKey) ? (
          [0, 1, 2, 3, 4].map((_) => {
            return (
              <div
                style={{
                  padding: "20px",
                }}
                className={style.item}
              >
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    style={{
                      paddingRight: "20px",
                    }}
                  >
                    <Skeleton />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton />
                  </Grid>
                </Grid>
                <Skeleton />
              </div>
            );
          })
        ) : !data.length ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <Intro
                containerStyle={{
                  margin: "auto",
                }}
                style={{
                  margin: "auto",
                  textAlign: "center",
                }}
                small
                title={locale.sorryNoResultFound}
                subtitle={locale.weCouldNotFindAMatchForYou}
              />
              {loading && (
                <div
                  style={{
                    padding: "30px",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress size={20} />
                </div>
              )}
            </div>
          </div>
        ) : (
          data.map((item = {}) => {
            const { createdAt, announcement: messageContent = {} } = item;
            const { subject, contentPreview, startDate } = messageContent;
            return (
              <div
                style={{
                  padding: "20px",
                  background: selectedMessage === createdAt ? "#f5f6fa" : "",
                  cursor: "pointer",
                }}
                className={style.item}
                onClick={() => {
                  setSelectedMessage(createdAt);
                  setRead(createdAt);
                }}
              >
                <Grid container>
                  <Grid item xs={9}>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        paddingRight: "20px",
                        color: item.unread ? "#014171" : "#000000",
                        fontWeight: item.unread ? "700" : 400,
                      }}
                    >
                      {subject}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Text subtitle>{shortDateTime(startDate)}</Text>
                  </Grid>
                </Grid>
                <Text
                  style={{
                    marginTop: "6px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  subtitle
                >
                  {contentPreview}
                </Text>
              </div>
            );
          })
        )}
        {loading && data.length ? (
          <div
            style={{
              padding: "30px",
              textAlign: "center",
            }}
          >
            <CircularProgress size={20} />
          </div>
        ) : null}
      </div>
    </Grid>
  );
};

export default InboxItems;
