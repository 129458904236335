import React from "react";
import { Field, Pill, Text, Title, TextField } from "components/commons";
import { InputSwitch } from "components/field";
import moment from "moment";

import { SettlementStatus } from "enums";
import locale from "localization";
import { prettifySettlementStatus, dateFormat, formatAmount, formatVolume } from "utils";

import styles from "./view-settlement-history.module.scss";
import { Skeleton } from "@material-ui/lab";

const ViewSettlementHistoryForm = ({
  viewMode,
  settlementDetails: {
    settlementOilcoId = "",
    discountVoucherStatus = "",
    createdAt = "",
    station,
    totalMopsMovementW1,
    totalNationwideMovementSinceBuy,
    totalPriceMovementDifferentialW1,
    totalRedemptionStationSpecificMovementSinceBuy,
    totalOilcoShoulderedDiscountAtBuy,
    totalOilcoShoulderedStationSwitch,
    totalAdditionalOilcoShoulderedAdjustment,
    periodStartDate,
    periodEndDate,
    totalOilcoSettlementReceivablePayable,
    totalLiter,
  },
  settlementLoading,
  setTotalMopsRemarks,
  totalMopsRemarks,
  setTotalOilcoSettlementReceivablePayableRemarks,
  totalOilcoSettlementReceivablePayableRemarks,
  oilcoSettlementStatus,
  setOilCoSettlementStatus,
  currentOilcoSettlementStatus,
}) => {
  return (
    <div>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <div
        style={{
          width: "700px",
        }}
      >
        <Field
          className={styles.fieldLabel}
          label={locale.settlementId}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : settlementOilcoId ? (
            settlementOilcoId
          ) : (
            "--"
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.periodCovered}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : periodStartDate && periodEndDate ? (
            <>
              {dateFormat(moment(periodStartDate))} <span style={{ fontWeight: 300 }}>to</span>{" "}
              {dateFormat(moment(periodEndDate))}
            </>
          ) : (
            "--"
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.totalPriceLocqLiter}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : totalLiter ? (
            formatVolume(totalLiter)
          ) : (
            "--"
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 950,
        }}
      >
        <Field
          className={styles.fieldLabel}
          label={locale.totalMOPSMovement}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : totalMopsMovementW1 ? (
            formatAmount(totalMopsMovementW1)
          ) : (
            "--"
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          labelClassName={styles.fieldLabelRemarks}
          label={locale.remarks}
          labelPosition={"left"}
          childrenClassName={viewMode ? styles.fieldInput : styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : viewMode ? (
            <TextField
              onChange={(_, { value }) => {
                setTotalMopsRemarks(value);
              }}
              value={totalMopsRemarks}
              maxLength={255}
            />
          ) : (
            <Text>{totalMopsRemarks || "--"}</Text>
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 950,
        }}
      >
        <Field
          className={styles.fieldLabel}
          label={locale.totalOilcoSettlementReceivable}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : totalOilcoSettlementReceivablePayable ? (
            formatAmount(totalOilcoSettlementReceivablePayable)
          ) : (
            "--"
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          labelClassName={styles.fieldLabelRemarks}
          label={locale.remarks}
          labelPosition={"left"}
          childrenClassName={viewMode ? styles.fieldInput : styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : viewMode ? (
            <TextField
              onChange={(_, { value }) => {
                setTotalOilcoSettlementReceivablePayableRemarks(value);
              }}
              value={totalOilcoSettlementReceivablePayableRemarks}
              maxLength={255}
            />
          ) : (
            <Text>{totalOilcoSettlementReceivablePayableRemarks || "--"}</Text>
          )}
        </Field>
      </div>
      <div className={styles.detailsContainer}>
        <Field
          className={styles.fieldContainer}
          label={locale.totalNationwideMovementSinceBuy}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalNationwideMovementSinceBuy)
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          label={locale.totalPriceMovementDifferential}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalPriceMovementDifferentialW1)
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          label={locale.totalRedSMovement}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalRedemptionStationSpecificMovementSinceBuy)
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          label={locale.totalOilcoShoulderedDiscount}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalOilcoShoulderedDiscountAtBuy)
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          label={locale.totalOilcoShoulderedStation}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalOilcoShoulderedStationSwitch)
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          label={locale.totalAdditionalOilcoShoulderedAdjustment}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatAmount(totalAdditionalOilcoShoulderedAdjustment)
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 950,
        }}
      >
        <Field
          className={styles.fieldContainer}
          label={locale.oilcoSettlementStatus}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "150px",
              }}
            />
          ) : viewMode && currentOilcoSettlementStatus !== SettlementStatus.Processed ? (
            <InputSwitch
              className={
                oilcoSettlementStatus === SettlementStatus.ForProcessing
                  ? styles.switchBackground
                  : ""
              }
              label={prettifySettlementStatus(oilcoSettlementStatus)}
              checked={oilcoSettlementStatus === SettlementStatus.ForProcessing ? true : false}
              onChange={(_, val) => {
                setOilCoSettlementStatus(
                  val ? SettlementStatus.ForProcessing : SettlementStatus.Processed
                );
              }}
            />
          ) : (
            <Pill
              sky={
                oilcoSettlementStatus === SettlementStatus.ForProcessing ||
                oilcoSettlementStatus === SettlementStatus.ForSettlement
              }
              grass={oilcoSettlementStatus === SettlementStatus.Processed}
            >
              {prettifySettlementStatus(oilcoSettlementStatus)}
            </Pill>
          )}
        </Field>
      </div>
    </div>
  );
};

export default ViewSettlementHistoryForm;
