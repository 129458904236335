import { Autocomplete, Field } from "components/commons";
import React from "react";
import locale from "localization";
import { prettifyVIPStatus } from "utils/pretty.utils";
import VIPStatus from "enums/vip-status";

const SelectVIPStatus = ({
  onChange,
  name,
  value,
  className,
  label = locale.vipStatus,
  labelClassName,
  fieldClassName,
  labelPosition,
  multiple,
  hasAll,
}) => {
  return (
    <Field
      className={className}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      childrenClassName={fieldClassName}
    >
      <Autocomplete
        value={value}
        name={name}
        placeholder={"Select"}
        multiple={multiple}
        hasAll={hasAll}
        options={[VIPStatus.NotOnboarded, VIPStatus.Active, VIPStatus.Hidden].map((val) => ({
          label: prettifyVIPStatus(val),
          value: val,
        }))}
        onChange={(_, { value }) => onChange({ [name]: value })}
      />
    </Field>
  );
};

export default SelectVIPStatus;
