import locale from "localization";

export const lubeServOnWheelsBookingsColumns = [
  {
    key: "bookingId",
    text: locale.bookingID,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "createdAt",
    text: locale.dateCreated,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "name",
    text: `${locale.name} / ${locale.mobileNumber}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "email",
    text: locale.emailAddress,
    style: {
      minWidth: "70px",
    },
  },

  {
    key: "type",
    text: locale.oilChangePackage,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "vehicleInfo",
    text: `${locale.vehicleModel} / ${locale.vehicleYear}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "address",
    text: locale.address,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "preferredDateTime",
    text: `${locale.preferredDate} / ${locale.preferredTime}`,
    style: {
      minWidth: "100px",
    },
  },
];
