import { GuyWithMagGlass } from "images";
import locale from "localization";
import React, { useCallback } from "react";
import Image from "../image/image";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import Table from "../table/table";
import Text from "../text/text";
import Title from "../title/title";
import styles from "./data-table.module.scss";
import classNames from "classnames";

const DataTable = ({
  loading,
  page,
  pageSize,
  onChangePage,
  onChangePageSize,
  columns,
  data,
  dataCount,
  hidePagination = false,
  actionHeader = false,
  actionHeaderProps,
  onCheckAll,
}) => {
  const onChangePageCb = useCallback(
    (page) => {
      if (onChangePage) {
        return onChangePage(page);
      }
    },
    [onChangePage]
  );

  const onChangePageSizeCb = useCallback(
    (pageSize) => {
      if (onChangePageSize) {
        return onChangePageSize(pageSize);
      }
    },
    [onChangePageSize]
  );

  const onCheckAllCb = useCallback(
    (checked) => {
      if (onCheckAll) {
        return onCheckAll(checked);
      }
    },
    [onCheckAll]
  );

  return (
    <div>
      <Loader open={loading} />
      {!data.length && !loading ? (
        <div className={styles.noSearchResultRender}>
          <div>
            <Image src={GuyWithMagGlass} />
            <Title small>{locale.sorryNoResultFound}</Title>
            <Text subtitle>{locale.weCouldNotFindAMatchForSearchCriteria}</Text>
            <Text subtitle>{locale.pleaseTryADifferentOne}</Text>
          </div>
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            data={data}
            actionHeaderProps={actionHeaderProps}
            actionHeader={actionHeader}
            onCheckAll={onCheckAllCb}
          />
        </>
      )}
      <div
        className={classNames({
          [`${styles.hidePagination}`]: (!data.length && !loading) || hidePagination,
        })}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          className={styles.pagination}
          dataCount={dataCount}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default DataTable;
