import axios from "axios";
import { ApiPath, Path } from "enums";
import jwt from "jsonwebtoken";
import moment from "moment";
import env from "../environments/env";
import { redirectTo } from "./url.utils";

axios.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const decodedAccessToken = jwt.decode(accessToken);
      if (moment().isAfter(moment.unix(decodedAccessToken.exp))) {
        try {
          accessToken = await callRefreshTokenApi();
          localStorage.setItem("accessToken", accessToken);
          return attachAccessToken(config, accessToken);
        } catch (err) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          redirectTo(Path.Auth);
        }
      } else {
        return attachAccessToken(config, accessToken);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const callRefreshTokenApi = async () => {
  const axiosInstance = axios.create();
  const res = await axiosInstance.post(`${env.API_URL}/${ApiPath.Refresh}`, {
    refreshToken: localStorage.getItem("refreshToken"),
  });
  return res.data?.data?.AccessToken;
};

const attachAccessToken = (config, accessToken) => {
  return {
    ...config,
    headers: {
      Authorization: `${accessToken}`,
      ...config.headers,
    },
  };
};
