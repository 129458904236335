import locale from "localization";
import BankProcessor from "enums/bank-processor";
import env from "environments/env";
import BankCodes from "./bank-codes";
import CashInChannel from "./cashin-channel";

export const bankItems = {
  [BankCodes.BdoPersonal]: {
    title: locale.bancoDeOro,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.Brankas,
    method: CashInChannel.OnlineBanking,
    max: 500000,
    shortTitle: "BDO",
  },
  [BankCodes.BDO]: {
    title: locale.bancoDeOro,
    fee: 20,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
    shortTitle: "BDO",
  },
  [BankCodes.BDONetwork]: {
    title: locale.bdoNetworkBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.Unionbank]: {
    title: locale.unionBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.Brankas,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.UnionbankOTC]: {
    title: locale.unionBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.AUB]: {
    title: locale.aubAsiaUnitedBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
    shortTitle: "AUB",
  },
  [BankCodes.BankOfCommerce]: {
    title: locale.bankOfCommerce,
    fee: 10,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.BPI]: {
    title: locale.bpiBankOfPhil,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 0,
    shortTitle: "BPI",
  },
  [BankCodes.BPIOTC]: {
    title: locale.bpiBankOfPhil,
    fee: 100,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
    shortTitle: "BPI",
  },
  [BankCodes.Chinabank]: {
    title: locale.chinabank,
    fee: 15,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.ChinabankOTC]: {
    title: locale.chinabank,
    fee: 50,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 100000,
  },
  [BankCodes.Eastwest]: {
    title: locale.eastwestBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.EastwestOTC]: {
    title: locale.eastwestBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.Landbank]: {
    title: locale.landBank,
    fee: 10,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 250000,
  },
  [BankCodes.LandbankOTC]: {
    title: locale.landBank,
    fee: 50,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.Maybank]: {
    title: locale.mayBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 0,
  },
  [BankCodes.MetroBank]: {
    title: locale.metroBank,
    fee: 50,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.MetroBankOTC]: {
    title: locale.metroBank,
    fee: 50,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.PNB]: {
    title: locale.pnbPhilNatBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
    shortTitle: "PNB",
  },
  [BankCodes.PNBOTC]: {
    title: locale.pnbPhilNatBank,
    fee: 25,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
    shortTitle: "PNB",
  },
  [BankCodes.PSBank]: {
    title: locale.psBank,
    fee: 25,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.RCBC]: {
    title: locale.rcbcRizalCommercialBank,
    fee: 5,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 50000,
    shortTitle: "RCBC",
  },
  [BankCodes.RCBCOTC]: {
    title: locale.rcbcRizalCommercialBank,
    fee: 25,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
    shortTitle: "RCBC",
  },
  [BankCodes.Robinsons]: {
    title: locale.robinsonsBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.RobinsonsOTC]: {
    title: locale.robinsonsBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.UCPB]: {
    title: locale.ucpb,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
    shortTitle: "UCPB",
  },
  [BankCodes.UCPBOTC]: {
    title: locale.ucpb,
    fee: 25,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
    shortTitle: "UCPB",
  },
  [BankCodes.SecurityBankOTC]: {
    title: locale.securityBank,
    fee: 50,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.SecurityBank]: {
    title: locale.securityBank,
    fee: 0,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
  [BankCodes.BOGX]: {
    title: "Bogus Bank OTC",
    fee: 0,
    // image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OTC,
    max: 500000,
  },
  [BankCodes.BOG]: {
    title: "Bogus Bank",
    fee: 0,
    // image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInChannel.OnlineBanking,
    max: 500000,
  },
};

export const banks = [
  BankCodes.Unionbank,
  BankCodes.AUB,
  BankCodes.BdoPersonal,
  BankCodes.BankOfCommerce,
  BankCodes.Chinabank,
  BankCodes.Landbank,
  BankCodes.MetroBank,
  BankCodes.PNB,
  BankCodes.RCBC,
  BankCodes.Robinsons,
  BankCodes.UCPB,
  BankCodes.BOG,
  BankCodes.SecurityBank,
]
  .sort()
  .filter((item) => {
    return (item === BankCodes.BOG && env.ID !== "prod") || item !== BankCodes.BOGX;
  })
  .map((item) => {
    return {
      ...bankItems[item],
      code: item,
    };
  });

export const otcs = [
  BankCodes.AUB,
  BankCodes.BDO,
  BankCodes.BDONetwork,
  BankCodes.BPIOTC,
  BankCodes.ChinabankOTC,
  BankCodes.Eastwest,
  BankCodes.LandbankOTC,
  BankCodes.MetroBankOTC,
  BankCodes.PNBOTC,
  BankCodes.RCBCOTC,
  BankCodes.RobinsonsOTC,
  BankCodes.SecurityBankOTC,
  BankCodes.UnionbankOTC,
  BankCodes.UCPBOTC,
  BankCodes.BOGX,
]
  .sort()
  .filter((item) => {
    return (item === BankCodes.BOGX && env.ID !== "prod") || item !== BankCodes.BOGX;
  })
  .map((item) => {
    const itemObj =
      item === BankCodes.AUB
        ? {
            title: locale.aubAsiaUnitedBank,
            fee: 0,
            isEnabled: true,
            processor: BankProcessor.DragonPay,
            method: CashInChannel.OTC,
            max: 50000,
          }
        : {};
    return {
      ...bankItems[item],
      ...itemObj,
      code: item,
      processor: BankProcessor.DragonPay,
      mode: CashInChannel.OTC,
    };
  });
