import moment from "moment";
import { Pill, Text } from "components/commons";
import {
  prettifyRFIDPaymentMethod,
  prettifyRFIDStatus,
  prettifyRFIDType,
} from "utils/pretty.utils";
import { formatAmount } from "utils";
import { DateTime } from "enums";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import RFIDStatus from "enums/rfid-status";

export const mapDataToList = (data) => {
  const {
    rfidAccountId,
    rfidTopupId,
    userDetail = {},
    accountNumber,
    plateNumber,
    referenceNumber,
    serviceFee,
    amount,
    createdAt,
    status,
    paymentMethod,
    orNumber,
    rfidType,
  } = data;
  const { mobileNumber, lastName = "", firstName = "", email = "" } = userDetail;
  return {
    rfidTopupId: <div>{rfidTopupId}</div>,
    rfidAccountId,
    name: (
      <LabelAndValue label={mobileNumber}>
        {`${firstName} ${lastName}`.trim() || email}
      </LabelAndValue>
    ),
    plateNumber: plateNumber || "--",
    type: prettifyRFIDType(rfidType),
    accountNumber,
    referenceNumber: referenceNumber || "--",
    orNumber: orNumber || "--",
    amount: formatAmount(amount),
    paymentMethod: prettifyRFIDPaymentMethod(paymentMethod),
    serviceFee: formatAmount(serviceFee),
    statusAndDate: (
      <>
        <Pill
          sky={status === RFIDStatus.Pending}
          deepRed={status === RFIDStatus.Failed}
          grass={status === RFIDStatus.Success}
          cement={status === RFIDStatus.Voided}
        >
          {prettifyRFIDStatus(status)}
        </Pill>
        <Text
          subtitle
          style={{
            marginTop: "10px",
          }}
        >
          {moment(createdAt).format(DateTime.R)}
        </Text>
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { type, paymentMethod, status, ...fs } = filterState;

  return {
    ...fs,
    rfidType: !type.isSelectedAll ? type.value.join(",") : null,
    paymentMethod: !paymentMethod.isSelectedAll ? paymentMethod.value.join(",") : null,
    status: !status.isSelectedAll ? status.value.join(",") : null,
  };
};
