import LabelAndValue from "components/commons/label-and-value/label-and-value";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";
import { capitalize, parseNumber } from "utils";
import locale from "localization";
import { formatNumber, formatDate, formatAmount } from "utils";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherStatusType,
  prettifyVoucherTypeSetting,
} from "utils/pretty.utils";
import { CampaignStatus, DateTime, Path } from "enums";
import { getVoucherTypeAndSubType } from "enums/voucher-type-options";
import { Image, Pill, PopOverMenu, Table, Title } from "components/commons";
import { MoreVert } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import VoucherRecipients from "enums/voucher-recipients";
import { GuyWithMagGlass } from "images";
import { Role } from "enums";
import VoucherCampaignGroup from "enums/voucher-campaign-group";

export const mapDataToList = ({
  data,
  history,
  updateVoucherRequest,
  confirmModal,
  getVoucherCampaignHistoryRequest,
  exportSingleVoucher,
  getUserAccess,
}) => {
  const {
    voucherCampaignId,
    campaignName,
    voucherPrefix,
    voucherCampaignGroup,
    voucherType,
    claimMethod,
    totalVoucherValue,
    totalVoucherCount,
    totalClaimedVoucherValue,
    totalClaimedVoucherCount,
    totalUsedVoucherCount,
    totalUsedVoucherValue,
    startDate,
    endDate,
    voucherSubType,
    createdByEmail,
    createdBy,
    createdAt,
    updatedAt,
    status,
  } = data;

  // const isPesoDiscount = voucherType === VoucherType.PesoDiscount;

  const submitExport = (voucherCampaignId) => {
    return exportSingleVoucher(voucherCampaignId);
  };
  let actionOptions = [];

  if (getUserAccess.role !== Role.Marketing) {
    let viewDetails = [
      {
        content: "View vouchers",
        onClick: () => {
          history.push(Path.Vouchers, {
            search: campaignName,
          });
        },
      },
    ];

    actionOptions.push(...viewDetails);
  }

  if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
    let viewDetails = [
      {
        content: "View details",
        onClick: () => {
          history.push(Path.ViewVoucherCampaignId(voucherCampaignId));
        },
      },
    ];

    actionOptions.push(...viewDetails);
  }

  if (getUserAccess?.fullAccess || getUserAccess?.exportAccess) {
    if (getUserAccess.role !== Role.Marketing) {
      actionOptions.push({
        content: locale.exportVouchers,
        onClick: () => {
          submitExport(voucherCampaignId);
        },
      });
    }
  }

  if (status !== CampaignStatus.Cancelled) {
    if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
      actionOptions.push({
        content: locale.editDetails,

        onClick: () => {
          history.push(Path.EditVoucherCampaignId(voucherCampaignId));
        },
      });
    }
  }

  if (![CampaignStatus.Cancelled, CampaignStatus.Ended].includes(status)) {
    if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
      actionOptions.push({
        content: locale.cancel,
        onClick: () => {
          confirmModal.show({
            refresh: true,
            title: `${locale.cancelCampaign}?`,
            content: locale.populate(locale.cancelCampaignDesc, [campaignName]),
            primary: {
              text: locale.yesCancelIt,
              onClick: async () => {
                await updateVoucherRequest.request({
                  action: "cancel",
                  voucherCampaignId,
                });
              },
            },
            secondary: {
              text: locale.cancel,
              onClick: async () => {
                confirmModal.close();
              },
            },
          });
        },
      });
    }
  }

  if (status === CampaignStatus.Cancelled) {
    if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
      actionOptions.push({
        content: locale.reactivate,
        onClick: () => {
          confirmModal.show({
            refresh: true,
            title: `${locale.reactivateCampaign}?`,
            content: locale.populate(locale.reactivateCampaignDesc, [campaignName]),
            primary: {
              text: locale.yesReactivateIt,
              onClick: async () => {
                await updateVoucherRequest.request({
                  action: "reactivate",
                  voucherCampaignId,
                });
              },
            },
            secondary: {
              text: locale.cancel,
              onClick: async () => {
                confirmModal.close();
              },
            },
          });
        },
      });
    }
  }
  if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
    actionOptions.push({
      content: locale.actionsHistory,
      onClick: async () => {
        confirmModal.show({
          title: `${locale.actionsHistory}`,
          content: (
            <div>
              <CircularProgress />
            </div>
          ),
          primary: null,
          secondary: null,
        });
        const res = await getVoucherCampaignHistoryRequest.request({
          voucherCampaignId,
        });
        const data = [];
        res.forEach(({ action, updatedBy, updatedByEmail, updatedAt }) => {
          const map = new Map();
          map.set("action", capitalize(action));
          map.set("modifiedBy", updatedByEmail || updatedBy);
          map.set("date", formatDate(updatedAt, DateTime.G));
          data.push(map);
        });
        confirmModal.show({
          title: `${locale.actionsHistory}`,
          content: (
            <div>
              {data.length ? (
                <Table
                  columns={[
                    {
                      key: "action",
                      text: locale.action,
                      style: {
                        minWidth: "70px",
                        textAlign: "left",
                      },
                    },
                    {
                      key: "modifiedBy",
                      text: locale.modifiedBy,
                      style: {
                        minWidth: "70px",
                        textAlign: "left",
                      },
                    },
                    {
                      key: "date",
                      text: locale.date,
                      style: {
                        minWidth: "70px",
                        textAlign: "left",
                      },
                    },
                  ]}
                  data={data}
                />
              ) : (
                <div>
                  <Image src={GuyWithMagGlass} />
                  <Title small>{locale.sorryNoResultFound}</Title>
                </div>
              )}
            </div>
          ),
          primary: null,
          secondary: null,
        });
      },
    });
  }

  return {
    voucherCampaignId: <div>{voucherCampaignId}</div>,
    name: campaignName,
    voucherPrefix,
    campaignGroup: prettifyVoucherCampaignGroup(voucherCampaignGroup),
    voucherType: `${prettifyVoucherStatusType(voucherType)} ${
      voucherSubType ? `(${prettifyVoucherTypeSetting(voucherSubType)})` : ""
    }`.trim(),
    claimMethod: prettifyVoucherClaimMethod(claimMethod),
    totalValue: (
      <LabelAndValue label={formatNumber(totalVoucherCount, 0)}>
        {voucherType === VoucherType.PesoDiscount
          ? "--"
          : totalVoucherValue
          ? formatAmount(totalVoucherValue)
          : "--"}
      </LabelAndValue>
    ),
    totalClaimed: (
      <LabelAndValue label={formatNumber(totalClaimedVoucherCount, 0)}>
        {voucherType === VoucherType.PesoDiscount
          ? "--"
          : totalClaimedVoucherValue
          ? formatAmount(totalClaimedVoucherValue)
          : "--"}
      </LabelAndValue>
    ),
    totalUsed: (
      <LabelAndValue label={formatNumber(totalUsedVoucherCount, 0)}>
        {totalUsedVoucherValue ? formatAmount(totalUsedVoucherValue) : "--"}
      </LabelAndValue>
    ),
    status: (
      <LabelAndValue label={formatDate(updatedAt, DateTime.G)}>
        <Pill
          style={{
            marginBottom: "10px",
          }}
          grass={status === CampaignStatus.Ongoing}
          sky={status === CampaignStatus.Upcoming}
          cement={status === CampaignStatus.Ended}
          deepRed={status === CampaignStatus.Cancelled}
        >
          {capitalize(status)}
        </Pill>
      </LabelAndValue>
    ),
    date: (
      <LabelAndValue label={formatDate(endDate, DateTime.G)}>
        {formatDate(startDate, DateTime.G)}
      </LabelAndValue>
    ),
    campaignCreator: (
      <LabelAndValue label={formatDate(createdAt, DateTime.G)}>
        {createdByEmail?.trim() || createdBy || "-"}
      </LabelAndValue>
    ),
    actions: (
      <PopOverMenu options={actionOptions}>
        <MoreVert />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { campaignCreators, status, voucherType, ...fs } = filterState;

  let newCampaignCreators = "";
  if (!campaignCreators.isAll) {
    newCampaignCreators = [];
    campaignCreators.value.forEach((item) => {
      newCampaignCreators.push(item.value);
    });

    newCampaignCreators = newCampaignCreators.join(",");
  }
  return {
    ...fs,
    status: !status.isSelectedAll ? status.value.join(",") : null,
    voucherType: voucherType === "all" ? "" : voucherType,
    campaignCreators: newCampaignCreators,
  };
};

export const mapApiRequest = ({ voucherType: type = [], ...param }) => {
  const voucher = getVoucherTypeAndSubType(type) || {};

  return {
    ...param,
    voucherType: voucher.type,
    voucherSubType: voucher.subType,
  };
};

export const mapFormToRequest = (form) => {
  const {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    campaignGroup: voucherCampaignGroup,
    voucherPrefix,
    campaignDuration = {},
    voucherValue,
    claimMethod,
    voucherTypeSetting: voucherSubType,
    voucherAmount,
    campaignImage,
    recipients,
    uploadCSV,
    voucherDiscountValue,
    minimumSpend,
    discountCap,
    requiredReferenceStation,
    voucherValueMultiClaim,
    claimLimit,
    discountValue,
    voucherQuantity,
    billedTo,
    billedToOthers,
    remarks,
    employeeRemarks,
  } = form;
  const { startDate, endDate } = campaignDuration;

  const obj = {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    voucherSubType,
    voucherCampaignGroup,
    startDate: formatDate(startDate, DateTime.N),
    endDate: formatDate(endDate, DateTime.N),
    voucherPrefix,
    claimMethod,
    campaignImage,
    billedTo,
    remarks,
  };
  if (
    [VoucherTypeSetting.OneTimeClaim].includes(voucherSubType) &&
    voucherType === VoucherType.PesoVoucherCode
  ) {
    obj.voucherValues = voucherValue.map((item) => {
      return {
        value: parseNumber(item.amount.value),
        quantity: parseNumber(item.quantity.value),
      };
    });
  }

  if (
    VoucherTypeSetting.MultiClaimGeneric === voucherSubType &&
    voucherType === VoucherType.PesoVoucherCode
  ) {
    obj.voucherValues = [
      {
        quantity: parseNumber(claimLimit),
        value: parseNumber(voucherValueMultiClaim),
      },
    ];
  }
  if (
    voucherType === VoucherType.PesoVoucherCode &&
    voucherSubType === VoucherTypeSetting.AutoSeed &&
    recipients === VoucherRecipients.SelectedAccounts
  ) {
    obj.voucherValues = voucherAmount.map((item) => {
      return {
        value: item?.amount?.value ? parseNumber(item.amount.value) : 0,
        quantity: item?.quantity?.value ? parseNumber(item.quantity.value) : 0,
        mobileNumber: `+63${item.mobileNumber.value}`,
      };
    });
  }

  if (
    voucherSubType === VoucherTypeSetting.AutoSeed &&
    (recipients === VoucherRecipients.UploadCsv ||
      recipients === VoucherRecipients.UploadCsvWithUnregisteredUsers)
  ) {
    obj.data = uploadCSV[0];
    obj.isCsv = true;
    obj.isCsvUpload = true;
    obj.isIncludingUnregisteredUsers =
      recipients === VoucherRecipients.UploadCsvWithUnregisteredUsers;
  }
  if (voucherType === VoucherType.PesoDiscount) {
    obj.discountValueType = voucherDiscountValue;
    obj.minimumSpend = parseNumber(minimumSpend);

    obj.referenceStationIds = requiredReferenceStation.map(({ value }) => value).join(",");
    obj.discountCap = parseNumber(discountCap);
    obj.quantity =
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric
        ? parseNumber(claimLimit)
        : parseNumber(voucherQuantity);
    obj.voucherQuantity =
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric
        ? parseNumber(claimLimit)
        : parseNumber(voucherQuantity);
    if (voucherSubType !== VoucherTypeSetting.AutoSeed) {
      obj.voucherValues = [
        {
          quantity:
            voucherSubType === VoucherTypeSetting.MultiClaimGeneric
              ? parseNumber(claimLimit)
              : parseNumber(voucherQuantity),
          value: parseNumber(discountValue),
        },
      ];
    } else {
      obj.voucherValues = voucherAmount.map((item) => {
        return {
          quantity: item?.quantity?.value ? parseNumber(item.quantity.value) : 0,
          mobileNumber: `+63${item.mobileNumber.value}`,
        };
      });
    }

    obj.discountValue = parseNumber(discountValue);
  }

  if (billedToOthers) {
    obj.billedTo = billedToOthers;
  }

  if (
    voucherCampaignGroup === VoucherCampaignGroup.SPIAllocation ||
    voucherCampaignGroup === VoucherCampaignGroup.SPIAllowance
  ) {
    obj.employeeRemarks = employeeRemarks;
  }

  return {
    ...obj,
  };
};

export const mapFormToRequestEdit = (form) => {
  const {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    campaignGroup: voucherCampaignGroup,
    voucherPrefix,
    campaignDuration = {},
    claimMethod,
    voucherTypeSetting: voucherSubType,
    campaignImage,
    billedTo,
    billedToOthers,
    remarks,
    employeeRemarks,
  } = form;
  const { startDate, endDate } = campaignDuration;

  const obj = {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    voucherSubType,
    voucherCampaignGroup,
    startDate: formatDate(startDate, DateTime.N),
    endDate: formatDate(endDate, DateTime.N),
    voucherPrefix,
    claimMethod,
    campaignImage,
    billedTo,
    remarks,
    employeeRemarks,
  };

  if (billedToOthers) {
    obj.billedTo = billedToOthers;
  }

  return {
    ...obj,
  };
};
