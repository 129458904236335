import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PLBPayWithLOCQPayRedemptionModule from "modules/plb/pay-with-locqpay/pay-with-locqpay.module";

const PLBPayWithLOCQPayRedemptionPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={Path.PLBPayWithLOCQPayRedemption}
        component={PLBPayWithLOCQPayRedemptionModule}
      />
      <Redirect to={Path.PLBPayWithLOCQPayRedemption} />
    </Switch>
  );
};

export default PLBPayWithLOCQPayRedemptionPage;
