import React from "react";
import classNames from "classnames";
import styles from "./icon-notification-count.module.scss";
import { IconButton } from "@material-ui/core";

const IconNotificationCount = ({ className, onClick, style, icon: Icon, count }) => {
  return (
    <IconButton onClick={onClick}>
      <div className={classNames(className, styles.container)} style={style}>
        <div className={classNames(styles.content)}>
          <Icon className={classNames(styles.icon)} />
          {Boolean(count) && (
            <div className={classNames(styles.count)}>
              <span>{count}</span>
            </div>
          )}
        </div>
      </div>
    </IconButton>
  );
};

export default IconNotificationCount;
