import React from "react";
import { Button, Image, Title, Text } from "components/commons";
import Header from "components/commons/header/header";
import { CarUnauthorized } from "images";
import locale from "localization";
import styles from "styles/error-page.module.scss";
import { redirectTo } from "utils";
import { useMount } from "hooks";

const UnauthorizedPage = () => {

  useMount(() => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  });

  return (
    <div>
      <Header />
      <div className={styles.content}>
        <Image src={CarUnauthorized} />
        <Title small>{locale.notAuthorized}</Title>
        <Text subtitle>{locale.sorryYouAreNotAuhorized}</Text>
        <Button 
          primary
          className={styles.button}
          onClick={() => {
            redirectTo("/auth");
          }}>{locale.goToLogin}</Button>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
