import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useForm, useApi, useModal, useMount } from "hooks";
import { ErrorCode, FormMode, Path } from "enums";
import { initialState as formState } from "./voucher-campaign-details.state";
import VoucherDetailsFormModule from "./voucher-campaign-details-form.module";
import styles from "./voucher-campaign-details.module.scss";
import locale from "localization";
import { Loader } from "components/commons";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import { useHistory } from "react-router-dom";
import { createVoucherCampaign, getVoucher, updateVoucherCampaign } from "apis";
import { voucherCampaignFormPost, voucherCampaignFormGet } from "./voucher-campaign-form.utils";

const VoucherCampaignDetailsModule = ({ pageMode, voucherCampaignId, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [campaignStatus, setCampaignStatus] = useState();
  const {
    request: getVoucherRequest,
    loading: loadingDiscount,
    result: getVoucherResult,
  } = useApi({
    api: getVoucher,
    pageError: true,
    params: {
      voucherCampaignId,
    },
  });

  const form = useMemo(() => {
    let initialState = {};
    if (voucherCampaignId) {
      const data = getVoucherResult;

      if (data) {
        initialState = voucherCampaignFormGet(data);
        setCampaignStatus(data.status);
      }
    }
    return formState(initialState);
  }, [voucherCampaignId, getVoucherResult]);

  const confirmModal = useModal();
  const { close } = confirmModal;
  const history = useHistory();

  useMount(() => {
    if (voucherCampaignId) {
      getVoucherRequest();
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    applyFieldErrors,
    modifyForm,
    clearForm,
    getFormValues,
  } = useForm({
    initialState: form,
  });

  const addRequest = useApi({
    api: createVoucherCampaign,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateVoucherCampaign,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      voucherCampaignId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;

    handleRequest(
      async () => {
        close();

        await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        clearForm();
        if (addMode) {
          history.push(Path.Campaigns);
        } else {
          history.push(Path.ViewVoucherCampaign, {
            voucherCampaignId,
          });
        }
      },
      null,
      (err) => {
        if (err?.data?.errorCode === ErrorCode.VoucherCampaignPrefixAlreadyExist) {
          return applyFieldErrors({
            voucherPrefix: locale.voucherPrefixAlreadyExists,
          });
        } else if (err?.data?.errorCode === ErrorCode.VoucherCampaignNameAlreadyExist) {
          return applyFieldErrors({
            campaignName: locale.voucherCampaignNameAlreadyExists,
          });
        }
        return err.showError();
      }
    );
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();

    const payload = voucherCampaignFormPost(currentFormValues);
    confirmModal.show({
      title: addMode ? locale.createCampaign : locale.saveChangesQuestion,
      content: addMode
        ? locale.areYouSureProceedCreatingCampaign
        : locale.areYouSureSaveAllChangesCampaign,
      secondary: {
        text: addMode ? locale.cancel : locale.continueEditing,
      },
      primary: {
        text: addMode ? locale.yesCreateCampaign : locale.saveChanges,
        onClick: () => {
          submit(payload);
        },
      },
    });
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  const onDateRangeCb = useCallback(
    (name, { value }) => {
      const { startDate, endDate } = value;
      modifyField(fields.campaignDuration.name, {
        value: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    },
    [fields.campaignDuration.name, modifyField]
  );

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      setDisableUpdate(!isFormSubmittable);
    }
  }, [editMode, fields, isFormSubmittable, loading, setDisableUpdate, setUpdateLoading]);

  const stateForm = {
    viewMode,
    addMode,
    editMode,
    fields,
    isFormSubmittable,
    loading,
    modifyField,
    submitForm,
    modifyForm,
    onDateRangeCb,
    handleSubmit,
  };
  return (
    <div>
      <Loader open={loadingDiscount} />
      {!loadingDiscount && (
        <div className={styles.container}>
          {!viewMode && <ConfirmModal {...confirmModal} />}
          <VoucherDetailsFormModule campaignStatus={campaignStatus} {...stateForm} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(VoucherCampaignDetailsModule);
