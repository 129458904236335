import React from "react";
import MaterialModal from "@material-ui/core/Modal";
import styles from "./modal.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import Title from "../title/title";

const Modal = ({
  active,
  close,
  title,
  children,
  closable = true,
  className,
  removePadding = false,
  style,
}) => {
  return (
    <MaterialModal
      open={active}
      onClose={closable ? close : null}
      className={classNames(styles.container, { [`${styles.removePadding}`]: removePadding })}
    >
      <div className={classNames(styles.content, className)} style={style}>
        <div className={styles.header}>
          <div>
            {title ? (
              <Title small className={styles.title}>
                {title}
              </Title>
            ) : null}
          </div>
          {closable && (
            <div className={styles.close} onClick={() => close()}>
              <CloseIcon className={styles.icon} />
            </div>
          )}
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </MaterialModal>
  );
};

export default Modal;
