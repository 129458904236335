import { Autocomplete, Field } from "components/commons";
import React from "react";
import locale from "localization";
import { PLBStatus } from "enums";
import { prettifyPLBStatus } from "utils/pretty.utils";

const SelectPLBStatus = ({
  onChange,
  name,
  value,
  className,
  label = locale.plbStatus,
  labelClassName,
  fieldClassName,
  labelPosition,
  multiple,
  hasAll,
}) => {
  return (
    <Field
      className={className}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      childrenClassName={fieldClassName}
    >
      <Autocomplete
        value={value}
        name={name}
        placeholder={"Select"}
        multiple={multiple}
        hasAll={hasAll}
        options={[PLBStatus.NotOnboarded, PLBStatus.Active, PLBStatus.Deactivated].map((val) => ({
          label: prettifyPLBStatus(val),
          value: val,
        }))}
        onChange={(_, { value }) => onChange({ [name]: value })}
      />
    </Field>
  );
};

export default SelectPLBStatus;
