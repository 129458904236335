import { ApiPath } from "enums";
import { Get, Put, Post } from "utils";

export const getKYCSubmission = async ({ ...query }) => {
  const res = await Get(`${ApiPath.KYCSubmission}`, query);
  return res.data;
};

export const getKYCSubmissionById = async ({ id, ...query }) => {
  const res = await Get(`${ApiPath.KYCSubmission}/${id}`, query);
  return res.data;
};

export const updateKYCSubmissionById = async ({ id, ...query }) => {
  const res = await Put(`${ApiPath.KYCSubmission}/${id}`, query);
  return res.data;
};

export const batchKYCUpdate = async ({ data }) => {
  // let formData = new FormData();
  // formData.append("data", file);
  const res = await Post(`${ApiPath.KYCSubmission}/batch`, data);
  return res.data;
};
