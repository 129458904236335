import { CustomerStatus } from "enums";
import React from "react";
import { prettifyCustomerStatus } from "utils/pretty.utils";
import LabelAndValue from "../label-and-value/label-and-value";
import Pill from "../pill/pill";

const DisplayCustomerName = ({ data = {} }) => {
  const { firstName, lastName, mobileNumber, status } = data;

  return (
    <LabelAndValue label={mobileNumber}>
      <div
        style={{
          whiteSpace: "nowrap",
          wordBreak: "keep-all",
          display: "flex",
          alignItems: "center",
        }}
      >
        {`${firstName} ${lastName}`.trim()}{" "}
        {status === CustomerStatus.Deleted && (
          <Pill
            cement
            xsmall
            style={{
              marginLeft: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {prettifyCustomerStatus(status)}
          </Pill>
        )}
      </div>
    </LabelAndValue>
  );
};

export default DisplayCustomerName;
