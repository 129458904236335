import { ApiPath } from "enums";
import { Get, Post, Put, Delete } from "utils";

export const getUser = async () => {
  const res = await Get(`${ApiPath.GetUser}`);
  return res.data;
};

export const getUsers = async (query) => {
  const res = await Get(`${ApiPath.GetUsers}`, { ...query });
  return res.data;
};

export const createUser = async ({ email, portal, role }) => {
  const res = await Post(`${ApiPath.GetUserProfile}/invite`, { email, portal, role });
  return res.data;
};

export const updateUserRole = async ({ userAccessId, ...body }) => {
  const res = await Put(`${ApiPath.UpdateUserAccess}/${userAccessId}`, body);
  return res.data;
};

export const removeUser = async ({ userAccessId, ...body }) => {
  const res = await Delete(`${ApiPath.UpdateUserAccess}/${userAccessId}`, body);
  return res.data;
};
