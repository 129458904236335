import classNames from "classnames";
import React from "react";
import styles from "./pill.module.scss";

const Pill = ({
  children,
  grass,
  cheddar,
  sky,
  deepRed,
  cement,
  desertstorm,
  className,
  cyan,
  xsmall,
  style,
  purple,
  salsa,
}) => {
  return (
    <div style={style}>
      <span
        className={classNames(styles.pill, className, {
          [`${styles.grass}`]: grass,
          [`${styles.cheddar}`]: cheddar,
          [`${styles.deepRed}`]: deepRed,
          [`${styles.sky}`]: sky,
          [`${styles.cement}`]: cement,
          [`${styles.desertstorm}`]: desertstorm,
          [`${styles.cyan}`]: cyan,
          [`${styles.xsmall}`]: xsmall,
          [`${styles.purple}`]: purple,
          [`${styles.salsa}`]: salsa,
        })}
      >
        {children}
      </span>
    </div>
  );
};

export default Pill;
