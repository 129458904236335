import { PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { prettifyPayWithPesoStatus, prettifySettlementStatus } from "utils/pretty.utils";
import { formatAmount, formatDate } from "utils";
import { DateTime, SettlementStatus, Role } from "enums";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";

import styles from "../non-fuel.module.scss";

export const mapDataToListPLC = ({
  merchantPayments,
  handleVoidPayment,
  handleActionsHistory,
  role,
  getUserAccess,
}) => {
  const {
    merchantPaymentId,
    paidAt,
    userDetail = {},
    merchant = {},
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    voidedAt,
    voidedByEmail,
  } = merchantPayments;

  const { mobileNumber, lastName = "", firstName = "", email = "" } = userDetail;
  const { businessName, name } = merchant;

  const options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    if (status === PayWithPesoStatus.Success) {
      options.push({
        content: locale.voidTransaction,
        disabled: role === Role.Accounting,
        onClick: () =>
          handleVoidPayment({
            merchantPaymentId,
            amount,
            settlementStatus,
            settlementId,
          }),
      });
    } else if (status === PayWithPesoStatus.Voided) {
      options.push({
        content: locale.actionsHistory,
        onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
      });
    }
  }

  return {
    locqpayId: <div>{merchantPaymentId}</div>,
    createdAt: formatDate(paidAt, DateTime.G),
    name: (
      <LabelAndValue label={mobileNumber}>
        {`${firstName} ${lastName}`.trim() || email}
      </LabelAndValue>
    ),
    merchant: <LabelAndValue label={`${businessName}`}>{name}</LabelAndValue>,
    amount: formatAmount(amount),
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.R) : ""}
        pillProps={{
          sky: status === PayWithPesoStatus.Pending,
          deepRed: status === PayWithPesoStatus.Failed,
          grass: status === PayWithPesoStatus.Success,
          cement: status === PayWithPesoStatus.Voided,
        }}
        pillLabel={prettifyPayWithPesoStatus(status)}
      />
    ),
    settlementStatus: (
      <div>
        <LabelAndPill
          label={settlementId || "- -"}
          pillProps={{
            cyan: settlementStatus === SettlementStatus.ForSettlement,
            sky: settlementStatus === SettlementStatus.ForProcessing,
            grass: settlementStatus === SettlementStatus.Processed,
            deepRed: settlementStatus === SettlementStatus.Cancelled,
          }}
          pillLabel={prettifySettlementStatus(settlementStatus)}
        />
      </div>
    ),
    action:
      options.length > 0 ? (
        <PopOverMenu options={options}>
          <MoreVertIcon className={styles.icon} />
        </PopOverMenu>
      ) : (
        ""
      ),
  };
};

export const mapFilterToRequestPLC = (filterState) => {
  const { settlementStatuses, status, ...fs } = filterState;

  return {
    ...fs,
    settlementStatuses: !settlementStatuses?.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    status: !status?.isSelectedAll ? status.value.join(",") : null,
  };
};
