export const matchPumpPriceModalState = (state = {}) => {
  const {
    matchCurrentDieselPrice = {
      value: "0",
    },
    matchCurrentGas91Price = {
      value: "0",
    },
    matchCurrentGas95Price = {
      value: "0",
    },
    matchCurrentGas97Price = {
      value: "0",
    },
    matchAccountSpecificDiesel = {
      value: "0",
    },
    matchAccountSpecificGas91 = {
      value: "0",
    },
    matchAccountSpecificGas95 = {
      value: "0",
    },
    matchAccountSpecificGas97 = {
      value: "0",
    },
    matchRefSPumpPriceDiesel = {
      value: "0",
    },
    matchRefSPumpPriceGas91 = {
      value: "0",
    },
    matchRefSPumpPriceGas95 = {
      value: "0",
    },
    matchRefSPumpPriceGas97 = {
      value: "0",
    },
    isPriceDifferenceDiesel = {
      value: "0",
    },
    isPriceDifferenceGas91 = {
      value: "0",
    },
    isPriceDifferenceGas95 = {
      value: "0",
    },
    isPriceDifferenceGas97 = {
      value: "0",
    },
  } = state;

  return {
    matchCurrentDieselPrice: {
      name: "matchCurrentDieselPrice",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...matchCurrentDieselPrice,
    },
    matchCurrentGas91Price: {
      disabled: true,
      inputDisabled: true,
      name: "matchCurrentGas91Price",
      innerLabel: true,
      parseNumber: true,
      ...matchCurrentGas91Price,
    },
    matchCurrentGas95Price: {
      disabled: true,
      inputDisabled: true,
      name: "matchCurrentGas95Price",
      innerLabel: true,
      parseNumber: true,
      ...matchCurrentGas95Price,
    },
    matchCurrentGas97Price: {
      disabled: true,
      inputDisabled: true,
      name: "matchCurrentGas97Price",
      innerLabel: true,
      parseNumber: true,
      ...matchCurrentGas97Price,
    },
    matchRefSPumpPriceDiesel: {
      name: "matchRefSPumpPriceDiesel",
      inputDisabled: true,
      innerLabel: true,
      disabled: true,
      parseNumber: true,
      ...matchRefSPumpPriceDiesel,
    },
    matchRefSPumpPriceGas91: {
      name: "matchRefSPumpPriceGas91",
      inputDisabled: true,
      innerLabel: true,
      disabled: true,
      parseNumber: true,
      ...matchRefSPumpPriceGas91,
    },
    matchRefSPumpPriceGas95: {
      name: "matchRefSPumpPriceGas95",
      inputDisabled: true,
      innerLabel: true,
      disabled: true,
      parseNumber: true,
      ...matchRefSPumpPriceGas95,
    },
    matchRefSPumpPriceGas97: {
      name: "matchRefSPumpPriceGas97",
      inputDisabled: true,
      innerLabel: true,
      disabled: true,
      parseNumber: true,
      ...matchRefSPumpPriceGas97,
    },
    matchAccountSpecificDiesel: {
      name: "matchAccountSpecificDiesel",
      isUpdate: false,
      innerLabel: true,
      parseNumber: true,
      ...matchAccountSpecificDiesel,
    },
    matchAccountSpecificGas91: {
      name: "matchAccountSpecificGas91",
      isUpdate: false,
      innerLabel: true,
      parseNumber: true,
      ...matchAccountSpecificGas91,
    },
    matchAccountSpecificGas95: {
      name: "matchAccountSpecificGas95",
      isUpdate: false,
      innerLabel: true,
      parseNumber: true,
      ...matchAccountSpecificGas95,
    },
    matchAccountSpecificGas97: {
      name: "matchAccountSpecificGas97",
      isUpdate: false,
      innerLabel: true,
      parseNumber: true,
      ...matchAccountSpecificGas97,
    },
    isPriceDifferenceDiesel: {
      name: "isPriceDifferenceDiesel",
      showUpdate: false,
      ...isPriceDifferenceDiesel,
    },
    isPriceDifferenceGas91: {
      name: "isPriceDifferenceGas91",
      showUpdate: false,
      ...isPriceDifferenceGas91,
    },
    isPriceDifferenceGas95: {
      name: "isPriceDifferenceGas95",
      showUpdate: false,
      ...isPriceDifferenceGas95,
    },
    isPriceDifferenceGas97: {
      name: "isPriceDifferenceGas97",
      showUpdate: false,
      ...isPriceDifferenceGas97,
    },
  };
};
