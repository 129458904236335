import { Field, Filter, SearchField, DateRange, Autocomplete } from "components/commons";

import locale from "localization";
import { CampaignStatus, VoucherCampaignCurrency, VoucherCampaignType } from "enums";
import React, { useCallback } from "react";
import styles from "./voucher-campaign-filter.module.scss";

const VoucherFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  onStatusChange,
  resetFilter,
  submitFilter,
  filterCount,
  onDateRangeChange,
  onTypeChange,
  onCurrencyChange,
}) => {
  const { Upcoming, Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Generic, Unique } = VoucherCampaignType;
  const { Peso, Liter, Points } = VoucherCampaignCurrency;

  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  let checker = (arr, target) => target.every((v) => arr.includes(v));

  const onStatusChangeCb = useCallback(
    (name, { props }) => {
      const allItem = [Upcoming, Ongoing, Ended, Cancelled];

      let newStatus = filterState.status;
      const newValue = props.value;
      let index = newStatus.indexOf(newValue);

      if (index !== -1) {
        if (newValue === "all") {
          newStatus = [];
        } else {
          const allIndex = newStatus.indexOf("all");
          if (allIndex !== -1) {
            newStatus.splice(allIndex, 1);
          }
          index = newStatus.indexOf(newValue);
          newStatus.splice(index, 1);
        }
      } else {
        if (newValue === "all") {
          newStatus = ["all", Upcoming, Ongoing, Ended, Cancelled];
        } else {
          newStatus.push(props.value);
        }
      }
      if (checker(newStatus, allItem)) {
        newStatus = ["all", Upcoming, Ongoing, Ended, Cancelled];
      }

      return onStatusChange(newStatus);
    },
    [Cancelled, Ended, Ongoing, Upcoming, filterState.status, onStatusChange]
  );

  const onTypeChangeCb = useCallback(
    (name, { props }) => {
      const allItem = [Generic, Unique];

      let newType = filterState.type;
      const newValue = props.value;
      let index = newType.indexOf(newValue);

      if (index !== -1) {
        if (newValue === "all") {
          newType = [];
        } else {
          const allIndex = newType.indexOf("all");
          if (allIndex !== -1) {
            newType.splice(allIndex, 1);
          }
          index = newType.indexOf(newValue);
          newType.splice(index, 1);
        }
      } else {
        if (newValue === "all") {
          newType = ["all", Generic, Unique];
        } else {
          newType.push(props.value);
        }
      }
      if (checker(newType, allItem)) {
        newType = ["all", Generic, Unique];
      }

      return onTypeChange(newType);
    },
    [Generic, Unique, filterState.type, onTypeChange]
  );

  const onCurrencyChangeCb = useCallback(
    (name, { props }) => {
      const allItem = [Peso, Liter, Points];

      let newCurrency = filterState.currency;
      const newValue = props.value;
      let index = newCurrency.indexOf(newValue);

      if (index !== -1) {
        if (newValue === "all") {
          newCurrency = [];
        } else {
          const allIndex = newCurrency.indexOf("all");
          if (allIndex !== -1) {
            newCurrency.splice(allIndex, 1);
          }
          index = newCurrency.indexOf(newValue);
          newCurrency.splice(index, 1);
        }
      } else {
        if (newValue === "all") {
          newCurrency = ["all", Peso, Liter, Points];
        } else {
          newCurrency.push(props.value);
        }
      }
      if (checker(newCurrency, allItem)) {
        newCurrency = ["all", Peso, Liter, Points];
      }

      return onCurrencyChange(newCurrency);
    },
    [Peso, Liter, Points, filterState.currency, onCurrencyChange]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <div className={styles.content}>
          <Field
            className={styles.filterInput}
            label={locale.dateCreated}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={onDateRangeChangeCb}
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="status"
              value={filterState.status}
              options={[
                { label: locale.all, value: "all" },
                { label: locale.upcoming, value: Upcoming },
                { label: locale.ongoing, value: Ongoing },
                { label: locale.ended, value: Ended },
                { label: locale.cancelled, value: Cancelled },
              ]}
              onChange={onStatusChangeCb}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.type}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="type"
              value={filterState.type}
              options={[
                { label: locale.all, value: "all" },
                { label: locale.generic, value: Generic },
                { label: locale.unique, value: Unique },
              ]}
              onChange={onTypeChangeCb}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.currency}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="currency"
              value={filterState.currency}
              options={[
                { label: locale.all, value: "all" },
                { label: locale.peso, value: Peso },
                { label: locale.liter, value: Liter },
                { label: locale.points, value: Points },
              ]}
              onChange={onCurrencyChangeCb}
              multiple
            />
          </Field>
        </div>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={`${locale.voucherCampaignId}, ${locale.name}, ${locale.prefix}`}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          searchWithSymbols={true}
          restrict={false}
        />
      </Field>
    </div>
  );
};

export default VoucherFilter;
