import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PLCLOCQPayForFuelModule from "modules/plc/pay-with-peso/pay-with-peso.module";

const PLCLOCQPayForFuelPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PLCLOCQPayForFuel} component={PLCLOCQPayForFuelModule} />
      <Redirect to={Path.PLCLOCQPayForFuel} />
    </Switch>
  );
};

export default PLCLOCQPayForFuelPage;
