import locale from "localization";

export const columns = [
  { key: "redemptionId", text: locale.redemptionId },
  { key: "redemptionDate", text: locale.redemptionDate },
  { key: "userDetails", text: locale.nameAndMobileNumber },
  { key: "redemptionStation", text: locale.redemptionStation },
  { key: "productCode", text: locale.product },
  { key: "creditPrice", text: locale.creditPrice, width: "9%" },
  { key: "volume", text: locale.volume, width: "9%" },
  { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "10%" },
  { key: "status", text: locale.status, width: "10%" },
  { key: "remarks", text: locale.remarks, width: "5%" },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus}/${locale.settlementId}`,
    width: "12%",
  },
  { key: "options", text: locale.actions, width: "5%" },
];
