import locale from "localization";

export const doxNegotiatedDiscountsColumns = [
  {
    key: "name",
    text: `${locale.stationName} / ${locale.stationType}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "stationCode",
    text: locale.stationCode,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "negotiatedDiscount",
    text: locale.negotiatedDiscount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "updatedAt",
    text: locale.dateUpdated,
    style: {
      minWidth: "70px",
    },
  },
];
