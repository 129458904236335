import { ApiPath } from "enums";
import { Get } from "utils";

export const getVouchersUnique = async ({ ...query }) => {
  const res = await Get(ApiPath.UniqueVoucherClaims, query);
  return res.data;
};

export const getVouchersUniqueV2 = async ({ ...query }) => {
  const res = await Get(`${ApiPath.UniqueVoucherClaims}/v2`, query);
  return res.data;
};

export const getVouchersCampaignCreators = async ({ ...query }) => {
  const res = await Get(ApiPath.VoucherCampaignCreators, query);
  return res.data;
};

export const createVoucherCampaign = async ({ ...query }) => {
  const res = await Get(ApiPath.UniqueVoucherClaims, query);
  return res.data;
};
