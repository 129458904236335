import { Image, Modal, Text, Title, ActionButton } from "components/commons";
import React from "react";
import styles from "./success-modal.module.scss";
import locale from "localization";
import { CarFormSuccess } from "images";

const SuccessModal = ({
  title = locale.success,
  content = "",
  image = CarFormSuccess,
  primary,
  secondary,
  contentHighlight = [],
  ...state
}) => {
  const {
    text: primaryText = "Got It",
    onClick: primaryOnClick = () => {
      state.close();
    },
  } = primary || {};
  const {
    text: secondaryText = "Cancel",
    onClick: secondaryClick = () => {
      state.close();
    },
  } = secondary || {};
  return (
    <Modal {...state}>
      <div className={styles.container}>
        <Image src={image} className={styles.image} />
        <Title small className={styles.title}>
          {title}
        </Title>
        <Text className={styles.content}>
          <locale.Populate text={content} items={contentHighlight} />
        </Text>
      </div>
      <ActionButton
        right={secondary}
        center={!secondary}
        items={[
          ...(secondary
            ? [
                {
                  onClick: () => {
                    secondaryClick();
                    state.close();
                  },
                  text: secondaryText,
                  primary: false,
                },
              ]
            : []),
          {
            onClick: () => {
              primaryOnClick();
            },
            text: primaryText,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default SuccessModal;
