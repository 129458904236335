import classNames from "classnames";
import React from "react";
import { TextField } from "..";
import styles from "./inner-label-textfield.module.scss";

const InnerLabelTextfield = ({ prefix, disabled, ...props }) => {
  return (
    <TextField
      {...props}
      disabled={disabled}
      prefix={
        <div
          className={classNames(styles.labelContainer, {
            [`${styles.disabled}`]: disabled,
          })}
        >
          {prefix}
        </div>
      }
    />
  );
};

export default InnerLabelTextfield;
