import { Intro, BackLink, DatePicker, Text, Button, Divider } from "components/commons";
import { InputFile } from "components/field";
import { Path } from "enums";
import React, { useCallback, useState } from "react";
import styles from "./edit-wholesale-price.module.scss";
import EditStatusModal from "./edit-wholesale-price-status-modal";
import { useModal } from "hooks";
import { uploadWholesalePriceFile } from "apis";
import { useApi } from "hooks";
import locale from "localization";
import moment from "moment";

const allowedTypes = ["csv"];

const setDate = (date) => {
  const isFutureDate = moment(date).isAfter(moment());
  if (isFutureDate) {
    const futureDate = moment(date).add(6, "hours").format("lll");
    return futureDate;
  } else {
    return moment().format("lll");
  }
};

const EditWholesalePriceModule = () => {
  const statusModal = useModal();
  const [effectiveAt, setEffectiveDate] = useState(null);
  const [fileInfo, seFileInfo] = useState(null);

  const onDateRangeChangeCb = useCallback((name, { value }) => {
    const date = setDate(value.date);
    setEffectiveDate(date);
  }, []);

  const {
    request: uploadWholesaleRequest,
    loading: uploadingWholesales,
    result: uploadWholesalesResult = { message: [], errorCode: null, count: 0 },
  } = useApi({
    api: uploadWholesalePriceFile,
    pageError: true,
  });

  const handleUpload = useCallback(async () => {
    const params = { fileInfo, effectiveAt };

    await uploadWholesaleRequest({ params });

    statusModal.show();
  }, [fileInfo, statusModal, effectiveAt, uploadWholesaleRequest]);

  const getFileInfoCB = useCallback(
    (fileInfo) => {
      seFileInfo(fileInfo);
    },
    [seFileInfo]
  );

  const { message } = uploadWholesalesResult;
  const handleClose = () => {
    document.getElementById("btn-upload").value = "";
    setEffectiveDate(null);
    seFileInfo(null);
  };

  return (
    <div>
      <BackLink text={locale.wholesalePrice} path={Path.WholesalePrice} />
      <Intro title={locale.editWholesalePrice} />
      <div className={styles.inputFile}>
        <InputFile allowedTypes={allowedTypes} getFileInfo={getFileInfoCB} />
      </div>
      <Divider size={20} />
      <Text className={styles.text}>{locale.effectiveDate}</Text>
      <div className={styles.effectiveAt}>
        <DatePicker
          name="effectiveDate"
          placeholder={locale.selectDate}
          value={{
            date: effectiveAt,
          }}
          minDate={moment()}
          onChange={onDateRangeChangeCb}
        />
      </div>
      <div className={styles.saveButton}>
        <Button
          loading={uploadingWholesales}
          disabled={!fileInfo || !effectiveAt || uploadingWholesales}
          onClick={handleUpload}
          className={styles.button}
          primary
        >
          Save
        </Button>
      </div>

      <EditStatusModal
        data={message ? message : uploadWholesalesResult.data}
        onClose={handleClose}
        success={message}
        {...statusModal}
      />
    </div>
  );
};

export default EditWholesalePriceModule;
