export const locale = {
  all: "All",
  admin: "Admin",
  applyFilters: "Apply Filters",
  browseYourFile: "Browse your file",
  currentDate: "Current date",
  createdDate: "Created date",
  cancel: "Cancel",
  cancelText: "Cancel",
  clearAll: "Clear All",
  dashboard: "Dashboard",
  downloads: "Downloads",
  diesel: "Diesel",
  dateOfGeneration: "Date of generation",
  editWholesalePrice: "Edit Wholesale Price",
  depot: "Depot",
  editWholesalePricefailed: "Edit Wholesale Price failed!",
  effectiveDate: "Effective date",
  export: "Export",
  emailAddress: "Email Address",
  filters: "Filters",
  failed: "Failed",
  failedText: "Failed",
  gasName: "Gas {0}",
  gas91: "Gas 91",
  gas95: "Gas 95",
  gas97: "Gas 97",
  goToWholesalePrice: "Go to Wholesale Price",
  goBackToEditWholesalePrice: "Go back to Edit Wholesale Price",
  inventory: "Inventory",
  inventoryLimit: "Fuel Inventory Limit",
  inProgress: "inProgress",
  inProgressText: "In Progress",
  done: "done",
  doneText: "Done",
  logIn: "Log In",
  myApproval: "My Approval",
  noPageAvailable: "Sorry! no page available.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  pending: "Pending",
  pendingText: "Pending",
  pleaseCheckUploadedFile: "Please check uploaded file and try again.",
  pleaseSelectCSVFile: "Please select a .csv file",
  pricelocq: "PriceLOCQ",
  pricelocqConsumer: "PriceLOCQ for Consumer",
  pricelocqBusiness: "PriceLOCQ for Business",
  pricing: "Pricing",
  password: "Password",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  payments: "Payments",
  rememberMe: "Remember Me",
  replace: "Replace",
  sorryNoResultFound: "Sorry, no results found",
  weCouldNotFindResults: "We could not find a match for {0}.",
  pleaseTryADifferentOne: "Please try a different one.",
  to: "to",
  reportType: "Report type",
  seaoil: "SEAOIL",
  seaoilPortal: "SEAOIL Portal",
  theEnteredEmailPassIncorrect: "The entered email or password is incorrect",
  uploadCsvFile: "Upload CSV file.",
  viewAndTrackWholesalePrice: "View and track wholesale price.",
  wholesalePrice: "Wholesale Price",
  wholesalePriceSuccessfullySaved: "Wholesale Price Successfully saved!",
  wppid: "WPP ID",
  youMayGoToWholesalePrice: "You may go to Wholesale Price page to check your wholesale prices.",
  invalidCsvFormat: "Invalid csv format!",
  supplierWppDoesNotHaveThisProductCode: "Supplier wpp does not have this product code!",
  invalidSupplierWppValue: "Invalid supplier wpp value!",
  campaignsAndPrograms: "Campaigns and Programs",
  campaigns: "Campaigns",
  vouchers: "Vouchers",
  uniqueVouchers: "Unique Vouchers",
  viewAllUniqueVouchers: "View all unique vouchers.",
  campaignName: "Campaign name",
  mobileNumber: "Mobile Number",
  name: "Name",
  voucherCode: "Voucher Code",
  dateClaimed: "Date Claimed",
  status: "Status",
  selectStatus: "Select Status",
  valueCurrency: "Value/Currency",
  claimedBy: "Claimed By",
  startDateEndDate: "Start Date/End Date",
  dateClaimedDateUsed: "Date Claimed/Date Used",
  liter: "Liter",
  peso: "Peso",
  points: "Points",
  used: "Used",
  unclaimed: "Unclaimed",
  expired: "Expired",
  void: "Void",
  availableInApp: "Available in App",
  voucher: "Voucher",
  viewAndCreateVoucherCampaigns: "View and create voucher campaigns.",
  voucherCampaignId: "Voucher Campaign ID",
  prefix: "Prefix",
  value: "Value",
  currency: "Currency",
  type: "Type",
  generic: "Generic",
  unique: "Unique",
  quantityMaxRedemptions: "Quantity Max Redemptions",
  startDate: "Start Date",
  endDate: "End Date",
  date: "Date",
  action: "Action",
  upcoming: "Upcoming",
  ongoing: "Ongoing",
  ended: "Ended",
  cancelled: "Cancelled",
  viewDetails: "View Details",
  reactivate: "Reactivate",
  editDetails: "Edit details",
  voucherPrefixAlreadyExists:
    "Voucher prefix already exist. Please try a different voucher prefix.",
  voucherCampaignNameAlreadyExists:
    "Voucher campaign name already exist. Please try a different voucher campaign name.",
  createCampaign: "Create campaign?",
  saveChangesQuestion: "Save changes?",
  areYouSureProceedCreatingCampaign:
    "Are you sure you want to proceed with creating this campaign?",
  areYouSureSaveAllChangesCampaign:
    "Are you sure you want to save all changes made to this campaign?",
  continueEditing: "Continue editing",
  yesCreateCampaign: "Yes, Create Campaign",
  saveChanges: "Save Changes",
  basicInformation: "Basic Information",
  ofCharacters: "{0} / {1} Characters",
  campaignRules: "Campaign Rules",
  create: "Create",
  voucherCampaignDetails: "Voucher Campaign Details",
  shortDescription: "Short description",
  longDescription: "Long description",
  voucherPrefix: "Voucher prefix",
  campaignImage: "Campaign Image",
  voucherCurrency: "Voucher currency",
  campaignDuration: "Campaign duration",
  voucherType: "Voucher type",
  voucherValue: "Voucher value",
  voucherQuantity: "Voucher quantity",
  voucherCampaigns: "Voucher Campaigns",
  editVoucherCampaign: "Edit Voucher Campaign",
  save: "Save",
  edit: "Edit",
  createVoucherCampaign: "Create Voucher Campaign",
  cancelCampaign: "Cancel campaign",
  yesCancelIt: "Yes, Cancel It",
  reactivateCampaign: "Reactivate campaign",
  youAreAboutToReactivateCampaign: "You are about to reactivate {0}. Do you want to proceed?",
  yesReactivateIt: "Yes, Activate it",
  totalCampaignValueCurrency: "Total Campaign Value/Currency",
  voucherQuantityCapitalized: "Voucher Quantity",
  weCouldNotFindAMatchForSearchCriteria: "We could not find a match for your search criteria",
  youAreAboutToCancelCampaign: "You are about to cancel {0}. Do you want to proceed?",
  dateCreated: "Date Created",
  dateCreatedRange: "Date Created Range",
  settlementHistory: "Settlement History",
  station: "Station",
  dateProcessed: "Date Processed",
  settlementId: "Settlement ID",
  totalLiters: "Total Liter Volume",
  discountVoucherStatus: "Discount Voucher Status",
  totalDiscountVoucher: "Total Discount Voucher to Dealer",
  totalSettlementWithSeaoil: "Total Settlement with SEAOIL",
  dealerSettlementPayoutStatus: "Dealer Settlement Payout Status",
  totalDealerSettlementPayout: "Total Settlement Payout to Dealer",
  settlements: "Settlements",
  viewTrackSettlement: "View and track settlements",
  forProcessing: "For Processing",
  forSettlement: "For Settlement",
  settled: "Settled",
  processed: "Processed",
  cancelSettlement: "Cancel Settlement",
  cancelSettlementMessage: "You are about to cancel settlement ID {0}. Do you want to proceed?",
  back: "Back",
  yesCancelSettlement: "Yes, Cancel Settlement",
  settlementDetails: "Settlement Details",
  periodOfStatement: "Period of Statement",
  settelmentCancelSuccess: "Settlement successfully cancelled",
  cancelSettlementSuccessMessage: "You have successfully cancelled the settlement ID {0}",
  report: "Report",
  editSettlement: "Are you sure you want to save all changes to this Settlement?",
  updateStatus: "Update Status",
  processSettlement: "Process Settlement",
  processSettlementQuestion: "Process settlement?",
  processSettlementMessage:
    "You are about to process settlement for {0} from {1} to {2} Do you want to proceed?",
  successProcessSettlementMessage:
    "You can now proceed with the payment add update the settlement status in the payment history afterwards",
  goBackSettlements: "Go back to Settlements",
  goSettlementHistory: "Go to Settlement History",
  dateToBeSettled: "Date to be Settled",
  generateReport: "Generate Report",
  noDataYet: "No data yet",
  generateReportError: "Generate report error",
  noRedemptionToSettled: "There are no redemptions to be settled",
  redemptionStation: "Redemption Station",
  stationBusinessName: "Station Business Name",
  remarks: "Remarks",
  view: "View",
  stationBusinessNameRedemptionStation: "Settlement ID, Station Business Name, Redemption Station",
  detailHaveBeenSuccessfullyUpdated: "Details have been successfully updated.",
  exclamatedSuccess: "Success!",
  editVoucherStatus: `Are you sure you want to update Discount voucher status to "{0}"? You can't undo this action`,
  inbox: "Inbox",
  search: "Search",
  selectAnItemToRead: "Select an item to read",
  noItemSelectedPleaseSelectAnItem: "No item selected. Please select an item in the list.",
  weCouldNotFindAMatchForYou:
    "We could not find a match for your search criteria. Please try a different one.",
  youMayNowCreateMultipleDenomination:
    "You may now create Multiple Denomination Voucher Campaigns!",
  noNeedToCreateSeparateCampaigns:
    "No need to create separate campaigns for every denomination. Click learn more to see how it works.",
  learnMore: "Learn More",
  maybeLater: "Maybe Later",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, got it!",
  processOtherSettlements: "Process previous settlement ID first.",
  processOtherSettlementsContent:
    "There is a settlement ID that is still for processing for this station. Please process this settlement ID first before updating status of current settlement ID selected",
  asPdf: "Export PDF",
  asExcel: "Export Excel",
  accounts: "Accounts",
  fleetAccounts: "Fleet Accounts",
  fleetAccountList: "Fleet Account List",
  businessNameId: "Business Name/ID",
  natureOfBusiness: "Nature of Business",
  referenceStation: "Reference Station",
  redemptionStations: "Redemption Stations",
  pesoBalance: "Peso Balance​",
  literBalance: "Liter Balance​",
  dateOnboarded: "Date Onboarded",
  statusDate: "Status/Date​",
  actions: "Actions",
  active: "Active",
  deactivated: "Deactivated",
  viewAccountDetails: "View Account Details",
  viewPricingDetails: "View Pricing Details",
  reactivateFleetAccount: "Reactivate fleet account?",
  deactivateFleetAccount: "Deactivate fleet account?",
  deactivateFleetAccountContent:
    "You are about to deactivate {0}. This will temporarily prevent {0} from doing any transaction. Do you want to proceed?",
  reactivateFleetAccountContent:
    "You are about to reactivate {0}. This will enable {0}​ to do transactions again. Do you want to proceed?",
  accountUpdateSaveWithEmail:
    "An account activation instruction will be sent to the new email addresses. Please review the below changes before you continue.",
  addFleetAccount: "Add Fleet Account",
  viewTrackFleetAccounts: "View and track fleet accounts.",
  businessNameNatureOfBusiness: "Business Name, Nature of Business",
  deactivate: "Deactivate",
  businessInformation: "Business Information",
  pricingInformation: "Pricing Information ",
  allStations: "All Stations",
  refsPumpPriceAtAssessment: "RefS Pump Price at Assessment",
  agreedDiscounts: "Agreed Discounts",
  accuMovementSinceAssessment: "Accumulated Movement since Assessment",
  contractPriceAtOnboarding: "Contract Price at Onboarding",
  sdum: "SDUM",
  freight: "Freight",
  estimateMonthlyVolume: "Estimate Monthly Volume​",
  existingVolume: "Existing Volume​",
  continue: "Continue",
  priceAssessmentDate: "Price Assessment Date",
  businessAddress: "Business Address",
  searchOrSelect: "Search or select",
  province: "Province",
  city: "City",
  businessName: "Business Name",
  typesOfBusiness: "Type of Business",
  emailForFleetAccount: "Email Address (for fleet portal access)​",
  emailForFleetAccountHelperText: "Add email addresses (up to 5) separated by a comma or space.",
  refSStationType: "Reference Station Type",
  acquirerNameSample: "e.g AB4DE",
  uniqueFuelCodePrefix: "Unique Fuel Code Prefix",
  acquirerName: "Acquirer Name",
  acquirerEmail: "Acquirer Email",
  contactInformation: "Contact Information",
  businessTelephoneNumber: "Telephone Number",
  businessTelephoneNumberSample: "(02) XXXX - XXXX",
  businessRepresentativeName: "Business Representative​",
  representativeMobileNumber: "Representative’s Mobile Number​​",
  representativeMobileNumberSample: "09XXXXXXXXX​​",
  representativeEmail: "Representative’s Email Address​",
  areYouSure: "Are you sure?",
  updatedReferenceStationOnly:
    "You are updating the reference station without changing the following details.",
  pumpPrice: "Pump Price",
  pricingDetails: "Pricing Details",
  editPricingDetails: "Edit Pricing Details",
  onboardingPricingInfo: "Onboarding Pricing Information",
  showOnboardingInfo: "Show Onboarding Information",
  currentPricingInfo: "Current Pricing Information",
  accumulatedStationSpecificMovement: "Accumulated Station-Specific Movement since Assessment",
  accuSpecificMovement: "Accumulated Account-Specific Movement",
  contractPrice: "Current Contract Price",
  referenceStationType: "Reference Station Type",
  applyAccountSpecificMovement: "Apply account specific movement",
  applyUpdates: "Apply Updates",
  update: "Update",
  updateAll: "Update All",
  applyPricingMovement: "Apply Account Specific Movement",
  currentPumpPrice: "Current Pump Price",
  refSPumpPrice: "RefS Pump Price",
  accountSpecificMovement: "Account Specific Movement",
  proceedWithChanges: "Proceed with Changes",
  newPumpPrice: "New Pump Price",
  matchRefSPumpPrice: "Match pump price with RefS pump price",
  selectAll: "Select All",
  allRedemptionStations: "All Redemption Stations",
  pricingChanges: "Pricing Changes",
  auditTrailId: "Audit Trail ID",
  product: "Product",
  dateEdited: "Date Edited",
  periodCovered: "Period Covered",
  businessNameBusinessIDReferenceStation: "Business Name, Business ID, Reference Station",
  fleetPrices: "Fleet Prices",
  supplierWppLabel: "Supplier WPP",
  sdumf: "SDUMF",
  orum: "ORUM",
  totalDiscount: "Total Discount",
  virtualRefsPumpPrice: "Virtual RefS Pump Price",
  viewAndTrackChangesOnPlbInformation: "View and Track Changes on PLB account pricing information",
  applyPriceChangesToMultipleAccount: "Apply Price Changes To Multiple Accounts",
  oldDepot: "Old Depot",
  oldSdum: "Old SDUM",
  oldFreight: "Old Freight",
  oldDiscount: "Old Discount",
  discount: "Discount",
  oldReferenceStation: "Old Reference Station",
  oldRedemptionStation: "Old Redemption Station",
  stationType: "Station Type",
  downloadSingle: "Download",
  download: "Downloads",
  reportGenerated: "Generating your report",
  deleteReport: "Delete report?",
  deleteReportQuestion: "Are you sure you want to delete {0}? You can't undo this action.",
  totalMDRFee: "Total MDR Fee",
  totalPayPeso: "Total Pay with Peso Amount",
  monthAndYear: "Month and Year",
  totalPremium: "Total Premium Payout",
  month: "Month",
  year: "Year",
  premiumPayment: "Premium Payment",
  doxPremiumPayment: "DOX Premium Payout",
  monthlyPremiumPayment: "Monthly Premium Payout for PriceLOCQ Redemptions",
  monthlyPremiumPaymentDetails: "Monthly Premium Payout Details",
  viewAndTrackMonthlyPremium: "View and track monthly premium",
  stationAccountList: "Station Account List",
  stationName: "Station Name",
  stationCode: "Station Code",
  dateModified: "Date Modified",
  operatingHours: "Operating Hours",
  products: "Products",
  stationPortalStatus: "Station Portal Status",
  plcStatus: "PLC Status",
  plbStatus: "PLB Status",
  youGotNew: "You got {0} new {1}.",
  updates: "updates",
  viewAndTrackStationAccounts: "View and track station accounts.",
  addStation: "Add Station",
  failedToLoad: "Failed to load.",
  plcInformation: "PLC Information",
  plbPricingInformation: "PLB - Pricing Information",
  settlementInformation: "Settlement Information",
  depotLocation: "Depot Location",
  depotId: "Depot ID",
  productAvailability: "Product Availability",
  fullAddress: "Full Address",
  sorryThisStationCodeDoesNotExist: "Sorry, this station code does not exist.",
  sorryStationAlreadyInDatabase: "Sorry, this station is already in the database.",
  sorryStationHasInvalidType: "Sorry, the station has an invalid station type.",
  municipality: "Municipality",
  stationOpeningTime: "Station Opening Time",
  stationClosingTime: "Station Closing Time",
  latitude: "Latitude",
  longitude: "Longitude",
  stationEmailAddress: "Station Email Address",
  rtmName: "RTM Name",
  rtmEmailAddress: "RTM Email Address",
  initialOnboarding: "Initial Onboarding",
  mobileNumberReceiving: "Mobile Number for Receiving SMS Confirmation",
  plbMaxDiscount: "PLB Max Discount per Liter",
  bankAccountName: "Bank Account Name",
  bankAccountNumber: "Bank Account Number",
  nameOfBank: "Name of Bank",
  settlementEmail: "Settlement Email",
  onboardLater: "Onboard Later",
  onboardNow: "Onboard Now",
  pleaseInputAtleast5Decimal:
    "Please input at least 5 decimal places e.g.{0} for a more accurate display of location pin in the app",
  formatStationName: "Format: [Brand name] [Area/Village/Municipality] - [Province Name]",
  editStationDetails: "Edit Station Details",
  viewStationDetails: "View Station Details",
  stationDetails: "Station Details",
  onBoardNowinPLBandPLC: "Onboard Now in PLC and PLB?",
  onBoardNowinPLC: "Onboard Now in PLC?",
  onboardInPLC: "Onboard in PLC",
  onboardInPLB: "Onboard in PLB",
  onBoardNowinPLB: "Onboard Now in PLB?",
  onBoardNowinVIP: "Onboard Now in VIP?",
  plbOnboardText:
    "Once Onboarded, the Station will now be available in the PriceLOCQ Fleet Portal and in the Website’s Station List. Do you want to proceed?",
  plcOnboardText:
    "Once Onboarded, the Station will now be available in the PriceLOCQ App and in the Website’s Station List. Do you want to proceed?",
  plcPlbOnboardText:
    "Once Onboarded, the Station will now be available in the PriceLOCQ App, PriceLOCQ Fleet Portal and in the Website’s Station List. Do you want to proceed?",
  vipOnboardText:
    "Once Onboarded, the Station can now register VIP Cards, Award Points, and etc. Do you want to proceed?",
  yesOnboardNow: "Yes, Onboard now",
  stationHasOnboarded: "Station has been successfully onboarded.",
  goToStationAccountList: "Go to Station Account List",
  addAnotherStation: "Add AnotherStation",
  askSsaveChanges: "Save changes?",
  areYouSureYouWantToSave:
    "Are you sure you want to save all changes made to this Station details?",
  deactivateInPLC: "Deactivate in PLC",
  reactivateInPLC: "Reactivate in PLC",
  hideInPLC: "Hide in PLC",
  hideInVIP: "Hide in VIP",
  showInPLC: "Show in PLC",
  deactivateInPLB: "Deactivate in PLB",
  reactivateInPLB: "Reactivate in PLB",
  deactivateStationInPLC: "Deactivate Station in PLC?",
  onceDeactivatedTheStation:
    "Once Deactivated, the Station will be removed from the PriceLOCQ app and in the Website’s Station List, and the Reference Stations of affected users will be replaced with the nearest available Station. Do you want to proceed?",
  nearestStation: "Nearest Station",
  yesDeactivateIt: "Yes, Deactivate It",
  reactivateStationInPLC: "Reactivate Station in PLC?",
  reactivateDescription:
    "Reactivating the station will bring the station back to the PriceLOCQ App and in the Website’s Station List. Do you want to proceed?",
  deactivateStationInPLB: "Deactivate Station in PLB?",
  onceDeactivatedPLB:
    "Once Deactivated, the Station will be removed from the PriceLOCQ Fleet Portal and in the Website’s Station List.  Do you want to proceed?",
  reactivateStationInPLB: "Reactivate Station in PLB?",
  reactivatingStationPLB:
    "Reactivating the station will bring the station back to the Fleet Portal and in the Website’s Station List.  Do you want to proceed?",
  hideStationInPLC: "Hide Station in PLC?",
  hidingStation:
    "Hiding a station will remove the station in the Station Selection and Search Station Pages in the App and in the Website. Affected users will not have their RefS changed. Do you want to proceed?",
  yesHideIt: "Yes, Hide It",
  unhideStation: "Unhide Station in PLC?",
  unhideDesc:
    "Unhiding a station will display the station again in the website and in the search station list.",
  hideStationInVIP: "Hide Station in VIP?",
  hidingStationVIP:
    "Hiding a station will remove the VIP related modules in Station Portal. Do you want to proceed?",
  unhideStationVIP: "Unhide Station in VIP?",
  unhideDescVIP: "Unhiding a station will display VIP related modules in the Station Portal.",
  yesUnhideIt: "Yes, Unhide It",
  oilcoSettlementStatus: "OilCo Settlement Status",
  oilcoSettlementHistory: "OilCo Settlement History",
  oilcoSettlementDetails: "OilCo Settlement Details",
  oilcoSettlementId: "OilCo Settlement ID",
  totalOilcoSettlementReceivable: "Total OilCo Settlement Receivable/(Payable)",
  totalMOPSMovement: "Total MOPS Movement",
  totalNationwideMovement: "Total Nationwide Movement Since Buy",
  totalPriceMovementDifferential: "Total Price Movement Differential",
  totalLiterRedemption: "Total Liter Redemption",
  totalRedSMovement: "Total RedS Specific Movement Since Buy",
  totalOilcoShoulderedDiscount: "Total OilCo Shouldered Discount at Buy",
  totalOilcoShoulderedStation: "Total OilCo Shouldered Station Switch",
  totalOilcoSettlement: "OilCo Settlement Status",
  periodRange: "{0} to {1}",
  totalPriceLocqLiter: "Total PriceLOCQ Liter Volume",
  stationTypeChangedFromPriceTool:
    "Station type changed from Price Tool and needs station details reviewing",
  stationDisappeared: "Station disappeared in Price Tool.",
  okGotIt: "Ok, Got It",
  changedFromNtoNInPriceTool: "{0} changed from {1} to {2} in price tool.",
  depotCode: "Depot Code",
  sendActivationEmail: "Send Activation Email",
  askSendActivationEmail: "Send Activation Email?",
  youAreAboutToSendActivationEmailTo:
    "You are about to send an Activation Email to {0}. Do you want to proceed?",
  emailTemplate: "Email Template",
  testFuelCode: "Test Fuel Code",
  sendResendWelcomeEmail: "Send/Resend New Welcome Email",
  askSendResendWelcomeEmail: "Send/Resend New Welcome Email?",
  youAreAboutToSendWelcomeEmail:
    "You are about to send/resend a new Welcome Email to {0}. Do you want to proceed?",
  yesSendActivationEmail: "Yes, Send Activation Email",
  yesSendWelcomeEmail: "Yes, Send Welcome Email",
  exportConsolidated: "Export Consolidated File",
  downloadReport: "Download Report",
  fleetAccountDetails: "Fleet Account Details",
  editFleetAccount: "Edit Fleet Account",
  saveAndSendEmail: "Save and Send Email​",
  saveDetails: "Save Details",
  newUser: "New user",
  forResending: "For resending",
  removedUser: "Removed user",
  grossFloorMargin: "Gross Floor Margin",
  floorPrice: "Floor Price",
  reviewChangesBelow: "Please review the below changes before you continue.",
  stationHasNoDepot: "Station has no depot.",
  forActivation: "For Activation",
  addStationAndOnBoardLater: "Add Station and Onboard Later",
  youAreNowAddingThisStation:
    "You are now adding this station to the database, you can onboard them later anytime. Do you want to proceed?",
  yesOnboardLater: "Yes, Onboard Later",
  stationHasBeenAdded: "Station has been successfully added",
  yesProceed: "Yes, proceed",
  priceChangesSuccessfullySaved: "Price changes successfully saved!",
  editAccountPricesFailed: "Edit Account Prices Failed",
  goToPricingChanges: "Go to Pricing Changes",
  goBackToEditAccountPrices: "Go back to Edit Account Prices",
  youMayGoToPricingChangesPage:
    "You may go to the Pricing Changes page to check your account pricing information",
  accountType: "Account Type",
  doxNegotiatedDiscounts: "DOX Negotiated Discounts",
  viewAndMonitorNegotiatedDiscountsApprovedByDOXStationDealer:
    "View and monitor negotiated discounts approved by DOX Station Dealer",
  negotiatedDiscount: "Negotiated Discount",
  dateUpdated: "Date Updated",
  otherServices: "Other Services",
  accessAuthorization: "Access Authorization",
  userAccess: "User Access",
  monitorUserAccessToOilCoPortal: "Monitor user access to Oilco Portal",
  addUser: "Add User",
  lubeServOnWheelsBookings: "Lubeserv on Wheels",
  viewAndTrackLubeServOnWheelsBookingsTransaction: "View and track booking activities.",
  address: "Address",
  bookingID: "Booking ID",
  preferredDate: "Preferred Date",
  preferredTime: "Preferred Time",
  oilChangePackage: "Oil Change Package",
  vehicleModel: "Vehicle Model",
  vehicleYear: "Vehicle Year",
  inactive: "Inactive",
  companyGroup: "Company Group",
  unhideInPLC: "Unhide in PLC",
  unhideInVIP: "Unhide in VIP",
  goToVoucherList: "Go to Voucher Campaigns List",
  voucherCampaignHasSuccessfullyCreated: "Voucher Campaign has been successfully created.",
  totalValue: "Total Value",
  numberOfVouchers: "Number of Vouchers",
  totalClaimedValue: "Total Claimed Value",
  vouchersClaimed: "Vouchers Claimed",
  totalUsedValue: "Total Used Value",
  voucherUsed: "Vouchers Used",
  campaignCreationDate: "Campaign Creation Date",
  partiallyRedeemed: "Liter credits are fully or partially redeemed",
  minimumRedemption: "Minimum allowed redemption is 1 liter.",
  campaignVouchers: "Campaign Vouchers",
  claimedDate: "Claimed Date",
  claimedDateRange: "Claimed Date Range",
  viewVouchers: "View Vouchers",
  cancelCampaignDesc: "You are about to permanently cancel {0}. Do you want to proceed?",
  claimedMethod: "Claimed Method",
  voucherId: "Voucher ID",
  dateUsed: "Date Used",
  actionsHistory: "Actions History",
  modifiedBy: "Modified By",
  areYouSureYouWantToSaveCampaign:
    "Are you sure you want to save all changes made to this campaign?",
  others: "Others",
  voucherImage: "Voucher Image",
  claimMethod: "Claim Method",
  pesoVoucherCode: "Peso Voucher Code",
  pesoDiscountOnBuyFuelVoucherCode: "Peso Discount on Buy Fuel Voucher Code",
  autoCashIn: "Auto Cash-In",
  seedInVoucherTray: "Seed in Voucher Tray",
  quantity: "Quantity",
  addDenomination: "Add Denomination",
  oneTimeClaim: "One-time Claim",
  autoSeed: "Auto-Seed",
  multiClaimGeneric: "Multi-claim Generic",
  recipients: "Recipients",
  autoSeedToRecipient: "Auto-Seed to Recipient",
  selectedAccounts: "Selected Accounts",
  uploadCsv: "Upload CSV",
  addRow: "Add Row",
  browseYourFiles: "Browse Your Files",
  voucherAmount: "Voucher Amount",
  claimLimit: "Claim Limit",
  voucherDiscountValue: "Voucher Discount Value",
  percentDiscount: "Percent Discount",
  specificAmount: "Specific Amount",
  shoulderedBy: "Shouldered By",
  discountCap: "Discount Cap",
  minimumSpend: "Minimum Spend",
  requiredReferenceStation: "Required Reference Station",
  voidTransactionText:
    "Voiding this transaction will remove {0} from the account's liter balance and return {1} to their balance. Please select a reason for voiding below to proceed",
  voidTransactionSuccess:
    "This transaction has been voided and {0} has been successfully returned to account's peso balance.",
  totalSales: "Total Sales",
  totalDealerShoulderedAdjustment: "Total Dealer Shouldered Adjustment: Station Switch",
  manualRedemption: "Manual Redemption",
  manualRedeem: "Manual Redeem",
  mobileAccountNumber: "Mobile Account Number",
  redemptionDateTime: "Redemption Date/Time",
  proceedManualRedemption: "Proceed with manual redemption?",
  manualRedemptionQuestion: "Are you sure you want to proceed with this manual redemption?",
  manualRedemptionSuccess: "You have successfully executed a manual redemption.",
  userNotExist: "Sorry, this user does not exist.",
  literError: "Sorry, this user does not have enough liter balance for this particular product",
  requestId: "Request ID",
  authAmount: "Auth Amount",
  campaignGroup: "Campaign Group",
  campaignCreator: "Campaign Creator",
  campaignGroups: "Campaign Groups",
  campaignCreators: "Campaign Creators",
  editViewColumns: "Edit View Columns",
  add: "Add",
  userRole: "Role",
  lubeServe: "Lubeserv",
  marketing: "Marketing",
  operations: "Operations",
  retailPricing: "Retail Pricing",
  finance: "Finance",
  audit: "Audit",
  accounting: "Accounting",
  userCanNowAccessTheOilcoApp: "User can now access the Oilco App",
  addAnotherUser: "Add Another User",
  success: "Success",
  emailAddressExist: "This email address already exists.",
  anAccountActivationInstructionHasBeenSent:
    "An account activation instruction has been sent to the email address you've provided.",
  goToListOfUsers: "Go to List of Users",
  uponClickingSaveAndSendEmail:
    'Upon clicking "Save and send email", an account activation instruction will be sent to {0} and the user will have the {1} access. Do you want to proceed?',
  yesChangeRole: "Yes, Change the Role",
  youAreAboutChangeRoleUser: "You are providing the {1} access to {0}.",
  thisWillChangeAccessUser: "This will change the access of {0} . Do you want to proceed?",
  accessType: "Access Type",
  totalAdditionalOilcoShoulderedAdjustment: "Total Additional Oilco Shouldered Adjustment",
  totalMopsRemarks: "Total MOPS Remarks",
  totalOilcoSettlementReceivableRemarks: "Total OilCo Settlement Receivable/(Payable) Remarks",
  confirmPassword: "Confirm Password",
  activateAccount: "Activate Account",
  goToLogin: "Go to Login",
  yourAccountHasBeenActivated:
    "Your account has been successfully activated. You can now use your password to log in to your account.",
  expiredUsedActivationLinkToken:
    "The activation link has expired. Please contact your administrator to request for a new activation link.",
  customerId: "Customer ID",
  reactivateCampaignDesc:
    "You are about to reactivate this campaign. All voucher statuses prior to cancelling will be retained. Do you want to proceed?",
  expiredActivationLinkToken:
    "The activation link has expired. Please contact your administrator to request for a new activation link.",
  lubeServOnSite: "Lubeserv on Site",
  lubeServOnWheels: "Lubeserv on Wheels",
  lubeServSite: "LubeServ Site",
  totalMOPSMovementReceivablePayable: "Total MOPS Movement Receivable/(Payable)",
  totalNationwideMovementSinceBuy: "Total Nationwide Movement Since Buy",
  totalOilcoSettlementStatus: "Total OilCo Settlement Status",
  saveDetailsAndSendEmail: "Save details and send email?",
  remove: "Remove",
  youAreAboutRemoveUser: "{0} will lose access to OILCO Portal. Do you want to proceed?",
  yesRemoveUser: "Yes, Remove",
  successfullyRemoveUser: "{0} have been successfully removed.",
  successfullyUpdateUser: "{0} Details have been successfully updated.",
  removedUserQuestion: "Remove user?",
  creatorsNotes: "Creator's Notes",
  billedTo: "Billed To",
  firstName: "First Name",
  lastName: "Last Name",
  setPassword: "Set Password",
  use8CharWithMix:
    "Use 8 or more characters with a mix of uppercase and lowercase letters and numbers/symbols.",
  exportVouchers: "Export Vouchers",
  plcCashIn: "Cash In (PLC)",
  plbCashIn: "Cash In (PLB)",
  import: "Import",
  fuel: "Fuel",
  priceBuildUp: "Price Build-up",
  michargedDidNotIntendtoPayViaPeso: "Mischarged: Did not intend to pay via Peso",
  michargedWrongAmountInput: "Mischarged: Wrong Amount Input",
  mischargeWrongInputByUser: "Mischarged: Wrong Volume Input by User",
  mischargeWrongVolumeInput: "Mischarged: Wrong Volume Input (Tank Full before reaching Amount)",
  viewTrackRedemptions: "View, track redemption activities of your fleet.",
  plcRedemption: "Redemptions (PLC)",
  plcLOCQPayForFuel: "LOCQPay for Fuel (PLC)",
  plbPayWithLOCQPayRedemption: "Pay with LOCQPay Redemptions (PLB)",
  plbRedemption: "Redemptions (PLB)",
  redemptionId: "Redemption ID",
  locqPayForFuel: "LOCQPay for Fuel",
  viewAndTrackScanToPayLocqPay: "View and track Scan-to-Pay via LOCQPay transactions for fuel.",
  locqPayId: "LOCQPay ID",
  transactionDateRange: "Transaction Date Range",
  payWithLocqPay: "Pay with LOCQPay",
  viewAllPayWithLocqPayTransactions: "View Pay with LOCQPay transactions",
  locqPayID: "LOCQPay ID",
  settlementStatus: "Settlement Status",
  discountPerLiter: "Discount Per Liter",
  finalAmountPaid: "Final Amount Paid",
  orNumber: "OR Number",
  gasUpStationWithOutDash: "Gas Up Station",
  cardNumber: "Card Number",
  driversName: `Driver's Name`,
  driversId: "ID",
  plateNumber: "Plate Number",
  dateOfTransaction: "Date of Transaction",
  paymentAmount: "Payment Amount",
  locqpayIdAndBusinessNameBusinessIdCardNumber:
    "LOCQPay ID, Business Name, Business ID, Card Number",
  viewAllTrackRedemptions: "View and track redemption activities.",
  viewTrackCashActivities: "View and track cash in activities.",
  cashInId: "Cash In ID",
  referenceNumber: "Reference Number",
  cashInAmount: "Cash In Amount",
  cashInFeeLocq: "Cash In Fee (LOCQ)",
  cashInFeeCustomer: "Cash In Fee (Customer)",
  cashInChannelColumn: "Cash In Channel",
  cashInChannel: "Cash-In Channel",
  statusCashInDate: "Status / Cash In Date",
  cashInIdAndBusinessName: "Request ID, Cash In ID/Number, Business Name",
  cashInIdNumber: "Cash In ID/Number",
  nameAndMobileNumber: "Name/ Mobile Number",
  cashinMethod: "Cash In Method",
  cashInDate: "Cash In Date",
  redemptionDate: "Redemption Date",
  gasUpStation: "Gas-Up Station",
  paymentMethod: "Payment Method",
  discountedVolume: "Discounted Volume",
  pumpPriceAtPayment: "Pump Price at Payment",
  balance: "Balance",
  aveCreditPrice: "Ave. Credit Price",
  volume: "Volume",
  transactionDate: "Transaction Date",
  payWithPesoId: "Pay with Peso ID",
  creditPrice: "Credit Price (₱/L)",
  totalCreditAmount: "Total Credit Amount",
  syncWithPricingTool: "Sync with Pricing Tool",
  searchStationNameSationCode: "Search Station Name, Station Code",
  priceBuildup: "Price Build-up",
  viewSationPricesAndMargin: "View station prices and margin breakdown.",
  creditPriceHistory: "Credit Price",
  basementPrice: "Basement Price",
  grossBasementPrice: "Gross Basement Margin",
  any: "Any",
  stationNameStationType: "Station Name/Station Type",
  vehicle: "Vehicle",
  drumContainer: "Drum/Container",
  fuelCodeRedemptions: "Fuel Code Redemptions",
  redemptionIdAndBusinessNameFuelCodeORNumber:
    "Redemption ID, Station Business Name, Fuel Code, OR Number, Business Name",
  dispensedToPlateNumber: "Dispensed to/ Plate Number",
  fuelCode: "Fuel Code",
  volumeCreditPrice: "Volume/Credit Price(₱/L)",
  redemptionStationPumpPrice: "Redemption Station Pump Price",
  pointsEarned: "Points Earned",
  editRemarks: "Edit Remarks",
  voidTransaction: "Void Transaction",
  errorDetails: "Error Details",
  noDriver: "No Available Driver",
  liters: "Liters",
  redemption: "Redemption",
  voided: "Voided",
  reasonRemarks: "Reason/Remarks",
  voidingThisTransaction:
    "Voiding this transaction will return {0} to the account’s liter balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed.",
  voidingThisTransactionSettlementProcessing:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction is already in the process of completing settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessed:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction has already been processed for settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed.",
  transactionVoided: "Transaction successfully voided",
  thisTransactionHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to {1}",
  voidingThisPayment:
    "Voiding this transaction will return {0} to the account's LOCQPay balance and deduct the previously rewarded {1} rebate from the transaction. Input the reason below to proceed.",
  thisPaymentHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to account's LOCQPay balance",
  accountName: "Account Name",
  manualPaymaya: "Manual - Paymaya",
  manualBankDeposit: "Manual - Bank Deposit",
  card: "Card",
  onlineBanking: "Online Banking",
  onlineBank: "Online Bank",
  locqRebate: "LOCQ Rebate",
  bnpl: "BNPL",
  newCustomerVouchers: "New Customer Vouchers",
  referral: "Referral",
  ewallet: "E-Wallet",
  otcBank: "OTC",
  onlineBankBrankas: "Online bank (Brankas)",
  onlineBankDragonpay: "Online bank (Dragonpay)",
  gcash: "GCash",
  billease: "Billease",
  cybersource: "Cybersource",
  brankas: "Brankas",
  sevenEleven: "7-Eleven",
  BDO: "BDO",
  unionBank: "Unionbank",
  expressPay: "ExpressPay",
  ecpay: "ECPay",
  hlhuillier: "H Lhuillier",
  rDPawnshop: "RD Pawnshop",
  trueMoney: "TrueMoney",
  bpi: "BPI",
  BPI: "BPI",
  withholdingTaxRefund: "Withholding Tax Refund",
  shopeePay: "ShopeePay",
  dragonPay: "Dragonpay",
  aubAsiaUnitedBank: "AUB (Asia UnitedBank)",
  bankOfCommerce: "Bank of Commerce",
  bpiBankOfPhil: "BPI (Bank of the Philippine Islands)",
  chinabank: "Chinabank",
  eastwestBank: "EastWest Bank",
  landBank: "Landbank",
  mayBank: "Maybank",
  pnbPhilNatBank: "PNB (Philippine National Bank)",
  psBank: "PSBank",
  rcbcRizalCommercialBank: "RCBC (Rizal Commercial Banking Corp)",
  robinsonsBank: "Robinsons Bank",
  ucpb: "UCPB (United Coconut Planters Bank)",
  securityBank: "Security Bank",
  bdoNetworkBank: "BDO Network Bank",
  rebate: "Rebate",
  payWithPeso: "Pay with Peso",
  locqPayDiscount: "LOCQPay Discount",
  seaoilReciprocity: "Seaoil Reciprocity",
  introductoryPromoRebate: "Introductory Promo Rebate",
  dispensedTo: "Dispensed to",
  existingUsersOnly: "existing users only",
  includingUnregisteredNumbers: "including unregistered numbers",
  assigned: "Assigned",
  gead: "GEAD Operations",
  employeeRemarks: "Employee Remarks",
  consumer: "Consumer",
  biz: "BIZ",
  customerList: "Customer List",
  dateRegistered: "Date Registered",
  userId: "User ID",
  email: "Email",
  referenceStationSelected: "Reference Station / Selected Stations",
  uniqueReferalCode: "Unique Referral Code",
  vipCardNumber: "VIP Card Number",
  vipLoyaltyCardType: "VIP Loyalty Card Type",
  vipLoyaltyType: "VIP Loyalty Type",
  referralCode: "Referral Code",
  cardType: "Card Type",
  loyaltyType: "Loyalty Type",
  locqpayBalance: "LOCQPay Balance",
  vipPointBalance: "VIP Point Balance",
  dateOfLastUpdate: "Date of Last Update",
  deleted: "Deleted",
  blocked: "Blocked",
  deactivatedUserInitiated: "Deactivated",
  viewCashins: "View Cash-ins",
  viewBuyFuel: "View Buy Fuel",
  viewRedemptions: "View redemptions",
  viewReferrals: "View Referrals",
  viewPurchasedVouchers: "View Purchased Vouchers",
  editAttributes: "Edit Attributes",
  block: "Block",
  updateMobileNumber: "Update Mobile Number",
  youAreAboutBlockAccount:
    "You are about to block {0} account. This will temporarily prevent {1} from doing any transaction. Do you want to proceed?",
  youAreAboutReactivateAccount:
    "You are about to reactivate {0} account. This will allow {1} to perform transactions in the app. Do you want to proceed?",
  blockAccount: "Block account",
  reactivateAccount: "Reactivate account",
  reason: "Reason",
  existingPriceLOCQAccount: "Existing PriceLOCQ Account",
  mergeAccounts: "Merge Accounts",
  newMobileNumber: "New Mobile Number",
  attributes: "Attributes",
  addCustomAttribute: "Add Custom Attribute",
  onlyAlphaNumericAllowedAndSpecialV2:
    "Only Alphanumeric characters and the following special symbol ( - , . , ' , spaces ) are allowed.",
  physical: "Physical",
  virtual: "Virtual",
  wouldYouLikeToSaveTheseAttributeChanges: "Would you like to save these attribute changes?",
  currentMobileNumber: "Current Mobile Number",
  questionUpdateMobileNumber: "Update mobile number?",
  questionDoYouWantToMergeTwoAccounts: "Do you want to merge the two accounts?",
  youAreNowUpdatingTheMobileNumber: "You are now updating the mobile number of {0} ({2}) to {1}.",
  successfullyUpdatedMobile: "You have successfully updated the account's mobile number",
  successfullyMergedTwoAccounts: "You have successfully merged the two accounts",
  youAreNowMergingTwoAccounts:
    "Merging the two accounts will transfer any remaining balances from the account {0} ({2}) to the account {1} ({3}). Account Properties of the latter will be retained.",
  yesContinue: "Yes, Continue",
  cardManagement: "Card Management",
  viewAndTrackVIPCards: "View and track VIP cards",
  vipCardFilterSearch: "VIP Card Number, Loyalty Card ID",
  loyalCardID: "Loyalty Card ID",
  vipPoints: "VIP Points",
  assignedStationOrCaravan: "Assigned Station/Caravan",
  dateProcured: "Date Procured",
  stationsProcured: "Station/Caravan Procured",
  deactivateVIPCard: "Deactivate VIP Card?",
  activateVIPCard: "Activate VIP Card?",
  activateVIPCardContent: "Are you sure you want to activate VIP Card {0} {1} {2}?",
  deactivateVIPCardContent: "Are you sure you want to deactivate VIP Card {0} {1} {2}?",
  registered: "Registered",
  unregistered: "Unregistered",
  customerRequest: "Customer Request",
  fraudRelatedConcern: "Fraud Related Concern",
  enterOtherReasonHere: "Enter other reason here",
  number: "Number",
  successDeactivateCard: "You have successfully deactivated VIP Card Number {0}.",
  successActivateCard: "You have successfully activated VIP Card Number {0}.",
  vipStatus: "VIP Status",
  onboardInVIP: "Onboard in VIP",
  viewCustomerList: "Manage customer list",
  vipRedemption: "VIP Points Redemption",
  VIPHistoryAwardPoints: "/vip/transaction-history/award-points",
  VIPHistoryPayWithPoints: "/vip/transaction-history/pay-with-points",
  VIPHistoryRedeemPoints: "/vip/transaction-history/redeem-points",
  payWithPoints: "Pay with Points",
  awardPoints: "Award Points",
  redeemPoints: "Redeem Points",
  transactionHistory: "Transaction History",
  VIP: "VIP",
  viewVipPointsAwarding: "View VIP Points Awarding Transaction History",
  productType: "Product Type",
  vipTransactionId: "VIP Transaction ID",
  mobileWithHash: "Mobile #",
  transactionAmount: "Transaction Amount",
  manualAtStation: "Manual At Station",
  stationBranch: "Station Branch",
  manualPointsAwarding: "Manual Points Awarding ",
  awardType: "Award Type",
  awardingDate: "Awarding Date",
  awardVoidTitleQuestion: "Void awarding this points?",
  awardVoidContentQuestion:
    "Are you sure you would like to void awarded points to {0}? Please select reason for voiding below.",
  earningLimitExceedMessage: "You have exceeded maximum earning limit",
  transactionDateAndTime: "Transaction Date and Time",
  selectDateAndTime: "Select Date and Time",
  proceedWithManualAwarding: "Proceed with manual awarding?",
  proceedWithManualAwardingContent: "You are awarding {0} to customer {1}. Do you want to proceed?",
  successAwardedVIPpoints: "You have successfully awarded VIP Points!",
  payWithPointsHistory: "View Pay with Points Transaction History",
  pointsRedeemed: "Points Redeemed",
  viewRedeemedPoints: "View Redeemed Points Transaction History",
  rewardsRedeemed: "Rewards Redeemed",
  voidPointsRedemption: "Void Points Redemption?",
  voidPointsRedemptionContentQuestion:
    "Are you sure you would like to void redeemed points to {0}? Please select reason for voiding below.",
  mobileNoNotRecognized: "Mobile no. not recognized",
  conversion: "Conversion",
  manualAtAdmin: "Manual At Admin",
  lubricant: "Lubricant",
  lpg: "LPG",
  lubeServ: "Lubeserv",
  voidPayWithPointsTitle: "Void Pay with Points Redemption?",
  voidPayWithPointsContent:
    "Are you sure you would like to void redeemed points (Pay with Points) to {0}? Please select reason for voiding below.",
  kycSubmission: "KYC Submission",
  kycSubmissions: "KYC Submissions",
  viewAndTrackKYCSubmission: "View and track KYC submission activities.",
  kycId: "KYC ID",
  driverLicenseIdID: "Driver’s License ID",
  driverLicenseIdIDNumber: "Driver’s License ID Number",
  dateApplied: "Date Applied",
  riderId: "Rider ID",
  company: "Company",
  viewDetailsKYC: "View details",
  dateLastUpdated: "Date Last Updated",
  personalDocuments: "Personal Documents",
  approve: "Approve",
  reject: "Reject",
  companyMobileNumber: "Company Mobile Number",
  externalIds: "Grab X SEAOIL VIP Card or Grab ID",
  externalIdsMoveIt: "MoveIt Rider ID",
  externalIdsAAP: "AAP Membership ID",
  externalIdsFoodPanda: "Foodpanda Rider ID",
  gotIt: "Got It",
  approveKYCrequest: "Approve KYC request?",
  areYouSureAprroveKYC: "Are you sure you want to approve this KYC request?",
  yesApproveRequest: "Yes, Approve Request",
  rejectKYCRequest: "Reject KYC request?",
  areyouSureYouWantToRejectKYC:
    "Are you sure you want to reject this KYC request? To proceed, input below the reason or message that you would like to display to the user.",
  kycDetails: "KYC Details",
  personalInformation: "Personal Information",
  companyName: "Company Name",
  mebershipID: "Membership ID",
  uniqueID: "Unique ID",
  middleName: "Middle Name",
  rfidReloading: "RFID Reloading",
  viewAndTrackRFIDReloadTransaction: "View and track RFID reload transactions.",
  reloadDateRange: "Reload Date Range",
  rfidType: "RFID Type",
  autosweep: "Autosweep",
  reloadID: "Reload ID",
  rfidAccountNumber: "RFID Account Number",
  rfidReloadID: "RFID Reload ID",
  loadAmount: "Load Amount",
  customerFee: "Customer Fee",
  locqpayForNonFuelPLC: "LOCQPay for Non-Fuel (PLC)",
  locqpayForNonFuelPLB: "LOCQPay for Non-Fuel (PLB)",
  viewLocqpayForNonfuelTransactionsPLC: "View LOCQPay for Non-Fuel transactions",
  id: "ID",
  cardNo: "Card No.",
  purchaseOrderNo: "Purchase Order No.",
  voidingThisNonFuel:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed.",
  customerName: "Customer Name",
  merchantName: "Merchant Name",
  reloadDate: "Reload Date",
  transactionId: "Transaction ID",
  retry: "Retry",
  importSuccessful: "Import successful!",
  importFailed: "Import Failed (with Errors)",
  customerDoesNotExist: "Customer does not exist (row): ",
  errorInStatusMessage: "Error in Status/Message (row): ",
  yesRejectRequest: "Yes, Reject Request",
  fileUploadFailed: "File upload failed. We have detected errors in your entries.",
  nonFuelSearch: "LOCQPay ID, Business name, Business ID, Card No.",
  uploadingAnImportFileWill: "Uploading an import file will overwrite the existing information.",
  dragAndDropFilesToUploadOr: "Drag and drop files to upload, or",
  addFile: "Add File",
  viewLocqpayForNonfuelTransactions: "View LOCQPay for Non-Fuel transactions",
  viewAndTrackScanToPayViaLOCQPayTransactions:
    "View and track Scan-to-Pay via LOCQPay transactions.",
  voidingThisTransactionNonFuel:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed.",
  voidingThisTransactionSettlementProcessingNonFuel:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance. This transaction is already in the process of completing settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessedNonFuel:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance. This transaction has already been processed for settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed.",
};

export default Object.freeze(locale);
