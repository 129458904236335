import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { Text } from "..";
import styles from "./field.module.scss";

const Field = ({
  children,
  childrenClassName,
  label,
  horizontal,
  className,
  message,
  messages = [],
  error,
  labelPosition,
  labelClassName,
  childrenStyle,
  subLabel,
  loading,
  showErrorIfDirty,
  isDirty,
  style,
  labelStyle,
  textStyle,
}) => {
  return (
    <div
      className={classNames(className, styles.container, {
        [`${styles.horizontal}`]: horizontal,
        [`${styles.labelLeft}`]: labelPosition === "left",
      })}
      style={style}
    >
      {label && (
        <div style={labelStyle} className={classNames(styles.title, labelClassName)}>
          <Text label style={textStyle}>
            {label}
          </Text>
          {subLabel && (
            <Text italic subtitle>
              {subLabel}
            </Text>
          )}
        </div>
      )}
      <div style={childrenStyle} className={classNames(styles.formContainer, childrenClassName)}>
        {loading ? <Skeleton /> : children}
        {message && (
          <Text
            className={classNames(styles.message, {
              [`${styles.error}`]: error,
            })}
          >
            {message}
          </Text>
        )}
        {(showErrorIfDirty && error && Boolean(messages.length) && isDirty) ||
        (!showErrorIfDirty && Boolean(messages.length))
          ? messages.map((message) => {
              return (
                <Text
                  className={classNames(styles.message, {
                    [`${styles.error}`]: error,
                  })}
                >
                  {message}
                </Text>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Field;
