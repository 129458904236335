import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getMerchantPayments = async ({ ...query }) => {
  const res = await Get(`${ApiPath.MerchantPayments}`, query);
  return res.data;
};

export const voidMerchantPayment = async ({ ...body }) => {
  const res = await Post(`${ApiPath.MerchantPayments}/void`, body);
  return res.data;
};

export const getStationsNonFuel = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Station}`, query);
  return res.data;
};
