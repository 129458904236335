import { Cities } from "assets";
import { Autocomplete } from "components/commons";
import { useMemo } from "react";
import { sortByKeyName } from "utils/object.utils";

const SelectCities = ({ value, province, name, multiple, hasAll = false, onChange, ...props }) => {
  const options = useMemo(() => {
    const filteredCities = province
      ? Cities.filter((data) => {
          return data.province === province;
        })
      : Cities;
    const preparedData = filteredCities.map((p) => {
      return {
        label: p.name,
        value: p.name,
      };
    });
    return preparedData;
  }, [province]);

  return (
    <Autocomplete
      {...props}
      value={value}
      name={name}
      placeholder={"Select"}
      disabled={!province}
      multiple={multiple}
      hasAll={hasAll}
      options={options.sort(sortByKeyName("label"))}
      onChange={(_, { value }) => onChange(name, { value })}
    />
  );
};

export default SelectCities;
