import { ActionButton, Modal } from "components/commons";
import locale from "localization";
import styles from "./confirm-status-change-modal.module.scss";
import React, { useCallback } from "react";
const ConfirmEditMobileModal = ({
  name,
  title,
  content,
  userId,
  submitting,
  submit,
  params,
  ...modalState
}) => {
  const { close, active } = modalState;
  const submitCb = useCallback(() => {
    submit(params);
  }, [params, submit]);
  return (
    <Modal
      active={active}
      title={title}
      close={close}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        {content}
        <br />
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submitCb();
            },
            text: locale.yesContinue,
            loading: submitting,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmEditMobileModal;
