import locale from "localization";

export const stationColumn = [
  { key: "id", text: `${locale.stationName}/${locale.stationCode}` },
  { key: "name", text: `${locale.stationBusinessName}/${locale.stationType}` },
  { key: "dateModified", text: locale.dateModified },
  { key: "cityProvince", text: `${locale.city}/${locale.province}` },
  { key: "operatingHours", text: locale.operatingHours },
  { key: "products", text: locale.products },
  { key: "status", text: `${locale.stationPortalStatus}/${locale.date}` },
  { key: "plcStatus", text: `${locale.plcStatus}/${locale.date}` },
  { key: "plbStatus", text: `${locale.plbStatus}/${locale.date}` },
  { key: "vipStatus", text: `${locale.vipStatus}/${locale.date}` },
  { key: "actions", text: locale.actions },
  { key: "alert", text: "" },
];
