import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { isPesoValid, formatNumber, parseNumber, isAmountValid } from "utils";

const InputNumber = ({
  className,
  onChange,
  innerLabel,
  decimalPlaces = 2,
  isPriceValue,
  ...props
}) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (props?.max && value > props.max) {
        return;
      }

      if (isPriceValue && isAmountValid(value)) {
        onChange(name, { value });
      }

      if ((!isPriceValue && isPesoValid(value)) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, {
          value: `${formatNumber(value, decimalPlaces)}`,
        });
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: num || "0" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} />;
  }

  return <TextField {...componentProps} />;
};

export default InputNumber;
