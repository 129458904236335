import { ApiPath } from "enums";
import { Get } from "utils";

export const getPremiumPayoutByDate = async ({ ...query }) => {
  const res = await Get(`${ApiPath?.PremiumPayout}/period-date`, query);
  return res.data;
};

export const getPremiumPayout = async ({ ...query }) => {
  const res = await Get(`${ApiPath?.PremiumPayout}`, query);
  return res.data;
};

export const getPremiumPayoutById = async ({ id, ...query }) => {
    const res = await Get(`${ApiPath.PremiumPayout}/${id}`, query);
    return res.data;
  };