import { Field, Modal, Text, ActionButton, Autocomplete, TextField } from "components/commons";
import React from "react";
import locale from "localization";
import useForm from "hooks/useForm.v2";
import { sendStationEmail } from "apis/account-stations";
import { useApi, useMount } from "hooks";
import { activateModalState } from "./activation-modal.state";

const SendWelcomeEmail = ({ stationName, refresh, stationId, ...modal }) => {
  const form = useForm(activateModalState);
  const { fields, initializeForm } = form;
  const { emailTemplate = {}, testFuelCode = {} } = fields;

  const sendStationEmailRequest = useApi({
    api: sendStationEmail,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { loading } = sendStationEmailRequest;

  useMount(() => {
    initializeForm({}, true);
  });

  return (
    <Modal
      {...modal}
      title={locale.sendResendWelcomeEmail}
      style={{
        width: "600px",
      }}
    >
      <Text className="text-center">
        <locale.Populate
          text={locale.youAreAboutToSendWelcomeEmail}
          items={[<b>{stationName}</b>]}
        />
      </Text>

      <Field
        childrenStyle={{
          minWidth: "400px",
          maxWidth: "400px",
        }}
        {...emailTemplate}
      >
        <Autocomplete
          {...emailTemplate}
          options={[
            {
              value: "plc",
              label: "PLC Only",
            },
            {
              value: "plb",
              label: "PLB Only",
            },
            {
              value: "plc-plb",
              label: "PLC and PLB",
            },
            {
              value: "plb-activated",
              label: "PLC Only (PLB-activated)",
            },
            {
              label: "PLB Only (PLC-activated)",
              value: "plc-activated",
            },
            {
              label: "VIP Only",
              value: "vip",
            },
          ]}
        />
      </Field>
      {["plc-activated", "plb", "plc-plb"].includes(emailTemplate.value) && (
        <Field
          childrenStyle={{
            minWidth: "400px",
            maxWidth: "400px",
          }}
          {...testFuelCode}
        >
          <TextField
            {...testFuelCode}
            onChange={(name, { value }) => {
              testFuelCode.onChange(name, { value, errorType: "" });
            }}
          />
        </Field>
      )}

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modal.close();
              initializeForm({}, true);
            },
          },
          {
            text: locale.yesSendWelcomeEmail,
            disabled: !form.isFormSubmittable,
            loading,
            onClick: () => {
              form.onSubmit(async ({ emailTemplate, testFuelCode: code }) => {
                try {
                  await sendStationEmailRequest.request({
                    stationId,
                    emailTemplate,
                    testFuelCode: code,
                    isResend: true,
                  });
                  modal.close();
                  initializeForm({}, true);
                  refresh();
                } catch (e) {
                  const { errorCode } = e?.data || {};
                  const errors = {
                    S1013: () => {
                      testFuelCode.onChange(testFuelCode.name, {
                        value: code,
                        errorType: "invalid-code",
                      });
                    },
                  };
                  if (errors[errorCode]) {
                    errors[errorCode]();
                  } else {
                    e.showError();
                  }
                }
              });
            },
          },
        ]}
      />
    </Modal>
  );
};

export default SendWelcomeEmail;
