const AccountStatus = {
  Active: "active",
  Deactivated: "deactivated",
  Pending: "pending",
  Expired: "expired",
  ForActivation: "for-activation",
  Inactive: "inactive",
  Registered: "registered",
  UnRegistered: "unregistered",
};

export default AccountStatus;
