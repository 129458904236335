import React from "react";
import styles from "./account-details.module.scss";
import {
  prettifyAccountStatus,
  formatVolume,
  formatCurrency,
  formatNumber,
  formatPesoWithDecimalRange,
  isEmailValid,
} from "utils";
import { AccountStatus } from "enums";
import locale from "localization";
import {
  Field,
  TextField,
  Title,
  Dropdown,
  Autocomplete,
  Button,
  Pill,
  Text,
  DatePicker,
} from "components/commons";
import {
  SelectStation,
  InputAmount,
  InputLitre,
  InputChip,
  InputSwitch,
  SelectRedemptionStation,
} from "components/field";
import classNames from "classnames";
import PlatformType from "enums/platform-type";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";

const AccountDetailsFormModule = ({ stationPricings = [], ...stateForm }) => {
  const {
    switchStatus,
    viewMode,
    addMode,
    editMode,
    otherDetails,
    fields,
    isFormSubmittable,
    modifyField,
    loading,
    populateProvince,
    pronvinceData,
    clearProvince,
    cities,
    provinceKeyFilter,
    submitForm,
    handleSubmit,
    onChangeTypeOfBusinessCb,
    handleAddEmail,
    onDeleteEmailAdrresses,
    handleStatusChange,
    selectRedemptionStationCb,
    onChangeShortNameCb,
    onChangeTelephoneCb,
    inputRef,
    validatedField,
    modifyForm,
    computeAutoPopulatedData,
    loadingStationPricing,
    computeContractPrice,
    getPriceMovement,
  } = stateForm;

  if (editMode) {
    if (!fields.existingGas91Volume.value) fields.existingGas91Volume.value = formatNumber(0, 3);
    if (!fields.existingGas95Volume.value) fields.existingGas95Volume.value = formatNumber(0, 3);
    if (!fields.existingGas97Volume.value) fields.existingGas97Volume.value = formatNumber(0, 3);
    if (!fields.existingDieselVolume.value) fields.existingDieselVolume.value = formatNumber(0, 3);
  }

  return (
    <div className={styles.container}>
      <Title xsmall>{locale.businessInformation}</Title>
      {viewMode && (
        <Field className={styles.fieldLabel} label={locale.status} horizontal>
          <Pill
            grass={otherDetails.status === AccountStatus.Active}
            cheddar={otherDetails.status === AccountStatus.Pending}
            deepRed={otherDetails.status === AccountStatus.Deactivated}
            cement={otherDetails.status === AccountStatus.Expired}
          >
            {prettifyAccountStatus(otherDetails.status)}
          </Pill>
        </Field>
      )}
      {editMode && (
        <Field className={styles.fieldLabel} label={locale.status} horizontal>
          <InputSwitch
            label={prettifyAccountStatus(fields.status.value)}
            checked={switchStatus}
            onChange={handleStatusChange}
            disabled={
              fields.status.value === AccountStatus.Pending ||
              fields.status.value === AccountStatus.Expired ||
              loading
            }
          />
        </Field>
      )}
      <Field className={styles.fieldLabel} {...fields.businessName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessName.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.businessName} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.businessNature}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessNature.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.businessNature} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.businessType}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessType.value}</Text>
        ) : (
          <Dropdown
            disabled={loading}
            onChange={onChangeTypeOfBusinessCb}
            {...fields.businessType}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.businessAddress}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessAddress.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.businessAddress} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.province}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.province.value}</Text>
        ) : (
          <Autocomplete
            disabled={loading}
            options={pronvinceData}
            onChange={populateProvince}
            {...fields.province}
            onInputChange={clearProvince}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.city}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.city.value}</Text>
        ) : (
          <Autocomplete
            onChange={modifyField}
            options={cities}
            disabled={!provinceKeyFilter || loading}
            {...fields.city}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.emailAddresses}>
        {viewMode ? (
          <div className={styles.pillEmailAddressesContainer}>
            {fields.emailAddresses.value.map((e, i) => (
              <Pill desertstorm className={styles.emailAddressInPill} key={i}>
                <span>{e.emailAddress}</span>
                <br />
                <span
                  className={classNames({
                    [styles.active]: e.status === AccountStatus.Active,
                    [styles.pending]: e.status === AccountStatus.Pending,
                    [styles.expired]: e.status === AccountStatus.Expired,
                    [styles.deactivated]: e.status === AccountStatus.Deactivated,
                  })}
                >
                  {prettifyAccountStatus(e.status)}
                </span>
              </Pill>
            ))}
          </div>
        ) : (
          <InputChip
            disabled={loading || switchStatus}
            {...fields.emailAddresses}
            handlePushValue={handleAddEmail}
            maxIndexValue={5}
            onDelete={onDeleteEmailAdrresses}
            className={styles.emailAddresses}
            validator={(text) => {
              const isError = text ? !isEmailValid(text) : false;

              return {
                error: isError,
                message: isError ? locale.pleaseEnterAValidEmailAddress : "",
              };
            }}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.shortName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.shortName.value}</Text>
        ) : (
          <TextField
            ref={inputRef}
            disabled={loading}
            {...fields.shortName}
            onChange={onChangeShortNameCb}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.acquirerName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.acquirerName.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.acquirerName} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.acquirerEmail}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.acquirerEmail.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.acquirerEmail} onChange={modifyField} />
        )}
      </Field>
      <Title xsmall>{locale.contactInformation}</Title>
      <Field className={styles.fieldLabel} {...fields.businessTelephoneNumber}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessTelephoneNumber.value}</Text>
        ) : (
          <TextField
            disabled={loading}
            {...fields.businessTelephoneNumber}
            onChange={onChangeTelephoneCb}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.businessRepresentativeName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.businessRepresentativeName.value}</Text>
        ) : (
          <TextField
            disabled={loading}
            {...fields.businessRepresentativeName}
            onChange={modifyField}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.representativeMobileNumber}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.representativeMobileNumber.value}</Text>
        ) : (
          <TextField
            disabled={loading}
            {...fields.representativeMobileNumber}
            onBlur={validatedField}
            onChange={modifyField}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.representativeEmail}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.representativeEmail.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.representativeEmail} onChange={modifyField} />
        )}
      </Field>
      {!(editMode || viewMode) && (
        <>
          <Title xsmall>{locale.pricingInformation}</Title>
          <Field
            className={styles.fieldLabel}
            {...fields.fleetStations}
            label={fields.fleetStations.inputLabel}
          >
            {viewMode ? (
              <Text className={styles.fieldText}>
                {!fields.fleetStations.value.length ? (
                  <p className={styles.italic}>{locale.allStations}</p>
                ) : (
                  fields.fleetStations.value
                    .map((value) => {
                      return value.label;
                    })
                    .sort()
                    .join(", ")
                )}
              </Text>
            ) : (
              <SelectRedemptionStation
                {...fields.fleetStations}
                onChange={selectRedemptionStationCb}
                disabled={loading}
                params={{
                  platformType: PlatformType.PLB,
                }}
              />
            )}
          </Field>
          <Field
            className={styles.fieldLabel}
            {...fields.referenceStationId}
            label={fields.referenceStationId.inputLabel}
          >
            {addMode ? (
              <SelectStation
                {...fields.referenceStationId}
                onChange={computeAutoPopulatedData}
                disabled={loading}
              />
            ) : (
              <Text className={styles.fieldText}>{fields.referenceStationId.label}</Text>
            )}
          </Field>
          {addMode && (
            <>
              <Field
                className={styles.fieldLabel}
                {...fields.priceAssessmentDate}
                label={fields.priceAssessmentDate.label}
              >
                <DatePicker
                  {...fields.priceAssessmentDate}
                  disableEndDate={moment()}
                  onChange={computeAutoPopulatedData}
                />
              </Field>
            </>
          )}
          {fields.priceAssessmentDate.value && (editMode || viewMode) && (
            <Field
              className={styles.fieldLabel}
              {...fields.depotId}
              label={fields.priceAssessmentDate.label}
            >
              <Text className={styles.fieldText}>{fields.priceAssessmentDate.value || ""}</Text>
            </Field>
          )}
          {(editMode || viewMode) && (
            <Field className={styles.fieldLabel} {...fields.depotId} label={"Date Onboarded"}>
              <Text className={styles.fieldText}>{fields.dateTimeOnboarded.value || ""}</Text>
            </Field>
          )}
          <Field
            className={styles.fieldLabel}
            {...fields.referenceStationType}
            label={fields.referenceStationType.label}
          >
            <Text className={styles.fieldText}>
              {fields.priceAssessmentDate.value
                ? stationPricings[0]?.stationType || ""
                : otherDetails.referenceStationType || ""}
            </Text>
          </Field>
          <Field className={styles.fieldLabel} {...fields.depotId} label={"Depot"}>
            <Text className={styles.fieldText}>
              {fields.priceAssessmentDate.value
                ? stationPricings[0]?.depot.name || ""
                : otherDetails.depot || ""}
            </Text>
          </Field>
          <Field
            className={classNames(
              styles.fieldLabel,
              styles.fieldPricingLabel,
              styles.fieldPricingLabelPosition
            )}
            horizontal
          >
            <div className={classNames(styles.horizontalInput)}>
              <Title xsmall className={styles.gasTitle}>
                {locale.refsPumpPriceAtAssessment}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.agreedDiscounts}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.accuMovementSinceAssessment}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.contractPriceAtOnboarding}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.sdum}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.freight}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.estimateMonthlyVolume}
              </Title>
              <Title xsmall className={styles.gasTitle}>
                {locale.existingVolume}
              </Title>
            </div>
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.diesel}
            horizontal
          >
            <div className={styles.horizontalInput}>
              {loadingStationPricing ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.baseDieselPrice.value)}
                </Text>
              )}
              {addMode || editMode ? (
                <InputAmount
                  {...fields.discountDiesel}
                  onChange={(name, { value }) => {
                    if (editMode) {
                      modifyForm({
                        [name]: { value },
                      });
                    } else {
                      modifyForm({
                        [name]: { value },
                        contractPriceDiesel: {
                          value: computeContractPrice(
                            fields.baseDieselPrice.value,
                            fields.accuMovementDiesel.value,
                            value
                          ),
                        },
                      });
                    }
                  }}
                  className={styles.gasAmount}
                  disabled={loading}
                />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.discountDiesel.value)}
                </Text>
              )}
              {getPriceMovement?.loading ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.accuMovementDiesel.value)}
                </Text>
              )}
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.contractPriceDiesel.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.sdumDiesel.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatPesoWithDecimalRange(fields.freightDiesel.value, 4)}
              </Text>
              {addMode || editMode ? (
                <>
                  <InputLitre
                    {...fields.estimatedDieselVolume}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                  <InputLitre
                    {...fields.existingDieselVolume}
                    // value={formatNumber(fields.existingDieselVolume.value, 3)}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                </>
              ) : (
                <>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.estimatedDieselVolume.value)}
                  </Text>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.existingDieselVolume.value)}
                  </Text>
                </>
              )}
            </div>
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas91}
            horizontal
          >
            <div className={styles.horizontalInput}>
              {loadingStationPricing ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.baseGas91Price.value)}
                </Text>
              )}
              {addMode || editMode ? (
                <InputAmount
                  {...fields.discountGas91}
                  onChange={(name, { value }) => {
                    if (editMode) {
                      modifyForm({
                        [name]: { value },
                      });
                    } else {
                      modifyForm({
                        [name]: { value },
                        contractPriceGas91: {
                          value: computeContractPrice(
                            fields.baseGas91Price.value,
                            fields.accuMovementGas91.value,
                            value
                          ),
                        },
                      });
                    }
                  }}
                  className={styles.gasAmount}
                  disabled={loading}
                />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.discountGas91.value)}
                </Text>
              )}
              {getPriceMovement?.loading ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.accuMovementGas91.value)}
                </Text>
              )}
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.contractPriceGas91.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.sdumGas91.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatPesoWithDecimalRange(fields.freightGas91.value, 4)}
              </Text>
              {addMode || editMode ? (
                <>
                  <InputLitre
                    {...fields.estimatedGas91Volume}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                  <InputLitre
                    {...fields.existingGas91Volume}
                    // value={formatNumber(fields.existingGas91Volume.value, 3)}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                </>
              ) : (
                <>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.estimatedGas91Volume.value)}
                  </Text>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.existingGas91Volume.value)}
                  </Text>
                </>
              )}
            </div>
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas95}
            horizontal
          >
            <div className={styles.horizontalInput}>
              {loadingStationPricing ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.baseGas95Price.value)}
                </Text>
              )}
              {addMode || editMode ? (
                <InputAmount
                  {...fields.discountGas95}
                  onChange={(name, { value }) => {
                    if (editMode) {
                      modifyForm({
                        [name]: { value },
                      });
                    } else {
                      modifyForm({
                        [name]: { value },
                        contractPriceGas95: {
                          value: computeContractPrice(
                            fields.baseGas95Price.value,
                            fields.accuMovementGas95.value,
                            value
                          ),
                        },
                      });
                    }
                  }}
                  className={styles.gasAmount}
                  disabled={loading}
                />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.discountGas95.value)}
                </Text>
              )}
              {getPriceMovement?.loading ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.accuMovementGas95.value)}
                </Text>
              )}
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.contractPriceGas95.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.sdumGas95.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatPesoWithDecimalRange(fields.freightGas95.value, 4)}
              </Text>
              {addMode || editMode ? (
                <>
                  <InputLitre
                    {...fields.estimatedGas95Volume}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                  <InputLitre
                    {...fields.existingGas95Volume}
                    // value={formatNumber(fields.existingGas95Volume.value, 3)}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                </>
              ) : (
                <>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.estimatedGas95Volume.value)}
                  </Text>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.existingGas95Volume.value)}
                  </Text>
                </>
              )}
            </div>
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas97}
            horizontal
          >
            <div className={styles.horizontalInput}>
              {loadingStationPricing ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.baseGas97Price.value)}
                </Text>
              )}
              {addMode || editMode ? (
                <InputAmount
                  {...fields.discountGas97}
                  onChange={(name, { value }) => {
                    if (editMode) {
                      modifyForm({
                        [name]: { value },
                      });
                    } else {
                      modifyForm({
                        [name]: { value },
                        contractPriceGas97: {
                          value: computeContractPrice(
                            fields.baseGas97Price.value,
                            fields.accuMovementGas97.value,
                            value
                          ),
                        },
                      });
                    }
                  }}
                  className={styles.gasAmount}
                  disabled={loading}
                />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.discountGas97.value)}
                </Text>
              )}
              {getPriceMovement?.loading ? (
                <Skeleton style={{ width: "100%" }} />
              ) : (
                <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                  {formatCurrency(fields.accuMovementGas97.value)}
                </Text>
              )}
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.contractPriceGas97.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatCurrency(fields.sdumGas97.value)}
              </Text>
              <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                {formatPesoWithDecimalRange(fields.freightGas97.value, 4)}
              </Text>
              {addMode || editMode ? (
                <>
                  <InputLitre
                    {...fields.estimatedGas97Volume}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                  <InputLitre
                    {...fields.existingGas97Volume}
                    // value={formatNumber(fields.existingGas97Volume.value, 3)}
                    onChange={modifyField}
                    className={styles.gasAmount}
                    disabled={loading}
                  />
                </>
              ) : (
                <>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.estimatedGas97Volume.value)}
                  </Text>
                  <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                    {formatVolume(fields.existingGas97Volume.value)}
                  </Text>
                </>
              )}
            </div>
          </Field>
        </>
      )}

      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!isFormSubmittable}
          onClick={() => {
            submitForm(handleSubmit);
          }}
        >
          {locale.continue}
        </Button>
      )}
    </div>
  );
};
export default AccountDetailsFormModule;
