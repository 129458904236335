import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

import { Button, Pill, PopOverList, PopOverMenu, Text } from "components/commons";

import { AccountStatus, DateTime, Path, PLBTypes } from "enums";
import locale from "localization";
import {
  formatAmount,
  formatVolume,
  prettifyAccountStatus,
  prettifyFleetAccountType,
  formatNumber,
} from "utils";

import styles from "./account-list.module.scss";

export const mapDataToList = ({ fleets, history, handleUpdateStatus, getUserAccess }) => {
  const {
    businessName,
    shortName,
    businessNature,
    station,
    fleetStations,
    wallet,
    literBalance,
    inventoryLimit,
    createdAt,
    status,
    fleetId,
    updatedAt,
    accountType,
    plbType,
  } = fleets;

  const fleetStationsCount = fleetStations?.length;

  const accountDate = {
    active: moment(updatedAt).format(DateTime.G),
    deactivated: moment(updatedAt).format(DateTime.G),
    pending: "--",
  };

  let options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
    const viewDetails = [
      {
        content: locale.viewAccountDetails,
        onClick: () => {
          history.push(Path.ViewFleetDetailsById(fleetId));
        },
        // disabled: plbType === PLBTypes.PLB_LOCQPAY || plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO,
      },
      {
        content: locale.viewPricingDetails,
        onClick: () => {
          history.push(Path.ViewFleetPricingById(fleetId));
        },
        disabled: plbType === PLBTypes.PLB_LOCQPAY || plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO,
      },
    ];
    options.push(...viewDetails);
  }

  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    const modifyDetails = [
      {
        removable: true,
        content: locale.deactivate,
        disabled: status === AccountStatus.Deactivated || status === AccountStatus.Expired,
        onClick: () => {
          handleUpdateStatus(
            {
              fleetId,
              status: AccountStatus.Deactivated,
            },
            businessName
          );
        },
      },
      {
        removable: true,
        content: locale.reactivate,
        disabled:
          status === AccountStatus.Active ||
          status === AccountStatus.Pending ||
          status === AccountStatus.Expired ||
          status === AccountStatus.Inactive,
        onClick: () => {
          handleUpdateStatus(
            {
              fleetId,
              status: AccountStatus.Active,
            },
            businessName
          );
        },
      },
    ];
    options.push(...modifyDetails);
  }

  return {
    businessNameId: (
      <>
        <Text>{businessName}</Text>
        <Text className={styles.subBusinessId}>{shortName}</Text>
      </>
    ),
    businessNature,
    accountType: <Text>{prettifyFleetAccountType(accountType)}</Text>,
    referenceStation: station?.name,
    fleetStations: fleetStationsCount ? (
      <PopOverList
        list={fleetStations.map((fleet) => {
          return fleet.name;
        })}
      >
        <Button link>{fleetStationsCount}</Button>
      </PopOverList>
    ) : (
      <p className={styles.italic}>{locale.allStations}</p>
    ),
    pesoBalance: formatAmount(wallet?.walletBalance),
    literBalance: formatVolume(literBalance),
    inventoryLimit: formatNumber(inventoryLimit, 2),
    dateOnboarded: moment(createdAt).format(DateTime.G),
    statusDate: (
      <>
        <Pill
          grass={status === AccountStatus.Active}
          cheddar={status === AccountStatus.Pending}
          deepRed={status === AccountStatus.Deactivated}
          cement={status === AccountStatus.Expired}
          desertstorm={status === AccountStatus.Inactive}
        >
          {prettifyAccountStatus(status)}
        </Pill>
        <Text className={styles.subBusinessId}>{accountDate[`${status}`]}</Text>
      </>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { status, accountType, ...fs } = filterState;
  const newStatus = status !== "all" ? status : "";
  const accountTypeStatus = accountType !== "all" ? accountType : "";

  return {
    ...fs,
    status: newStatus,
    accountType: accountTypeStatus,
  };
};
