import { useCallback } from "react";
import useApi from "./useApi";
import useModal from "./useModal";
import locale from "localization";

const useExport = ({ api, reportType, mappedFilterState, hasModal }) => {
  const { request, loading } = useApi({ api, params: api.params });
  const successModalComponent = useModal();

  const exportReport = useCallback(
    async ({ fileType, redemptionStationId, startDate, endDate, settlementType, voucherCampaignId }) => {
      const filterState = {
        ...(fileType
          ? {
              fileType,
            }
          : {}),
        redemptionStationId,
        startDate,
        endDate,
        settlementType,
        voucherCampaignId,
        ...mappedFilterState,
      };
      delete filterState.page;
      delete filterState.perPage;

      const resp = await request({ ...filterState, reportType });
      if (hasModal) {
        //temporary
        successModalComponent.show({
          title: locale.exclamatedSuccess,
          content: locale.reportGenerated,
        });
      } else {
        window.open(resp?.downloadUrl, "_blank");
      }
    },
    [mappedFilterState, request, reportType, hasModal, successModalComponent]
  );

  return { exportReport, exporting: loading, successModalComponent, mappedFilterState };
};

export default useExport;
