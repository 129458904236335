import React, { useMemo } from "react";
import locale from "localization";
import Title from "../title/title";
import Text from "../text/text";
import styles from "./edit-view-column.module.scss";
import Checkbox from "../checkbox/checkbox";
import classNames from "classnames";
import Button from "../button/button";

const EditViewColumns = ({ excluded, setExcluded, columns = [], allColumns = [], onApply }) => {
  const availableColumns = useMemo(() => {
    if (allColumns.length) {
      return allColumns;
    }
    return columns;
  }, [allColumns, columns]);

  return (
    <div>
      <Title xsmall className={styles.title}>
        {locale.editViewColumns}
      </Title>
      <Text subtitle>{locale.selectTheAdditionalColumns}</Text>
      <div className={classNames(styles.content)}>
        {availableColumns.map((header, key) => {
          const included = !excluded.includes(header.key);
          const toBeExcluded = included
            ? [...excluded, header.key]
            : excluded.filter((val) => val !== header.key);
          return (
            <div key={key} className={classNames("flex items-center", styles.item)}>
              <Checkbox
                value={included}
                disabled={included && availableColumns.length === toBeExcluded.length}
                onChange={() => {
                  if (availableColumns.length !== toBeExcluded.length) {
                    setExcluded(
                      included
                        ? [...excluded, header.key]
                        : excluded.filter((val) => val !== header.key)
                    );
                  }
                }}
              />
              {header.text}
            </div>
          );
        })}
      </div>
      <div className={styles.submit}>
        <Button
          primary
          outline
          disabled={excluded.length === availableColumns.length}
          onClick={() => {
            onApply(
              availableColumns
                .filter((item) => {
                  return !excluded.includes(item.key);
                })
                .map((item) => item.key)
            );
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default EditViewColumns;
