import {
  prettifyPayWithPesoStatus,
  prettifyProduct,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { formatAmount, formatDate } from "utils";
import { DateTime, Products, SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOver, PopOverMenu, Product, Text } from "components/commons";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import moment from "moment";
import styles from "../non-fuel.module.scss";

export const mapDataToListPLB = ({
  merchantPayments,
  handleVoidPayment,
  handleActionsHistory,
  getUserAccess,
}) => {
  const {
    merchantPaymentId,
    paidAt,
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    voidedAt,
    voidedByEmail,
    fleet,
    driver,
    vehicle,
    cardNumber,
    poNumber,
    orNumber,
    stationName,
    stationBusinessName,
    remarks,
    transactionRemarks,
  } = merchantPayments;

  const options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    if (status === PayWithPesoStatus.Success) {
      options.push({
        content: locale.voidTransaction,
        onClick: () =>
          handleVoidPayment({
            merchantPaymentId,
            amount,
            settlementStatus,
            settlementId,
          }),
      });
    } else if (status === PayWithPesoStatus.Voided) {
      options.push({
        content: locale.actionsHistory,
        onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
      });
    }
  }

  return {
    paymentId: <div>{merchantPaymentId}</div>,
    createdAt: formatDate(paidAt, DateTime.G),
    businessNameID: (
      <LabelAndValue label={`${fleet?.shortName}`}>{fleet?.businessName}</LabelAndValue>
    ),
    redemptionStation: (
      <LabelAndValue label={`${stationBusinessName}`}>{stationName}</LabelAndValue>
    ),
    cardNumber: <div>{cardNumber}</div>,
    driver: (
      <div>
        {driver ? (
          <Text>{`${driver?.firstName} ${driver?.lastName}`} </Text>
        ) : (
          <Text>{locale.any}</Text>
        )}
        {driver ? (
          <Text className={styles.subBusinessId}>{driver?.driverLicenseId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>{locale.any}</Text>
        )}
      </div>
    ),
    plateNumber: (
      <div>{vehicle ? <Text>{`${vehicle?.plateNumber}`} </Text> : <Text>{locale.any}</Text>}</div>
    ),
    product: <Product purple>{prettifyProduct(Products.Lubes)}</Product>,
    amount: formatAmount(amount),
    poNumber: <div>{poNumber}</div>,
    orNumber: <div>{orNumber}</div>,
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.R) : ""}
        pillProps={{
          sky: status === PayWithPesoStatus.Pending,
          deepRed: status === PayWithPesoStatus.Failed,
          grass: status === PayWithPesoStatus.Success,
          cement: status === PayWithPesoStatus.Voided,
        }}
        pillLabel={prettifyPayWithPesoStatus(status)}
      />
    ),
    transactionRemarks: transactionRemarks,
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div className="link">View</div>
      </PopOver>
    ) : (
      ""
    ),
    transactionDate: (
      <div>
        <Text>{moment(paidAt).format(DateTime.P)}</Text>
      </div>
    ),
    settlementStatus: (
      <div>
        <LabelAndPill
          label={settlementId || "- -"}
          pillProps={{
            cyan: settlementStatus === SettlementStatus.ForSettlement,
            sky: settlementStatus === SettlementStatus.ForProcessing,
            grass: settlementStatus === SettlementStatus.Processed,
          }}
          pillLabel={prettifySettlementStatus(settlementStatus)}
        />
      </div>
    ),
    action: (
      <>
        {status === PayWithPesoStatus.Success && options.length > 0 ? (
          <PopOverMenu options={options}>
            <MoreVertIcon className={styles.icon} />
          </PopOverMenu>
        ) : (
          ""
        )}
      </>
    ),
  };
};

export const mapFilterToRequestPLB = (filterState) => {
  const { settlementStatuses, redemptionStation, status, ...fs } = filterState;

  return {
    ...fs,
    settlementStatuses: !settlementStatuses.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    status: !status.isSelectedAll ? status.value.join(",") : null,
    redemptionStationIds: redemptionStation?.value,
  };
};
