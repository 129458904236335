import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import React from "react";
import {
  prettifyRFIDPaymentMethod,
  prettifyRFIDStatus,
  prettifyRFIDType,
} from "utils/pretty.utils";
import styles from "./rfid-filter.module.scss";
import locale from "localization";
import RFIDStatus from "enums/rfid-status";
import RFIDPaymentMethod from "enums/rfid-payment-method";
import RFIDType from "enums/rfid-type";

const RFIDFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className="flex items-center">
          <Filter
            submit={applyFilter}
            clear={applyClearFilter}
            close={applyResetFilter}
            filterCount={filterCount}
          >
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.reloadDateRange}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            {[
              {
                value: filterState.type.value,
                name: "type",
                label: locale.rfidType,
                options: [
                  {
                    label: prettifyRFIDType(RFIDType.Autosweep),
                    value: RFIDType.Autosweep,
                  },
                  {
                    label: prettifyRFIDType(RFIDType.Easytrip),
                    value: RFIDType.Easytrip,
                  },
                ],
              },
              {
                value: filterState.paymentMethod.value,
                name: "paymentMethod",
                label: locale.paymentMethod,
                options: [
                  {
                    label: prettifyRFIDPaymentMethod(RFIDPaymentMethod.Card),
                    value: RFIDPaymentMethod.Card,
                  },
                  {
                    label: prettifyRFIDPaymentMethod(RFIDPaymentMethod.PesoBalance),
                    value: RFIDPaymentMethod.PesoBalance,
                  },
                ],
              },
              {
                value: filterState.status.value,
                name: "status",
                label: locale.status,
                options: [
                  {
                    label: prettifyRFIDStatus(RFIDStatus.Pending),
                    value: RFIDStatus.Pending,
                  },
                  {
                    label: prettifyRFIDStatus(RFIDStatus.Success),
                    value: RFIDStatus.Success,
                  },
                  {
                    label: prettifyRFIDStatus(RFIDStatus.Failed),
                    value: RFIDStatus.Failed,
                  },
                  {
                    label: prettifyRFIDStatus(RFIDStatus.Voided),
                    value: RFIDStatus.Voided,
                  },
                ],
              },
            ].map(({ name, options, value, label }) => {
              return (
                <Field
                  label={label}
                  labelPosition={"left"}
                  className={styles.fieldSelect}
                  childrenClassName={styles.fieldContent}
                >
                  <Autocomplete
                    name={name}
                    value={value}
                    hasAll
                    multiple
                    options={options}
                    onChange={(name, { value, isSelectedAll }) => {
                      modifyFilter({ [name]: { value, isSelectedAll } });
                    }}
                  />
                </Field>
              );
            })}
          </Filter>
          <Field className={styles.search}>
            <SearchField
              restrict={false}
              placeholder={[
                locale.reloadID,
                locale.rfidAccountNumber,
                locale.name,
                locale.mobileNumber,
                locale.referenceNumber,
                locale.orNumber,
              ].join(", ")}
              value={searchKey}
              onChange={(_, { value }) => modifySearch(value)}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default RFIDFilter;
