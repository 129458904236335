import { Path } from "enums";
import UserAccessModule from "modules/user-access/user-access.module";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const AccessAuthorizationPage = () => {
  return (
    <Switch>
      <Route exact path={Path.UserAccess} component={UserAccessModule} />
      <Redirect to={Path.AccessAuthorization} />
    </Switch>
  );
};

export default AccessAuthorizationPage;
