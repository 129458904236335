import locale from "localization";
import { isNumber } from "utils/text.utils";

export const onboardPLCState = {
  form: {
    mobileNumber: {
      label: locale.mobileNumberReceiving,
      maxLength: 10,
      type: "input",

      validations: {
        isRequired: true,

        isNumeric: true,
        custom: [
          (value) => {
            const error = value
              ? value[0] !== "9" || value.length !== 10 || !isNumber(value)
              : false;
            return {
              error,
              message: error ? "Invalid mobile number" : "",
            };
          },
        ],
      },
    },
    bankAccountName: {
      label: locale.bankAccountName,
      visible: false,
      validations: {},
    },
    bankAccountNumber: {
      label: locale.bankAccountNumber,
      visible: false,
      type: "input",
      validations: {
        isNumeric: true,
      },
    },
    nameOfBank: {
      label: locale.nameOfBank,
      visible: false,
      validations: {},
    },
    settlementEmail: {
      label: locale.settlementEmail,
      visible: false,
      validations: {
        isEmail: true,
      },
      placeholder: "business@email.com",
    },
  },
};
