import { PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { prettifyRole } from "utils/pretty.utils";
import styles from "./user-access.module.scss";

export const mapDataToList = ({
  users = {},
  handleUpdateRole,
  handleRemoveUser,
  getUserAccess,
}) => {
  const { user, role, userAccessId } = users;
  let options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    const modifyDetails = [
      {
        removable: true,
        content: locale.editDetails,
        onClick: () => {
          handleUpdateRole(
            {
              userAccessId,
              role,
            },
            user?.email
          );
        },
      },
      {
        removable: true,
        content: locale.remove,
        onClick: () => {
          handleRemoveUser(
            {
              userAccessId,
              role,
            },
            user?.email
          );
        },
      },
    ];
    options.push(...modifyDetails);
  }

  return {
    email: user?.email,
    role: prettifyRole(role),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
    role: fs.role !== "all" ? fs.role : "",
  };
};
