import { ErrorRounded } from "@material-ui/icons";
import React from "react";
import { useMemo } from "react";

const AlertIcon = ({ alertType, yellow, red }) => {
  const alertColor = {
    yellow: "#ffaa01",
    red: "#ee3b34",
  };

  const alert = useMemo(() => {
    if (alertType) {
      return alertType;
    }
    if (yellow) return "yellow";

    if (red) return "red";
  }, [alertType, yellow, red]);

  return (
    <div
      style={{
        width: "14px",
        height: "25px",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ErrorRounded
        style={{
          width: "15px",
          height: "15px",
          fontSize: "15px",
          color: alertColor[alert] || alertColor.yellow,
        }}
      />
    </div>
  );
};

export default AlertIcon;
