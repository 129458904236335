import locale from "localization";

export const nonFuelColumnsPLC = [
  {
    key: "locqpayId",
    text: locale.locqPayID,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "createdAt",
    text: locale.dateOfTransaction,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "name",
    text: `${locale.name} / ${locale.mobileNumber}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "merchant",
    text: `${locale.merchantName} / ${locale.customerName}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "amount",
    text: locale.paymentAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus} / ${locale.settlementId}`,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },
];
