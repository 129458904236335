import React from "react";
import { Text } from "..";

const LabelAndValue = ({ label, children, className }) => {
  return (
    <div className={className}>
      <Text small>{children}</Text>
      <Text subtitle>{label}</Text>
    </div>
  );
};

export default LabelAndValue;
