import { Text } from "components/commons";
import { DateTime } from "enums";
import React, { useEffect } from "react";
import { formatDate } from "utils";
import style from "./inbox.module.scss";

const InboxContent = ({ data = [], selectedMessage }) => {
  const message = data.find((item) => item.createdAt === selectedMessage);
  const { announcement: messageContent = {} } = message;
  const { subject, content, startDate } = messageContent;
  useEffect(() => {
    const item = document.getElementById("inbox-content");
    item.scrollTo(0, 0);
  }, [selectedMessage]);

  if (!data.length) {
    return null;
  }
  return (
    <div
      id="inbox-content"
      className={style.content}
      style={{
        padding: "0px 20px",
        overflowY: "auto",
      }}
    >
      <div>
        <div>
          <Text
            style={{
              fontSize: "14px",
              color: "#035783",
            }}
          >
            {subject}
          </Text>
          <Text
            subtitle
            style={{
              fontSize: "12px",
            }}
          >
            {formatDate(startDate, DateTime.H)}
          </Text>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

export default InboxContent;
