export const initialFilterState = () => ({
  stationTypes: {
    value: [
      "COCO",
      "CODO",
      "COXO",
      "DODO",
      "DODOX",
      "WIBCO",
      "WIDOX",
      "WILBO",
      "WILCO",    
    ],
    isAll: true 
  },
  referenceStation: {
    value: [],
    isAll: true,
  },
});