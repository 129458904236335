import { getWholesalePrice, generatePricingReportv2} from "apis";
import { DataTable, Intro } from "components/commons";
import { useApi, useFilter, useMount, useExport } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { wholesalePriceColumns } from "./wholesale-price-columns";
import WholesaleFilter from "./wholesale-price-filter";
import { wholesalePriceFilterState } from "./wholesale-price-filter.state";
import { formatAmount, dateTimeFormat } from "utils";
import { Path } from "enums";

import styles from "./wholesale-price.module.scss";
import locale from "localization";
import moment from "moment";
import { SuccessModal } from "components/modals";
import { UserContext } from "contexts";

const WholesalePriceModule = () => {
  const history = useHistory();

  const { sessionId: connectionId } = useContext(UserContext)

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(wholesalePriceFilterState());

  const {
    request: getWholesaleRequest,
    loading: fetchingWholesales,
    result: getWholesalesResult = { supplierWpps: [], count: 0 },
  } = useApi({
    api: getWholesalePrice,
    pageError: true,
  });

  useMount(() => {
    fetchWholesales(requestState);
  });

  const fetchWholesales = useCallback(
    async (rs) => {
      if (rs.depot) delete rs.depot;

      await getWholesaleRequest(submitFilter({ ...rs }));
    },
    [getWholesaleRequest, submitFilter]
  );

  const preparedWholesalesListtData = useMemo(() => {
    const { supplierWpps } = getWholesalesResult;

    if (supplierWpps.length > 0) {
      const preparedData = supplierWpps.map((s) => {
        const map = new Map();

        map.set("batchId", <span className={styles.batchId}>{s.batchId}</span>);
        map.set("depot", <span className={styles.depot}>{s.depot.name}</span>);
        map.set("created_date", dateTimeFormat(s.createdAt));
        map.set("effective_date", dateTimeFormat(moment(s.effectiveAt)));
        map.set("diesel", s.diesel ? formatAmount(s.diesel) : formatAmount(0));
        map.set("gas91", s.gas91 ? formatAmount(s.gas91) : formatAmount(0));
        map.set("gas95", s.gas95 ? formatAmount(s.gas95) : formatAmount(0));
        map.set("gas97", s.gas97 ? formatAmount(s.gas97) : formatAmount(0));
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getWholesalesResult]);

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });

      const newRequestState = prepareRequestState(requestState);

      fetchWholesales(newRequestState);
    },
    [modifyFilters, fetchWholesales]
  );

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate });
    },
    [modifyFilters]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      const newRequestState = prepareRequestState(requestState);
      fetchWholesales(newRequestState);
    },
    [modifyFilters, fetchWholesales]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchWholesales(newRequestState);
    },
    [fetchWholesales, modifyFilters]
  );

  const prepareRequestState = (requestState) => {
    const newRequestState = requestState;
    return newRequestState;
  };

  const exportReport = useExport({
    api: generatePricingReportv2,
    hasModal: true,
    reportType: "supplier-wpp",
    mappedFilterState: {...requestState, connectionId}
  })

  return (
    <div>
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro
          title={locale.wholesalePrice}
          subtitle={locale.viewAndTrackWholesalePrice}
          actionText={locale.editWholesalePrice}
          actionOnClick={() => {
            history.push(Path.EditWholesalePrice);
          }}
        />
      </div>
      <div className={styles.filters}>
        <WholesaleFilter
          filterState={filterState}
          onSearchChange={modifyFilter}
          onDateRangeChange={onDateRangeCb}
          onSearch={onSearchCb}
          onFieldChange={(name, { label, value }) => {
            modifyFilters({ depot: label, depotId: value, depotName: label });
          }}
          resetFilter={() => {
            modifyFilters({
              province: "all",
              city: "all",
              diesel: null,
              gas91: null,
              gas95: null,
              gas97: null,
              allProducts: false,
              ...submittedFilter,
            });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            const newRequestState = requestState;
            delete newRequestState.allProducts;
            fetchWholesales(newRequestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            const newRequestState = prepareRequestState(requestState);
            delete newRequestState.allProducts;
            fetchWholesales(newRequestState);
          }}
          filterCount={filterCount}
          {...exportReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingWholesales}
          page={filterState.page}
          columns={wholesalePriceColumns}
          data={preparedWholesalesListtData}
          dataCount={getWholesalesResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          searchKey={submittedFilter.searchKey}
          requestState={requestState}
        />
      </div>
    </div>
  );
};

export default WholesalePriceModule;
