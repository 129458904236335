import RFIDPaymentMethod from "enums/rfid-payment-method";
import RFIDStatus from "enums/rfid-status";
import RFIDType from "enums/rfid-type";

export const rfidFilterState = (fleetId) => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  status: {
    isSelectedAll: true,
    value: [RFIDStatus.Pending, RFIDStatus.Success, RFIDStatus.Failed, RFIDStatus.Voided],
  },
  paymentMethod: {
    isSelectedAll: true,
    value: [RFIDPaymentMethod.Card, RFIDPaymentMethod.PesoBalance],
  },
  type: { isSelectedAll: true, value: [RFIDType.Autosweep, RFIDType.Easytrip] },
  page: 1,
  perPage: 10,
});
