import moment from "moment";
import { Pill, Text } from "components/commons";
import { prettifyCashInChannel, prettifyCashInStatus } from "utils/pretty.utils";
import { formatAmount } from "utils";
import { CashInChannel, CashInStatus, DateTime } from "enums";

import styles from "./cashin.module.scss";
import { bankItems } from "enums/banks";
import BankProcessor from "enums/bank-processor";

// const displayCashinDate = (cashin) => {
//   const { status, fulfilledAt, expiresAt, createdAt } = cashin;
//   let date = null;
//   // todo: complete status date display when backend is ready
//   // need to finalize backend conditions in PRD
//   switch (status) {
//     case CashInStatus.Success:
//       date = fulfilledAt;
//       break;
//     case CashInStatus.Expired:
//       // issue encounter here, some "expired" status with expiresAt equal to null
//       date = expiresAt ? expiresAt : createdAt;
//       break;
//     default:
//       date = createdAt;
//   }
//   return moment(date).format(DateTime.G);
// };

export const mapDataToList = (cashins) => {
  const {
    cashinId,
    referenceNumber,
    amount,
    locqFee,
    customerFee,
    fleet,
    cashinChannel,
    status,
    mode,
    createdAt,
    paymentProcessor,
    remarks,
  } = cashins;
  const bank = bankItems[mode] || bankItems[paymentProcessor];
  const cashInMode = bank?.shortTitle || bank?.title || "";
  return {
    cashInId: cashinId,
    referenceNumber,
    remarks,
    amount: formatAmount(amount),
    cashInFeeLocq: formatAmount(locqFee),
    cashInFeeCustomer: formatAmount(customerFee),
    businessNameId: (
      <>
        <Text>{fleet?.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet?.shortName}</Text>
      </>
    ),
    cashInChannel: `${prettifyCashInChannel(cashinChannel)} ${
      cashInMode ? `(${cashInMode})` : ""
    }`.trim(),
    statusCashInDate: (
      <>
        <Pill
          sky={status === CashInStatus.Pending}
          deepRed={status === CashInStatus.Failed}
          grass={status === CashInStatus.Success}
          cement={status === CashInStatus.Expired || status === CashInStatus.Voided}
        >
          {prettifyCashInStatus(status)}
        </Pill>
        {/* <Text className={styles.subFulfilledDate}>{displayCashinDate(cashins)}</Text> */}
        <Text className={styles.subFulfilledDate}>{moment(createdAt).format(DateTime.G)}</Text>
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { cashinStatus, cashinChannel, ...fs } = filterState;
  const newCashinStatus = cashinStatus !== "all" ? cashinStatus : "";
  const newCashinChannel = cashinChannel !== "all" ? cashinChannel : "";

  const obj = {
    ...fs,
    cashinStatus: newCashinStatus,
    cashinChannel: newCashinChannel,
  };

  return obj;
};

export const apiMapper = (param) => {
  const obj = { ...param };
  if (
    [
      CashInChannel.OnlineBankDragonPay,
      CashInChannel.OnlineBankingBrankas,
      CashInChannel.OnlineBankBPI,
    ].includes(obj.cashinChannel)
  ) {
    obj.paymentProcessor =
      CashInChannel.OnlineBankBPI === obj.cashinChannel
        ? BankProcessor.BPI
        : CashInChannel.OnlineBankDragonPay === obj.cashinChannel
        ? BankProcessor.DragonPay
        : BankProcessor.Brankas;
    obj.cashinChannel = CashInChannel.OnlineBanking;
  }
  return { ...obj };
};
