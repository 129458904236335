import React, { useCallback, useState } from "react";

import {
  DateRange,
  Autocomplete,
  Title,
  Field,
  Pagination,
  Filter,
  Loader,
  Text,
  Image,
} from "components/commons";

import locale from "localization";
import styles from "./downloads.module.scss";
import DownloadCard from "components/commons/download-card/download-card";
import { useApi, useModal, useMount } from "hooks";
import { generateReportList, deleteReport } from "apis";
import { OilcoReports } from "enums";
import { prettifyReportType } from "utils";
import { useContext } from "react";
import { UserContext } from "contexts";
import { ConfirmModal } from "components/modals";
import { GuyWithMagGlass } from "images";

let filterStateValue = {
  perPage: 10,
  page: 1,
  startDate: null,
  endDate: null,
  reportType: [],
};

const filterState = {
  getPageState: () => ({ perPage: filterStateValue?.perPage, page: filterStateValue?.page }),
  setPageState: (value) => Object.assign(filterStateValue, value),
  getStartEndDate: () => ({
    startDate: filterStateValue?.startDate,
    endDate: filterStateValue?.endDate,
  }),
  setStartEndDate: (value) => Object.assign(filterStateValue, { ...value, page: 1 }),
  getStringReportType: () => filterStateValue?.reportType?.join(","),
  getReportType: () => filterStateValue?.reportType,
  setReportType: (value) =>
    (filterStateValue = { ...filterStateValue, reportType: value, page: 1 }),
};

Object.freeze(filterState);

const DownloadModule = () => {
  const { user } = useContext(UserContext);
  const confirmDeleteModal = useModal();
  const [reportType, setReportType] = useState([...Object.values(OilcoReports)]);
  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null,
  });

  const downloads = useApi({
    api: generateReportList,
  });

  const removeReport = useApi({
    api: deleteReport,
  });

  const onChangeRequest = useCallback(() => {
    downloads?.request({
      userId: user?.userId,
      reportTypes: filterState?.getStringReportType(),
      ...filterState?.getPageState(),
      ...filterState?.getStartEndDate(),
    });
    //eslint-disable-next-line
  }, [downloads]);

  const onChangePageSize = useCallback(
    (perPage) => {
      filterState?.setPageState({
        perPage,
        page: 1,
      });
      onChangeRequest();
    },
    [onChangeRequest]
  );

  const onChangePage = useCallback(
    (page) => {
      filterState?.setPageState({
        page,
      });
      onChangeRequest();
    },
    [onChangeRequest]
  );

  const applyClearFilter = useCallback(() => {
    filterState?.setStartEndDate({
      startDate: null,
      endDate: null,
    });
    filterState?.setReportType([...Object.values(OilcoReports)]);
    setReportType([...Object.values(OilcoReports)]);
    setStartEndDate({
      startDate: null,
      endDate: null,
    });
    onChangeRequest();
  }, [onChangeRequest]);

  const onDeleteReport = useCallback(
    async (reportId) => {
      const resp = await removeReport.request({ reportId });

      if (resp) {
        confirmDeleteModal?.close();
        onChangeRequest();
      }
    },
    [confirmDeleteModal, onChangeRequest, removeReport]
  );

  const confirmDelete = useCallback(
    async ({ fileName, reportId }) => {
      confirmDeleteModal.show({
        title: locale?.deleteReport,
        content: <locale.Populate text={locale.deleteReportQuestion} items={[<b>{fileName}</b>]} />,
        primary: {
          text: locale?.delete,
          onClick: () => {
            onDeleteReport(reportId);
          },
        },
        secondary: {
          text: locale?.cancel,
          onClick: () => confirmDeleteModal?.close(),
        },
      });
    },
    [onDeleteReport, confirmDeleteModal]
  );

  const reportTypeOptions = Object.values(OilcoReports).map((data) => ({
    label: prettifyReportType(data),
    value: data,
  }));

  useMount(() => {
    filterState?.setReportType([...Object.values(OilcoReports)]);
    onChangeRequest();
  });

  return (
    <>
      <Title>{locale.download}</Title>
      <ConfirmModal
        {...confirmDeleteModal}
        loading={removeReport?.loading}
        disabled={removeReport?.loading}
      />
      <div className={styles?.container}>
        <Filter submit={onChangeRequest} clear={applyClearFilter}>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.dateOfGeneration}
            className={styles.fieldLabel}
          >
            <DateRange
              value={{
                startDate: startEndDate?.startDate,
                endDate: startEndDate?.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                filterState?.setStartEndDate({
                  startDate,
                  endDate,
                });
                setStartEndDate({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            label={locale.reportType}
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              capitalize={false}
              placeholder={"Select"}
              multiple
              hasAll
              value={reportType}
              options={reportTypeOptions}
              onChange={(_, { value }) => {
                setReportType(value);
                filterState?.setReportType(value);
              }}
            />
          </Field>
        </Filter>
      </div>
      <div
        style={{
          padding: "0 25%",
        }}
      >
        {downloads?.loading ? (
          <Loader open={downloads?.loading} />
        ) : downloads?.result?.reports?.length === 0 ? (
          <div className={styles.noSearchResultRender}>
            <div>
              <Image src={GuyWithMagGlass} />
              <Title small>{locale.sorryNoResultFound}</Title>
              <Text subtitle>{locale.weCouldNotFindAMatchForSearchCriteria}</Text>
              <Text subtitle>{locale.pleaseTryADifferentOne}</Text>
            </div>
          </div>
        ) : (
          downloads?.result?.reports?.map((data, key) => (
            <DownloadCard
              data={data}
              key={key}
              reloadData={onChangeRequest}
              deleteReport={confirmDelete}
            />
          ))
        )}
      </div>
      <Pagination
        pageSize={filterState?.getPageState().perPage}
        page={filterState?.getPageState().page}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        dataCount={downloads?.result?.count}
      />
    </>
  );
};

export default DownloadModule;
