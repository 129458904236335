export const cardListFilterState = (searchKey) => ({
  startDate: null,
  endDate: null,
  startDateUpdated: null,
  endDateUpdated: null,
  searchKey: searchKey || "",
  loyaltyCardStatuses: "all",
  // accountType: "all",
  // stationIds: {
  //   value: null,
  //   label: "All",
  // },
  page: 1,
  perPage: 10,
});
