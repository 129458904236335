import React, { useContext } from "react";
import { Route, Switch } from "react-router";
import { Path } from "enums";
import VouchersPage from "./vouchers.page";
import ViewVoucherCampaign from "modules/voucher-campaign/view-voucher-campaign/view-voucher-campaign.module";
import EditVoucherCampaign from "modules/voucher-campaign/edit-voucher-campaign/edit-voucher-campaign.module";
import AddVoucherCampaign from "modules/voucher-campaign/add-voucher-campaign/add-voucher-campaign.module";
import { FeatureFlagContext } from "contexts";
import VoucherCampaign from "modules/voucher-campaign/v2/voucher-campaign.module";
import AddVoucher from "modules/voucher-campaign/v2/add-voucher";
import EditVoucher from "modules/voucher-campaign/v2/edit-voucher";
import ViewVoucher from "modules/voucher-campaign/v2/view-voucher";
import VoucherCampaignModule from "modules/voucher-campaign/voucher-campaign.module";

const CampaignsPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <>
      <Switch>
        <Route
          exact
          path={Path.VoucherCampaign}
          // component={VoucherCampaign}
          component={hasFeature("VOUCHER_V2") ? VoucherCampaign : VoucherCampaignModule}
        />
        <Route
          exact
          path={Path.Vouchers}
          // component={VoucherCampaign}
          component={VouchersPage}
        />
        <Route
          exact
          path={Path.AddVoucherCampaign}
          component={hasFeature("VOUCHER_V2") ? AddVoucher : AddVoucherCampaign}
        />
        <Route exact path={Path.EditVoucherCampaign} component={EditVoucherCampaign} />
        <Route exact path={Path.ViewVoucherCampaign} component={ViewVoucherCampaign} />
        {/* <Route exact path={Path.Vouchers} component={VouchersPage} />
      <Route exact path={Path.AddVoucherCampaign} component={AddVoucherCampaign} /> */}
        <Route exact path={Path.ViewVoucherCampaignDetails} component={ViewVoucher} />
        <Route exact path={Path.EditVoucherCampaignDetails} component={EditVoucher} />
        {/* <Route path={Path.Campaigns} component={VoucherCampaignModule} /> */}
        {/* <Redirect to={Path.Campaigns} /> */}
      </Switch>
    </>
  );
};

export default CampaignsPage;
