import React from "react";

import { BackLink, Intro } from "components/commons";
import { Path } from "enums";
import locale from "localization";
import { useMount, useRouter } from "hooks";

import styles from "./view-premium-payout.module.scss";

import ViewPremiumPayoutTable from "./view-premium-payout-table.module";
import ViewPremiumPayoutForm from "./view-premium-payout-form.module";

const ViewPremiumPayout = () => {
  const {
    history,
    query: { year, month },
  } = useRouter();

  useMount(() => {
    if (!month && !year) {
      history.push(Path.PremiumPayout);
    }
  });

  return (
    <>
      <div className={styles.container}>
        <BackLink text={locale.monthlyPremiumPayment} path={Path.PremiumPayout} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.monthlyPremiumPaymentDetails}
          />
        </div>
        <ViewPremiumPayoutForm month={month} year={year} />
        <ViewPremiumPayoutTable month={month} year={year} />
      </div>
    </>
  );
};

export default ViewPremiumPayout;
