import { Field, Filter, SearchField, DateRange, Autocomplete, Button } from "components/commons";
import { SelectStation } from "components/field";
import locale from "localization";
import { CustomerStatus, VipType } from "enums";
import React from "react";
import styles from "./customer-list-filter.module.scss";
import classNames from "classnames";
import { prettifyVipType } from "utils/pretty.utils";

const CustomerFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  openImport,
}) => {
  const { Active, Deactivated, Deleted, Blocked } = CustomerStatus;

  return (
    <div className={classNames(styles.filter, "flex items-center justify-between")}>
      <div className="flex items-center justify-between">
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={applyResetFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.filterInput}
            label={locale.dateRegistered}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="status"
              value={filterState.status.value}
              options={[
                { label: locale.active, value: Active },
                { label: locale.deactivatedUserInitiated, value: Deactivated },
                { label: locale.deleted, value: Deleted },
                { label: locale.blocked, value: Blocked },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
              hasAll
              multiple
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.updateStartDate,
                endDate: filterState.updateEndDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  updateStartDate: startDate,
                  updateEndDate: endDate,
                });
              }}
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.referenceStation}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <SelectStation
              {...filterState?.redemptionStation}
              onChange={(_, { value, label }) => {
                modifyFilter({ redemptionStation: { value, label } });
              }}
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.vipLoyaltyType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="vipType"
              {...filterState?.vipType}
              value={filterState.vipType.value}
              options={[
                { label: prettifyVipType(VipType.Consumer), value: VipType.Consumer },
                { label: prettifyVipType(VipType.Rides), value: VipType.Rides },
                { label: prettifyVipType(VipType.Biz), value: VipType.Biz },
                { label: prettifyVipType(VipType.Public), value: VipType.Public },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
              hasAll
              multiple
            />
          </Field>
        </Filter>

        <Field className={styles.search}>
          <SearchField
            placeholder={`${locale.mobileNumber}, ${locale.name}, ${locale.email}, ${locale.vipCardNumber}`}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
            restrict={true}
            searchWithSymbols={true}
          />
        </Field>
      </div>
      <div className={styles.downloadButton}>
        {openImport !== null ? (
          // <Button
          //   startIcon={<span className={classNames("icon-download", styles.import)} />}
          //   importButton
          //   onClick={openImport}
          // >
          //   {locale.import}
          // </Button>
          <></>
        ) : (
          <div></div>
        )}
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </div>
    </div>
  );
};

export default CustomerFilter;
