import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { TextField, Text, Checkbox } from "..";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import styles from "./dropdown-paginated.module.scss";
import { debounced } from "utils";
import { useMount } from "hooks";

const DropdownPaginated = ({
  name,
  value,
  label,
  onChange,
  request,
  loading,
  total,
  formatRequest,
  placeholder,
  isMultiple,
  allLabel,
  allSelectedText,
  selectAllLabel,
  className,
  isAll,
  options = [],
  allOption = [],
  ...props
}) => {
  const [data, setData] = useState(options);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [active, setActive] = useState(false);
  const [searching, setSearching] = useState("");
  const optionsRef = useRef();
  const inputRef = useRef();
  //eslint-disable-next-line
  useEffect(
    debounced(async () => {
      setSearching(searchKey);
      const r = await request({
        page: 1,
        searchKey,
      });
      setData([...formatRequest(r)]);
      setPage(1);
      setSearching("");
    }, 1000),
    [searchKey]
  );

  const renderData = useMemo(() => {
    const newData = data.reduce((prev, curr) => {
      if (!prev.some((obj) => obj.label === curr.label && obj.value === curr.value)) {
        prev.push(curr);
      }
      return prev;
    }, []);

    if (searchKey) {
      return newData.filter((d) => {
        return d.label.toLowerCase().includes(searchKey.toLowerCase());
      });
    }

    return [...allOption, ...newData];
  }, [searchKey, data, allOption]);

  const renderPlaceholder = useMemo(() => {
    if (label) {
      return label;
    }
    return placeholder;
  }, [placeholder, label]);

  const onFocus = async () => {
    if (!isMultiple) {
      const r = await request({
        page: 1,
        searchKey: "",
      });
      setData([...formatRequest(r)]);
      setPage(1);

      setSearchKey("");
    }
  };

  useMount(async () => {
    if (isMultiple) {
      const r = await request({
        page: 1,
        searchKey: "",
      });
      setData([...formatRequest(r)]);
      setPage(1);
    }
  });

  return (
    <div className={classNames(className, styles.container)}>
      {active && (
        <div
          className={styles.dimmer}
          onClick={() => {
            setActive(false);
          }}
        ></div>
      )}

      <div
        onMouseDown={() => {
          setSearchKey("");
          setActive(true);
        }}
      >
        <TextField
          ref={inputRef}
          className={classNames(styles.textfield, {
            [`${styles.active}`]: active && label,
          })}
          value={
            active
              ? searchKey
              : (isAll || value?.length) && isMultiple
              ? isAll
                ? allSelectedText
                : allLabel
              : label
          }
          onChange={(name, { value }) => {
            setSearchKey(value);
          }}
          onEnter={async () => {
            const r = await request({
              page: 1,
              searchKey,
              label,
            });
            setData([...formatRequest(r)]);
            setPage(1);
          }}
          onFocus={onFocus}
          onBlur={() => {
            if (!isMultiple) {
              optionsRef.current.scrollTop = 0;
              setActive(false);
              setSearchKey("");
            }
          }}
          prefix={
            isMultiple && (value.length || isAll) ? (
              <div className={styles.selectedLength}>
                {isAll ? total : value.length}
                <CloseOutlinedIcon
                  className={styles.removeIcon}
                  onMouseDown={(e) => {
                    onChange(name, {
                      value: [],
                      isAll: false,
                    });
                    setActive(false);
                    setSearchKey("");
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
            ) : null
          }
          suffix={
            <ArrowDropDownIcon
              className={styles.clickable}
              onClick={async () => {
                if (inputRef) {
                  inputRef.current.focus();
                }
                if (!active) {
                  setActive(true);
                  const r = await request({
                    page: 1,
                    searchKey,
                    label,
                  });
                  setData([...formatRequest(r)]);
                  setPage(1);
                }
              }}
            />
          }
          {...props}
          placeholder={renderPlaceholder}
        />
      </div>
      <div
        className={classNames(styles.options, {
          [`${styles.active}`]: active && !props.disabled,
          [`${styles.empty}`]: !data.length,
          [`${styles.disabled}`]: props.disabled,
        })}
        ref={optionsRef}
        onScroll={async (e) => {
          if (
            !loading &&
            // !isMultiple &&
            e.target.scrollTop + 10 > e.target.scrollHeight - e.target.offsetHeight &&
            total > data.length
          ) {
            const p = page + 1;
            const r = await request({
              page: p,
              searchKey,
            });

            setPage(p);
            setData([...data, ...formatRequest(r)]);
          }
        }}
      >
        {(searching || (page === 1 && loading)) && (
          <div className={styles.option}>
            <CircularProgress className={styles.circle} />
            <Text subtitle>{searchKey ? `Searching "${searchKey}"...` : "Loading..."}</Text>
          </div>
        )}
        {isMultiple && (
          <div
            className={classNames(styles.option, {
              [`${styles.selectedAll}`]: isAll,
            })}
            onMouseDown={() => {
              onChange(name, {
                value: [],
                isAll: !isAll,
              });
            }}
          >
            <Checkbox value={isAll} checked={isAll} />
            <div className={styles.allText}>{selectAllLabel}</div>
          </div>
        )}
        {renderData.map((option, key) => {
          const selectedValue = isMultiple
            ? value.filter((id) => {
                return option.value === id.value;
              })
            : [];
          const isChecked = selectedValue.length || isAll;
          return (
            <div
              key={key}
              className={classNames(styles.option, {
                [`${styles.selected}`]: option.value === value,
              })}
              onClick={() => {
                if (inputRef) {
                  inputRef.current.focus();
                }
              }}
              onMouseDown={() => {
                if (isMultiple) {
                  if (Array.isArray(value)) {
                    const newValue = isAll
                      ? data
                          .filter((id) => {
                            return option.value !== id.value;
                          })
                          .map((id) => {
                            return {
                              value: id.value,
                              label: id.label,
                            };
                          })
                      : isChecked
                      ? value.filter((id) => {
                          return option.value !== id.value;
                        })
                      : [
                          ...value,
                          {
                            value: option.value,
                            label: option.label,
                          },
                        ];
                    const willSelectAll = newValue.length === total && searchKey === "";
                    onChange(name, {
                      value: willSelectAll ? [] : newValue,
                      isAll: willSelectAll,
                    });
                  }
                } else {
                  setActive(false);
                  onChange(name, {
                    value: option.value,
                    label: option.label,
                    ...option,
                  });
                }
              }}
            >
              {isMultiple && <Checkbox value={isChecked} checked={isChecked} />}
              {option.label}
            </div>
          );
        })}
        {!loading && !renderData.length && (
          <div className={styles.option}>
            <Text subtitle>No Data.</Text>
          </div>
        )}
        {loading && !searching && page !== 1 && (
          <div className={styles.option}>
            <CircularProgress className={styles.circle} />
            <Text subtitle>Loading...</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownPaginated;
