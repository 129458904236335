import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { UserGroup } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useContext } from "react";
import { FeatureFlagContext, UserContext } from "contexts";
import { Portal } from "enums";
import UserRoleAccess from "utils/user-role-access.utils";

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  const { role } =
    user?.userAccesses?.find((element) => {
      return element.portal === Portal.OILCO;
    }) || {};
  return (
    <div className={styles.sidebarContainer}>
      <List className={styles.sidebarList}>
        {UserRoleAccess?.filter((child) => {
          return child.key ? hasFeature(`PAGE_${child.key}`) : true;
        })
          .filter((child) => {
            return child.isSeaoilRetail
              ? !(user.groups.indexOf(UserGroup.SeaoilRetailPricing) > -1)
              : true;
          })
          .map((item, index) => {
            if (!item) return <></>;

            if (!user.userAccesses)
              return (
                <span key={index}>
                  {item.exact ? (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  ) : (
                    <ChildItem item={item} />
                  )}
                </span>
              );

            return item.permission?.map((permission) => {
              return permission === role ? (
                <span key={index}>
                  {item.exact ? (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  ) : (
                    <ChildItem item={item} role={role} />
                  )}
                </span>
              ) : (
                <></>
              );
            });
          })}
      </List>
    </div>
  );
};

const ChildItem = ({ item, role }) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.customIcon}
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .filter((child) => {
              return child.isSeaoilRetail
                ? !(user.groups.indexOf(UserGroup.SeaoilRetailPricing) > -1)
                : true;
            })
            .map((child, childKey) => {
              return child.permission?.map((permission) => {
                return permission.role === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Sidebar;
