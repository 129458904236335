import { formatAmount, formatNumber } from "utils";
import { prettifyProduct, prettifyVipTransactionHistoryStatus } from "utils/pretty.utils";
import moment from "moment";
import { DateTime, Products } from "enums";
import { Pill, PopOver, PopOverMenu, Product } from "components/commons";
import { VIPAwardPointsStatusEnum } from "enums/vip-transaction-history-status";
import locale from "localization";
import styles from "./pay-history.module.scss";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export const mapDataToList = ({ pointsHistory, onOptionClickVoid, getUserAccess }) => {
  const {
    cardNumber,
    mobileNumber,
    amount,
    productCode,
    transactionId,
    finalAmount,
    createdAt,
    rewardRedeemed,
    station,
    remarks,
    orNumber,
    status,
    name,
  } = payHistoryType(pointsHistory);

  const options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.voidAccess) {
    if (status !== VIPAwardPointsStatusEnum.Voided) {
      options.push({
        content: locale.void,
        onClick: () => {
          onOptionClickVoid?.(pointsHistory);
        },
      });
    }
  }

  const styleCell = {
    wordBreak: "break-all",
  };

  const print = (val) => {
    return val ? val : "--";
  };

  return {
    vipTransactionId: (
      <div
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {print(transactionId)}
      </div>
    ),
    nameAndMobileNumber: (
      <div
        style={{
          ...styleCell,
          width: "130px",
        }}
      >
        {print(name)}
        <br />
        {print(mobileNumber)}
      </div>
    ),
    vipCardNumber: (
      <div
        style={{
          ...styleCell,
          width: "120px",
        }}
      >
        {print(cardNumber)}
      </div>
    ),

    station: (
      <div
        style={{
          ...styleCell,
          width: "150px",
        }}
      >
        {print(station)}
      </div>
    ),
    productType: (
      <div
        style={{
          ...styleCell,
          width: "100px",
        }}
      >
        {productCode ? (
          <Product
            grass={productCode === Products.Gas91}
            salsa={productCode === Products.Gas95}
            deepBlue={productCode === Products.Gas97}
            cheddar={productCode === Products.Diesel}
            purple={[Products.Lubes, Products.LubeServ, Products.Lpg].includes(productCode)}
          >
            {prettifyProduct(productCode)}
          </Product>
        ) : (
          "--"
        )}
      </div>
    ),
    transactionAmount: (
      <div
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {formatAmount(amount ?? 0)}
      </div>
    ),
    pointsRedeemed: (
      <div
        style={{
          ...styleCell,
          width: "100px",
        }}
      >
        {formatNumber(rewardRedeemed, 2)} pts
      </div>
    ),
    finalAmountPaid: (
      <div
        style={{
          ...styleCell,
          width: "100px",
        }}
      >
        {formatAmount(finalAmount ?? 0)}
      </div>
    ),
    orNumber: (
      <div
        className="min-60 nowrap"
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {orNumber}
      </div>
    ),
    dateOfTransaction: (
      <div
        className="min-60 nowrap"
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {moment(createdAt).format(DateTime.K)}
      </div>
    ),
    status: (
      <Pill
        grass={status === VIPAwardPointsStatusEnum.Success}
        cement={status === VIPAwardPointsStatusEnum.Voided}
        style={{
          width: "80px",
        }}
      >
        {prettifyVipTransactionHistoryStatus(print(status))}
      </Pill>
    ),
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div
          className="link"
          style={{
            ...styleCell,
            width: "50px",
          }}
        >
          <b>{locale.view}</b>
        </div>
      </PopOver>
    ) : (
      ""
    ),
    action: options.length > 0 && (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { awardTypes, referenceStation, ...fs } = filterState;

  if (referenceStation.value.length > 0) {
    fs.stationIds = referenceStation.value.map(({ value }) => value).join(",");
  }

  if (referenceStation.isSelectedAll) {
    fs.stationIds = undefined;
  }

  return {
    ...fs,
  };
};

export const payHistoryType = (responseData) => {
  return {
    pointsHistoryId: responseData?.pointsHistoryId,
    userId: responseData?.userId,
    fleetId: responseData?.fleetId,
    name: responseData?.name,
    cardNumber: responseData?.cardNumber,
    points: responseData?.points,
    awardType: responseData?.awardType,
    transactionId: responseData?.pointsHistoryId,
    productCode: responseData?.productCode,
    stationId: responseData?.stationId,
    mobileNumber: responseData?.mobileNumber,
    amount: responseData?.amount,
    finalAmount: responseData?.finalAmount,
    rewardRedeemed: responseData?.points,
    createdAt: responseData?.createdAt,

    //
    station: responseData?.station?.name,
    orNumber: responseData?.transactionId,
    status: responseData?.status,
    remarks: responseData?.remarks,
  };
};
