import { ActionButton, Modal, Field, Dropdown, TextField } from "components/commons";
import locale from "localization";
import styles from "./confirm-status-change-modal.module.scss";
import { updateCustomer } from "apis";
import { useApi, useForm } from "hooks";
import { useCallback } from "react";
import { useEffect } from "react";
import { CustomerStatus } from "enums";
import { initialState } from "./confirm-status-change-modal.state";
import CustomerStatusRemarks from "enums/customer-status-remarks";

const ConfirmStatusChangeModal = ({ name, userId, status, setCustomerProfile, ...modalState }) => {
  const { close, active } = modalState;

  const {
    request: updateCustomerRequest,
    loading: updateLoadingState,
    result: updateCustomerResult,
  } = useApi({
    api: updateCustomer,
    modalError: false,
    params: {
      userId,
    },
  });

  const {
    fields = {},
    modifyField,
    isFormSubmittable,
  } = useForm({
    initialState,
  });

  const { remarks, others } = fields;

  const submit = useCallback(async () => {
    await updateCustomerRequest({
      status: status === CustomerStatus.Active ? CustomerStatus.Blocked : CustomerStatus.Active,
      remarks:
        fields.remarks.value === CustomerStatusRemarks.Others
          ? fields.others.value
          : fields.remarks.value,
    });
    close();
  }, [updateCustomerRequest, status, fields.remarks.value, fields.others.value, close]);

  useEffect(() => {
    setCustomerProfile((prevState) => ({ ...prevState, updatedProfile: updateCustomerResult }));
  }, [updateCustomerResult, setCustomerProfile]);

  const isActive = status === CustomerStatus.Active;

  const onChangeRemarksCb = useCallback(
    async (value) => {
      modifyField(fields.remarks.name, { value: value });
    },
    [fields.remarks.name, modifyField]
  );

  const validateForm = () => {
    let isValid = fields.remarks.value === CustomerStatusRemarks.Others;
    return isValid ? fields.others.value !== "" : isFormSubmittable;
  };

  return (
    <Modal
      active={active}
      title={`${isActive ? locale.blockAccount : locale.reactivateAccount}?`}
      close={close}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <locale.Populate
          text={`${
            isActive ? locale.youAreAboutBlockAccount : locale.youAreAboutReactivateAccount
          }`}
          items={[<strong>{name}'s</strong>, name]}
        />
        <br />

        {isActive && (
          <Field
            className={styles.field}
            labelClassName={styles.fieldLabel}
            horizontal
            {...remarks}
          >
            <Dropdown
              className={styles.dropdown}
              type="simple"
              options={remarks.options}
              value={fields?.remarks?.value}
              onChange={onChangeRemarksCb}
            />
          </Field>
        )}

        {isActive && fields?.remarks?.value === CustomerStatusRemarks.Others && (
          <Field className={styles.field} labelClassName={styles.fieldLabel} horizontal>
            <TextField
              className={styles.longField}
              {...others}
              onChange={(name, { value }) => {
                modifyField("others", { value });
              }}
            />
          </Field>
        )}
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submit(submit);
            },
            disabled: isActive ? !validateForm() : false,
            text: isActive ? locale.blockAccount : locale.yesReactivateIt,
            loading: updateLoadingState,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmStatusChangeModal;
