import { Title, Field, TextField, Autocomplete, Text, DatePickerV2 } from "components/commons";

import { SelectRedemptionStation, InputLitre, InputMobileNumber } from "components/field";
import locale from "localization";
import styles from "./manual-redemption.module.scss";
import PlatformType from "enums/platform-type";
import { prettifyProduct } from "utils/pretty.utils";
import { GasType } from "modules/fleet-accounts/pricing/pricing.module";
import { useCallback, useState } from "react";
import { isMobileNumberValid } from "utils/text.utils";
import { useApi, useMount } from "hooks";
import { getUserByMobileNumber } from "apis/user.api";
import { handleRequest } from "utils";
import { getFuelBalanceByUserId } from "apis/fuel-balance.api";
import Validation from "utils/validation.utils";
import { Products } from "enums";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import moment from "moment";

const validationObject = {
  validation: null,
};

const InitialValidation = {
  setValidation: (e) => (validationObject.validation = e),
  getValidation: () => validationObject.validation,
};

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

const ManualRedemptionForm = ({ fields, modifyField, modifyForm, getFormValues }) => {
  const [productOption, setProductOption] = useState([]);

  const validateAccount = useApi({
    api: getUserByMobileNumber,
    handleOwnError: {
      badrequest: true,
    },
  });

  const getFuelBalance = useApi({
    api: getFuelBalanceByUserId,
    handleOwnError: {
      badrequest: true,
    },
  });

  const validateNumber = useCallback(
    (mobileNumber) => {
      handleRequest(
        async () => {
          const resp = await validateAccount?.request({ mobileNumber: `0${mobileNumber}` });
          if (resp) {
            const { data } = resp;
            modifyForm({
              [fields?.accountName?.name]: {
                value: `${data?.firstName} ${data?.lastName}`,
              },
              [fields?.mobileAccountNumber?.name]: {
                validations: [...InitialValidation?.getValidation()],
                value: mobileNumber,
                error: false,
                message: null,
                userId: data?.userId,
              },
              [fields?.redemptionDate?.name]: {
                disableStartDate: null,
              },
              redemptionStation: {
                value: "",
                label: "",
              },
              [fields?.product?.name]: {
                value: "",
              },
              [fields?.liters?.name]: {
                value: "",
              },
            });

            setProductOption([]);
            await getFuelBalance?.request({ userId: data?.userId });
          }
        },
        {},
        ({ data: { errorCode } }) => {
          if (errorCode === "P1005") {
            modifyForm({
              [fields?.accountName?.name]: {
                value: ``,
              },
              [fields?.mobileAccountNumber?.name]: {
                value: mobileNumber,
                error: true,
                message: locale?.userNotExist,
                validations: [Validation?.accountError()],
              },
            });
          }
        }
      );
    },
    [validateAccount, fields, modifyForm, getFuelBalance]
  );

  useMount(() => {
    if (!InitialValidation?.getValidation()) {
      InitialValidation.setValidation(fields?.mobileAccountNumber?.validations);
    }
  });

  return (
    <>
      <Title xsmall style={{ minWidth: 300, color: "#000" }}>
        {locale.basicInformation}
      </Title>
      <div className={styles.container}>
        <Field
          className={styles.fieldLabel}
          label={locale.mobileAccountNumber}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
          {...fields?.mobileAccountNumber}
        >
          <InputMobileNumber
            {...fields?.mobileAccountNumber}
            placeholder="XXXXXXXXXX"
            fontColor={"#4c69a4"}
            onChange={async (name, { value }) => {
              modifyForm({
                [name]: { value },
              });

              if (value.length === 10 && isMobileNumberValid("0" + value)) {
                validateNumber(value);
              }
            }}
          />
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.accountName}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          <Text>{fields?.accountName?.value}</Text>
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.redemptionStation}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          <SelectRedemptionStation
            {...fields.redemptionStation}
            disabled={!fields?.accountName?.value}
            inputDisabled={!fields?.accountName?.value}
            fontColor={"#4c69a4"}
            onChange={(name, { value, label, stationProduct, createdAt, stationCode }) => {
              modifyForm({
                [fields?.redemptionStation?.name]: {
                  value: stationCode,
                  label,
                },
                [fields?.redemptionDate?.name]: {
                  disableStartDate: createdAt,
                },
              });

              const gasType = Object.keys(GasType)
                .map((data) => (stationProduct[data] === true ? data : null))
                .map((data, key) => ({
                  gas: data,
                  limit: getFuelBalance?.result?.data[key]?.fuelBalance,
                }))
                .filter((data) => data.gas);

              setProductOption(
                gasType.map(
                  (data) =>
                    data && {
                      value: data.gas,
                      label: prettifyProduct(data.gas),
                      customLabel: (
                        <LabelAndCircle
                          label={prettifyProduct(data.gas)}
                          color={getProductColor(data.gas)}
                        />
                      ),
                      gasLimit: data?.limit,
                    }
                )
              );
            }}
            params={{
              platformType: PlatformType.PLC,
            }}
          />
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.product}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            {...fields.product}
            hasCustomLabel
            disabled={productOption.length === 0}
            options={productOption}
            fontColor={"#4c69a4"}
            onChange={(_, { value, isAll }) => {
              modifyForm({
                [fields?.product?.name]: { value, label: value },
                ...(value && {
                  [fields?.liters?.name]: {
                    validations: [
                      Validation.max(
                        productOption?.find((data) => data?.value === value).gasLimit,
                        {
                          customMessage: locale.literError,
                        }
                      ),
                      Validation.min(1, {
                        customMessage: locale.minimumRedemption,
                      }),
                    ],
                  },
                }),
              });
            }}
          />
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.liters}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          {...fields?.liters}
          childrenClassName={styles.fieldContent}
        >
          <InputLitre
            placeholder="0.000"
            disabled={!fields?.product?.value}
            fontColor={"#4c69a4"}
            inputDisabled={!fields?.product?.value}
            {...fields?.liters}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value },
              });
            }}
          />
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.redemptionDateTime}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContentContainer}
        >
          <DatePickerV2
            disabled={!fields?.redemptionStation?.value}
            inputDisabled={!fields?.redemptionStation?.value}
            {...fields?.redemptionDate}
            disableEndDate={new Date()}
            className={styles.datePicker}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value: value,
                },
                [fields?.redemptionTime?.name]: {
                  value:
                    fields?.redemptionTime?.value.length > 0 ? "" : fields?.redemptionTime?.value,
                  error: false,
                },
              });
            }}
          />
          <TextField
            disabled={!fields?.redemptionStation?.value || !fields?.redemptionDate?.value}
            inputDisabled={!fields?.redemptionStation?.value || !fields?.redemptionDate?.value}
            {...fields?.redemptionTime}
            type={"time"}
            placeholder="Select Time"
            value={fields?.redemptionTime?.value}
            fontColor={"#4c69a4"}
            required
            onChange={(name, { value }) => {
              modifyForm({ [name]: { value } });
              const today = moment().format("YYYY-MM-DD").toString();
              const timeToday = moment().valueOf();
              const selectedDate = moment(fields?.redemptionDate?.value)
                .format("YYYY-MM-DD")
                .toString();
              const valueTime = moment(`${value}:00`, "HH:mm").valueOf();
              if (moment(selectedDate).isSame(today)) {
                if (valueTime > timeToday) {
                  modifyForm({
                    [fields?.redemptionTime?.name]: {
                      value: "",
                      error: true,
                    },
                  });
                }
              }
            }}
          />
        </Field>
        <Field
          className={styles.fieldLabel}
          label={locale.remarks}
          labelClassName={styles.fieldLabelInfo}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          <TextField
            {...fields?.remarks}
            multiline
            rowsMax={1}
            fontColor={"#4c69a4"}
            placeholder="Add Text"
            onChange={(n, { value }) => {
              modifyForm({ [n]: { value } });
            }}
          ></TextField>
        </Field>
      </div>
    </>
  );
};

export default ManualRedemptionForm;
