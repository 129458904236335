import React from "react";
import { Button as MaterialButton, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import styles from "./button.module.scss";

const Button = ({
  className,
  variant = "contained",
  loading,
  disabled,
  children,
  onClick,
  primary,
  link,
  outline,
  startIcon,
  importButton,
  exportButton,
  style,
  textButton,
}) => {
  return (
    <div>
      <MaterialButton
        style={style}
        startIcon={startIcon}
        className={classNames(className, styles.button, {
          [`${styles.disabled}`]: disabled,
          [`${styles.primary}`]: primary,
          [`${styles.link}`]: link,
          [`${styles.outline}`]: outline,
          [`${styles.exportbutton}`]: exportButton || importButton,
          [`${styles.textButton}`]: textButton,
        })}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? (
          <CircularProgress
            className={classNames(styles.circular, {
              [`${styles.primary}`]: primary,
            })}
          />
        ) : (
          children
        )}
      </MaterialButton>
    </div>
  );
};

export default Button;
