import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { isLiterValid, formatNumber, parseNumber } from "utils";

const InputLitre = ({ className, onChange, onBlur, innerLabel, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (isLiterValid(value) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, { value: `${formatNumber(value)} L` });
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value.replace(" L", "")));
        onChange(props.name, { value: num || "" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix="L" />;
  }
  return <TextField {...componentProps} />;
};

export default InputLitre;
