import React, { useState, useMemo } from "react";
import Dropzone from "react-dropzone";
import { ActionButton, Modal, Image, Field, Text } from "components/commons";
import { ImportIcon } from "images";
import locale from "localization";
import { importUserCohort } from "apis";
import { useApi, useModal } from "hooks";
import { handleRequest, prettifyAttributeUploadResponse } from "utils";
import { ConfirmModal as ImportStatusFileModal } from "components/modals";
import styles from "./import-file-modal.module.scss";
import { ErrorCode } from "enums";

const ImportFileModal = ({ fetchCustomersRequest, ...modalState }) => {
  const { close, active } = modalState;
  const importStatusFileModal = useModal();

  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const { request: importUserCohortRequest, loading: importingUserCohort } = useApi({
    api: importUserCohort,
    modalError: false,
  });

  const upload = () => {
    let file = selectedFiles[0];
    handleRequest(
      async () => {
        const importUserCohortResult = await importUserCohortRequest({ file });
        if (importUserCohortResult) {
          const data = importUserCohortResult?.data;
          if (data?.missingAttributes || data?.missingUsers || data?.missingCohorts) {
            importStatusFileModal.show({
              title: locale.importSuccessfulWithErrors,
              content: locale.importSuccessfulWithErrorsContent,
              itemsWithError: data,
              secondary: {
                text: locale.gotIt,
                loading: false,
                onClick: () => {
                  close();
                  setSelectedFiles(undefined);
                  fetchCustomersRequest();
                },
              },
              primary: {
                text: locale.retry,
                onClick: () => {
                  importStatusFileModal.close();
                },
              },
            });
          } else {
            close();
            importStatusFileModal.show({
              title: locale.importSuccessful,
              content: locale.detailHaveBeenSuccessfullyUpdated,
              primary: {
                text: locale.gotIt,
                onClick: () => {
                  setSelectedFiles(undefined);
                  fetchCustomersRequest();
                  importStatusFileModal.close();
                },
              },
            });
            setSelectedFiles(undefined);
          }
        }
      },
      {},
      (err) => {
        if (err?.data?.errorCode === ErrorCode.InvalidCsvFormat) {
          importStatusFileModal.show({
            title: locale.error,
            content: locale.invalidCsvFileTypeFormat,
            primary: {
              text: locale.retry,
              onClick: () => {
                setSelectedFiles(undefined);
                importStatusFileModal.close();
              },
            },
          });
        }
        return err.showError(false);
      }
    );
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };

  const DisplayUploadDetails = useMemo(() => {
    const items = importStatusFileModal.itemsWithError;
    if (items)
      return Object.keys(items).map((key, index) => {
        return (
          <Field
            className={styles.fileDetails}
            key={index}
            horizontal
            label={prettifyAttributeUploadResponse(key)}
          >
            <Text className={styles.fileDetailsList}>
              {Array.isArray(items[key]) ? items[key].join(", ") : items[key]}
            </Text>
          </Field>
        );
      });
  }, [importStatusFileModal.itemsWithError]);

  return (
    <>
      <Modal
        active={active}
        title={locale.import}
        close={() => {
          setSelectedFiles(undefined);
          close();
        }}
        className={styles.modal}
        titleStyle={styles.titleStyle}
      >
        <div className={styles.content}>
          <Dropzone onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dropZone} {...getRootProps()}>
                <input {...getInputProps()} />
                <Image src={ImportIcon} />
                <div>{locale.dragAndDropFilesToUpload}</div>
                {selectedFiles && selectedFiles[0].name && (
                  <div>{selectedFiles && selectedFiles[0].name}</div>
                )}
              </div>
            )}
          </Dropzone>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                setSelectedFiles(undefined);
                close();
              },
              text: locale.cancel,
              disabled: importingUserCohort,
            },
            {
              onClick: () => {
                upload();
              },
              text: locale.continue,
              loading: importingUserCohort,
              disabled: !selectedFiles,
            },
          ]}
        />
      </Modal>
      <ImportStatusFileModal {...importStatusFileModal}>
        {DisplayUploadDetails}
      </ImportStatusFileModal>
    </>
  );
};

export default ImportFileModal;
