import React from "react";
import { TimePicker as TimePickerMui } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./time-picker.module.scss";

const TimePicker = ({ value, onChange, name }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePickerMui
        label="Time"
        value={value}
        onChange={(value) => {
          onChange(name, { value });
        }}
        renderInput={(params) => (
          <TextField
            className={styles.input}
            {...params}
            onKeyUp={() => {}}
            onKeyDown={() => {}}
            onChange={() => {}}
            label=""
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
