import { PopOverMenu, Text, Product, Pill } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  prettifyProduct,
  prettifySettlementStatus,
  prettifyPayWithLOCQPayStatus,
} from "utils/pretty.utils";
import { formatAmount, formatVolume } from "utils";
import { DateTime, Products, SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import { locale } from "localization/en";
import styles from "./pay-with-locqpay.module.scss";
import PayWithLOCQPayStatus from "enums/pay-with-locqpay-status";
import moment from "moment";

export const mapDataToList = ({ payments, handleVoidPayment, getUserAccess }) => {
  const {
    paymentId,
    paidAt,
    stationName,
    productCode,
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    literVolume,
    pumpPrice,
    averageCreditPrice,
    totalDiscount,
    discount,
    finalAmount,
    fleet,
    stationBusinessName,
    cardNumber,
    driver,
    vehicle,
    orNumber,
  } = payments;

  const options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.voidAccess) {
    if (status === PayWithPesoStatus.Success) {
      options.push({
        content: locale.voidTransaction,
        onClick: () =>
          handleVoidPayment({
            paymentId,
            finalAmount,
            settlementStatus,
            settlementId,
            totalDiscount,
            businessName: fleet?.businessName,
            amount,
          }),
      });
    }
  }

  return {
    paymentId: <div>{paymentId}</div>,
    businessNameId: (
      <div>
        <Text>{fleet.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet.shortName}</Text>
      </div>
    ),
    paymentStationAndStationBusinessName: (
      <div>
        <Text>{stationName}</Text>
        <Text className={styles.subBusinessId}>{stationBusinessName}</Text>
      </div>
    ),
    cardNumber: (
      <div>
        <Text>{cardNumber ?? locale.any}</Text>
      </div>
    ),
    driversNameandId: (
      <div>
        {driver ? (
          <Text>{`${driver?.firstName} ${driver?.lastName}`} </Text>
        ) : (
          <Text>
            <i>{locale.any}</i>
          </Text>
        )}

        <Text className={styles.subBusinessId}>{driver?.driverLicenseId}</Text>
      </div>
    ),
    plateNumber: (
      <div>
        {vehicle ? (
          <Text> {vehicle.plateNumber}</Text>
        ) : (
          <Text>
            <i>{locale.any}</i>
          </Text>
        )}
      </div>
    ),
    paidAt: (
      <div>
        <Text className={styles.subBusinessId}>{moment(paidAt).format(DateTime.P)}</Text>
      </div>
    ),
    amount: <div className="min-100">{formatAmount(amount)}</div>,
    product: (
      <Product
        grass={productCode === Products.Gas91}
        salsa={productCode === Products.Gas95}
        deepBlue={productCode === Products.Gas97}
        cheddar={productCode === Products.Diesel}
      >
        {prettifyProduct(productCode)}
      </Product>
    ),
    volumeCreditPrice: (
      <div>
        <Text>{formatVolume(literVolume)}</Text>
        <Text className={styles.subBusinessId}>{formatAmount(averageCreditPrice)}</Text>
      </div>
    ),
    finalAmount: <div className="min-100">{formatAmount(finalAmount)}</div>,
    pumpPrice: <div className="min-100">{formatAmount(pumpPrice)}</div>,
    discount: <div className="min-100">{formatAmount(totalDiscount)}</div>,
    discountedVolume: <div className="min-100">{formatAmount(discount)}</div>,
    orNumber: orNumber,
    status: (
      <Pill
        grass={status === PayWithLOCQPayStatus.Success}
        cement={status === PayWithLOCQPayStatus.Voided}
      >
        {prettifyPayWithLOCQPayStatus(status)}
      </Pill>
    ),
    settlementStatus: (
      <>
        <Pill
          grass={settlementStatus === SettlementStatus.Processed}
          cyan={settlementStatus === SettlementStatus.ForSettlement}
          sky={settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(settlementStatus)}
        </Pill>
        {status !== PayWithLOCQPayStatus.Voided &&
        (settlementStatus === SettlementStatus.ForProcessing ||
          settlementStatus === SettlementStatus.Processed) ? (
          <Text className={styles.subBusinessId}>{settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </>
    ),
    options:
      options.length > 0 ? (
        <PopOverMenu options={options}>
          <MoreVertIcon className={styles.icon} />
        </PopOverMenu>
      ) : (
        ""
      ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { redemptionStation, productCodes, paymentStatus, ...fs } = filterState;

  return {
    ...fs,
    redemptionStationId: redemptionStation.value,
    productCodes: productCodes.join(","),
    paymentStatus: paymentStatus.join(","),
  };
};
