import { formatAmount, formatDate } from "utils";
import { DateTime } from "enums";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import classNames from "classnames";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./dox-negotiated-discounts.module.scss";

export const mapDataToList = (data) => {
  const { name, stationType, stationCode, stationNegotiatedDiscount, createdAt, updatedAt } = data;

  return {
    name: <LabelAndValue label={stationType}>{name}</LabelAndValue>,
    stationCode,
    product:
      stationNegotiatedDiscount &&
      stationNegotiatedDiscount.map((item, key) => (
        <div key={`${item.productCode}-${key}`}>
          <div className="flex items-center">
            <div className={classNames(styles.circle, styles[item.productCode])}></div>
            {prettifyProduct(item.productCode)}
          </div>
        </div>
      )),
    negotiatedDiscount:
      stationNegotiatedDiscount &&
      stationNegotiatedDiscount.map((item, key) => (
        <div key={`${item.productCode}-${key}`}>
          <div className="flex items-center">{formatAmount(item.discount)}</div>
        </div>
      )),
    updatedAt: updatedAt ? formatDate(updatedAt, DateTime.G) : formatDate(createdAt, DateTime.G),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { stationTypes, ...fs } = filterState;

  if (stationTypes.value.length) {
    fs.stationTypes = stationTypes.value.join(",");
  }

  return {
    ...fs,
  };
};
