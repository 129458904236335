import classNames from "classnames";
import { Modal, ActionButton, TextField, Field, Autocomplete } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./void-transaction-modal.module.scss";
import { VoidTransactionValue } from "enums/void-transaction-value";
import { prettifyVoidTransactionValue } from "utils/pretty.utils";

const VoidTransactionModal = ({
  close,
  active,
  onSubmit,
  remarks = "",
  onChangeRemarks,
  loading,
  title,
  description,
  label,
  actionText,
  value,
  setValue,
}) => {
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        {description}
        <Field
          label={label}
          labelPosition="left"
          childrenStyle={{
            width: "400px",
          }}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Autocomplete
            name="voidTransaction"
            value={value}
            onChange={(name, { value }) => {
              if (value !== VoidTransactionValue.Others) {
                onChangeRemarks(value);
              } else {
                onChangeRemarks("");
              }
              setValue(value);
            }}
            options={[
              VoidTransactionValue.WrongInputByUser,
              VoidTransactionValue.WrongVolumeInput,
              VoidTransactionValue.Others,
            ].map((item) => ({
              label: prettifyVoidTransactionValue(item),
              value: item,
            }))}
          />
          {value === VoidTransactionValue.Others && (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <TextField
                multiline
                rowsMax={1}
                onChange={(n, { value }) => {
                  onChangeRemarks(value);
                }}
                value={remarks}
              ></TextField>
            </div>
          )}
        </Field>
      </div>
      <ActionButton
        right
        loading={loading}
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
              setValue("");
            },
          },
          {
            onClick: () => {
              onSubmit(remarks);
              setValue("");
            },
            text: actionText,
            disabled: remarks?.length === 0 || !value,
          },
        ]}
      />
    </Modal>
  );
};

export default VoidTransactionModal;
