import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PLCLOCQPayForFuelPage from "./plc-locqpay-for-fuel.page";
import PLCRedemptionPage from "./plc-redemption.page";
import PLBPayWithLOCQPayRedemptionPage from "./plb-pay-with-locqpay-redemption.page";
import PLBCashInPage from "./plb-redemption.page";
import PLCManualRedemptionPage from "./plc-manual-redemption.page";

const PaymentsPage = () => {
  return (
    <Switch>
      <Route path={Path.PLCRedemption} component={PLCRedemptionPage} />
      <Route path={Path.PLCLOCQPayForFuel} component={PLCLOCQPayForFuelPage} />
      <Route path={Path.PLBPayWithLOCQPayRedemption} component={PLBPayWithLOCQPayRedemptionPage} />
      <Route path={Path.PLBRedemption} component={PLBCashInPage} />
      <Route path={Path.PLCManualRedemption} component={PLCManualRedemptionPage} />
      <Redirect to={Path.ConsumerFuelPage} />
    </Switch>
  );
};

export default PaymentsPage;
