import locale from "localization";

export const payWithPesoColumns = [
  {
    key: "paymentId",
    text: locale.payWithPesoId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "createdAt",
    text: locale.dateOfTransaction,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "name",
    text: `${locale.name} / ${locale.mobileNumber}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "stationName",
    text: locale.gasUpStation,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "stationCode",
    text: locale.stationCode,
    style: {
      minWidth: "80px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "paymentMethod",
    text: locale.paymentMethod,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "amount",
    text: locale.paymentAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "discount",
    text: locale.discount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "discountPerLiter",
    text: locale.discountPerLiter,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "discountedVolume",
    text: locale.discountedVolume,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "finalAmountPaid",
    text: locale.finalAmountPaid,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "pumpPrice",
    text: locale.pumpPriceAtPayment,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "balanceAverageCreditPrice",
    text: `${locale.balance} / ${locale.aveCreditPrice}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "literVolume",
    text: locale.volume,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus} / ${locale.settlementId}`,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },
];
