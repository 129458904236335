import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { useState, useCallback } from "react";
import { DefaultProfile, SeaoilLogo } from "../../../images";
import { Path } from "enums";
import locale from "localization";
// import { SeaoilIcon, PriceLocqBusiness, HomeIcon, PriceLocqConsumer } from "images";
// import { DropdownMenu } from "components/commons";
import Image from "../image/image";
import styles from "./header.module.scss";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { useApi } from "hooks";
import { redirectTo } from "utils";
import { logout } from "apis";
import { useContext } from "react";
import { AppContext, MessagesContext } from "contexts";
import IconNotificationCount from "../icon-notification-count/icon-notification-count";
import { useHistory } from "react-router-dom";
import { EmailOutlined, GetAppRounded } from "@material-ui/icons";

// const menuItems = [
//   {
//     label: locale.seaoil,
//     icon: SeaoilIcon,
//     link: Path.Seaoil,
//     exact: true,
//   },
//   {
//     label: locale.pricelocq,
//     icon: HomeIcon,
//     link: Path.Pricelocq,
//     exact: true,
//   },
//   {
//     label: locale.pricelocqConsumer,
//     icon: PriceLocqConsumer,
//     link: Path.PricelocqConsumer,
//   },
//   {
//     label: locale.pricelocqBusiness,
//     icon: PriceLocqBusiness,
//     link: Path.PricelocqBusiness,
//   },
// ];

const Header = ({ setNewNotification, newNotification }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { toggleLoading } = useContext(AppContext);
  const messages = useContext(MessagesContext);
  const history = useHistory();
  const { request: logoutRequest } = useApi({
    api: logout,
  });

  const logoutCb = useCallback(async () => {
    toggleLoading(true);
    await logoutRequest();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("stationId");
    toggleLoading(false);
    redirectTo(Path.Auth);
  }, [logoutRequest, toggleLoading]);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(() => !open);
  };

  return (
    <div id="header">
      <AppBar className={styles.headerContainer}>
        <Toolbar disableGutters>
          <div className={styles.logoContainer}>
            {/* <DropdownMenu menuItems={menuItems} /> */}
            <Image src={SeaoilLogo} className={styles.logo} />
          </div>
          <div className={styles.toolbarContainer}>
            <Typography variant="h6" className={styles.title}>
              {locale.seaoilPortal}
            </Typography>
            <div className={styles.menuContainer}>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  setNewNotification();
                  history.push(Path.Downloads);
                }}
              >
                {newNotification && <div className={styles?.notificationIcon} />}
                <GetAppRounded className={styles.downloadIcon} />
              </IconButton>
              {messages && (
                <IconNotificationCount
                  icon={EmailOutlined}
                  count={messages.unreadMessages}
                  onClick={() => {
                    history.push(Path.Inbox);
                  }}
                />
              )}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpen}
              >
                <Image src={DefaultProfile} className={styles.profile} />
                <KeyboardArrowDownRoundedIcon className={styles.caretIcon} />
              </IconButton>
              <Menu
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                anchorEl={anchorEl}
                open={open}
                className={styles.menu}
                onClose={handleClose}
              >
                <MenuItem className={styles.menuItem} onClick={logoutCb}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
