import locale from "localization";

export const rfidColumns = [
  {
    key: "rfidTopupId",
    text: locale.rfidReloadID,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "referenceNumber",
    text: locale.referenceNumber,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "name",
    text: `${locale.name} / ${locale.mobileNumber}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "type",
    text: locale.rfidType,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "accountNumber",
    text: locale.rfidAccountNumber,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "plateNumber",
    text: locale.plateNumber,
    style: {
      minWidth: "80px",
    },
  },
  {
    key: "paymentMethod",
    text: locale.paymentMethod,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "amount",
    text: locale.loadAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "serviceFee",
    text: locale.customerFee,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "statusAndDate",
    text: `${locale.status} / ${locale.reloadDate}`,
    style: {
      minWidth: "100px",
    },
  },
];
