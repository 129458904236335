import { DateTime } from "enums";
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import styles from "./date-picker.module.scss";
import { Calendar } from "images";
import Image from "../image/image";
import { useMount } from "hooks";
import moment from "moment";

const DatePicker = (
  { placeholder, value, name, onChange, minDate = null, isAfter = false },
  ref
) => {
  const [selectedDate, setSelectedDate] = useState();

  const [dirty, setDirty] = useState(false);

  const date = useMemo(() => {
    if (value) {
      return value?.date;
    }
    return selectedDate;
  }, [selectedDate, value]);

  useImperativeHandle(ref, () => ({
    clearValue: () => {
      setSelectedDate(null);
      setDirty(false);
    },
  }));

  const dateRef = useRef();

  const modifiers = useMemo(() => {
    return { start: date };
  }, [date]);

  const changeCb = useCallback(
    (value) => {
      setDirty(true);
      if (onChange) {
        return onChange(name, { value, dirty });
      }
    },
    [name, onChange, dirty]
  );

  const changeDateCb = useCallback(
    (start) => {
      setSelectedDate(start);
      if (dirty) {
        dateRef.current.getInput().blur();
      }
      changeCb({
        date: new Date(moment(start).format(DateTime.C)),
      });
    },
    [changeCb, dirty]
  );

  useMount(() => {
    dateRef.current.input.readOnly = true;
  });

  return (
    <div className={styles.inputFromTo}>
      <div className={styles.date}>
        <DayPickerInput
          ref={dateRef}
          value={date}
          placeholder={placeholder || "Select date"}
          format={DateTime.G}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [date],
            modifiers,
            disabledDays: [
              new Date(moment(date)),
              {
                before: new Date(moment(minDate)),
              },
            ],
            numberOfMonths: 1,
          }}
          onDayChange={changeDateCb}
        />
      </div>

      <Image className={styles.icon} src={Calendar} />
    </div>
  );
};

export default forwardRef(DatePicker);