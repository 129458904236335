import { Products, SettlementStatus } from "enums";
import RedemptionStatus from "enums/redemption-status";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  dispensedTo: "all",
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  redemptionStation: {
    value: [],
    isAll: true,
  },
  settlementStatuses: {
    isSelectedAll: true,
    value: [
      SettlementStatus.ForSettlement,
      SettlementStatus.ForProcessing,
      SettlementStatus.Processed,
    ],
  },
  redemptionStatuses: {
    isSelectedAll: true,
    value: [RedemptionStatus.Success, RedemptionStatus.Voided],
  },
  page: 1,
  perPage: 10,
});
