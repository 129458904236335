import { getVoucherCampaigns, updateVoucherCampaign } from "apis";
import { DataTable, Intro, Text, PopOverMenu } from "components/commons";
import classNames from "classnames";
import moment from "moment";
import {
  dateTimeFormat,
  formatDate,
  formatPesoWithDecimalRange,
  formatVolume,
  formatNumber,
  prettifyVoucherCampaignType,
  prettifyVoucherCampaignCurrency,
} from "utils";
import { CampaignStatus, VoucherCampaignCurrency, Path } from "enums";
import { useApi, useFilter, useMount, useModal, useRouter } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { campaignsColumns } from "./voucher-campaign-columns";
import { vouchersFilterState } from "./voucher-campaign-filter.state";
import ConfirmCancelVoucherModal from "./confirm-cancel-voucher-modal";
import VoucherFilter from "./voucher-campaign-filter";
import styles from "./voucher-campaign.module.scss";
import locale from "localization";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmReactivateVoucherModal from "./confirm-reactivate-voucher-modal";
import { prettifyVoucherCampaignStatus } from "utils/pretty.utils";

const VoucherCampaignModule = () => {
  const confirmModal = useModal();
  const reactivateModal = useModal();
  const [name, setName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const { Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Peso, Liter } = VoucherCampaignCurrency;
  const { history } = useRouter();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    filterCount,
    clearFilter,
  } = useFilter(vouchersFilterState());

  const {
    request: getVoucherCampaignRequest,
    loading: fetchingCampaigns,
    result: getVoucherResult = { voucherCampaigns: [], count: 0 },
  } = useApi({
    api: getVoucherCampaigns,
    pageError: false,
  });

  useMount(() => {
    fetchCampaigns(requestState);
  });

  const { request: cancelCampaignRequest, loading: updatingCampaign } = useApi({
    api: updateVoucherCampaign,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      campaignId,
    },
  });

  const fetchCampaigns = useCallback(
    async (requestState) => {
      const NewRequestState = submitFilter(requestState);
      const request = {
        kind: NewRequestState?.kind,
        page: NewRequestState?.page,
        perPage: NewRequestState?.perPage,
        startDate: NewRequestState?.startDate,
        endDate: NewRequestState?.endDate,
        searchKey: NewRequestState?.searchKey,
        status: NewRequestState?.status?.filter((data) => data !== "all").join(","),
        currencies: NewRequestState?.currency?.filter((data) => data !== "all").join(","),
        types: NewRequestState?.type?.filter((data) => data !== "all").join(","),
      };

      await getVoucherCampaignRequest(request);
    },
    [getVoucherCampaignRequest, submitFilter]
  );

  const getStatus = useCallback(
    (status) => {
      return (
        <div
          className={classNames(styles.upcoming, {
            [`${styles.ongoing}`]: Ongoing === status,
            [`${styles.ended}`]: Ended === status,
            [`${styles.cancelled}`]: Cancelled === status,
          })}
        >
          {prettifyVoucherCampaignStatus(status)}
        </div>
      );
    },
    [Cancelled, Ended, Ongoing]
  );

  const onTypeChangeCb = useCallback(
    (type) => {
      modifyFilters({ type, page: 1 });
    },
    [modifyFilters]
  );

  const onCurrencyChangeCb = useCallback(
    (currency) => {
      modifyFilters({ currency, page: 1 });
    },
    [modifyFilters]
  );

  const preparedCustomerListData = useMemo(() => {
    const { voucherCampaigns } = getVoucherResult;
    const displayValue = (value, currency) => {
      let returnValue = null;
      switch (currency) {
        case Peso:
          returnValue = formatPesoWithDecimalRange(value ? value : 0, 2);
          break;
        case Liter:
          returnValue = formatVolume(value);
          break;
        default:
          returnValue = formatNumber(value, 0);
      }
      return returnValue;
    };

    if (voucherCampaigns.length > 0) {
      const preparedData = voucherCampaigns.map((s) => {
        const map = new Map();
        map.set(
          "voucherCampaignId",
          <div>
            <Text small>{s.voucherCampaignId && s.voucherCampaignId}</Text>
          </div>
        );
        map.set(
          "namePrefix",
          <div className={styles.city}>
            <Text small>{s.campaignName && s.campaignName}</Text>
            <Text subtitle>{s.voucherPrefix && s.voucherPrefix}</Text>
          </div>
        );
        map.set(
          "valueCurrency",
          <div>
            <Text small>{displayValue(s.voucherValue, s.voucherCurrency)}</Text>
            <Text subtitle>
              {s.voucherCurrency && prettifyVoucherCampaignCurrency(s.voucherCurrency)}
            </Text>
          </div>
        );
        map.set(
          "voucherType",
          <div>
            <Text small>{s.voucherType && prettifyVoucherCampaignType(s.voucherType)}</Text>
          </div>
        );
        map.set(
          "actualRedemption",
          <div>
            <Text small>{s.actualRedemption && s.actualRedemption}</Text>
          </div>
        );
        map.set(
          "quantityMaxRedemptions",
          <div>
            <Text small>{s.voucherQuantity && s.voucherQuantity}</Text>
          </div>
        );
        map.set(
          "startEndDate",
          <div>
            <Text small>{s.startDate && formatDate(s.startDate, "DD MMM YYYY")}</Text>
            <Text subtitle>{s.endDate && formatDate(s.endDate, "DD MMM YYYY")}</Text>
          </div>
        );
        map.set(
          "dateCreated",
          <div className={styles.product}>
            <Text small>{s.createdAt && formatDate(s.createdAt)}</Text>
            <Text subtitle>{s.createdAt && formatDate(s.createdAt, "hh:mma")}</Text>
          </div>
        );
        map.set(
          "status",
          <div>
            {getStatus(s.status)}
            <Text subtitle>{s.updatedAt !== null && dateTimeFormat(s.updatedAt)}</Text>
          </div>
        );
        map.set(
          "action",
          <PopOverMenu
            options={[
              {
                content: locale.viewDetails,
                onClick: () => {
                  history.push(Path.ViewVoucherCampaign, {
                    voucherCampaignId: s.voucherCampaignId,
                    editable: s.status !== Cancelled,
                  });
                },
              },
              {
                content: locale.editDetails,
                onClick: () => {
                  history.push(Path.EditVoucherCampaign, {
                    voucherCampaignId: s.voucherCampaignId,
                  });
                },
                disabled: s.status === Cancelled,
              },

              {
                content: locale.cancel,
                onClick: () => {
                  setName(s.campaignName);
                  setCampaignId(s.voucherCampaignId);
                  setSelectedCampaign(s);
                  confirmModal.show();
                },
                disabled: s.status === Cancelled || s.status === Ended,
              },
              {
                content: locale.reactivate,
                onClick: () => {
                  setName(s.campaignName);
                  setCampaignId(s.voucherCampaignId);
                  setSelectedCampaign(s);
                  reactivateModal.show();
                },
                disabled:
                  !(s.status === Cancelled) ||
                  s.status === Ended ||
                  moment(s.endDate, true).isBefore(moment()),
              },
            ]}
          >
            <MoreVertIcon className={styles.actionIcon} />
          </PopOverMenu>
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    Liter,
    Peso,
    Ended,
    Cancelled,
    confirmModal,
    reactivateModal,
    history,
    getVoucherResult,
    getStatus,
  ]);

  const onSubmitCancel = useCallback(async () => {
    const cancelValues = {
      ...selectedCampaign,
      campaignId: campaignId,
      status: "cancelled",
    };
    // close();
    await cancelCampaignRequest(cancelValues);
    confirmModal.close();
    const { requestState } = modifyFilters();
    fetchCampaigns(requestState);
  }, [
    campaignId,
    confirmModal,
    modifyFilters,
    fetchCampaigns,
    cancelCampaignRequest,
    selectedCampaign,
  ]);

  const onSubmitReactivate = useCallback(async () => {
    const cancelValues = {
      ...selectedCampaign,
      campaignId: campaignId,
      status: "ongoing",
    };
    // close();
    await cancelCampaignRequest(cancelValues);
    reactivateModal.close();
    const { requestState } = modifyFilters();
    fetchCampaigns(requestState);
  }, [
    campaignId,
    reactivateModal,
    cancelCampaignRequest,
    modifyFilters,
    fetchCampaigns,
    selectedCampaign,
  ]);
  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [modifyFilters, fetchCampaigns]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [fetchCampaigns, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [modifyFilters, fetchCampaigns]
  );

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onStatusChangeCb = useCallback(
    (status) => {
      modifyFilters({ status, page: 1 });
    },
    [modifyFilters]
  );

  const prepareRequestState = (requestState) => {
    const newRequestState = requestState;
    return newRequestState;
  };

  return (
    <div>
      <div>
        <Intro
          title={`${locale.voucher} ${locale.campaigns}`}
          subtitle={locale.viewAndCreateVoucherCampaigns}
          actionText={locale.createVoucherCampaign}
          actionOnClick={() => {
            history.push(Path.AddVoucherCampaign);
          }}
        />
      </div>
      <div className={styles.filters}>
        <VoucherFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStatusChange={onStatusChangeCb}
          onTypeChange={onTypeChangeCb}
          onCurrencyChange={onCurrencyChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          resetFilter={() => {
            modifyFilters({
              endDate: filterState.endDate,
              startDate: filterState.startDate,
              status: filterState.status,
              type: filterState.type,
              currency: filterState.currency,
              ...submittedFilter,
            });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchCampaigns(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            const newRequestState = prepareRequestState(requestState);
            fetchCampaigns(newRequestState);
          }}
          filterCount={filterCount}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingCampaigns}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={campaignsColumns}
          data={preparedCustomerListData}
          dataCount={getVoucherResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
      <ConfirmReactivateVoucherModal
        loading={updatingCampaign}
        onSubmitReactivate={onSubmitReactivate}
        name={name}
        {...reactivateModal}
      />
      <ConfirmCancelVoucherModal
        loading={updatingCampaign}
        onSubmitCancel={onSubmitCancel}
        name={name}
        {...confirmModal}
      />
    </div>
  );
};

export default VoucherCampaignModule;
