import React, { useCallback, useMemo, useState } from "react";
import StationForm from "./station-form";
import useForm from "hooks/useForm.v2";
import { stationFormInitialState } from "./station-form.state";
import { useApi, useModal, useMount } from "hooks";
import { createStation, getStationByCode } from "apis/account-stations";
import { Path, Products } from "enums";
import { mapFormToRequest } from "./station.mapper";
import PlatformType from "enums/platform-type";
import OnboardStatus from "enums/onboard-status";
import { Button } from "components/commons";
import locale from "localization";
import OnboardStationModal from "./onboard-station-modal";
import { ConfirmModal, SuccessModal } from "components/modals";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SuccessFormSubmit } from "images";

const AddStation = () => {
  const history = useHistory();
  const [submittedStationCode, setSubmittedStationCode] = useState("");
  const [validStationCode, setValidStationCode] = useState("");
  const [island, setIsland] = useState("");
  const form = useForm(stationFormInitialState);
  const { modifyForm, fields } = form;
  const { stationCode } = fields;
  const onboardModal = useModal();
  const successModal = useModal();
  const confirmModal = useModal();
  const stationCodeRequest = useApi({
    api: getStationByCode,
    handleOwnError: true,
  });

  const createRequest = useApi({
    api: createStation,
  });

  useMount(() => {
    form.initializeForm({}, true);
  });

  const isValidStationCode =
    submittedStationCode &&
    validStationCode &&
    submittedStationCode === validStationCode &&
    stationCode.value === validStationCode;

  const onSubmit = useCallback(async () => {
    if (isValidStationCode) {
      await form.onSubmit(async (params) => {
        const mappedParams = { ...mapFormToRequest(params), island };
        if (params.initialOnboarding === OnboardStatus.OnBoardNow) {
          onboardModal.show({
            platformType: mappedParams.platformType,
            submit: async () => {
              await createRequest.request(mappedParams);
              onboardModal.close();
              successModal.show();
            },
          });
        } else {
          confirmModal.show({
            title: locale.addStationAndOnBoardLater,
            content: locale.youAreNowAddingThisStation,
            primary: {
              text: locale.yesOnboardLater,
              onClick: async () => {
                await createRequest.request(mappedParams);
                confirmModal.close();
                successModal.show({
                  content: locale.stationHasBeenAdded,
                });
              },
            },
            secondary: {
              text: locale.cancel,
              onClick: async () => {
                confirmModal.close();
              },
            },
          });
        }
      });
    } else {
      setSubmittedStationCode(stationCode.value);
      try {
        const res = await stationCodeRequest.request({
          stationCode: stationCode.value,
        });
        setValidStationCode(stationCode.value);
        const {
          businessName,
          stationType,
          availableProducts = [],
          depot = {},
          companyGroup,
          island,
        } = res || {};
        setIsland(island);
        const { depotCode, depotLocation } = depot;
        form.initializeForm(
          {
            stationCode: {
              value: stationCode.value,
              visible: true,
            },
            businessName: {
              value: businessName,
              visible: true,
            },
            stationType: {
              value: stationType,
              visible: true,
            },
            depotId: {
              value: depotCode,
            },
            depotLocation: {
              value: depotLocation,
            },
            companyGroup: {
              value: companyGroup,
            },
            productAvailability: {
              value: {
                diesel: availableProducts.includes(Products.Diesel),
                gas91: availableProducts.includes(Products.Gas91),
                gas95: availableProducts.includes(Products.Gas95),
                gas97: availableProducts.includes(Products.Gas97),
              },
              visible: true,
            },
          },
          true
        );
      } catch (e) {
        const { data = {} } = e || {};
        const { errorCode } = data;
        const errorMessage = ["S1000", "S1001", "S1002", "S1007", "S1008"];

        if (errorMessage.includes(errorCode?.toString())) {
          stationCode.onChange(stationCode.name, {
            errorCode: errorCode,
            value: stationCode.value,
          });
        } else {
          e.showError();
        }
      }
    }
  }, [
    isValidStationCode,
    form,
    island,
    onboardModal,
    createRequest,
    successModal,
    confirmModal,
    stationCode,
    stationCodeRequest,
  ]);

  const isFormSubmittable = useMemo(() => {
    if (isValidStationCode) {
      return form.isFormSubmittable;
    }
    return (
      (stationCode && !stationCode?.error && stationCode?.value?.length >= 4) ||
      stationCode?.value !== submittedStationCode
    );
  }, [isValidStationCode, stationCode, form.isFormSubmittable, submittedStationCode]);

  const onChangeOnBoardingValue = useCallback(
    ({ initialOnboardingValue, platformTypeValue }) => {
      const { fields = {} } = form;
      const {
        mobileNumberReceiving,
        bankAccountName,
        bankAccountNumber,
        nameOfBank,
        settlementEmail,
        diesel,
        gas91,
        gas95,
        gas97,
        plbMaxDiscount,
        stationType,
      } = fields;

      const showPLCInformation = [PlatformType.PLC, PlatformType.PLCandPLB].includes(
        platformTypeValue.value
      );
      const showSettlementInfo =
        initialOnboardingValue.value === OnboardStatus.OnBoardNow &&
        ["CODO", "DODO"].includes(stationType.value);

      const showMaxDiscounts =
        [PlatformType.PLB, PlatformType.PLCandPLB].includes(platformTypeValue.value) &&
        ["WIDOX", "DODOX"].includes(stationType.value);

      modifyForm({
        ...fields,
        platformType: platformTypeValue,
        initialOnboarding: initialOnboardingValue,
        mobileNumberReceiving: {
          value: showPLCInformation ? mobileNumberReceiving.value : "",
          validations: {
            ...mobileNumberReceiving.validations,
            isRequired: showPLCInformation,
          },
          visible: showPLCInformation,
        },
        bankAccountName: {
          value: showSettlementInfo ? bankAccountName.value : "",
          validations: {
            ...bankAccountName.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        nameOfBank: {
          value: showSettlementInfo ? nameOfBank.value : "",
          validations: {
            ...nameOfBank.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        bankAccountNumber: {
          value: showSettlementInfo ? bankAccountNumber.value : "",
          validations: {
            ...bankAccountNumber.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        settlementEmail: {
          value: showSettlementInfo ? settlementEmail.value : "",
          validations: {
            ...settlementEmail.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        plbMaxDiscount: {
          value: showMaxDiscounts ? plbMaxDiscount.value : "",
          visible: showMaxDiscounts,
        },
        diesel: {
          value: showMaxDiscounts ? diesel.value : "",
          validations: {
            ...diesel.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas91: {
          value: showMaxDiscounts ? gas91.value : "",
          validations: {
            ...gas91.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas95: {
          value: showMaxDiscounts ? gas95.value : "",
          validations: {
            ...gas95.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas97: {
          value: showMaxDiscounts ? gas97.value : "",
          validations: {
            ...gas97.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
      });
    },
    [form, modifyForm]
  );

  const formFields = useMemo(() => {
    const { fields, ...value } = form;
    if (isValidStationCode) {
      const obj = {};
      Object.keys(fields).forEach((key) => {
        const fieldKeys = Object.keys(fields[key]);
        obj[key] = {
          ...fields[key],
          visible: fieldKeys.includes("visible") ? fields[key].visible : true,
        };
      });
      const { platformType, initialOnboarding, ...fieldObj } = obj;
      return {
        ...value,
        fields: {
          ...fieldObj,
          initialOnboarding: {
            ...initialOnboarding,
            validations: {
              isRequired: false,
            },
            value: OnboardStatus.OnBoardLater,
            onChange: (name, value) => {
              onChangeOnBoardingValue({
                initialOnboardingValue: value,
                platformTypeValue: {
                  value: "",
                  validations: {
                    isRequired: value.value === OnboardStatus.OnBoardNow,
                  },
                },
              });
            },
          },
          platformType: {
            ...platformType,
            validations: {
              isRequired: false,
            },
            onChange: (name, value) => {
              onChangeOnBoardingValue({
                initialOnboardingValue: initialOnboarding,
                platformTypeValue: value,
              });
            },
          },
        },
      };
    }
    const { stationCode } = fields;
    return {
      ...value,
      fields: {
        stationCode: {
          ...stationCode,
          onChange: (name, field) => {
            stationCode.onChange(name, {
              ...field,
              errorCode: null,
            });
          },
        },
      },
    };
  }, [form, isValidStationCode, onChangeOnBoardingValue]);

  return (
    <div>
      <StationForm isFormSubmittable={isFormSubmittable} form={formFields} onSubmit={onSubmit} />
      <div
        style={{
          marginTop: "50px",
        }}
      >
        <Button
          loading={stationCodeRequest.loading || createRequest.loading}
          onClick={onSubmit}
          primary
          disabled={!isFormSubmittable}
        >
          {locale.continue}
        </Button>
      </div>
      <ConfirmModal {...confirmModal} loading={createRequest.loading} />
      <OnboardStationModal {...onboardModal} loading={createRequest.loading} />
      <SuccessModal
        image={SuccessFormSubmit}
        title={locale.exclamatedSuccess}
        content={locale.stationHasOnboarded}
        {...successModal}
        close={() => {
          history.push(Path.Station);
          successModal.close();
        }}
        secondary={{
          text: locale.goToStationAccountList,
          onClick: () => {
            history.push(Path.Station);
            successModal.close();
          },
        }}
        primary={{
          text: locale.addAnotherStation,
          onClick: () => {
            form.initializeForm({}, true);
            setSubmittedStationCode("");
            setValidStationCode("");
            setIsland("");
            successModal.close();
          },
        }}
      />
    </div>
  );
};

export default AddStation;
