import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import ManualRedemptionModule from "modules/plc/redemption/manual-redemption/manual-redemption.module";

const PLCManualRedemptionPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PLCManualRedemption} component={ManualRedemptionModule} />
      <Redirect to={Path.PLCManualRedemption} />
    </Switch>
  );
};

export default PLCManualRedemptionPage;
