import { Intro, Text } from "components/commons";
import { SettlementStatus, UserAccess, Role } from "enums";
import { useApi, useModal } from "hooks";
import useExport from "hooks/useExport";
import React, { useCallback, useContext, useState, useMemo } from "react";
import { payWithLOCQPayColumns } from "./pay-with-locqpay-columns";
import PayWithLOCQPayFilter from "./pay-with-locqpay-filter";
import { payWithLOCQPayFilterState } from "./pay-with-locqpay-filter.state";
import styles from "./pay-with-locqpay.module.scss";
import locale from "localization";
import { formatAmount } from "utils";
import { getPayments, generateReportAdmin, voidPayment, updatePaymentRemarks } from "apis";
import VoidTransactionModal from "./void-transaction-modal";
import { ConfirmModal, SuccessModal } from "components/modals";
import { UserContext } from "contexts";

import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./pay-with-locqpay.mapper";

const PayWithLOCQPayModule = (props) => {
  const voidTransactionModal = useModal();
  const confirmModal = useModal();

  const { sessionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PLBPayWithLOCQPayRedemption;
  });
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const columns = useMemo(() => {
    return getUserAccess.role === Role.PortalAdmin
      ? payWithLOCQPayColumns
      : payWithLOCQPayColumns.filter((e) => e.key !== "options");
  }, [getUserAccess]);

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidPayment,
    pageError: false,
    modalError: true,
  });

  const updateRemarksApi = useApi({
    api: updatePaymentRemarks,
    modalError: true,
    pageError: false,
  });

  const handleVoidPayment = ({
    paymentId,
    finalAmount,
    settlementId,
    settlementStatus,
    totalDiscount,
    businessName,
    amount,
  }) => {
    setRemarks("");
    voidTransactionModal.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={
              settlementId
                ? settlementStatus === SettlementStatus.ForProcessing
                  ? locale.voidingThisTransactionSettlementProcessing
                  : locale.voidingThisTransactionSettlementProcessed
                : locale.voidingThisPayment
            }
            items={[<b>{formatAmount(amount)}</b>, <b>{formatAmount(totalDiscount)}</b>]}
          />
        </Text>
      ),
      label: locale.reasonRemarks,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidTransactionCb({
          paymentId,
          value,
          finalAmount,
          businessName,
        });
      },
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getPayments,
      params: {
        platformType: "plb",
      },
    },
    filter: { initialState: payWithLOCQPayFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "payments",
      mapper: (payments) => mapDataToList({ payments, handleVoidPayment, getUserAccess }),
      columns,
    },
  });

  const onVoidTransactionCb = useCallback(
    async ({ paymentId, value, finalAmount, businessName }) => {
      await voidRequest({ paymentId, remarks: value });
      voidTransactionModal.close();
      table.fetch({});
      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[<b>{formatAmount(finalAmount)}</b>, <b>{businessName}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    [voidRequest, table, voidTransactionModal, voidingRequest, confirmModal]
  );

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "fuel-card",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      connectionId: sessionId,
      platformType: "plb",
    },
  });

  delete table.result.count;

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <div>
        <Intro title={locale.payWithLocqPay} subtitle={locale.viewAllPayWithLocqPayTransactions} />
      </div>
      <div className={styles.filters}>
        <PayWithLOCQPayFilter {...filter} {...search} {...exports} getUserAccess={getUserAccess} />
      </div>
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default PayWithLOCQPayModule;
