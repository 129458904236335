import classNames from "classnames";
import { Modal, ActionButton, TextField, Field, Autocomplete } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./transaction-modal.module.scss";
import { DeactivateCardValue } from "enums/deactivate-card-value";
import { prettifyDeactivateCardValue } from "utils/pretty.utils";
import { AccountStatus } from "enums";

const TransactionModal = ({
  close,
  status,
  active,
  onSubmit,
  remarks = "",
  onChangeRemarks,
  loading,
  title,
  description,
  label,
  actionText,
  value,
  setValue,
}) => {
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        {description}
        {status === AccountStatus.Deactivated ? (
          <>
            <Field
              label={label}
              labelPosition="left"
              childrenStyle={{
                width: "400px",
              }}
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <Autocomplete
                name="voidTransaction"
                value={value}
                onChange={(name, { value }) => {
                  if (value !== DeactivateCardValue.Others) {
                    onChangeRemarks(value);
                  } else {
                    onChangeRemarks("");
                  }
                  setValue(value);
                }}
                options={[
                  DeactivateCardValue.CustomerRequest,
                  DeactivateCardValue.FraudRelatedConcern,
                  DeactivateCardValue.Others,
                ].map((item) => ({
                  label: prettifyDeactivateCardValue(item),
                  value: item,
                }))}
              />
              {value === DeactivateCardValue.Others && (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      multiline
                      rowsMax={1}
                      onChange={(n, { value }) => {
                        onChangeRemarks(value);
                      }}
                      value={remarks}
                    ></TextField>
                  </div>
                </>
              )}
            </Field>
          </>
        ) : (
          <Field
            label={label}
            labelPosition="left"
            childrenStyle={{
              width: "400px",
            }}
            style={{
              display: "flex",
              alignItems: "start",
            }}
          >
            <div>
              <TextField
                multiline
                rowsMax={1}
                placeholder={locale.enterOtherReasonHere}
                onChange={(n, { value }) => {
                  onChangeRemarks(value);
                  setValue(value);
                }}
                value={remarks}
              ></TextField>
            </div>
          </Field>
        )}
      </div>
      <ActionButton
        right
        loading={loading}
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
              setValue("");
            },
          },
          {
            onClick: () => {
              onSubmit(remarks);
              setValue("");
            },
            text: actionText,
            disabled: remarks?.length === 0 || !value,
          },
        ]}
      />
    </Modal>
  );
};

export default TransactionModal;
