import classNames from "classnames";
import GroupCheckbox from "components/commons/group-checkbox/group-checkbox";
import { Products } from "enums";
import locale from "localization";
import React from "react";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./select-product-checkbox.module.scss";
const SelectProductCheckbox = ({ onChange, value, name }) => {
  return (
    <GroupCheckbox
      className={styles.productCode}
      title={locale.availableProducts}
      options={[
        {
          content: (
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles.diesel)}></div>
              {prettifyProduct(Products.Diesel)}
            </div>
          ),
          value: Products.Diesel,
        },
        {
          content: (
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles.gas91)}></div>
              {prettifyProduct(Products.Gas91)}
            </div>
          ),
          value: Products.Gas91,
        },
        {
          content: (
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles.gas95)}></div>
              {prettifyProduct(Products.Gas95)}
            </div>
          ),
          value: Products.Gas95,
        },
        {
          content: (
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles.gas97)}></div>
              {prettifyProduct(Products.Gas97)}
            </div>
          ),
          value: Products.Gas97,
        },
      ]}
      value={value}
      onChange={(_, { value }) => {
        onChange(name, { value });
      }}
    />
  );
};

export default SelectProductCheckbox;
