import locale from "localization";

export const cardListColumns = [
  { key: "loyalCardID", text: locale.loyalCardID, width: "7%" },
  { key: "vipCardNumber", text: locale.vipCardNumber, width: "12%" },
  { key: "vipPoints", text: locale.vipPoints, width: "12%" },
  { key: "assignedStationOrCaravan", text: locale.assignedStationOrCaravan, width: "12%" },
  { key: "dateProcured", text: locale.dateProcured, width: "10%" },
  { key: "dateRegistered", text: locale.dateRegistered, width: "12%" },
  { key: "statusDate", text: locale.statusDate, width: "10%" },
  { key: "remarks", text: locale.remarks, width: "10%" },
  { key: "actions", text: locale.actions, width: "3%" },
];
