import React, { useRef, useState } from "react";
import { useModal, useRouter } from "hooks";
import locale from "localization";
import styles from "./account-edit.module.scss";
import { FormMode } from "enums";
import { useHistory } from "react-router-dom";
import { Intro } from "components/commons";
import AccountDetailsModule from "../account-details/account-details.module";

const EditFleetAccountModule = (props) => {
  const accountDetailsRef = useRef();
  const history = useHistory();
  const { query } = useRouter();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const fleetId = query?.id ?? "";
  const successModal = useModal();

  return (
    <div>
      <div className={styles.intro}>
        <Intro
          actionText={locale.save}
          actionOnClick={() => {
            accountDetailsRef.current.handleUpdate();
          }}
          actionClassName={styles.primarySaveButton}
          actionLoading={updateLoading}
          actionDisabled={disableUpdate}
          actionSecondaryText={locale.cancel}
          actionSecondaryOutlined
          actionSecondaryOnClick={() => {
            history.goBack();
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
          title={locale.editFleetAccount}
        />
        <AccountDetailsModule
          pageMode={FormMode.Edit}
          fleetId={fleetId}
          setUpdateLoading={setUpdateLoading}
          setDisableUpdate={setDisableUpdate}
          ref={accountDetailsRef}
          {...successModal}
        />
      </div>
    </div>
  );
};

export default EditFleetAccountModule;
