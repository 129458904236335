import { Text } from "components/commons";
import locale from "localization";

export const transactionHistoryColumns = [
  {
    key: "vipTransactionId",
    text: <Text style={{ width: "100px" }}>{locale.vipTransactionId}</Text>,
  },
  {
    key: "nameAndMobileNumber",
    text: locale.nameAndMobileNumber,
    width: "100px",
  },
  {
    key: "vipCardNumber",
    text: <Text style={{ width: "50px" }}>{locale.vipCardNumber}</Text>,
  },
  {
    key: "station",
    text: <Text style={{ width: "100px" }}>{`SEAOIL ${locale.stationBranch}`}</Text>,
    style: {
      width: "150px",
    },
  },
  {
    key: "transactionAmount",
    text: <Text style={{ width: "100px" }}>{locale.transactionAmount}</Text>,
  },
  { key: "productType", text: <Text style={{ width: "" }}>{locale.productType}</Text> },

  { key: "pointsEarned", text: locale.pointsEarned, width: "6%" },
  { key: "orNumber", text: locale.orNumber, width: "6%" },
  { key: "awardType", text: locale.awardType, width: "6%" },
  {
    key: "dateOfTransaction",
    text: <Text style={{ width: "100px" }}>{locale.dateOfTransaction}</Text>,
  },
  { key: "status", text: locale.status, style: { width: "60px" } },
  { key: "remarks", text: locale.remarks, width: "10%" },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },

  // station portal
  // { key: "vipTransactionId", text: locale.vipTransactionId, width: "8%" },
  // { key: "vipCardNumber", text: locale.vipCardNumber, width: "12%" },
  // { key: "mobileWithHash", text: locale.mobileWithHash, width: "15%" },
  // { key: "transactionAmount", text: locale.transactionAmount, width: "8%" },
  // { key: "productTypeCapitalize", text: locale.productType, width: "8%" },
  // { key: "pointsEarned", text: locale.pointsEarned, width: "6%" },
  // { key: "orNumber", text: locale.orNumber, width: "6%" },
  // {
  //   key: "dateOfTransaction",
  //   text: locale.dateOfTransaction,
  //   style: { minWidth: "12%" },
  // },
];
