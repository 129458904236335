import React from "react";
import MaterialModal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./loader.module.scss";

const Loader = ({ open }) => {
  return (
    <MaterialModal className={styles.loader} open={open || false}>
      <div className={styles.circleContainer}>
        <CircularProgress className={styles.circle} />
      </div>
    </MaterialModal>
  );
};

export default Loader;
