import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { generateReportAdmin, getPriceChanges } from "apis";
import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { SuccessModal } from "components/modals";
import { Path, UserAccess } from "enums";
import useDataTable from "hooks/useDataTable";
import useExport from "hooks/useExport";
import locale from "localization";

import { pricingChangesColumns } from "./pricing-changes-columns";
import styles from "./pricing-changes.module.scss";
import PriceChangesFilter from "./pricing-changes-filter";
import { mapDataToList, mapFilterToRequest } from "./pricing-changes-mapper";
import { pricingChangesFilterState } from "./pricing-changes-filter.state";
import { UserContext } from "contexts";

const PricingChangesModule = () => {
  const history = useHistory();
  const { sessionId: connectionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PricingChanges;
  });

  const { filter, search, table } = useDataTable({
    api: { api: getPriceChanges },
    filter: { initialState: pricingChangesFilterState(), mapper: mapFilterToRequest },
    table: { key: "fleetAuditTrails", mapper: mapDataToList, columns: pricingChangesColumns },
  });

  const exportReport = useExport({
    hasModal: true,
    api: generateReportAdmin,
    reportType: "pricing-changes",
    mappedFilterState: { ...filter?.mappedFilterState, searchKey: search?.searchKey, connectionId },
  });

  return (
    <>
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro
          title={locale.pricingChanges}
          subtitle={locale.viewAndTrackChangesOnPlbInformation}
          actionText={
            getUserAccess?.fullAccess || getUserAccess?.modifyAccess
              ? locale.applyPriceChangesToMultipleAccount
              : ""
          }
          actionOnClick={() => {
            history.push(Path.BatchPricingChanges);
          }}
        />
      </div>
      <div className={styles.filters}>
        <PriceChangesFilter
          {...filter}
          {...search}
          {...exportReport}
          getUserAccess={getUserAccess}
        />
      </div>
      <div className={styles?.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default PricingChangesModule;
