import React, { useState } from "react";
import { TextField } from "..";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styles from "./password-field.module.scss";
const PasswordField = ({ ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <TextField
      {...props}
      type={visible ? "text" : "password"}
      suffix={
        <div className={styles.clickable} onClick={() => setVisible(!visible)}>
          {visible ? (
            <VisibilityOff className={styles.icon} />
          ) : (
            <Visibility className={styles.icon} />
          )}
        </div>
      }
    />
  );
};

export default PasswordField;
