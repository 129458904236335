import locale from "localization";
import CustomerStatusRemarks from "enums/customer-status-remarks";
import { prettifyCustomerRemarks } from "utils/pretty.utils";

export const initialState = {
    remarks: {
      name: "remarks",
      value: "",
      placeholder: "Select",
      required: true,
      label: `${locale.reason}/${locale.remarks}`,
      options: [
        {
            label: prettifyCustomerRemarks(CustomerStatusRemarks.CustomerRequest),
            value: CustomerStatusRemarks.CustomerRequest
        },
        {
            label: prettifyCustomerRemarks(CustomerStatusRemarks.Fraud),
            value: CustomerStatusRemarks.Fraud
        },
        {
            label: prettifyCustomerRemarks(CustomerStatusRemarks.Others),
            value: CustomerStatusRemarks.Others
        },
      ]
    },
    others: {
      name: "others",
      value: '',
      maxLength: 255,
      multiline: true,
      placeholder: locale.enterRemarks
    }
}