import { Intro, BackLink, Button } from "components/commons";
import { InputFile } from "components/field";
import { Path } from "enums";
import React, { useCallback, useState } from "react";
import styles from "./batch-pricing-changes.module.scss";
import BatchPricingChangesModal from "./batch-pricing-changes-modal";
import { useModal } from "hooks";
import { useApi } from "hooks";
import locale from "localization";
import { batchUpdateVirtualStation } from "apis/virtual-station.api";

const allowedTypes = ["csv"];

const BatchPricingChangesModule = () => {
  const batchPricingChangesModal = useModal();
  const [fileInfo, seFileInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const {
    request: batchUpdateVirtualStationRequest,
    loading: loadingBatchUpdateVirtualStation,
    result: batchUpdateVirtualStationResult = { message: [], errorCode: null, count: 0 },
  } = useApi({
    api: batchUpdateVirtualStation,
    pageError: true,
  });

  const handleUpload = useCallback(async () => {
    const params = { fileInfo };

    await batchUpdateVirtualStationRequest({ params });

    batchPricingChangesModal.show();
  }, [fileInfo, batchPricingChangesModal, batchUpdateVirtualStationRequest]);

  const getFileInfoCB = useCallback(
    (fileInfo) => {
      seFileInfo(fileInfo);
    },
    [seFileInfo]
  );

  const { message } = batchUpdateVirtualStationResult;
  const handleClose = () => {
    document.getElementById("btn-upload").value = "";
    seFileInfo(null);
    setSelectedFile(null);
  };

  return (
    <div>
      <BackLink text={locale.pricingChanges} path={Path.PricingChanges} />
      <Intro title={locale.applyPriceChangesToMultipleAccount} />
      <div className={styles.inputFile}>
        <InputFile
          allowedTypes={allowedTypes}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          getFileInfo={getFileInfoCB}
        />
      </div>

      <div className={styles.saveButton}>
        <Button
          loading={loadingBatchUpdateVirtualStation}
          disabled={!fileInfo || loadingBatchUpdateVirtualStation}
          onClick={handleUpload}
          className={styles.button}
          primary
        >
          Save
        </Button>
      </div>

      <BatchPricingChangesModal
        data={message ? message : batchUpdateVirtualStationResult.data}
        onClose={handleClose}
        success={message}
        {...batchPricingChangesModal}
      />
    </div>
  );
};

export default BatchPricingChangesModule;
