const ErrorCode = {
  IncorrectCredentials: "P1002",
  DeactivatedFuelCode: "FC1001",
  NotYetValidFuelCode: "FC1002",
  ExpiredFuelCode: "FC1003",
  ReachedLimitFuelCode: "FC1004",
  InvalidFuelCode: "FC1005",
  FuelCodeNotEnoughBalance: "FC1007",
  InsufficientBalance: "W1001",
  NoProductFuelBalance: "FB1002",
  NotActiveDriver: "FC1011",
  NotActiveVehicle: "FC1012",
  InvalidInviteUserToken: "P1017",
  ExpiredInviteUserToken: "P1018",
  InvalidCsvFormat: "E9997",
  SupplierWppDoesNotHaveThisProductCode: "SWP1000",
  InvalidSupplierWppValue: "SWP1001",
  VoucherCampaignPrefixAlreadyExist: "VC1000",
  VoucherCampaignNameAlreadyExist: "VC1008",
};

export default ErrorCode;
