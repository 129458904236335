import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getStationsNonFuel } from "apis/non-fuel.api";
import { useApi } from "hooks";
const SelectStation = ({ placeholder = "", ...props }) => {
  const {
    request,
    loading,
    result = { stations: [], count: 0 },
  } = useApi({
    api: getStationsNonFuel,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        isPlbOnboarded: true,
        ...params,
        perPage: 10000,
        page: params.page || 1,
      });
    },
    [request]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { stations = [] } = result || {};
        if (stations && stations.length > 0) {
          const newStations = stations;

          return newStations.map((d) => {
            return {
              label: d.name,
              value: d.stationId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
      {...{ allOption: [{ label: "All", value: null }] }}
    />
  );
};

export default SelectStation;
