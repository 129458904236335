import { Intro, DataTable, Text } from "components/commons";
import { useApi, useFilter, useMount } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { priceBuildupColumns } from "./price-buildup-columns";
import { priceBuildupFilterState } from "./price-buildup-filter.state";
import styles from "./price-buildup.module.scss";
import locale from "localization";
import PriceBuildupFilter from "./price-buildup-filter";
import { getPriceBuildup, getSeaoilPriceTool } from "apis/station.api";
import classNames from "classnames";
import { prettifyProduct } from "utils/pretty.utils";
import { formatAmount } from "utils";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { SuccessModal } from "components/modals";
import { UserContext } from "contexts";
import { UserAccess } from "enums";

const PriceBuildupModule = () => {
  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(priceBuildupFilterState());

  const { sessionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PriceBuildUp;
  });

  const {
    request: getPriceBuildupRequest,
    loading: fetchingPriceBuildupResult,
    result: getPriceBuildupRequestResult = { stations: [], count: 0 },
  } = useApi({
    api: getPriceBuildup,
    pageError: false,
  });

  const { request: getSeaoilPriceToolRequest, loading: syncingSeaoilPriceTool } = useApi({
    api: getSeaoilPriceTool,
    pageError: false,
  });

  useMount(() => {
    fetchPriceBuildup(requestState);
  });

  const fetchPriceBuildup = useCallback(
    async (requestState) => {
      const newRequestState = submitFilter(requestState);
      let stationTypes = newRequestState.stationTypes;

      if (stationTypes) {
        const index = stationTypes.indexOf("all");

        if (index !== -1) {
          delete newRequestState.stationTypes;
        } else {
          newRequestState.stationTypes = stationTypes.toString();
        }
      } else {
        delete newRequestState.stationTypes;
      }

      await getPriceBuildupRequest(newRequestState);
    },
    [getPriceBuildupRequest, submitFilter]
  );

  const preparedMopsData = useMemo(() => {
    const { stations } = getPriceBuildupRequestResult;
    if (stations && stations.length > 0) {
      const preparedData = stations.map((d) => {
        const map = new Map();
        map.set(
          "name",
          <div>
            <Text>{d.name}</Text>
            <Text className={styles.lowerText}>{d.stationType}</Text>
          </div>
        );
        map.set(
          "stationCode",
          <div>
            <Text>{d.stationCode}</Text>
          </div>
        );

        map.set(
          "product",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles[item.productCode])}></div>
                    {prettifyProduct(item.productCode)}
                  </div>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "supplierWpp",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.supplierWpp ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "sdum",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.sdum ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "freight",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.freight ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "sdumf",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.sdumf ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "orum",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.orum ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "creditPrice",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.price ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "floorPrice",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.floorPrice ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "grossFloorMargin",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.grossFloorMargin ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "basementPrice",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.basementPrice ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );

        map.set(
          "grossBasementPrice",
          <div>
            {d.stationPriceBuildUp.map((item, index) => {
              return (
                <div key={index}>
                  <Text>{formatAmount(item.grossBasementMargin ?? 0)}</Text>
                </div>
              );
            })}
          </div>
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getPriceBuildupRequestResult]);

  const onStationTypeCb = useCallback(
    (stationTypes) => {
      modifyFilters({ stationTypes, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchPriceBuildup(requestState);
    },
    [modifyFilters, fetchPriceBuildup]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchPriceBuildup(requestState);
    },
    [modifyFilters, fetchPriceBuildup]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchPriceBuildup(requestState);
    },
    [modifyFilters, fetchPriceBuildup]
  );
  const onDownloadFileCb = async () => {
    // const { requestState } = modifyFilters({ reportType: "price-buildup" });
    // delete requestState.perPage;
    // delete requestState.page;
    // const { downloadUrl } = await generateReportRequest(requestState);
    // window.open(downloadUrl, "_blank");
  };

  const onSyncSeaoilPriceToolCb = async () => {
    await getSeaoilPriceToolRequest({ isManualTrigger: true });
    const { requestState } = clearFilter();
    fetchPriceBuildup(requestState);
  };

  const exportReport = useExport({
    hasModal: true,
    api: generateReportAdmin,
    reportType: "price-buildup",
    mappedFilterState: {
      ...requestState,
      stationTypes: requestState?.stationTypes?.join(","),
      connectionId: sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro title={locale.priceBuildup} subtitle={locale.viewSationPricesAndMargin} />
      </div>
      <div className={styles.filters}>
        <PriceBuildupFilter
          filterState={filterState}
          onStationTypeChange={onStationTypeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onFieldChange={(name, { value }) => {
            modifyFilter(name, { value: value || "all" });
          }}
          resetFilter={() => {
            modifyFilters({
              stationType: "all",
              ...submittedFilter,
            });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchPriceBuildup(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchPriceBuildup(requestState);
          }}
          filterCount={filterCount}
          onDownloadFile={onDownloadFileCb}
          onSyncSeaoilPriceTool={onSyncSeaoilPriceToolCb}
          syncingSeaoilPriceTool={syncingSeaoilPriceTool}
          {...exportReport}
          // fetchingReport={fetchingReport}
          getUserAccess={getUserAccess}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.table}>
          <DataTable
            loading={fetchingPriceBuildupResult}
            page={filterState.page}
            pageSize={filterState.perPage}
            columns={priceBuildupColumns}
            data={preparedMopsData}
            dataCount={getPriceBuildupRequestResult.count}
            onChangePage={onChangePageCb}
            onChangePageSize={onChangePageSizeCb}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceBuildupModule;
