import React, { useState } from "react";
import styles from "./input-chip.module.scss";
import classNames from "classnames";
import ChipInput from "material-ui-chip-input";
import { Chip } from "@material-ui/core";
import { AccountStatus } from "enums";
import { prettifyAccountStatus } from "utils";
import locale from "localization";

const InputChip = ({
  value = [],
  handlePushValue,
  onDelete,
  className,
  error,
  helperText,
  disabled,
  validator,
}) => {
  const [text, setText] = useState("");
  const exists = text.trim() && value.includes(text.trim());

  const onAddUp = (e) => {
    if (!exists) handlePushValue(e.trim());
    setText("");
  };
  const chipRenderer = ({ chip, className, handleClick, handleDelete }, key) => {
    const chipDisplay = chip.emailAddress ? chip.emailAddress : chip;
    return (
      <Chip
        className={className}
        key={key}
        label={
          <div className={styles.chipComponent}>
            <div>{chipDisplay}</div>
            {chip.status && (
              <div
                className={classNames(styles.status, {
                  [styles.grass]: chip.status === AccountStatus.Active,
                  [styles.cheddar]: chip.status === AccountStatus.Pending,
                  [styles.deepRed]: chip.status === AccountStatus.Deactivated,
                  [styles.cement]: chip.status === AccountStatus.Expired,
                })}
              >
                {prettifyAccountStatus(chip.status)}
              </div>
            )}
            {chip.resend && (
              <div className={classNames(styles.status, styles.deepRed)}>{locale.forResending}</div>
            )}
            {chip.new && (
              <div className={classNames(styles.status, styles.grass)}>{locale.newUser}</div>
            )}
          </div>
        }
        onClick={handleClick}
        onDelete={handleDelete}
        disabled={disabled}
      />
    );
  };
  const { error: isError, message } = validator(text);

  return (
    <div>
      <ChipInput
        disabled={disabled}
        blurBehavior="add"
        value={value || []}
        onAdd={onAddUp}
        newChipKeyCodes={[188, 32]}
        onDelete={onDelete}
        className={classNames(styles.chipItem, className, {
          [styles.error]: error || isError,
        })}
        InputProps={{
          value: text,
          onChange: (e) => {
            const val = e?.target.value.toString();
            setText(val);
          },
          onBlur: () => {
            onAddUp(text);
          },
          onKeyUp: (e) => {
            if (e.key === "Enter" && onAddUp) {
              onAddUp(e?.target.value.trim());
            }
          },
        }}
        chipRenderer={chipRenderer}
      />
      <span
        className={classNames(styles.helperText, { [styles.error]: error || isError || exists })}
      >
        {exists ? locale.populate(locale.alreadyExists, [text]) : message || helperText}
      </span>
    </div>
  );
};

export default InputChip;
