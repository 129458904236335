const Colors = {
  Gray: "#667085",
  Black: "#000000",
  White: "#ffffff",
  Green: "#1db457",
  Red: "#f04438",
  Washed: "#6670857d",
  Gray50: "#F9FAFB",
  Green50: "#93c3a5",
  Blue: "#1570EF",
  Danger: "#9C2A10",
  Green2: "#A5E1BC",
  LightGreen: "#ecf8e5",
  Gray100: "#98A2B3",
  Gray200: "#ebecf0",
  DarkGreen: "#01612C",
  LightBlue: "#138CE7",
};

export default Colors;
