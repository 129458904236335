import { Autocomplete, Field } from "components/commons";
import React from "react";
import locale from "localization";
import { PLCStatus } from "enums";
import { prettifyPLCStatus } from "utils/pretty.utils";

const SelectPLCStatus = ({
  onChange,
  name,
  value,
  className,
  label = locale.plcStatus,
  labelClassName,
  fieldClassName,
  labelPosition,
  multiple,
  hasAll,
}) => {
  return (
    <Field
      className={className}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      childrenClassName={fieldClassName}
    >
      <Autocomplete
        value={value}
        name={name}
        placeholder={"Select"}
        multiple={multiple}
        hasAll={hasAll}
        options={[
          PLCStatus.NotOnboarded,
          PLCStatus.Active,
          PLCStatus.Deactivated,
          PLCStatus.Hidden,
        ].map((val) => ({
          label: prettifyPLCStatus(val),
          value: val,
        }))}
        onChange={(_, { value }) => onChange({ [name]: value })}
      />
    </Field>
  );
};

export default SelectPLCStatus;
