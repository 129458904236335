import { useCallback } from "react";
import { useState } from "react";
import useMount from "./useMount";

const useTable = ({
  request,
  columns: col = [],
  initialColumns = [],
  data,
  mapper,
  count,
  ...props
}) => {
  const [pageState, setPageState] = useState({
    page: 1,
    perPage: 10,
  });
  const [isReverse, setIsReverse] = useState(false);
  const [columns, setColumns] = useState([]);
  const [excluded, setExcluded] = useState([]);

  useMount(() => {
    setColumns(
      initialColumns.length
        ? col.filter(({ key }) => {
            return initialColumns.includes(key);
          })
        : col
    );
    setExcluded(
      initialColumns.length
        ? col
            .filter(({ key }) => {
              return !initialColumns.includes(key);
            })
            .map(({ key }) => key)
        : []
    );
  });

  const modifyPage = useCallback(
    ({ page, perPage, isReverse: isReverseValue }) => {
      const newPageState = { page: page || pageState.page, perPage: perPage || pageState.perPage };
      setPageState(newPageState);
      if (isReverseValue != null) {
        setIsReverse(isReverseValue);
      }
      return { ...newPageState, isReverse: isReverseValue !== null ? isReverseValue : isReverse };
    },
    [pageState, isReverse]
  );

  return {
    pageState,
    modifyPage,
    columns,
    allColumns: col,
    excluded,
    setExcluded,
    data: data.map((d) => {
      const dat = mapper ? mapper(d) : d;
      const map = new Map();
      Object.keys(dat).forEach((key) => {
        map.set(key, dat[key]);
      });

      return map;
    }),
    resetActiveColumn: () => {
      const selected = columns.map((item) => item.key);
      setExcluded(col.filter((item) => !selected.includes(item.key)).map((item) => item.key));
    },
    onChangeActiveColumns: (keys) => {
      setColumns(col.filter((c) => keys.includes(c.key)));
    },
    dataCount: count,
    // resetPage: () => {
    //   setPage(1);
    // },
    isReverse,
    ...props,
  };
};

export default useTable;
