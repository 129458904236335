const CashInStatus = {
  Completed: "completed",
  Cancelled: "cancelled",
  Success: "success",
  Pending: "pending",
  Failed: "failed",
  Expired: "expired",
  Voided: "voided",
};

export default CashInStatus;
