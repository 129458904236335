const StationStatus = {
  ForActivation: "for-activation",
  Pending: "pending",
  Active: "active",
  Expired: "expired",
  Deactivated: "deactivated",
  Hidden: "hidden",
};

export default StationStatus;
