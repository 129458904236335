import locale from "localization";

export const priceBuildupColumns = [
  { key: "name", text: locale.stationNameStationType, width: "18%" },
  { key: "stationCode", text: locale.stationCode, width: "8%" },
  { key: "product", text: locale.product, width: "8%" },
  // { key: "supplierWpp", text: locale.supplierWppLabel, width: "8%" },
  { key: "sdum", text: locale.sdum, width: "8%" },
  { key: "freight", text: locale.freight, width: "8%" },
  { key: "sdumf", text: locale.sdumf, width: "8%" },
  // { key: "orum", text: locale.orum, width: "8%" },
  { key: "creditPrice", text: locale.creditPriceHistory, width: "8%" },
  { key: "floorPrice", text: locale.floorPrice, width: "8%" },
  { key: "grossFloorMargin", text: locale.grossFloorMargin, width: "8%" },
  { key: "basementPrice", text: locale.basementPrice, width: "8%" },
  { key: "grossBasementPrice", text: locale.grossBasementPrice, width: "8%" },
];
