const StationHistoryField = {
  BusinessName: "business-name",
  StationType: "station-type",
  DepotCode: "depot-code",
  Diesel: "diesel",
  Gas91: "gas91",
  Gas95: "gas95",
  Gas97: "gas97",
  CompanyGroup: "company-group",
};

export default StationHistoryField;
