import React from "react";
import classNames from "classnames";
import styles from "./icon.module.scss";
import { IconNames } from "./icon-names";
import Colors from "enums/colors";
import { Size } from "enums/size";

export const Icons = IconNames;

const Icon = ({ icon, style, color = Colors.Gray, size = Size.Medium, customSize, ...props }) => {
  return (
    <img
      alt={icon}
      {...props}
      src={icon}
      className={classNames({
        [`${styles.black}`]: color === Colors.Black,
        [`${styles.gray}`]: color === Colors.Gray,
        [`${styles.green}`]: color === Colors.Green,
        [`${styles.red}`]: color === Colors.Red,
        [`${styles.small}`]: !customSize && size === Size.Small,
        [`${styles.medium}`]: !customSize && size === Size.Medium,
        [`${styles.large}`]: !customSize && size === Size.Large,
        [`${styles.xlarge}`]: !customSize && size === Size.XLarge,
      })}
      style={{
        fill: color,
        display: "flex",
        alignItems: "center",
        height: customSize ? `${customSize}px!important` : "20px",
        width: customSize ? `${customSize}px!important` : "20px",
        cursor: props.onClick ? "pointer" : "",
        ...style,
      }}
    />
  );
};

export default Icon;
