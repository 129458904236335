import { Modal, Image, Title, Text, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import styles from "./result-activate-account.module.scss";
import { useHistory } from "react-router-dom";
import { Path } from "enums";
import { SuccessActivateAccount } from "images";

const ResultActivateAccount = ({
  active,
}) => {
  const history = useHistory();

  return (
    <div>
      <Modal className={styles.modal} active={active} closable={false} removePadding={true}>
        <div className={styles.container}>
          <Image src={SuccessActivateAccount} />
          <Title medium className={styles.title}>{`${locale.success}!`}</Title>
          <Text>{locale.yourAccountHasBeenActivated}</Text>
          <Text>{locale.youCanNowLoginIn}</Text>
        </div>
        <ActionButton
          center
          items={
            [
              {
                text: locale.goToLogin,
                primary: true,
                onClick: () => {
                  history.push(Path.Auth);
                },
              }
            ]
          }
        />
      </Modal>
    </div>
  );
};

export default ResultActivateAccount;
