import React from "react";
import Modal from "../modal/modal";
import styles from "./preview-modal.module.scss";

const PreviewModal = ({ children, ...modal }) => {
  return (
    <Modal
      {...modal}
      className={styles.modal}
      contentStyle={styles.modalContent}
      closeOnDimmer
      containerStyle={styles.container}
      onClickBody={modal.close}
    >
      {children}
    </Modal>
  );
};

export default PreviewModal;
