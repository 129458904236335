import React, { useMemo, useCallback, useState } from "react";
import {
  ActionButton,
  Modal,
  Field,
  Text,
  Title,
  Dropdown,
  Button,
  PopOverMenu,
  Image,
} from "components/commons";
import { RemoveIcon } from "images";
import locale from "localization";
import styles from "./edit-attribute-modal.module.scss";
import { initialState as formState } from "./edit-attribute.state";
import { useForm } from "hooks";

const EditAttributeModal = ({
  userId,
  submit,
  request,
  fixDisplay,
  initialState,
  attributes,
  count,
  loading,
  submitting,
  attributeInput,
  setAttributeInput,
  cohortInputs,
  setCohortInputs,
  clearAttributeForm,
  onChangeCohortCb,
  ...modalState
}) => {
  const { close, active } = modalState;
  const [activeClicked, setActiveClicked] = useState(false);

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    // dirty
  } = useForm({
    initialState: form,
  });

  // const invalidForm = useMemo(() => {
  //   return attributeInput?.length !== cohortInputs?.length;
  // }, [attributeInput, cohortInputs]);

  const onChangeAccountTypeCb = useCallback(
    (value) => {
      modifyField(fields.accountType.name, { value: value });
    },
    [fields.accountType.name, modifyField]
  );

  const preparedAttributes = useMemo(() => {
    let preparedData = [];

    attributes?.forEach((attribute) => {
      const selected = Object.keys(attributeInput).some((k) => {
        return attributeInput[k].name === attribute?.name;
      });
      if (!selected) {
        preparedData.push({
          content: attribute?.name,
          onClick: () => {
            if (attribute) {
              setAttributeInput([
                ...attributeInput,
                { name: attribute?.name, cohorts: attribute?.cohorts },
              ]);
            }
          },
        });
      }
    });
    return preparedData;
  }, [attributeInput, attributes, setAttributeInput]);

  const removeAttribute = useCallback(
    (attributeName, index) => {
      let cloneCohort = [...cohortInputs];
      cloneCohort.splice(index, 1);
      setCohortInputs(cloneCohort);
      setAttributeInput(attributeInput.filter((attribute) => attribute.name !== attributeName));
    },
    [attributeInput, cohortInputs, setAttributeInput, setCohortInputs]
  );

  const submitCb = useCallback(() => {
    submit({
      accountType: { userId, accountTypeId: fields?.accountType?.value },
      cohort: { userId, cohortIds: cohortInputs },
    });
  }, [cohortInputs, fields?.accountType?.value, submit, userId]);

  return (
    <Modal
      active={active}
      title={locale.editAttributes}
      close={() => {
        clearAttributeForm();
        close();
      }}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <Field className={styles.fieldLabel} {...fixDisplay?.mobileNumber}>
          <Text className={styles.textStyle}>{fixDisplay?.mobileNumber?.value}</Text>
        </Field>
        <Title xsmall className={styles.titleStyle}>
          {locale.attributes}
        </Title>
        <div className={styles.attributeContent}>
          <Field className={styles.fieldLabel} {...fields?.accountType}>
            <Dropdown
              dropdownClassName={styles.dropdown}
              value={fields?.accountType?.value}
              type="simple"
              options={fields?.accountType?.options}
              onChange={onChangeAccountTypeCb}
            />
          </Field>
          {attributeInput?.map((attribute, index) => (
            <Field className={styles.fieldLabel} label={attribute?.name} horizontal key={index}>
              <div className={styles.attributesInput}>
                <Dropdown
                  key={index}
                  name={index.toString()}
                  dropdownClassName={styles.dropdown}
                  type="simple"
                  options={attribute?.cohorts?.map((cohort) => {
                    return {
                      label: cohort.name,
                      value: cohort.cohortId,
                    };
                  })}
                  onChange={onChangeCohortCb}
                  value={cohortInputs[index]}
                  // useEventAttribute={true}
                />
                <span
                  onClick={() => removeAttribute(attribute?.name, index)}
                  className={styles.removeCohort}
                >
                  <Image src={RemoveIcon} />
                </span>
              </div>
            </Field>
          ))}
        </div>

        <div className={styles.addCustomAttributeContainer}>
          <PopOverMenu
            options={preparedAttributes}
            position="top"
            disabledClick={count === attributeInput?.length}
            setActiveClicked={setActiveClicked}
          >
            <Button
              className={styles.addCustomAttributeButton}
              outline={!activeClicked}
              loading={loading}
              disabled={count === attributeInput?.length}
              primary={activeClicked}
            >
              {locale.addCustomAttribute}
            </Button>
          </PopOverMenu>
        </div>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              clearAttributeForm();
              close();
            },
            text: locale.cancel,
            disabled: submitting,
          },
          {
            onClick: () => {
              submitCb();
            },
            text: locale.save,
            loading: submitting,
            // disabled: invalidForm,
          },
        ]}
      />
    </Modal>
  );
};

export default EditAttributeModal;
