import { Text, Product, PopOverList, Button } from "components/commons";
import styles from "./customer-list.module.scss";
import classNames from "classnames";
import { formatDate, formatPesoWithDecimalRange, capitalize } from "utils";
import { CustomerStatus, DateTime, Products } from "enums";
import locale from "localization";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { prettifyCustomerStatus, prettifyVipType } from "utils/pretty.utils";
import { formatNumber } from "utils";

export const mapDataToList = ({ customers, actionComponent, literBalance, customerProfile }) => {
  const { Gas91, Gas95, Gas97, Diesel } = Products;

  if (!!customerProfile?.updatedProfile) {
    customers =
      customers?.userId === customerProfile.updatedProfile.userId
        ? { ...customers, ...customerProfile.updatedProfile }
        : customers;
  }

  const {
    firstName,
    lastName,
    mobileNumber,
    email,
    referenceStation,
    selectedStation,
    createdAt,
    walletBalance,
    fuelBalances,
    updatedAt,
    status,
    vipType,
    loyaltyCards,
    vipPoints,
  } = customers;

  const cardType =
    loyaltyCards && loyaltyCards.length > 0
      ? loyaltyCards.map((item) => (item.source === "physical" ? "physical" : item.source))
      : locale.virtual;

  return {
    fullName: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {firstName && firstName.toLowerCase()}
        </Text>
        <Text className={styles.lowerText} small>
          {lastName && lastName.toLowerCase()}
        </Text>
      </div>
    ),
    contact: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {mobileNumber && mobileNumber}
        </Text>
        <Text className={classNames(styles.lowerText, styles.lowerCase)} small>
          {email && email.toLowerCase()}
        </Text>
      </div>
    ),
    vip: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {loyaltyCards.length === 0 ? (
            "--"
          ) : loyaltyCards.length > 1 ? (
            <PopOverList list={loyaltyCards.map((l) => l.cardNumber)}>
              <Button link className={styles.loyaltyCardButton}>
                {loyaltyCards.length}
              </Button>
            </PopOverList>
          ) : (
            loyaltyCards[0].cardNumber
          )}
        </Text>
        <Text className={styles.upperText} subtitle>
          {Array.isArray(cardType) ? capitalize(cardType[0]) : cardType}
        </Text>
      </div>
    ),
    loyaltyType: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {prettifyVipType(vipType)}
        </Text>
      </div>
    ),
    // vip: (
    //   <div className={styles.city}>
    //     <Text className={styles.upperText} subtitle>
    //       {loyaltyCards.length === 0 ? (
    //         "--"
    //       ) : loyaltyCards.length > 2 ? (
    //         <PopOverList list={loyaltyCards.map((l) => l.cardNumber)}>
    //           <Button link className={styles.loyaltyCardButton}>
    //             {loyaltyCards.length}
    //           </Button>
    //         </PopOverList>
    //       ) : (
    //         loyaltyCards[0].cardNumber
    //       )}
    //     </Text>
    //     <Text className={styles.upperText} subtitle>
    //       {Array.isArray(cardType) ? capitalize(cardType[0]) : cardType}
    //     </Text>
    //   </div>
    // ),
    // loyaltyType: (
    //   <div className={styles.city}>
    //     <Text className={styles.upperText} subtitle>
    //       {prettifyVipType(vipType)}
    //     </Text>
    //   </div>
    // ),
    station: (
      <div className={styles.city}>
        <Text className={classNames(styles.upperText, styles.uppercase)} subtitle>
          {referenceStation && referenceStation.name.toLowerCase()}
        </Text>
        <Text className={classNames(styles.lowerText, styles.uppercase)} small>
          {selectedStation && selectedStation.name.toLowerCase()}
        </Text>
      </div>
    ),
    registered: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {createdAt && formatDate(createdAt, "D MMM YYYY")}
        </Text>
        <Text className={classNames(styles.upperText, styles.lowerCase)} subtitle>
          {createdAt && formatDate(createdAt, "hh:mma")}
        </Text>
      </div>
    ),
    walletBalance: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {formatPesoWithDecimalRange(walletBalance ? walletBalance : 0, 2)}
        </Text>
      </div>
    ),
    product: (
      <div className={styles.product}>
        <Product cheddar>
          <Text className={styles.upperText} subtitle>
            {locale.diesel}
          </Text>
        </Product>
        <Product grass>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["91"])}
          </Text>
        </Product>
        <Product salsa>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["95"])}
          </Text>
        </Product>
        <Product deepBlue>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["97"])}
          </Text>
        </Product>
      </div>
    ),
    literBalance: (
      <div className={classNames(styles.product, styles.right)}>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Diesel)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas91)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas95)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas97)}
          </Text>
        </div>
      </div>
    ),
    vipPointsBalance: (
      <div className={styles.city}>
        {vipPoints ? (
          <Text className={styles.upperText} subtitle>
            {formatNumber(vipPoints, 2)}
          </Text>
        ) : (
          "-"
        )}
      </div>
    ),
    status: (
      <div className={styles.city}>
        <LabelAndPill
          label={updatedAt ? formatDate(updatedAt, DateTime.K) : "--"}
          pillProps={{
            grass: status === CustomerStatus.Active,
            cement: status === CustomerStatus.Deleted,
            deepRed: status === CustomerStatus.Blocked || status === CustomerStatus.Deactivated,
          }}
          pillLabel={prettifyCustomerStatus(status)}
        />
      </div>
    ),
    action: actionComponent({ name: `${firstName} ${lastName}`, data: customers }),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { status, redemptionStation, vipType, ...fs } = filterState;
  return {
    ...fs,
    status: !status?.isSelectedAll ? status.value.join(",") : null,
    vipType: !vipType?.isSelectedAll ? vipType.value.join(",") : null,
    referenceStationId: redemptionStation?.value,
  };
};
