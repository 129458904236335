import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getVoucherCampaigns = async ({ ...query }) => {
  const res = await Get(`${ApiPath.VoucherCampaigns}`, query);
  return res.data;
};
export const createVoucherCampaign = async ({ ...body }) => {
  const res = await Post(`${ApiPath.VoucherCampaigns}`, body);
  return res.data;
};

export const getVoucher = async ({ voucherCampaignId }) => {
  const res = await Get(`${ApiPath.VoucherCampaigns}/${voucherCampaignId}`);
  return res.data;
};

export const updateVoucherCampaign = async ({ voucherCampaignId, ...body }) => {
  const res = await Put(`${ApiPath.VoucherCampaigns}/${voucherCampaignId}`, body);
  return res.data;
};

export const validateVoucherRecipient = async (body) => {
  const res = await Post(`${ApiPath.ValidateVoucherRecipient}`, body);
  return res;
};
