import { Products, SettlementStatus } from "enums";
import RedemptionStatus from "enums/redemption-status";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  redemptionStation: {
    value: [],
    isAll: true,
  },
  settlementStatuses: [
    SettlementStatus.ForSettlement,
    SettlementStatus.ForProcessing,
    SettlementStatus.Processed,
  ],
  redemptionStatuses: [RedemptionStatus.Success, RedemptionStatus.Voided],
  page: 1,
  perPage: 10,
});
