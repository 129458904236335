import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getFleet = async ({ fleetId }) => {
  const res = await Get(`${ApiPath.Fleet}/${fleetId}`);
  return res.data;
};

export const getFleetByName = async ({ name }) => {
  const res = await Get(`${ApiPath.Fleet}/short-name/${name}`);
  return res.data;
};

export const getFleetAccounts = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Fleet}`, query);
  return res.data;
};

export const getUserFleets = async () => {
  const res = await Get(`${ApiPath.GetUserFleets}`);
  return res.data;
};

export const getFleetInvite = async ({ token }) => {
  const res = await Get(`${ApiPath.Fleet}/token/${token}`);
  return res.data;
};

export const getFleetPricing = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetFleetPriceTool}`, { ...query });
  return res.data;
};

export const acceptInvite = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Fleet}/invite/accept`, body);
  return res.data;
};

export const createFleetAccount = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Fleet}`, body);
  return res.data;
};

export const updateFleetAccount = async ({ fleetId, ...body }) => {
  const res = await Put(`${ApiPath.Fleet}/${fleetId}`, body);
  return res.data;
};

export const updateFleetPricing = async ({ fleetId, ...body }) => {
  const res = await Put(`${ApiPath.PricingDetails(fleetId)}`, body);
  return res;
};

export const updatePricingValue = async ({ fleetId, ...body }) => {
  const res = await Post(`${ApiPath.PricingDetails(fleetId)}/price-movement`, body);
  return res;
};

export const updateLoyaltyCardByCardNumber = async ({ cardNumber, ...body }) => {
  const res = await Put(`${ApiPath.VIPCard}/card-number/${cardNumber}`, body);
  return res.data;
};
