import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import FleetAccountListModule from "modules/fleet-accounts/account-list/account-list.module";

const FleetAccountsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.FleetAccounts} component={FleetAccountListModule} />
      <Redirect to={Path.FleetAccounts} />
    </Switch>
  );
};

export default FleetAccountsPage;
