import locale from "localization";

export const columns = [
  {
    key: "voucherCode",
    text: locale.voucherCode,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "voucherId",
    text: locale.voucherId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "voucherPrefix",
    text: locale.voucherPrefix,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "type",
    text: `${locale.voucherType}/ ${locale.claimedMethod}`,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "name",
    text: `${locale.campaignName}/ ${locale.campaignGroup}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "campaignCreator",
    text: locale.campaignCreator,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "value",
    text: locale.value,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "claimedBy",
    text: locale.claimedBy,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "customerId",
    text: locale.customerId,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "date",
    text: `${locale.startDate}/ ${locale.endDate}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "claimedDate",
    text: `${locale.claimedDate}/ ${locale.dateUsed}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "billedTo",
    text: locale.billedTo,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "status",
    text: locale.status,
    style: {
      minWidth: "120px",
    },
  },
];
