import PlatformType from "enums/platform-type";
import locale from "localization";

export const activateModalState = {
  form: {
    emailTemplate: {
      label: locale.emailTemplate,
      validations: {
        isRequired: true,
      },
      value: "",
    },
    testFuelCode: {
      label: locale.testFuelCode,
      value: "",
      validations: {
        custom: [
          (value, field, fields) => {
            let error = false;
            const { errorType } = field;
            const { emailTemplate } = fields;

            if (errorType === "invalid-code") {
              return {
                error: true,
                message: "Invalid test fuel code",
              };
            }

            if (
              [PlatformType.PLB, PlatformType.PLCandPLB, "plc-activated"].includes(
                emailTemplate.value
              ) &&
              !value.toString().trim()
            ) {
              error = true;
            }
            return {
              error,
              message: error ? "This field is required" : "",
            };
          },
        ],
      },
    },
  },
};
