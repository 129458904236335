import { Modal, Text, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import { updateStationStatus } from "apis/account-stations";
import { useApi } from "hooks";
import VIPStatus from "enums/vip-status";

const OnboardVIPModal = ({
  refresh,
  stationId,
  stationType,
  hasSettlementInfo = false,
  ...modal
}) => {
  const onboardRequest = useApi({
    api: updateStationStatus,
  });

  return (
    <Modal
      {...modal}
      title={locale.onBoardNowinVIP}
      style={{
        width: "600px",
      }}
    >
      <Text className="text-center">{locale.vipOnboardText}</Text>

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modal.close();
            },
          },
          {
            loading: onboardRequest.loading,
            text: locale.yesOnboardNow,
            onClick: async () => {
              await onboardRequest.request({
                stationId,
                vipStatus: VIPStatus.Active,
              });
              modal.close();
              refresh();
            },
          },
        ]}
      />
    </Modal>
  );
};

export default OnboardVIPModal;
