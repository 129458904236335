import { Modal, Text, ActionButton } from "components/commons";
import { useHistory } from "react-router-dom";
import locale from "localization";
import React from "react";
import styles from "./batch-pricing-changes-modal.module.scss";
import { Path } from "enums";

const BatchPricingChangesModal = ({ data, success, close, active, onClose }) => {
  const history = useHistory();
  const message = success ? locale.youMayGoToPricingChangesPage : locale.pleaseCheckUploadedFile;
  const title = success ? locale.priceChangesSuccessfullySaved : locale.editAccountPricesFailed;
  const buttonMessage = success ? locale.goToPricingChanges : locale.goBackToEditAccountPrices;
  const forwardTo = Path.PricingChanges;

  const closeMethod = () => {
    close();
    onClose();
  };

  return (
    <Modal active={active} title={title} close={closeMethod} className={styles.modal}>
      <div className={styles.content}>
        <Text className={styles.message} align="center">
          {message}
        </Text>
      </div>
      <ActionButton
        primary
        className={styles.primary}
        center
        items={[
          {
            onClick: () => {
              success ? history.push(forwardTo) : closeMethod();
            },
            text: buttonMessage,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default BatchPricingChangesModal;
