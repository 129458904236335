import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getMessages = async () => {
  const res = await Get(`${ApiPath.Inbox}`, {
    platformType: "plb",
    portalType: "oilco",
  });
  return {
    messages: res.data,
  };
};

export const readAnnouncementMessage = async (body) => {
  const res = await Post(`${ApiPath.Inbox}/read/announcement`, body);
  return res;
};

export const readAllAnnouncementMessage = async () => {
  const res = await Post(`${ApiPath.Inbox}/read/announcement/all`, {
    platformType: "plb",
    portalType: "oilco",
  });
  return res;
};

export const readInboxMessage = async (body) => {
  const res = await Post(`${ApiPath.Inbox}/read`, body);
  return res;
};
