import React, { useCallback, useMemo } from "react";
import StationForm from "./station-form";
import useForm from "hooks/useForm.v2";
import { stationFormInitialState } from "./station-form.state";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { getAccountStationById, updateStation } from "apis/account-stations";
import locale from "localization";
import { capitalizeWord, formatAmount, isNumber } from "utils/text.utils";
import { ActionButton, Text } from "components/commons";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Path } from "enums";
import { mapFormToRequest } from "./station.mapper";
import { ConfirmModal } from "components/modals";
import PLCStatus from "enums/plc-status";
import PLBStatus from "enums/plb-status";

const EditStation = () => {
  const history = useHistory();
  const { query = {} } = useRouter();
  const { id: stationId } = query;
  const form = useForm(stationFormInitialState);
  const { initializeForm } = form;
  const confirmModal = useModal();

  const stationRequest = useApi({
    api: getAccountStationById,
    pageError: true,
  });

  const updateRequest = useApi({
    api: updateStation,
  });

  useMount(async () => {
    const res = await stationRequest.request({
      stationId,
    });
    const {
      city,
      province,
      stationCode,
      stationType,
      name,
      businessName,
      stationProduct,
      companyGroup,
      address,
      latitude,
      longitude,
      depot = {},
      email,
      rtmName,
      stationFleetDiscount = [],
      rtmEmail,
      mobileNumber,
      bankAccountName,
      bankAccountNumber,
      bankName,
      settlementEmail,
      opensAt,
      closesAt,
      platformType,
      plcStatus,
      plbStatus,
    } = res || {};
    const { diesel, gas91, gas95, gas97 } = stationProduct;
    const { depotCode, name: depotLocation } = depot || {};

    const plcOnboarded = Boolean(PLCStatus.NotOnboarded !== plcStatus && plcStatus);
    const plbOnboarded = Boolean(PLBStatus.NotOnboarded !== plbStatus && plbStatus);

    // const showPLCInformation = plcOnboarded;
    const showSettlementInfo =
      (plcOnboarded || plbOnboarded) && ["CODO", "DODO"].includes(stationType);

    const showMaxDiscounts = plbOnboarded && ["WIDOX", "DODOX"].includes(stationType);

    const gasDiscount = {
      plbMaxDiscount: {
        visible: showMaxDiscounts,
      },
    };

    stationFleetDiscount.forEach((discount) => {
      gasDiscount[discount.productCode] = {
        value: formatAmount(discount.maxDiscount, ""),
        validations: {
          isAmount: true,
          isRequired: showMaxDiscounts,
        },
      };
    });
    initializeForm(
      {
        stationCode: {
          value: stationCode,
          visible: true,
          content: <Text strong>{stationCode}</Text>,
        },
        depotLocation: {
          value: depotLocation,
        },
        depotId: {
          value: depotCode,
        },
        stationName: {
          value: name,
        },
        businessName: {
          value: businessName,
        },
        companyGroup: {
          value: companyGroup,
        },
        stationType: {
          value: stationType,
        },
        emailAddress: {
          value: email,
        },
        mobileNumberReceiving: {
          visible: plcOnboarded,
          value:
            mobileNumber && mobileNumber?.toString()[0] === "0"
              ? mobileNumber.toString().substring(1, mobileNumber.length)
              : mobileNumber?.toString()?.replace("+63", ""),
          validations: {
            isRequired: plcOnboarded,
            isNumeric: true,
            custom: [
              (value) => {
                const error = value
                  ? value[0] !== "9" || value.length !== 10 || !isNumber(value)
                  : false;
                return {
                  error,
                  message: error ? "Invalid mobile number" : "",
                };
              },
            ],
          },
        },
        productAvailability: {
          value: {
            diesel,
            gas91,
            gas95,
            gas97,
          },
        },
        rtmName: {
          value: rtmName,
        },
        rtmEmailAddress: {
          value: rtmEmail,
        },
        province: {
          value: province,
        },
        city: {
          value: capitalizeWord(city),
        },
        fullAddress: {
          value: address,
        },
        platformType: {
          value: platformType || "",
          validations: {
            isRequired: false,
          },
        },
        latitude: {
          value: latitude,
        },
        longitude: {
          value: longitude,
        },
        initialOnboarding: {
          visible: false,
          validations: {
            isRequired: false,
          },
        },
        ...gasDiscount,
        bankAccountName: {
          value: bankAccountName,
          visible: showSettlementInfo,
          validations: {
            isRequired: showSettlementInfo,
          },
        },
        bankAccountNumber: {
          value: bankAccountNumber,
          visible: showSettlementInfo,
          validations: {
            isNumber: true,
            isRequired: showSettlementInfo,
          },
        },
        nameOfBank: {
          value: bankName,
          visible: showSettlementInfo,
          validations: {
            isRequired: showSettlementInfo,
          },
        },
        settlementEmail: {
          value: settlementEmail,
          visible: showSettlementInfo,
          validations: {
            isEmail: true,
            isRequired: showSettlementInfo,
          },
        },
        openingTime: {
          value: moment(opensAt, "HH:mm:ss").format(),
        },
        closingTime: {
          value: moment(closesAt, "HH:mm:ss").format(),
        },
      },
      true
    );
  });

  const onSubmit = useCallback(
    async (params) => {
      confirmModal.show({
        title: locale.askSsaveChanges,
        content: locale.areYouSureYouWantToSave,
        secondary: {
          text: locale.continueEditing,
          onClick: () => {
            confirmModal.close();
          },
        },
        primary: {
          text: locale.saveChanges,
          onClick: async () => {
            const mappedParams = mapFormToRequest(params);
            await updateRequest.request(
              {
                ...mappedParams,
                stationId,
              },
              null,
              {
                cleaned: false,
              }
            );
            confirmModal.show({
              content: locale.detailHaveBeenSuccessfullyUpdated,
              title: locale.exclamatedSuccess,
              primary: {
                text: locale.gotIt,
                onClick: () => {
                  history.push(Path.ViewStationId(stationId));
                },
              },
              secondary: null,
            });
          },
        },
      });
    },
    [stationId, updateRequest, confirmModal, history]
  );

  const formFields = useMemo(() => {
    const { fields, ...value } = form;
    const obj = {};
    Object.keys(fields).forEach((key) => {
      const fieldKeys = Object.keys(fields[key]);
      obj[key] = {
        ...fields[key],
        visible: fieldKeys.includes("visible") ? fields[key].visible : true,
      };
    });
    return {
      ...value,
      fields: obj,
    };
  }, [form]);
  return (
    <div>
      <ConfirmModal loading={updateRequest.loading} {...confirmModal} />

      <StationForm
        title={locale.editStationDetails}
        form={formFields}
        onSubmit={onSubmit}
        loading={stationRequest.loading}
        intro={{
          actionContent: (
            <ActionButton
              right
              items={[
                {
                  text: locale.cancel,
                  onClick: () => {
                    history.push(Path.ViewStationId(stationId));
                  },
                },
                {
                  disabled: !form.isFormSubmittable,
                  text: locale.save,
                  onClick: () => {
                    form.onSubmit(onSubmit);
                  },
                },
              ]}
            />
          ),
        }}
      />
    </div>
  );
};

export default EditStation;
