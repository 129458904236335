import React from "react";
import PopOver from "../pop-over/pop-over";
import styles from "./alert-tooltip.module.scss";
import classNames from "classnames";

const AlertTooltip = ({ alertType, children, title, ...popOver }) => {
  const alertColor = {
    yellow: "#ffaa01",
    red: "#ee3b34",
  };

  return (
    <PopOver
      {...popOver}
      borderColor={alertColor[alertType] || alertColor.yellow}
      className={classNames(styles.container)}
      backgroundStyle={classNames({
        [`${styles.yellow}`]: alertType === "yellow",
        [`${styles.red}`]: alertType === "red",
      })}
      contentStyle={styles.content}
      arrowStyle={classNames(styles.arrow)}
      content={children}
    >
      {title}
    </PopOver>
  );
};

export default AlertTooltip;
