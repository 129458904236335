const env = {
  ID: "prod",
  API_URL: "https://api.locq.com",
  WEB_SOCKET_URL: "wss://pd3wolnhxa.execute-api.ap-southeast-1.amazonaws.com/prod",
  STATION_URL: "https://station-ap.pricelocq.com",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyB7-3Rjep658GjfGxO9Fn2nmRYT2KaawEw",
    authDomain: "pricelocq-business-prod.firebaseapp.com",
    databaseURL:
      "https://pricelocq-business-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pricelocq-business-prod",
    storageBucket: "pricelocq-business-prod.appspot.com",
    messagingSenderId: "518328425697",
    appId: "1:518328425697:web:d19951f5a89443b7f12ba0",
  },
};

export default env;
