import { ApiPath } from "enums";
import { Get } from "utils";

export const getPricingMovement = async ({ ...query }) => {
  const res = await Get(`${ApiPath.PricingMovement}`, query);
  return res.data;
};

export const getPricingAccumulatedMovement = async ({ ...query }) => {
  const res = await Get(`${ApiPath.PricingMovement}/accumulated`, query);
  return res.data;
};
