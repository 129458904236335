import React from "react";
import { CSVIcon, PDFIcon, PDFCancelledIcon, CSVCancelledIcon } from "../../../images";
import {
  Card,
  CardContent,
  LinearProgress,
  Box,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Text from "../text/text";
import Image from "../image/image";
import styles from "./download-card.module.scss";
import Pill from "../pill/pill";
import { dateTimeFormat, prettifyReportType, formatBytes } from "utils";
import Button from "../button/button";
import locale from "localization";
import { ReportStatus } from "enums";
import { ClearOutlined, DeleteOutlineOutlined } from "@material-ui/icons";
import { useApi } from "hooks";
import { updateReport } from "apis";

const LinearProgressWithLabel = () => {
  // TODO: Add progress computation
  return (
    <Box className={styles.progressContainer}>
      <Box className={styles.progressInnerContainer} mr={1}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

const checkFileTypeImage = (file, status) => {
  const fileName = file.split(".");
  const fileImage = {
    csv: status === ReportStatus?.Cancelled ? CSVCancelledIcon : CSVIcon,
    pdf: status === ReportStatus?.Cancelled ? PDFCancelledIcon : PDFIcon,
  };

  return fileImage[fileName[1]];
};

const renderValue = (status, fileUrl) => {
  const component = {
    [`in progress`]: <LinearProgressWithLabel />,
    [`cancelled`]: <Text>{locale?.cancelled}</Text>,
    [`success`]: (
      <Button outline primary onClick={() => window.open(fileUrl)}>
        {locale?.downloadSingle}
      </Button>
    ),
  };

  return component[status];
};

const DownloadCard = ({ data, inDrawer, key, deleteReport }) => {
  const reportUpdate = useApi({
    api: updateReport,
  });

  const dataValue = reportUpdate?.result ?? data;

  return (
    <Card className={styles.cardContainer} key={key}>
      <CardContent className={styles.cardImageContent}>
        <Image
          src={checkFileTypeImage(dataValue?.fileName, dataValue.status)}
          className={styles.logo}
        />
      </CardContent>
      {inDrawer ? (
        <CardContent style={{ width: "70%", alignSelf: "center" }}>
          <Text>{dataValue?.fileName}</Text>
          {dataValue?.status === ReportStatus?.InProgress ? (
            <LinearProgressWithLabel progress={dataValue?.progress} />
          ) : (
            <Button
              outline
              primary
              className={styles.downloadButton}
              onClick={() => window.open(dataValue?.fileUrl)}
            >
              {locale?.downloadSingle}
            </Button>
          )}
          <Pill cement={true}>{prettifyReportType(dataValue?.reportType)}</Pill>
          <div style={{ display: "flex", gap: 10, alignSelf: "end" }}>
            <Text className={styles?.subBusinessId}>{formatBytes(dataValue?.fileSize, 2)}</Text>
            <Text className={styles?.subBusinessId}>{dateTimeFormat(dataValue?.createdAt)}</Text>
          </div>
        </CardContent>
      ) : (
        <>
          <CardContent style={{ width: "50%", alignSelf: "center" }}>
            <Text {...(dataValue?.status === ReportStatus?.Cancelled ? { strike: true } : {})}>
              {dataValue?.fileName}
            </Text>
            <Pill cement={true}>{prettifyReportType(dataValue?.reportType)}</Pill>
            <div style={{ display: "flex", gap: 10, alignSelf: "end" }}>
              <Text className={styles?.subBusinessId}>{formatBytes(dataValue?.fileSize, 2)}</Text>
              <Text className={styles?.subBusinessId}>{dateTimeFormat(dataValue?.createdAt)}</Text>
            </div>
          </CardContent>
          <CardContent
            style={{
              width: "50%",
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {renderValue(dataValue?.status, dataValue?.fileUrl)}
          </CardContent>
          <CardContent style={{ alignSelf: "center" }}>
            {dataValue?.status === ReportStatus?.InProgress ? (
              <IconButton
                disabled={reportUpdate?.loading}
                onClick={() =>
                  reportUpdate?.request({
                    reportId: dataValue?.reportId,
                    status: ReportStatus?.Cancelled,
                  })
                }
              >
                {reportUpdate?.loading ? (
                  <CircularProgress className={styles.circular} />
                ) : (
                  <ClearOutlined />
                )}
              </IconButton>
            ) : (
              <IconButton
                onClick={() => deleteReport({ fileName: dataValue?.fileName, reportId: dataValue?.reportId })}
              >
                <DeleteOutlineOutlined className={styles.deleteIcon} />
              </IconButton>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default DownloadCard;
