import { Intro } from "components/commons";
import React, { useContext } from "react";
import locale from "localization";
import KYCSubmissionFilter from "./kyc-submission-filter";
import { getKYCSubmission } from "apis/kyc-submission.api";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";

import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import { mapFilterToRequest, mapDataToList } from "./kyc-submission.mapper";
import { columns } from "./kync-submission-columns";
import { initialFilterState } from "./kyc-submission-filter.state";
import { useRouter } from "hooks";
import { generateProfileAdmin } from "apis";
import { UserContext } from "contexts";

const KYCSubmission = () => {
  const { sessionId: connectionId } = useContext(UserContext);
  const { history } = useRouter();
  const { filter, search, table } = useDataTable({
    api: {
      api: getKYCSubmission,
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "kyc",
      mapper: (kyc) =>
        mapDataToList({
          kyc,
          history,
        }),
      columns,
    },
  });

  const exportReport = useExport({
    api: generateProfileAdmin,
    reportType: "kyc-submission",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      connectionId,
    },
  });

  return (
    <div>
      <div>
        <Intro title={locale.kycSubmissions} subtitle={locale.viewAndTrackKYCSubmission} />
      </div>
      <KYCSubmissionFilter {...filter} {...search} {...exportReport} />
      <SuccessModal {...exportReport?.successModalComponent} />
      <DataTableV2 {...table} paginationV2={true} />
    </div>
  );
};

export default KYCSubmission;
