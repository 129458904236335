import { Text } from "components/commons";
import locale from "localization";

export const payHistoryColumns = [
  {
    key: "vipTransactionId",
    text: <Text style={{ width: "100px" }}>{locale.vipTransactionId}</Text>,
  },
  {
    key: "nameAndMobileNumber",
    text: locale.nameAndMobileNumber,
    width: "100px",
  },
  {
    key: "vipCardNumber",
    text: <Text style={{ width: "50px" }}>{locale.vipCardNumber}</Text>,
  },
  {
    key: "station",
    text: <Text style={{ width: "100px" }}>{`SEAOIL ${locale.stationBranch}`}</Text>,
    style: {
      width: "150px",
    },
  },
  {
    key: "productType",
    text: <Text style={{ width: "" }}>{locale.productType}</Text>,
    style: {
      width: "100px",
    },
  },
  {
    key: "transactionAmount",
    text: <Text style={{ width: "100px" }}>{locale.transactionAmount}</Text>,
    style: {
      width: "80px",
    },
  },
  {
    key: "pointsRedeemed",
    text: locale.pointsRedeemed,
    style: {
      width: "80px",
    },
  },
  {
    key: "finalAmountPaid",
    text: locale.finalAmountPaid,
    style: {
      width: "80px",
    },
  },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: {
      width: "80px",
    },
  },
  {
    key: "dateOfTransaction",
    text: <Text style={{ width: "100px" }}>{locale.dateOfTransaction}</Text>,
    style: {
      width: "90px",
    },
  },
  {
    key: "status",
    text: locale.status,
    style: {
      width: "50px",
    },
  },
  {
    key: "remarks",
    text: locale.remarks,
    style: {
      width: "50px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },

  // station portal
  // { key: "vipTransactionId", text: locale.vipTransactionId, style: { minWidth: "8%" } },
  // { key: "vipCardNumber", text: locale.vipCardNumber, style: { minWidth: "10%" } },
  // { key: "mobileWithHash", text: locale.mobileWithHash, style: { minWidth: "6%" } },
  // { key: "transactionAmount", text: locale.transactionAmount, style: { minWidth: "6%" } },
  // { key: "pointsRedeemed", text: locale.pointsRedeemed, style: { minWidth: "6%" } },
  // {
  //   key: "productTypeCapitalize",
  //   text: locale.productType,
  //   style: { minWidth: "10%" },
  // },
  // { key: "finalAmountPaid", text: locale.finalAmountPaid, style: { minWidth: "10%" } },
  // {
  //   key: "orNumber",
  //   text: locale.orNumber,
  //   style: { minWidth: "6%" },
  // },
  // {
  //   key: "dateOfTransaction",
  //   text: locale.dateOfTransaction,
  //   style: { minWidth: "20%" },
  // },
];
