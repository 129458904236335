import locale from "localization";

export const onboardPLBState = {
  form: {
    plbMaxDiscount: {
      label: locale.plbMaxDiscount,
      visible: false,
    },
    bankAccountName: {
      label: locale.bankAccountName,
      visible: true,
      validations: {
        isRequired: true,
      },
    },
    bankAccountNumber: {
      label: locale.bankAccountNumber,
      visible: true,
      validations: {
        isNumber: true,
        isRequired: true,
      },
    },
    nameOfBank: {
      label: locale.nameOfBank,
      visible: true,
      validations: {
        isRequired: true,
      },
    },
    settlementEmail: {
      label: locale.settlementEmail,
      visible: true,
      validations: {
        isEmail: true,
        isRequired: true,
      },
    },
    diesel: {
      label: "Diesel",
    },
    gas91: {
      label: "Gas 91",
    },
    gas95: {
      label: "Gas 95",
    },
    gas97: {
      label: "Gas 97",
    },
  },
};
