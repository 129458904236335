import React from "react";
import { Path } from "enums";
import { Route, Switch } from "react-router-dom";
import NonFuelModule from "modules/non-fuel/non-fuel.module";

const NonFuelPage = () => {
  return (
    <>
      <Switch>
        <Route exact path={Path.NonFuelPLC} component={NonFuelModule} />
      </Switch>
      <Switch>
        <Route exact path={Path.NonFuelPLB} component={NonFuelModule} />
      </Switch>
    </>
  );
};

export default NonFuelPage;
