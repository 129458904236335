import React, { useMemo, useCallback } from "react";
import { ActionButton, Modal, Field, Text, TextField } from "components/commons";
import locale from "localization";
import styles from "./edit-mobile-number-modal.module.scss";
import { getUserByMobileNumber } from "apis/user.api";
import { initialState as formState } from "./edit-mobile-number.state";
import { isMobileNumberValid, capitalize } from "utils/text.utils";
import { handleRequest } from "utils";
import { useForm, useApi, useMount } from "hooks";

const validationObject = {
  validation: null,
};

const InitialValidation = {
  setValidation: (e) => (validationObject.validation = e),
  getValidation: () => validationObject.validation,
};

const EditMobileNumberModal = ({
  userId,
  submit,
  request,
  fixDisplay,
  initialState,
  attributes,
  count,
  loading,
  submitting,
  ...modalState
}) => {
  const { close, active } = modalState;

  const validateAccount = useApi({
    api: getUserByMobileNumber,
    handleOwnError: {
      badrequest: true,
    },
  });

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    isFormSubmittable,
    modifyForm,
    modifyField,
    // dirty
  } = useForm({
    initialState: form,
  });

  const submitCb = useCallback(() => {
    submit({
      fromMobileNumber: fixDisplay?.fromMobileNumber?.value,
      toMobileNumber: `+63${fields?.toMobileNumber?.value}`,
      fullName: fixDisplay?.fullName.value,
      mergeToAccount: fields?.accountName?.value,
      isExist: fields?.isExist?.value,
    });

    modifyField(fields?.toMobileNumber?.name, { value: "" });
  }, [
    fields?.toMobileNumber?.value,
    fields?.toMobileNumber?.name,
    fixDisplay?.fromMobileNumber?.value,
    fixDisplay?.fullName.value,
    fields?.isExist?.value,
    fields?.accountName?.value,
    modifyField,
    submit,
  ]);

  const validateNumber = useCallback(
    (mobileNumber) => {
      handleRequest(
        async () => {
          const resp = await validateAccount?.request({ mobileNumber: `0${mobileNumber}` });
          if (resp) {
            const { data } = resp;
            modifyForm({
              [fields?.accountName?.name]: {
                value: `${capitalize(data?.firstName)} ${capitalize(data?.lastName)}`,
              },
              [fields?.toMobileNumber?.name]: {
                validations: [...InitialValidation?.getValidation()],
                value: mobileNumber,
                error: false,
                message: null,
                userId: data?.userId,
              },
              [fields?.isExist?.name]: {
                value: true,
              },
            });
          }
        },
        {},
        ({ data: { errorCode } }) => {
          if (errorCode) {
            modifyForm({
              [fields?.accountName?.name]: {
                value: "",
              },
              [fields?.toMobileNumber?.name]: {
                validations: [...InitialValidation?.getValidation()],
                value: mobileNumber,
                error: false,
                message: null,
                userId: "",
              },
              [fields?.isExist?.name]: {
                value: false,
              },
            });
          }
        }
      );
    },
    [
      validateAccount,
      modifyForm,
      fields?.accountName?.name,
      fields?.toMobileNumber?.name,
      fields?.isExist?.name,
    ]
  );

  useMount(() => {
    if (!InitialValidation?.getValidation()) {
      InitialValidation.setValidation(fields?.toMobileNumber?.validations);
    }
  });
  return (
    <Modal
      active={active}
      title={locale.updateMobileNumber}
      close={() => {
        close();
      }}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <Field className={styles.fieldLabel} {...fixDisplay?.fromMobileNumber}>
          <Text className={styles.textStyle}>{fixDisplay?.fromMobileNumber?.value}</Text>
        </Field>

        <Field className={styles.fieldLabel} {...fields?.toMobileNumber}>
          <TextField
            className={styles.textStyle}
            {...fields?.toMobileNumber}
            fontColor={"#4c69a4"}
            value={fields?.toMobileNumber.value}
            onChange={async (name, { value }) => {
              modifyForm({
                [name]: { value },
              });

              if (value.length === 10 && isMobileNumberValid("0" + value)) {
                validateNumber(value);
              }
            }}
            prefix={
              <div>
                <Text
                  style={{
                    fontSize: "14px",
                  }}
                >
                  +63
                </Text>
              </div>
            }
          />
          {fields?.accountName?.value && fields?.toMobileNumber.value.length === 10 && (
            <div className={styles.accountName}>
              <Text label>{locale.existingPriceLOCQAccount}</Text>
              <Text strong>{fields?.accountName?.value}</Text>
            </div>
          )}
        </Field>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
            disabled: submitting,
          },
          {
            onClick: () => {
              submitCb();
            },
            text: fields?.isExist.value ? locale.mergeAccounts : locale.update,
            loading: submitting,
            disabled: !isFormSubmittable,
          },
        ]}
      />
    </Modal>
  );
};

export default EditMobileNumberModal;
