import React from "react";
import { useMemo } from "react";
import AlertIcon from "./alert-icon";
import AlertTooltip from "./alert-tooltip";

const AlertIconTooltip = ({ children, alertType, yellow, red, ...icon }) => {
  const alert = useMemo(() => {
    if (alertType) {
      return alertType;
    }
    if (yellow) return "yellow";

    if (red) return "red";
  }, [alertType, yellow, red]);

  return (
    <AlertTooltip {...icon} alertType={alert} title={<AlertIcon alertType={alert} />}>
      {children}
    </AlertTooltip>
  );
};

export default AlertIconTooltip;
