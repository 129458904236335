export const checkIfObjectValuesAreEqual = (obj1, obj2) => {
  // sorting of keys must match, else this will not work
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sortByKeyName = (key, string = true) => {
  return (a, b) => {
    if (a && b && a[key] && b[key]) {
      const first = string && a[key] ? a[key].toString().toLowerCase() : a[key];
      const second = string && b[key] ? b[key].toString().toLowerCase() : b[key];
      if (first < second) return -1;

      if (first > second) return 1;
    }

    return 0;
  };
};

export const parseCSVFile = async (file, columns = []) => {
  const fileContent = await file?.text();
  const rows = fileContent.split("\n");
  const errors = [];
  const rowErrors = [];
  const items = rows
    .filter((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      if (index === 0) {
        r.forEach((text, i) => {
          const columnText = columns[i]?.text;
          const isValid = text === columnText;
          if (!isValid) {
            errors.push(`Column ${i + 1} should be '${columnText}'`);
            rowErrors.push("header");
          }
        });
      }
      return index !== 0;
    })
    .map((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      const obj = {};
      r.forEach((text, i) => {
        obj[columns[i]?.key] = text;
      });
      const messages = [];
      r.forEach((text, i) => {
        if (columns[i]?.validation) {
          const errorStatus = columns[i]?.validation(text, obj);
          if (errorStatus.error) {
            messages.push(errorStatus.message);
          }
        }
      });
      if (messages.length) {
        errors.push(`Row ${index + 1}: ${messages.join(", ")}`);
        rowErrors.push(index + 1);
        obj.error = true;
      }
      return { ...obj, rowNumber: index + 1 };
    });
  return {
    items,
    passed: items.filter((item) => !item.error),
    errors,
    rowErrors,
  };
};

export const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export const exportCSV = ({ rows, filename }) => {
  const csvContent = "data:text/csv;charset=utf-8," + rows.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);

  link.click();
};

export const sortProductsBySku = (products, key = "sku") => {
  if (!products) return [];

  return products?.sort((a, b) => {
    const regex = /^(diesel|gas\d{2})/;
    return regex.test(b[key]) - regex.test(a[key]) || a[key].localeCompare(b[key]);
  });
};
