import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const login = async ({ email, password }) => {
  const res = await Post(`${ApiPath.Login}`, { email, password });
  return res.data;
};

export const logout = async () => {
  const res = await Get(`${ApiPath.Logout}`);
  return res.data;
};

export const refresh = async ({ refreshToken }) => {
  const res = await Post(`${ApiPath.Refresh}`, { refreshToken });
  return res.data;
};
