import React, { useCallback } from "react";
import { Checkbox as MaterialCheckbox, Switch } from "@material-ui/core";
import styles from "./checkbox.module.scss";
import classNames from "classnames";

const Checkbox = ({ value = false, onChange, name, disabled, switchMode = false, colored }) => {
  const onChangeCb = useCallback(() => {
    if (onChange) {
      onChange(name, {
        value: !value,
      });
    }
  }, [name, value, onChange]);

  return (
    <div
      className={classNames({
        [`${styles.disabled}`]: disabled,
      })}
    >
      {switchMode ? (
        <Switch
          className={classNames(styles.checkbox, {
            [`${styles.green}`]: colored && value,
            [`${styles.danger}`]: colored && !value,
          })}
          value={value}
          disabled={disabled}
          onChange={onChangeCb}
        />
      ) : (
        <MaterialCheckbox
          className={styles.checkbox}
          checked={value === "indeterminate" ? null : value}
          indeterminate={value === "indeterminate"}
          disabled={disabled}
          onChange={onChangeCb}
        />
      )}
    </div>
  );
};

export default Checkbox;
