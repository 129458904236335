import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import styles from "./list.module.scss";

const List = ({
  components,
  loading,
  horizontal = true,
  vertical,
  className,
}) => {
  if (loading) {
    return <Skeleton />;
  }
  return (
    <div
      className={classNames(styles.container, className, {
        [`${styles.horizontal}`]: horizontal,
        [`${styles.vertical}`]: vertical,
      })}
    >
      {components.map((component, index) => {
        return (
          <div
            key={index}
            className={classNames(styles.list, {
              [`${styles.horizontal}`]: horizontal,
              [`${styles.vertical}`]: vertical,
            })}
          >
            {component}
          </div>
        );
      })}
    </div>
  );
};

export default List;
