import Validation from "utils/validation.utils";

export const accumulatedModalState = (state = {}) => {
  const {
    currentDieselPrice = {
      value: "0",
    },
    currentGas91Price = {
      value: "0",
    },
    currentGas95Price = {
      value: "0",
    },
    currentGas97Price = {
      value: "0",
    },
    newPumpPriceDiesel = {
      value: "0",
    },
    newPumpPriceGas91 = {
      value: "0",
    },
    newPumpPriceGas95 = {
      value: "0",
    },
    newPumpPriceGas97 = {
      value: "0",
    },
    accountSpecificDiesel = {
      value: "0",
    },
    accountSpecificGas91 = {
      value: "0",
    },
    accountSpecificGas95 = {
      value: "0",
    },
    accountSpecificGas97 = {
      value: "0",
    },
  } = state;

  return {
    currentDieselPrice: {
      name: "currentDieselPrice",
      inputDisabled: true,
      disabled: true,
      innerLabel: true,
      parseNumber: true,
      ...currentDieselPrice,
    },
    currentGas91Price: {
      disabled: true,
      inputDisabled: true,
      name: "currentGas91Price",
      innerLabel: true,
      parseNumber: true,
      ...currentGas91Price,
    },
    currentGas95Price: {
      disabled: true,
      inputDisabled: true,
      name: "currentGas95Price",
      innerLabel: true,
      parseNumber: true,
      ...currentGas95Price,
    },
    currentGas97Price: {
      disabled: true,
      inputDisabled: true,
      name: "currentGas97Price",
      innerLabel: true,
      parseNumber: true,
      ...currentGas97Price,
    },
    newPumpPriceDiesel: {
      name: "newPumpPriceDiesel",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...newPumpPriceDiesel,
    },
    newPumpPriceGas91: {
      name: "newPumpPriceGas91",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...newPumpPriceGas91,
    },
    newPumpPriceGas95: {
      name: "newPumpPriceGas95",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...newPumpPriceGas95,
    },
    newPumpPriceGas97: {
      name: "newPumpPriceGas97",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...newPumpPriceGas97,
    },
    accountSpecificDiesel: {
      validations: [Validation?.priceChange()],
      name: "accountSpecificDiesel",
      innerLabel: true,
      parseNumber: true,
      ...accountSpecificDiesel,
    },
    accountSpecificGas91: {
      validations: [Validation?.priceChange()],
      name: "accountSpecificGas91",
      innerLabel: true,
      parseNumber: true,
      ...accountSpecificGas91,
    },
    accountSpecificGas95: {
      validations: [Validation?.priceChange()],
      name: "accountSpecificGas95",
      innerLabel: true,
      parseNumber: true,
      ...accountSpecificGas95,
    },
    accountSpecificGas97: {
      validations: [Validation?.priceChange()],
      name: "accountSpecificGas97",
      innerLabel: true,
      parseNumber: true,
      ...accountSpecificGas97,
    },
  };
};
