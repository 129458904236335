import React from "react";
import Dropzone from "react-dropzone";
import locale from "localization";
import { useCallback } from "react";
import { CloseRounded } from "@material-ui/icons";
import { ConfirmModal } from "components/modals";
import { useModal } from "hooks";

const UploadFile = ({
  onChange,
  value,
  name,
  fileName,
  file: fileExt = [],
  image = true,
  text = locale.browseYourFiles,
  errorMessage = "Invalid file type",
  maxSizeMB = null,
  acceptInvalid = false,
}) => {
  const confirmModal = useModal();

  const onChangeFile = useCallback(
    (files, errorType) => {
      if (image || (!image && !errorType)) {
        onChange(name, { value: files, errorType });
      } else {
        confirmModal.show({
          content: errorMessage,
          primary: {
            text: "OK",
            onClick: () => {
              confirmModal.close();
            },
          },
        });
      }
    },
    [confirmModal, onChange, errorMessage, name, image]
  );
  const onSelectFile = useCallback(
    (files) => {
      if (files.length > 0) {
        const fileName = files[0];
        const { size } = fileName || {};
        const mb = size / 1024;
        const bytes = maxSizeMB * 1024;
        if (maxSizeMB && mb > bytes) {
          onChangeFile(files, "invalid-size");
        } else {
          var windowUrl = window.URL || window.webkitURL;
          const img = new Image();
          var objectUrl = windowUrl.createObjectURL(fileName);
          const file = files[0];
          const fn = file.name ? file.name.split(".") : file.path ? file.path.split(".") : "";
          const ext = fn[fn.length - 1];
          if (image && fileExt.includes(ext.toLowerCase())) {
            img.onload = function () {
              onChangeFile(files, this.width === this.height ? "" : "invalid-dimension");
              windowUrl.revokeObjectURL(objectUrl);
            };

            img.src = objectUrl;
          } else {
            onChangeFile(files, !fileExt.includes(ext) ? "invalid-file" : "");
          }
        }
      }
    },
    [image, maxSizeMB, onChangeFile, fileExt]
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <div
        className="pointer"
        style={{
          height: "35px",
          border: "1px solid #753bbd",
          borderRadius: "4px",
          padding: "0px 20px",
          width: "fit-content",
          color: "#753bbd",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
        }}
      >
        <input type={"file"} onChange={onSelectFile} />
        {(value && value[0]?.name) || fileName ? locale.replace : locale.browseYourFiles}
      </div> */}
      <Dropzone onDrop={onSelectFile} preventDropOnDocument multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="pointer"
            style={{
              height: "35px",
              border: "1px solid #753bbd",
              borderRadius: "4px",
              padding: "0px 20px",
              width: "fit-content",
              color: "#753bbd",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input {...getInputProps()} />
            {(value && value[0]?.name) || fileName ? locale.replace : text}
          </div>
        )}
      </Dropzone>
      {((value && value[0]?.name) || fileName) && (
        <div
          style={{
            marginLeft: "10px",
            background: "#f5f6fa",
            minHeight: "35px",
            borderRadius: "40px",
            color: "#753bbd",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <div>{fileName ? fileName : value && value[0].name}</div>
          <CloseRounded
            style={{
              margin: "0px 5px",
              fontSize: "14px",
            }}
            className="pointer"
            onClick={() => {
              onChange(name, { value: [], fileName: null, errorType: "" });
            }}
          />
        </div>
      )}
      <ConfirmModal {...confirmModal} />
    </div>
  );
};

export default UploadFile;
