import { DateTime } from "enums";
import moment from "moment";
import momentTimeZone from "moment-timezone";
const localMomentTimeZone = momentTimeZone.tz("Asia/Manila");

export const dateFormat = (date) => {
  return moment(date).format("DD MMM yyyy");
};

export const dateTimeFormat = (dateTime) => {
  return moment(dateTime).format("DD MMM yyyy hh:mma");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};

export const localTimeZone = (date) => {
  return localMomentTimeZone.format(date);
};

export const shortDateTime = (dateTime) => {
  const dateTimeMoment = moment(dateTime);
  const isToday = moment().format("MMDDYYYY") === dateTimeMoment.format("MMDDYYYY");
  const isSameYear = moment().format("YYYY") === dateTimeMoment.format("YYYY");
  if (isToday) {
    return formatDate(dateTime, "hh:mm A");
  }
  if (isSameYear) {
    return formatDate(dateTime, "DD MMM");
  }
  return formatDate(dateTime, "DD MMM YYYY");
};
