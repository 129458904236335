import { FeatureFlagContext } from "contexts";
import useFeatureFlag from "hooks/useFeatureFlag";
import React from "react";

const FeatureFlagPage = ({ children }) => {
  const featureFlag = useFeatureFlag();
  return (
    <FeatureFlagContext.Provider
      value={{
        ...featureFlag,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagPage;
