import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getDepots } from "apis";
import locale from "localization";
import { useApi } from "hooks";

const SelectDepot = ({ placeholder = locale.searchOrSelect, ...props }) => {
  const {
    request,
    loading,
    result = { depots: [], count: 0 },
  } = useApi({
    api: getDepots,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        order: "asc",
        orderBy: "name",
        perPage: 10,
        ...params,
      });
    },
    [request]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { depots = [] } = result || {};
        if (depots && depots.length > 0) {
          const newDepots = depots;

          newDepots.unshift({
            name: "All",
            depotCode: "all",
          });

          return newDepots.map((d) => {
            return {
              label: d.name,
              value: d.depotId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
    />
  );
};

export default SelectDepot;
