import React from "react";
import LoginModule from "../modules/auth/login/login.module";

const AuthPage = (props) => {
  return (
    <div>
      <LoginModule {...props} />
    </div>
  );
};

export default AuthPage;
