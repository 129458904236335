import React, { useEffect, useState, useContext } from "react";
// import { EditRounded } from "@material-ui/icons";
// ActionButton, Text, Button, Field
import { BackLink, Intro } from "components/commons";
import { Path } from "enums";
import locale from "localization";
import { useApi, useModal, useMount, useRouter, useWebSocket } from "hooks";

import styles from "./view-settlement-history.module.scss";

import ViewSettlementHistoryForm from "./view-settlement-history-form.module";
import ViewSettlementHistoryTable from "./view-settlement-history-table.module";
import { getSettlementHistoryById, updateSettlementHistory } from "apis";

import { ConfirmModal, ErrorModal } from "components/modals";
import env from "environments/env";
import { UserContext } from "contexts";

const ViewSettlementHistory = () => {
  const {
    history,
    query: { id: settlementId },
  } = useRouter();

  const confirmModal = useModal();
  const successModal = useModal();
  const errorModal = useModal();
  const serverErrorModal = useModal();

  const [oilcoSettlementStatus, setOilCoSettlementStatus] = useState();
  const [totalMopsRemarks, setTotalMopsRemarks] = useState();
  const [
    totalOilcoSettlementReceivablePayableRemarks,
    setTotalOilcoSettlementReceivablePayableRemarks,
  ] = useState();
  const [downloadUrl, setDownloadUrl] = useState();

  // const [edit, setEdit] = useState(false);
  const { onDisconnect, onConnect } = useContext(UserContext);
  const { message, sessionId } = useWebSocket({ URL: env?.WEB_SOCKET_URL });

  const { result, request, loading } = useApi({
    api: getSettlementHistoryById,
    pageError: true,
  });

  const updateSettlement = useApi({
    api: updateSettlementHistory,
    handleOwnError: {
      badrequest: true,
      unauthorized: true,
    },
    pagError: false,
    modalError: false,
  });

  // const onUpdateSuccess = () => {
  //   successModal.show({
  //     title: locale.exclamatedSuccess,
  //     content: locale.detailHaveBeenSuccessfullyUpdated,
  //     primary: {
  //       text: locale.gotIt,
  //       onClick: async () => {
  //         const res = await request({ id: settlementId });
  //         setOilCoSettlementRemarks(res.oilcoSettlementRemarks);
  //         setOilCoSettlementStatus(res.oilcoSettlementStatus);
  //         setEdit(false);
  //         successModal.close();
  //       },
  //     },
  //   });
  // };

  // const renderSingleSettlement = (settlements) => {
  //   return (
  //     <>
  //       <div style={{ marginTop: 20 }}></div>
  //       <Field
  //         className={styles.fieldLabel}
  //         label={locale.dateProcessed}
  //         labelClassName={styles.fieldModalLabel}
  //         labelPosition={"left"}
  //         childrenClassName={styles.fieldContent}
  //       >
  //         {dateTimeFormat(settlements?.createdAt)}
  //       </Field>
  //       <Field
  //         className={styles.fieldLabel}
  //         label={locale.settlementId}
  //         labelClassName={styles.fieldModalLabel}
  //         labelPosition={"left"}
  //         childrenClassName={styles.fieldContent}
  //       >
  //         <Button link onClick={() => refetchSettlement(settlements?.settlementId)}>
  //           {settlements?.settlementId}
  //         </Button>
  //       </Field>
  //     </>
  //   );
  // };

  // const renderMultipleSettlements = (settlements) => {
  //   return (
  //     <>
  //       <div className={styles.multipleSettlementCollection}>
  //         <Text className={styles.fieldModalHeading}>{locale.dateProcessed}</Text>
  //         <Text className={styles.fieldModalHeading}>{locale.settlementId}</Text>
  //       </div>
  //       {settlements.map((settlement, key) => (
  //         <Field
  //           key={key}
  //           className={styles.fieldLabel}
  //           label={dateTimeFormat(settlement?.createdAt)}
  //           labelClassName={styles.fieldModalLabel}
  //           labelPosition={"left"}
  //           childrenClassName={styles.fieldContent}
  //         >
  //           <Button link onClick={() => refetchSettlement(settlement?.settlementId)}>
  //             {settlement?.settlementId}
  //           </Button>
  //         </Field>
  //       ))}
  //     </>
  //   );
  // };

  // const refetchSettlement = async (settlementId) => {
  //   history.push(Path.ViewSettlementHistoryById(settlementId));
  //   const res = await request({ id: settlementId });
  //   setOilCoSettlementRemarks(res.oilcoSettlementRemarks);
  //   setOilCoSettlementStatus(res.oilcoSettlementStatus);
  //   setEdit(!edit);
  //   errorModal.close();
  // };

  // const onConfirmRequest = () => {
  //   confirmModal.show({
  //     title: locale.saveChangesQuestion,
  //     content: (
  //       <locale.Populate
  //         text={
  //           oilcoSettlementStatus === SettlementStatus?.Settled
  //             ? locale.editVoucherStatus
  //             : locale.editSettlement
  //         }
  //         items={[<strong>{prettifySettlementStatus(oilcoSettlementStatus)}</strong>]}
  //       />
  //     ),
  //     secondary: {
  //       text: locale.continueEditing,
  //       onClick: () => confirmModal.close(),
  //     },
  //     primary: {
  //       text: locale.updateStatus,
  //       onClick: () =>
  //         handleRequest(
  //           async () => {
  //             await updateSettlement.request({
  //               settlementId,
  //               remarks: oilcoSettlementRemarks,
  //               oilcoSettlementStatus,
  //             });
  //             onUpdateSuccess();
  //             confirmModal.close();
  //           },
  //           {
  //             S0004: ({ settlements }) => {
  //               confirmModal.close();
  //               errorModal.show({
  //                 title: locale.processOtherSettlements,
  //                 content: (
  //                   <>
  //                     <Text align={"center"}>{locale.processOtherSettlementsContent}</Text>
  //                     {settlements.length > 1
  //                       ? renderMultipleSettlements(settlements)
  //                       : renderSingleSettlement(settlements[0])}
  //                   </>
  //                 ),
  //               });
  //             },
  //           }
  //         ),
  //     },
  //   });
  // };

  useEffect(() => {
    if (message.length !== 0) {
      setDownloadUrl(message[0].fileUrl);
    }
  }, [message]);

  useMount(() => {
    if (!settlementId) {
      history.push(Path.SettlementHistory);
    } else {
      onDisconnect();
    }
  });

  //eslint-disable-next-line
  useEffect(async () => {
    if (sessionId) {
      const res = await request({ id: settlementId, connectionId: sessionId });
      setTotalMopsRemarks(res.totalMopsRemarks);
      setTotalOilcoSettlementReceivablePayableRemarks(
        res.totalOilcoSettlementReceivablePayableRemarks
      );
      setOilCoSettlementStatus(res.oilcoSettlementStatus);
    }

    return () => {
      onConnect();
    };
    //eslint-disable-next-line
  }, [sessionId]);

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        loading={updateSettlement?.loading}
        disabled={updateSettlement?.loading}
        closable={!updateSettlement?.loading}
      />
      <ConfirmModal {...successModal} />
      <ConfirmModal {...errorModal} />
      <ErrorModal {...serverErrorModal} />
      <div className={styles.container}>
        <BackLink text={locale.oilcoSettlementHistory} path={Path.SettlementHistory} />
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "100%" }}>
            <Intro
              style={{
                minWidth: "400px",
              }}
              title={locale.oilcoSettlementDetails}
            />
          </div>
          {/* {edit ? (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setOilCoSettlementRemarks(result.oilcoSettlementRemarks);
                    setOilCoSettlementStatus(result.oilcoSettlementStatus);
                    setEdit(!edit);
                  },
                  text: locale.cancel,
                  primary: false,
                },
                {
                  onClick: onConfirmRequest,
                  text: locale.save,
                  primary: true,
                  disabled: !(
                    (oilcoSettlementRemarks || null) !== result.oilcoSettlementRemarks ||
                    oilcoSettlementStatus !== result.oilcoSettlementStatus
                  ),
                },
              ]}
            />
          ) : (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setEdit(!edit);
                  },
                  text: locale.edit,
                  startIcon: <EditRounded />,
                  exportButton: true,
                  primary: true,
                },
              ]}
            />
          )} */}
        </div>
        <ViewSettlementHistoryForm
          settlementDetails={{ ...result } || null}
          setTotalMopsRemarks={setTotalMopsRemarks}
          totalMopsRemarks={totalMopsRemarks}
          setTotalOilcoSettlementReceivablePayableRemarks={
            setTotalOilcoSettlementReceivablePayableRemarks
          }
          totalOilcoSettlementReceivablePayableRemarks={
            totalOilcoSettlementReceivablePayableRemarks
          }
          oilcoSettlementStatus={oilcoSettlementStatus}
          setOilCoSettlementStatus={setOilCoSettlementStatus}
          settlementLoading={loading}
          viewMode={false}
          currentoilcoSettlementStatus={result?.oilcoSettlementStatus}
        />
        <ViewSettlementHistoryTable
          settlementDetails={{ ...result }}
          settlementId={settlementId}
          downloadUrl={downloadUrl}
        />
      </div>
    </>
  );
};

export default ViewSettlementHistory;
