import { CustomerStatus, VipType } from "enums";

export const customerFilterState = () => ({
  page: 1,
  perPage: 10,
  endDate: null,
  startDate: null,
  updateStartDate: null,
  updateEndDate: null,
  vipType: {
    isSelectedAll: true,
    value: [VipType.Consumer, VipType.Rides, VipType.Biz, VipType.Public],
  },
  redemptionStation: {
    value: null,
    label: "All",
  },
  status: {
    isSelectedAll: true,
    value: [
      CustomerStatus.Active,
      CustomerStatus.Deactivated,
      CustomerStatus.Deleted,
      CustomerStatus.Blocked,
    ],
  },
});
