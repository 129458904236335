import React from "react";
import Dropzone from "react-dropzone";
import { Image } from "components/commons";
import { ImportIcon } from "images";
import locale from "localization";
import styles from "./import-file.module.scss";
import { useCallback } from "react";
import { Text } from "..";
import { DescriptionOutlined, WarningRounded } from "@material-ui/icons";

const ImportFile = ({ onChange, value }) => {
  const onSelectFile = useCallback(
    (files) => {
      if (files.length > 0) {
        onChange(files);
      }
    },
    [onChange]
  );

  return (
    <div
      style={{
        background: "#f7f8fa",
        padding: "35px 0px",
        border: "dashed 2px #a1aeb7",
        borderRadius: "4px",
      }}
    >
      <Dropzone
        onDrop={onSelectFile}
        multiple={false}
        accept={{
          "text/csv": [".csv"],
        }}
      >
        {({ getRootProps, getInputProps, ...props }) => {
          const { isDragReject } = props || {};
          return (
            <div className={styles.dropZone} {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragReject ? (
                <WarningRounded
                  style={{
                    color: "#ee3b34",
                  }}
                />
              ) : (
                <Image src={ImportIcon} />
              )}

              <div
                style={{
                  marginBottom: "20px",
                  color: isDragReject ? "#ee3b34" : "black",
                }}
              >
                {isDragReject ? locale.invalidFile : locale.dragAndDropFilesToUploadOr}
              </div>

              {value && value[0]?.name && (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "auto",
                      width: "fit-content",
                      color: isDragReject ? "#ee3b34" : "black",
                    }}
                  >
                    <DescriptionOutlined
                      style={{
                        color: isDragReject ? "#ee3b34" : "black",
                      }}
                    />
                    {value && value[0].name}
                  </div>
                </div>
              )}
              {!isDragReject && (
                <div>
                  <Text
                    strong
                    style={{
                      textDecoration: "underline",
                      color: isDragReject ? "#ee3b34" : "#753bbd",
                      marginTop: "20px",
                    }}
                  >
                    {value?.[0] ? locale.replaceFile : locale.addFile}
                  </Text>
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default ImportFile;
