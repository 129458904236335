import { ActionButton, Modal, Text } from "components/commons";
import PlatformType from "enums/platform-type";
import React from "react";
import { useMemo } from "react";
import locale from "localization";

const OnboardStationModal = ({ platformType, loading, ...modal }) => {
  const isPLC = [PlatformType.PLC, PlatformType.PLCandPLB].includes(platformType);
  const isPLB = [PlatformType.PLB, PlatformType.PLCandPLB].includes(platformType);
  const title = useMemo(() => {
    if (isPLC && isPLB) {
      return locale.onBoardNowinPLBandPLC;
    }
    if (isPLC) {
      return locale.onBoardNowinPLC;
    }
    return locale.onBoardNowinPLB;
  }, [isPLC, isPLB]);

  const content = useMemo(() => {
    if (isPLC && isPLB) {
      return locale.plcPlbOnboardText;
    }
    if (isPLC) {
      return locale.plcOnboardText;
    }
    return locale.plbOnboardText;
  }, [isPLC, isPLB]);
  return (
    <Modal
      {...modal}
      title={title}
      style={{
        maxWidth: "400px",
      }}
    >
      <Text className="text-center">{content}</Text>
      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: modal.close,
          },
          {
            loading: loading,
            text: locale.yesOnboardNow,
            onClick: modal.submit,
          },
        ]}
      />
    </Modal>
  );
};

export default OnboardStationModal;
