import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashboardModule from "../modules/dashboard/dashboard.module";

const DashboardPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Slash} component={DashboardModule} />
      <Redirect to={Path.Slash} />
    </Switch>
  );
};

export default DashboardPage;
