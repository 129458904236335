import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Intro } from "components/commons";
import { SuccessModal, ConfirmModal } from "components/modals";
import TransactionModal from "./transaction-modal";

import { generateReportAdminLoyalty, getVipCards, updateLoyaltyCardByCardNumber } from "apis";
import { AccountStatus, UserAccess } from "enums";
// Path
import { useApi, useModal } from "hooks";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { handleRequest } from "utils";

import { cardListColumns } from "./card-list-columns";
import CardListFilter from "./card-list-filter";
import { cardListFilterState } from "./card-list-filter.state";
import { mapFilterToRequest, mapDataToList } from "./card-list.mapper";
import CardList from "./card-list.list";
import useExport from "hooks/useExport";
import { UserContext } from "contexts";

const CardListModule = (props) => {
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");
  const history = useHistory();
  const confirmModal = useModal();
  const transactionModal = useModal();

  const { sessionId: connectionId, userAccess } = useContext(UserContext);
  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.CardManagement;
  });

  const { request: updateStatusRequest, loading } = useApi({
    api: updateLoyaltyCardByCardNumber,
    handleOwnError: {
      badrequest: true,
    },
  });

  const handleUpdateStatus = (params) => {
    const { status, cardNumber } = params;
    const activation = status === AccountStatus.Deactivated;
    transactionModal.show({
      cardNumber,
      status,
      title: activation ? locale.deactivateVIPCard : locale.activateVIPCard,
      description: activation ? (
        <>
          <locale.Populate
            text={locale.deactivateVIPCardContent}
            items={[<br />, locale.number, <b>{cardNumber}</b>]}
          />
        </>
      ) : (
        <locale.Populate
          text={locale.activateVIPCardContent}
          items={[<br />, locale.number, <b>{cardNumber}</b>]}
        />
      ),
      label: locale.reasonRemarks,
      labelReason: locale.reason,
      labelRemarks: locale.remarks,
      actionText: activation ? locale.yesDeactivateIt : locale.yesReactivateIt,
      submit: async (value) => {
        await submitUpdateStatus(params, value);
      },
    });
  };

  const newColumns =
    getUserAccess?.fullAccess || getUserAccess?.modifyAccess
      ? cardListColumns
      : cardListColumns.filter((column) => column.key !== "actions");

  const { filter, search, table } = useDataTable({
    api: { api: getVipCards },
    filter: { initialState: cardListFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "loyaltyCards",
      mapper: (loyaltyCards) =>
        mapDataToList({
          loyaltyCards,
          history,
          handleUpdateStatus,
          getUserAccess,
        }),
      columns: newColumns,
      tableProps: { history, handleUpdateStatus },
    },
  });

  const submitUpdateStatus = async (params, value) => {
    await handleRequest(async () => {
      const activation = params.status === AccountStatus.Deactivated;
      await updateStatusRequest({
        ...params,
        remarks: value,
      });
      transactionModal.close();
      table.fetch({});
      confirmModal.show({
        title: locale.exclamatedSuccess,
        content: (
          <locale.Populate
            text={activation ? locale.successDeactivateCard : locale.successActivateCard}
            items={[<b>{params.cardNumber}</b>]}
          />
        ),
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
    });
  };

  const exportReport = useExport({
    api: generateReportAdminLoyalty,
    hasModal: true,
    reportType: "loyalty-cards",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      fleetStatus: filter?.mappedFilterState?.status,
      searchKey: search?.searchKey,
      connectionId,
    },
  });

  return (
    <>
      <TransactionModal
        {...transactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={loading}
        onSubmit={transactionModal.submit}
        value={value}
        setValue={setValue}
      />
      <SuccessModal {...exportReport?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <Intro title={locale.cardManagement} subtitle={locale.viewAndTrackVIPCards} />
      <CardListFilter {...filter} {...search} {...exportReport} getUserAccess={getUserAccess} />
      <CardList table={table} />
    </>
  );
};

export default CardListModule;
