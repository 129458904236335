import {
  getMessages,
  readAllAnnouncementMessage,
  readAnnouncementMessage,
  readInboxMessage,
} from "apis/messages.api";
import { AnnouncementModal } from "components/modals";
import { MessagesContext } from "contexts";
import { Path } from "enums";
import MessageType from "enums/message-type";
import { useApi, useMount } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const MessagesPage = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [reads, setReads] = useState([]);
  const [readAnnouncements, setReadAnnouncements] = useState([]);
  const [acknowledgeAnnouncementModal, setAcknowledgeAnnouncementModal] = useState(false);

  const [initializing, setInitializing] = useState(true);
  const {
    request,
    result = { messages: [] },
    loading,
    error,
  } = useApi({
    api: getMessages,
    handleOwnError: true,
  });

  const acknowledgeAnnouncement = useApi({
    api: readAnnouncementMessage,
    handleOwnError: true,
  });

  const acknowledgeAnnouncementAll = useApi({
    api: readAllAnnouncementMessage,
    handleOwnError: true,
  });

  const readMessageInboxRequest = useApi({
    api: readInboxMessage,
    handleOwnError: true,
  });

  const announcements = useMemo(() => {
    const announcement = {};
    const { messages = [] } = result || {};
    messages.forEach((element = {}) => {
      const { messageId, announcement: content, createdAt } = element;
      if (
        !content.readAt &&
        !reads.includes(createdAt) &&
        element.type === MessageType.Announcement
      ) {
        announcement[createdAt] = {
          messageId,
          createdAt,
          title: content.modalTitle,
          content: content.modalContent,
          active: !readAnnouncements.includes(createdAt),
          onClose: () => {
            if (!readAnnouncements.includes(createdAt)) {
              setReadAnnouncements([...readAnnouncements, createdAt]);
              acknowledgeAnnouncement.request({
                createdAt,
              });
              // localStorage.setItem("announcements", [...readAnnouncements, messageId].join(","));
            }
          },
          openMessage: () => {
            setAcknowledgeAnnouncementModal(true);
            acknowledgeAnnouncementAll.request();
            history.push(`${Path.Inbox}?messageId=${createdAt}`);
          },
        };
      }
    });
    return Object.keys(announcement).map((key) => announcement[key]);
  }, [
    readAnnouncements,
    result,
    history,
    reads,
    acknowledgeAnnouncement,
    acknowledgeAnnouncementAll,
  ]);

  const fetch = useCallback(
    async (param = {}) => {
      try {
        const { searchKey } = param;
        await request({
          searchKey,
        });
        setInitializing(false);
      } catch (e) {
        setInitializing(false);
      }
    },
    [request]
  );

  useMount(() => {
    fetch();
    // const acknowledgedAnnouncements = localStorage.getItem("announcements") || "";
    // setReadAnnouncements(acknowledgedAnnouncements.split(","));
  });

  return (
    <MessagesContext.Provider
      value={{
        fetch,
        initializing,
        loading,
        error,
        data:
          result?.messages?.map((message) => {
            return {
              ...message,
              unread: reads.includes(message.createdAt) ? false : !Boolean(message.readAt),
            };
          }) || [],
        announcements,
        unreadMessages:
          result?.messages?.filter((message) =>
            reads.includes(message.createdAt) ? false : !Boolean(message.readAt)
          )?.length || 0,
        setRead: (id) => {
          if (!reads.includes(id)) {
            setReads([...reads, id]);
            readMessageInboxRequest.request({
              createdAt: id,
            });
          }
        },
        openMessage: (id) => {
          history.push(`${Path.Inbox}?messageId=${id}`);
        },
      }}
    >
      {children}
      {announcements.slice(0, 5).map((announcement, id) => {
        return (
          <AnnouncementModal
            key={id}
            {...announcement}
            active={
              location.pathname === Path.Inbox || acknowledgeAnnouncementModal
                ? false
                : announcement.active
            }
          />
        );
      })}
    </MessagesContext.Provider>
  );
};

export default MessagesPage;
