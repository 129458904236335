const Products = {
  Gas91: "gas91",
  Gas95: "gas95",
  Gas97: "gas97",
  Diesel: "diesel",
  LubeServ: "lubeserv",
  Lubes: "lubes",
  // Lpg: "lpg",
};

export default Products;
