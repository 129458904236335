import React from "react";
import styles from "./divider.module.scss";
const Divider = ({ vertical = false, size = 10 }) => {
  return (
    <div
      style={vertical ? { flexDirection: "column", width: size } : { height: size }}
      className={styles.divider}
    >
      &nbsp;
    </div>
  );
};

export default Divider;
