import React from "react";
import { ErrorOutline, ReportProblemOutlined } from "@material-ui/icons";
import classNames from "classnames";
import styles from "./highlight.module.scss";

const Highlight = ({ className, children, error, warning }) => {
  return (
    <div className={classNames(className, styles.highlight, { [`${styles.error}`]: error }, { [`${styles.error}`]: warning })}>
      {error && <ErrorOutline className={classNames({ [`${styles.errorIcon}`]: error })} />}
      {warning && <ReportProblemOutlined className={classNames({ [`${styles.errorIcon}`]: warning })} />}
      {children}
    </div>
  );
};

export default Highlight;
