import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = {
  email: {
    name: "email",
    placeholder: "example@email.com",
    value: localStorage.getItem("email") || "",
    validations: [
      Validation.required({ noMessage: true }),
      Validation.emailAddress({ customMessage: locale.pleaseEnterAValidEmailAddress }),
    ],
    maxLength: 75,
    label: locale.emailAddress,
  },
  password: {
    name: "password",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.password,
  },
  remember: {
    name: "remember",
    value: localStorage.getItem("email") ? true : false,
    label: locale.rememberMe,
  },
};
