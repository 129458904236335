import React, { Fragment, useCallback, useContext, useState, useEffect } from "react";
import { useApi, useMount, useWebSocket } from "hooks";
import { getUser } from "apis";
import { AppContext, UserContext } from "contexts";
import { Route, Switch } from "react-router";
import { Path } from "enums";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import DashboardPage from "./dashboard.page";
// import MyApprovalPage from "./my-approval.page";
import PricingPage from "./pricing.page";
import PaymentsPage from "./payments.page";
// import InventoryPage from "./inventory.page";
// import AdminPage from "./admin.page";
import SeaoilPage from "./seaoil.page";
import PricelocqPage from "./pricelocq.page";
import PricelocqConsumerPage from "./pricelocq-consumer.page";
import PricelocqBusinessPage from "./pricelocq-business.page";
import CampaignsPage from "./campaigns.page";
import AppLoader from "components/commons/app-loader/app-loader";
import MessagesPage from "./messages.page";
import InboxPage from "./inbox.module";
import AccountsPage from "./accounts.page";
import DownloadModule from "modules/download/downloads.module";
import LubeServOnWheelsBookingsPage from "./lubeserv-on-wheels-bookings.page";
import AccessAuthorizationPage from "./access-authorization.page";
import RFIDPage from "./rfid.page";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";
import env from "environments/env";
import LubeServOnSiteBookingsPage from "./lubeserv-on-site-bookings.page";
import { Portal } from "enums";
import UserRoleAccess from "utils/user-role-access.utils";
import FuelPage from "./fuel.page";
import VipPage from "./vip.page";
import NonFuelModule from "modules/non-fuel/non-fuel.module";
import NonFuelPage from "./non-fuel.page";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const [user, setUser] = useState();
  const [newNotification, setNewNotification] = useState(false);
  const [userAccess, setUserAccess] = useState([]);
  const { message, onDisconnect, onConnect, sessionId } = useWebSocket({
    URL: env?.WEB_SOCKET_URL,
  });

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUser,
    pageError: true,
  });

  useEffect(() => {
    if (message.length !== 0 && message.reportId) {
      setNewNotification(true);
    }
  }, [message]);

  useMount(() => {
    fetchUser();
  });

  const fetchUser = useCallback(async () => {
    const userData = await getUserRequest();

    const { role } = userData?.userAccesses.find((element) => {
      return element.portal === Portal.OILCO;
    });

    UserRoleAccess.forEach((value) => {
      value?.items?.forEach((value) => {
        const { key } = value;

        const userAccess = value?.permission?.filter((item) => {
          if (item?.role === role) {
            return item;
          }

          return "";
        });

        if (userAccess.length > 0) {
          setUserAccess((prevState) => [
            ...prevState,
            {
              key,
              ...userAccess[0],
              role,
            },
          ]);
        }
      });
    });

    setUser(userData);
  }, [getUserRequest]);

  const loading = fetchingUser || appState?.loading || !user;

  return (
    <UserContext.Provider
      value={{
        user,
        userAccess,
        fetchUser,
        loading: fetchingUser,
        onDisconnect,
        onConnect,
        sessionId,
      }}
    >
      {loading ? (
        <AppLoader />
      ) : user ? (
        <MessagesPage>
          <Fragment>
            <Header
              setNewNotification={() => setNewNotification(false)}
              newNotification={newNotification}
            />
            <Sidebar />
            {/* <DownloadDrawer
              isOpen={isDrawerOpen}
              drawerData={message}
              setIsOpen={setIsDrawerOpen}
            /> */}
            <div
              style={{
                paddingLeft: "196px",
                marginTop: "60px",
                minWidth: "1170px",
                minHeight: "calc(100vh - 70px)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  marginTop: "60px",
                  padding: "27px 36px",
                  minWidth: "1170px",
                  overflowX: "auto",
                  minHeight: "100vh",
                  backgroundColor: "#fff",
                }}
              >
                <Switch>
                  <Route exact path={Path.Slash} component={DashboardPage} />
                  {/* <Route path={Path.MyApproval} component={MyApprovalPage} /> */}
                  <Route path={Path.Pricing} component={PricingPage} />
                  <Route path={Path.Campaigns} component={CampaignsPage} />
                  <Route path={Path.Payments} component={PaymentsPage} />
                  <Route path={Path.ConsumerFuelPage} component={FuelPage} />
                  {/* <Route path={Path.Inventory} component={InventoryPage} /> */}
                  {/* <Route path={Path.Admin} component={AdminPage} exact /> */}
                  <Route path={Path.Seaoil} component={SeaoilPage} exact />
                  <Route path={Path.Pricelocq} component={PricelocqPage} exact />
                  <Route path={Path.PricelocqConsumer} component={PricelocqConsumerPage} exact />
                  <Route path={Path.Inbox} component={InboxPage} />
                  <Route path={Path.PricelocqBusiness} component={PricelocqBusinessPage} exact />
                  <Route path={Path.Accounts} component={AccountsPage} />
                  <Route
                    path={Path.LubeServOnWheelsBookings}
                    component={LubeServOnWheelsBookingsPage}
                  />
                  <Route
                    path={Path.LubeServOnSiteBookings}
                    component={LubeServOnSiteBookingsPage}
                  />

                  <Route path={Path.ConsumerRFID} component={RFIDPage} />
                  <Route exact path={Path.NonFuel} component={NonFuelPage} />
                  <Route path={Path.NonFuelPLB} component={NonFuelModule} />
                  <Route path={Path.AccessAuthorization} component={AccessAuthorizationPage} />

                  <Route path={Path.Downloads} component={DownloadModule} />
                  <Route path={Path.VIP} component={VipPage} />
                </Switch>
              </div>
            </div>
          </Fragment>
        </MessagesPage>
      ) : null}
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
