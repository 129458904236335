import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    toMobileNumber = {
      value: "",
    },
    accountName = {
      value: "",
    },
    isExist = {
      value: false,
    },
  } = state;
  return {
    accountName: {
      name: "accountName",
      ...accountName,
    },
    isExist: {
      name: "isExist",
      ...isExist,
    },
    toMobileNumber: {
      name: "toMobileNumber",
      label: locale.newMobileNumber,
      required: true,
      horizontal: true,
      maxLength: 10,
      parseNumber: true,
      validations: [
        Validation.mobileNumber({
          hasInputLabel: true,
        }),
      ],
      ...toMobileNumber,
    },
  };
};
