import classNames from "classnames";
import { Modal, ActionButton, TextField, Field, Autocomplete } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./void-non-fuel-modal.module.scss";
import { VoidPaymentValue } from "enums/void-payment-value";
import { prettifyVoidPaymentValue } from "utils/pretty.utils";

const VoidPaymentModalPLC = ({
  close,
  active,
  onSubmit,
  remarks = "",
  onChangeRemarks,
  loading,
  title,
  description,
  label,
  actionText,
  value,
  setValue,
}) => {
  return (
    <Modal
      titleStyle={styles.titleStyle}
      active={active}
      title={title}
      close={close}
      className={styles.modal}
    >
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        {description}
        <Field
          label={label}
          labelPosition="left"
          childrenStyle={{
            width: "400px",
          }}
          style={{
            display: "flex",
          }}
        >
          <Autocomplete
            name="voidPayment"
            value={value}
            onChange={(name, { value }) => {
              if (value !== VoidPaymentValue.Others) {
                onChangeRemarks(value);
              } else {
                onChangeRemarks("");
              }
              setValue(value);
            }}
            options={[
              VoidPaymentValue.WrongAmountInput,
              VoidPaymentValue.DidNotIntendToPayViaPeso,
              VoidPaymentValue.Others,
            ].map((item) => ({
              label: prettifyVoidPaymentValue(item),
              value: item,
            }))}
          />
        </Field>
        <Field
          label={" "}
          labelPosition="left"
          childrenStyle={{
            width: "400px",
          }}
          style={{
            display: "flex",
          }}
        >
          {value === VoidPaymentValue.Others && (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <TextField
                multiline
                rowsMax={1}
                onChange={(n, { value }) => {
                  onChangeRemarks(value);
                }}
                value={remarks}
              ></TextField>
            </div>
          )}
        </Field>
      </div>
      <ActionButton
        right
        loading={loading}
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
              setValue("");
            },
          },
          {
            onClick: () => {
              onSubmit(remarks);
              setValue("");
            },
            text: actionText,
            disabled: remarks?.length === 0,
          },
        ]}
      />
    </Modal>
  );
};

export default VoidPaymentModalPLC;
