import { Button, Modal, Title } from "components/commons";
import React from "react";
import { locale } from "localization/en";

const AnnouncementModal = ({ title, content, openMessage, ...modal }) => {
  return (
    <Modal {...modal} closable={false}>
      <div
        style={{
          margin: "0px auto 10px auto",
          textAlign: "center",
        }}
      >
        <Title
          small
          style={{
            margin: "0px auto 10px auto",
            textAlign: "center",
          }}
        >
          {title}
        </Title>
      </div>
      <div
        style={{
          padding: "20px 0px",
          maxHeight: "350px",
          overflowY: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div
        style={{
          padding: "20px 50px",
        }}
      >
        <Button
          primary
          full
          style={{
            width: "100%",
          }}
          onClick={() => {
            modal.onClose();
            if (openMessage) {
              openMessage();
            }
          }}
        >
          {locale.learnMore}
        </Button>
        <Button
          textStyle
          variant="text"
          textButton
          style={{
            width: "100%",
            margin: "20px 0px 0px 0px",
          }}
          onClick={() => {
            modal.onClose();
          }}
        >
          {locale.maybeLater}
        </Button>
      </div>
    </Modal>
  );
};

export default AnnouncementModal;
