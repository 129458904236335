import { CampaignStatus } from "enums";

export const voucherCampaignFilterState = (fleetId) => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  voucherType: "all",
  page: 1,
  perPage: 10,
  status: {
    isSelectedAll: true,
    value: [
      CampaignStatus.Upcoming,
      CampaignStatus.Ongoing,
      CampaignStatus.Ended,
      CampaignStatus.Cancelled,
    ],
  },
  campaignCreators: {
    value: [],
    isAll: true,
  },
});
