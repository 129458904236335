import React, { useState, useContext } from "react";
import { Path, PLBTypes } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import FleetAccountsPage from "./fleet-accounts.page";
import CardManagementPage from "./card-management.page";
import ViewFleetAccountModule from "modules/fleet-accounts/details/account-view/account-view.module";

import PanelButtons from "components/commons/panel-button/panel-buttons";
import ViewFleetPricingModule from "modules/fleet-accounts/pricing/pricing.module";
import AddFleetAccountModule from "modules/fleet-accounts/details/account-add/account-add.module";
import EditFleetAccountModule from "modules/fleet-accounts/details/account-edit/account-edit.module";

import locale from "localization";

import { BackLink } from "components/commons";
import { useRouter } from "hooks";
import StationPage from "./station.page";
import { useApi, useMount } from "hooks";
import { getFleet } from "apis";
import CustomerListModule from "modules/customer/customer-list/customer-list.module";
import { FeatureFlagContext } from "contexts";
import KYCPage from "./kyc.page";

const ViewFleetPage = () => {
  const [disableButton, setDisableButton] = useState(false);
  const { query } = useRouter();
  const fleetId = query?.id;

  const { request: getFleetRequest } = useApi({
    api: getFleet,
    modalError: true,
    params: {
      fleetId,
    },
  });

  useMount(async () => {
    if (fleetId) {
      const res = await getFleetRequest();
      if (res) {
        setDisableButton(
          res?.plbType === PLBTypes.PLB_LOCQPAY || res?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO
            ? true
            : false
        );
      }
    }
  });

  return (
    <>
      <BackLink text={locale.fleetAccountList} path={Path.FleetAccounts} />
      <PanelButtons
        items={[
          {
            text: "Fleet Account Details",
            path: Path.ViewFleetAccountById(query?.id),
            disable: false,
          },
          {
            text: "Pricing Details",
            path: Path.ViewFleetPricingById(query?.id),
            disable: disableButton,
          },
        ]}
      />
      <div style={{ marginTop: 60 }}>
        <Switch>
          <Route path={Path.ViewFleetAccount} component={ViewFleetAccountModule} />
          <Route path={Path.EditFleetAccount} component={EditFleetAccountModule} />
          <Route path={Path.ViewFleetPricing} component={ViewFleetPricingModule} />
          <Redirect to={Path.ViewFleetAccount} />
        </Switch>
      </div>
    </>
  );
};

// ViewFleetPage

const AccountsPage = () => {
  // ViewFleetDetails
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route exact path={Path.FleetAccounts} component={FleetAccountsPage} />
      <Route exact path={Path.CardManagement} component={CardManagementPage} />
      <Route exact path={Path.AddFleetAccount} component={AddFleetAccountModule} />
      <Route path={Path.CustomersList} component={CustomerListModule} />
      <Route path={Path.ViewFleetDetails} component={ViewFleetPage} />
      <Route path={Path.Station} component={StationPage} />
      {hasFeature("PAGE_KYC_SUBMISSIONS") && (
        <Route path={Path.KYCSubmissions} component={KYCPage} />
      )}
      <Redirect to={Path.FleetAccounts} />
    </Switch>
  );
};

export default AccountsPage;
