import { LubeServOnWheelsPreferredTime } from "enums/lubeserv-preferredtime";
import OilChangeType from "enums/oil-change-type";

export const lubeServOnWheelsBookingsFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  type: [
    OilChangeType.dieselMonoGrade,
    OilChangeType.dieselMultiGrade,
    OilChangeType.dieselFullySynthetic,
    OilChangeType.gasolineMonoGrade,
    OilChangeType.gasolineMultiGrade,
    OilChangeType.gasolineFullySynthetic,
  ],
  selectedPreferredDate: null,
  selectedPreferredTime: [
    LubeServOnWheelsPreferredTime.nineAM,
    LubeServOnWheelsPreferredTime.twelvePM,
    LubeServOnWheelsPreferredTime.threePM,
  ],
  page: 1,
  perPage: 10,
});
