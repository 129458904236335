import { useState } from "react";

const useModal = () => {
  const [active, setActive] = useState(false);
  const [state, setState] = useState({});

  return {
    show: (modalState = {}) => {
      setActive(true);
      setState(modalState);
    },
    close: () => setActive(false),
    active,
    setState,
    ...state,
  };
};

export default useModal;
