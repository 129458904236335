import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import Station from "modules/station/station.module";
import AddStation from "modules/station/add-station";
import EditStation from "modules/station/edit-station";
import ViewStation from "modules/station/view-station";

const StationPage = () => {
  return (
    <Switch>
      <Route exact path={Path.AddStation} component={AddStation} />
      <Route exact path={Path.EditStation} component={EditStation} />
      <Route exact path={Path.ViewStation} component={ViewStation} />
      <Route exact path={Path.Station} component={Station} />
      <Redirect to={Path.Station} />
    </Switch>
  );
};

export default StationPage;
