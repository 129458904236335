export const vouchersFilterState = () => ({
  page: 1,
  perPage: 10,
  kind: "campaign",
  endDate: null,
  startDate: null,
  status: ["all", "upcoming", "ongoing", "ended", "cancelled"],
  type: ["all", "generic", "unique"],
  currency: ["all", "peso", "liter", "points"],
});
