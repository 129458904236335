// import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getAccountStations = async ({ ...query }) => {
  const res = await Get(`ms-station/station`, { ...query, orderBy: "stationId" });
  return res.data;
};

export const getAccountStationById = async ({ stationId }) => {
  const res = await Get(`ms-station/station/${stationId}`);
  return res.data;
};

export const getAlertStationCount = async ({ ...query }) => {
  const res = await Get(`ms-station/station/alert/count`, { ...query });
  return res.data;
};

export const getStationByCode = async ({ stationCode }) => {
  const res = await Get(`ms-station/station/price-tool/${stationCode}`);
  return res.data;
};

export const getAccountStationByCode = async ({ stationCode }) => {
  const res = await Get(`ms-station/station/code/${stationCode}`);
  return res.data;
};

export const createStation = async ({ ...body }) => {
  const res = await Post(`ms-station/station`, body);
  return res.data;
};

export const updateStation = async ({ stationId, ...body }) => {
  const res = await Put(`ms-station/station/${stationId}`, body);
  return res.data;
};

export const updateStationStatus = async ({ stationId, ...body }) => {
  const res = await Put(`ms-station/station/${stationId}/status`, body);
  return res.data;
};

export const sendStationEmail = async ({ stationId, ...body }) => {
  const res = await Post(`ms-station/station/${stationId}/email`, body);
  return res.data;
};

export const getNearestStation = async ({ stationId, ...body }) => {
  const res = await Get(`ms-station/station/${stationId}/nearest`, body);
  return res.data;
};

export const clearStationAlert = async ({ stationId }) => {
  const res = await Put(`ms-station/station/${stationId}/alert/clear`, {});
  return res.data;
};

export const clearStationHistory = async ({ stationHistoryId }) => {
  const res = await Put(`ms-station/station/${stationHistoryId}/history/acknowledge`, {});
  return res.data;
};
