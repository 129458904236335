import React, { useCallback } from "react";
import { Radio as MaterialRadio } from "@material-ui/core";
import { Text } from "components/commons";
import styles from "./radio.module.scss";
import classNames from "classnames";

const Radio = ({
  className,
  radioClassName,
  options,
  value,
  onChange,
  name,
  vertical,
  disabled,
  style,
}) => {
  const onChangeCb = useCallback(
    (val) => {
      if (onChange) return onChange(name, { value: val });
    },
    [onChange, name]
  );

  return (
    <div
      style={style}
      className={classNames(styles.radioContainer, { [`${styles.vertical}`]: vertical }, className)}
    >
      {options.map((o, i) => {
        return (
          <div
            key={i}
            className={classNames(styles.radio, radioClassName, {
              [`${styles.disabled}`]: disabled,
            })}
          >
            <div className="flex items-center">
              <MaterialRadio
                checked={value === o.value}
                value={o.value}
                onChange={() => {
                  onChangeCb(o.value);
                }}
                disabled={disabled}
              ></MaterialRadio>
              <Text>{o.text}</Text>
            </div>

            {value === o.value && <div> {o.content}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
