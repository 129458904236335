import locale from "localization";

export const columns = [
  { key: "businessName", text: locale.businessName, width: "10%" },
  {
    key: "referenceStation",
    text: `${locale.referenceStation} / ${locale.stationType}`,
    width: "10%",
  },
  { key: "product", text: locale.product, width: "6.72%" },
  // { key: "supplierWpp", text: locale.supplierWppLabel, width: "6.72%" },
  // { key: "orum", text: locale.orum, width: "6.72%" },
  { key: "sdum", text: locale.sdum, width: "6.72%" },
  { key: "freight", text: locale.freight, width: "6.72%" },
  { key: "sdumf", text: locale.sdumf, width: "6.72%" },
  { key: "virtualRefSPumpPrice", text: locale.virtualRefsPumpPrice, width: "6.72%" },
  { key: "totalDiscount", text: locale.totalDiscount, width: "6.72%" },
  { key: "estimateMonthlyVolume", text: locale.estimateMonthlyVolume, width: "6.72%" },
  { key: "grossFloorMargin", text: locale.grossFloorMargin, width: "6.72%" },
  { key: "floorPrice", text: locale.floorPrice, width: "6.72%" },
];
