import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./account-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import AccountDetailsModule from "../account-details/account-details.module";

const AddFleetAccountModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.fleetAccountList} path={Path.FleetAccounts} />
      <div className={styles.intro}>
        <Intro title={locale.addFleetAccount} />
        <AccountDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default AddFleetAccountModule;
