import Pill from "../pill/pill";
import Text from "../text/text";

const LabelAndPill = ({ label, pillLabel, pillProps, className }) => {
  return (
    <div>
      <Pill {...pillProps}>{pillLabel}</Pill>
      <div style={{ marginTop: "5px" }}>
        <Text subtitle>{label}</Text>
      </div>
    </div>
  );
};
export default LabelAndPill;
