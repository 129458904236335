import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { isNumeric } from "utils";

const InputMobileNumber = ({ className, onChange, innerLabel, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (isNumeric(value) || !value) {
        onChange(name, { value });
      }
    },
  };

  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix="+63" />;
  }
  return <TextField {...componentProps} />;
};

export default InputMobileNumber;
