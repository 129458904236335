export const payHistoryState = () => ({
  startDate: null,
  endDate: null,
  page: 1,
  perPage: 10,
  searchKey: "",
  productCodes: "",
  referenceStation: {
    isSelectedAll: true,
    value: [],
  },
});
