import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PLCRedemptionModule from "modules/plc/redemption/redemption.module";

const PLCRedemptionPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PLCRedemption} component={PLCRedemptionModule} />
      <Redirect to={Path.PLCRedemption} />
    </Switch>
  );
};

export default PLCRedemptionPage;
