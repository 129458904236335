import { ApiPath } from "enums";
import { Get } from "utils";

export const getStations = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Station}`, query);
  return res.data;
};

export const getStationsPricing = async ({ ...query }) => {
  const res = await Get(`${ApiPath.StationPricing}`, query);
  return res.data;
};

export const getStationAccounts = async ({ ...query }) => {
  const res = await Get(`${ApiPath.StationAccounts}`, query);
  return res.data;
};

export const getPriceBuildup = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetPriceBuildup}`, query);
  return res.data;
};

export const getSeaoilPriceTool = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetSeaoilPriceTool}`, query);
  return res.data;
};

export const getStationPricing = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.GetStationPricing(stationId)}`, query);
  return res.data;
};

export const getStationNegotiatedDiscount = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetStationNegotiatedDiscount}`, query);
  return res.data;
};
