import { Pill, PopOver, PopOverMenu, Text } from "components/commons";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import { DateTime, Products, SettlementStatus } from "enums";
import RedemptionStatus from "enums/redemption-status";
import { formatCurrency, formatDate, formatVolume } from "utils";
import {
  prettifyProduct,
  prettifyRedemptionStatus,
  prettifySettlementStatus,
  prettifyVoidTransactionValue,
} from "utils/pretty.utils";
import styles from "../../plb/redemptions/redemptions.module.scss";
import locale from "localization";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DisplayCustomerName } from "components/commons/data-display";

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

export const mapDataToList = (
  redemptions,
  setRemarks,
  voidTransactionModal,
  onVoidTransactionCb,
  updateRemarksApi,
  setValue,
  getUserAccess
) => {
  const {
    redemptionId,
    averageCreditPrice,
    literVolume,
    totalCreditPrice,
    userDetail,
    station,
    status,
    remarks,
    productCode,
    redeemedAt,
    settlementId,
    settlementStatus,
  } = redemptions;

  const options = [];

  if (getUserAccess?.fullAccess || getUserAccess?.voidAccess) {
    if (status === RedemptionStatus.Success) {
      options.push({
        content: locale.voidTransaction,
        onClick: () => {
          setRemarks("");
          voidTransactionModal.show({
            redemptions,
            title: `${locale.voidTransaction}?`,
            description: (
              <Text align="center">
                <locale.Populate
                  text={
                    settlementId
                      ? settlementStatus === SettlementStatus.ForProcessing
                        ? locale.voidingThisTransactionSettlementProcessing
                        : locale.voidingThisTransactionSettlementProcessed
                      : locale.voidingThisTransaction
                  }
                  items={[<b>{formatVolume(literVolume)}</b>]}
                />
              </Text>
            ),
            label: locale.reasonRemarks,
            actionText: locale.voidTransaction,
            submit: (value) => {
              onVoidTransactionCb({
                redemptionItem: redemptions,
                value,
              });
            },
          });
        },
      });
    }
  }

  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    if (status === RedemptionStatus.Voided) {
      options.push({
        content: locale.editRemarks,
        onClick: () => {
          setRemarks(remarks);
          setValue(prettifyVoidTransactionValue(remarks));
          voidTransactionModal.show({
            redemptions,
            title: locale.editRemarks,
            description: null,
            label: locale.reasonRemarks,
            actionText: locale.save,
            submit: async (value) => {
              await updateRemarksApi.request({
                id: redemptionId,
                remarks: value,
              });
              redemptions.remarks = value;
              voidTransactionModal.close();
              setRemarks("");
            },
          });
        },
      });
    }
  }

  const obj = {
    redemptionId: <div className="min-70">{redemptionId}</div>,
    redemptionDate: <div className="min-80">{formatDate(redeemedAt, DateTime.G)}</div>,
    userDetails: <DisplayCustomerName data={userDetail} />,
    redemptionStation: <div className="min-150">{station?.name || ""}</div>,
    productCode: (
      <div className="min-70">
        <LabelAndCircle label={prettifyProduct(productCode)} color={getProductColor(productCode)} />
      </div>
    ),
    creditPrice: <div className="min-70">{formatCurrency(averageCreditPrice)}</div>,
    volume: <div className="min-70">{formatVolume(literVolume)}</div>,
    totalCreditAmount: <div className="min-70">{formatCurrency(totalCreditPrice)}</div>,
    status: (
      <Pill grass={status === RedemptionStatus.Success} cement={status === RedemptionStatus.Voided}>
        {prettifyRedemptionStatus(status)}
      </Pill>
    ),
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div className="link">View</div>
      </PopOver>
    ) : (
      ""
    ),
    settlementStatus: (
      <>
        <Pill
          grass={settlementStatus === SettlementStatus.Processed}
          cyan={settlementStatus === SettlementStatus.ForSettlement}
          sky={settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(settlementStatus)}
        </Pill>
        {settlementStatus === SettlementStatus.ForProcessing ||
        settlementStatus === SettlementStatus.Processed ? (
          <Text className={styles.subBusinessId}>{settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </>
    ),
    options: options.length > 0 && (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };

  return obj;
};

export const mapFilterToRequest = (filterState) => {
  const { redemptionStation, redemptionStatuses, productCodes, settlementStatuses, ...props } =
    filterState;
  if (redemptionStation.value.length) {
    props.redemptionStationIds = redemptionStation.value
      .map(({ value }) => {
        return value;
      })
      .join(",");
  }

  return {
    ...props,
    redemptionStatuses: redemptionStatuses.join(","),
    productCodes: productCodes.join(","),
    settlementStatuses: settlementStatuses.join(","),
  };
};
