import { Field, Modal, TextField, Text, ActionButton, Title } from "components/commons";
import React from "react";
import locale from "localization";
import useForm from "hooks/useForm.v2";
import { onboardPLCState } from "./onboard-plc-form.state";
import { updateStationStatus } from "apis/account-stations";
import { useApi } from "hooks";
import { PLCStatus } from "enums";
import { useEffect } from "react";

const OnboardPLCModal = ({ refresh, stationId, stationType, hasSettlementInfo, ...modal }) => {
  const form = useForm(onboardPLCState);
  const { fields, initializeForm } = form;
  const { mobileNumber = {} } = fields;

  const onboardRequest = useApi({
    api: updateStationStatus,
  });

  const { loading } = onboardRequest;

  const {
    bankAccountName = {},
    nameOfBank = {},
    bankAccountNumber = {},
    settlementEmail = {},
  } = fields;
  useEffect(() => {
    if (stationType) {
      const showSettlementInfo = !hasSettlementInfo && ["CODO", "DODO"].includes(stationType);

      form.initializeForm(
        {
          bankAccountName: {
            value: showSettlementInfo ? bankAccountName.value : "",
            validations: {
              ...bankAccountName.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          nameOfBank: {
            value: showSettlementInfo ? nameOfBank.value : "",
            validations: {
              ...nameOfBank.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          bankAccountNumber: {
            value: showSettlementInfo ? bankAccountNumber.value : "",
            validations: {
              ...bankAccountNumber.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          settlementEmail: {
            value: showSettlementInfo ? settlementEmail.value : "",
            validations: {
              ...settlementEmail.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.active]);

  return (
    <Modal
      {...modal}
      title={locale.onBoardNowinPLC}
      style={{
        width: "400px",
      }}
    >
      <Text className="text-center">{locale.plcOnboardText}</Text>

      <Field {...mobileNumber}>
        <TextField
          {...mobileNumber}
          prefix={
            <div>
              <Text
                style={{
                  fontSize: "14px",
                }}
              >
                +63
              </Text>
            </div>
          }
        />{" "}
      </Field>

      {[
        {
          title: locale.settlementInformation,
          fields: [
            {
              ...bankAccountName,
              content: bankAccountName.content || (
                <TextField {...bankAccountName} disabled={loading} />
              ),
            },
            {
              ...bankAccountNumber,
              content: bankAccountNumber.content || (
                <TextField {...bankAccountNumber} disabled={loading} />
              ),
            },
            {
              ...nameOfBank,
              content: nameOfBank.content || <TextField {...nameOfBank} disabled={loading} />,
            },
            {
              ...settlementEmail,
              content: settlementEmail.content || (
                <TextField {...settlementEmail} disabled={loading} />
              ),
            },
          ],
        },
      ]
        .filter((group) => {
          const hasVisible = group.fields.find((item) => item.visible);
          return hasVisible;
        })
        .map((group, groupIndex) => {
          return (
            <div
              key={groupIndex}
              style={{
                maxWidth: "500px",
              }}
            >
              <Title xsmall>{group.title}</Title>
              <div>
                {group.fields.map((field, fieldIndex) => {
                  return (
                    <Field
                      childrenStyle={{
                        minWidth: "250px",
                        maxWidth: "180px",
                      }}
                      {...field}
                      key={fieldIndex}
                      labelPosition="left"
                      label={field.label}
                      error={field.error}
                      messages={field.messages}
                    >
                      {field.content || (
                        <Text strong>{typeof field.value === "string" ? field.value : "-"}</Text>
                      )}
                    </Field>
                  );
                })}
              </div>
            </div>
          );
        })}

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modal.close();
              initializeForm({}, true);
            },
          },
          {
            loading: onboardRequest.loading,
            disabled: !form.isFormSubmittable,
            text: locale.yesOnboardNow,
            onClick: () => {
              form.onSubmit(
                async ({
                  mobileNumber,
                  bankAccountName,
                  bankAccountNumber,
                  nameOfBank,
                  settlementEmail,
                }) => {
                  await onboardRequest.request({
                    stationId,
                    mobileNumber,
                    plcStatus: PLCStatus.Active,
                    bankAccountName,
                    bankAccountNumber,
                    bankName: nameOfBank,
                    settlementEmail,
                  });
                  modal.close();
                  initializeForm({}, true);
                  refresh();
                }
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

export default OnboardPLCModal;
