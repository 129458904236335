const BankCodes = {
  BdoPersonal: "BDO_PERSONAL",
  Bpi: "BPI_PERSONAL",
  Metrobank: "METROBANK_PERSONAL",
  Unionbank: "UNIONBANK_PERSONAL",
  RCBCPersonal: "RCBC_PERSONAL",
  PNBPersonal: "PNB_PERSONAL",
  Dummy: "DUMMY_BANK_PERSONAL",
  AUB: "AUB",
  BankOfCommerce: "BOC",
  BPI: "bpi",
  Chinabank: "CBDD",
  Eastwest: "EWXB",
  EastwestOTC: "EWXB",
  Landbank: "LBPA",
  Maybank: "MAYB",
  MetroBank: "MBTC",
  PNB: "PNBB",
  PSBank: "PSB",
  RCBC: "RCBC",
  Robinsons: "RSB",
  UCPB: "UCPB",
  BDONetwork: "BNRX",
  BDO: "BDRX",
  BPIOTC: "BPXB",
  ChinabankOTC: "CBXB",
  LandbankOTC: "LBXB",
  MetroBankOTC: "MBXB",
  PNBOTC: "PNXB",
  RCBCOTC: "RCXB",
  RobinsonsOTC: "RSBB",
  SecurityBankOTC: "SBCB",
  SecurityBank: "SBC",
  UnionbankOTC: "UBXB",
  UCPBOTC: "UCXB",
  AUBOTC: "AUB",
  BOG: "BOG",
  BOGX: "BOGX",
};

export default BankCodes;
