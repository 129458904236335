export const tableFormState = (state = {}) => {
  const {
    baseDieselPrice = {
      value: "0",
    },
    baseGas91Price = {
      value: "0",
    },
    baseGas95Price = {
      value: "0",
    },
    baseGas97Price = {
      value: "0",
    },
    discountDiesel = {
      value: "0",
    },
    discountGas91 = {
      value: "0",
    },
    discountGas95 = {
      value: "0",
    },
    discountGas97 = {
      value: "0",
    },
    accuMovementDiesel = {
      value: "0",
    },
    accuMovementGas91 = {
      value: "0",
    },
    accuMovementGas95 = {
      value: "0",
    },
    accuMovementGas97 = {
      value: "0",
    },
    accuSpecificDiesel = {
      value: "0",
    },
    accuSpecificGas91 = {
      value: "0",
    },
    accuSpecificGas95 = {
      value: "0",
    },
    accuSpecificGas97 = {
      value: "0",
    },
    contractPriceDiesel = {
      value: "0",
    },
    contractPriceGas91 = {
      value: "0",
    },
    contractPriceGas95 = {
      value: "0",
    },
    contractPriceGas97 = {
      value: "0",
    },
    estimatedDieselVolume = {
      value: "0",
    },
    estimatedGas91Volume = {
      value: "0",
    },
    estimatedGas95Volume = {
      value: "0",
    },
    estimatedGas97Volume = {
      value: "0",
    },
    existingDieselVolume = {
      value: "0",
    },
    existingGas91Volume = {
      value: "0",
    },
    existingGas95Volume = {
      value: "0",
    },
    existingGas97Volume = {
      value: "0",
    },
    stationMovementDiesel = {
      value: "0",
    },
    stationMovementGas91 = {
      value: "0",
    },
    stationMovementGas95 = {
      value: "0",
    },
    stationMovementGas97 = {
      value: "0",
    },
    sdumDiesel = {
      value: "0",
    },
    sdumGas91 = {
      value: "0",
    },
    sdumGas95 = {
      value: "0",
    },
    sdumGas97 = {
      value: "0",
    },
    freightDiesel = {
      value: "0",
    },
    freightGas91 = {
      value: "0",
    },
    freightGas95 = {
      value: "0",
    },
    freightGas97 = {
      value: "0",
    },
  } = state;

  return {
    baseDieselPrice: {
      name: "baseDieselPrice",
      disabled: true,
      inputDisabled: true,
      innerLabel: true,
      parseNumber: true,
      ...baseDieselPrice,
    },
    baseGas91Price: {
      disabled: true,
      name: "baseGas91Price",
      innerLabel: true,
      inputDisabled: true,
      parseNumber: true,
      ...baseGas91Price,
    },
    baseGas95Price: {
      disabled: true,
      name: "baseGas95Price",
      innerLabel: true,
      inputDisabled: true,
      parseNumber: true,
      ...baseGas95Price,
    },
    baseGas97Price: {
      disabled: true,
      name: "baseGas97Price",
      innerLabel: true,
      inputDisabled: true,
      parseNumber: true,
      ...baseGas97Price,
    },
    discountDiesel: {
      name: "discountDiesel",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...discountDiesel,
    },
    discountGas91: {
      name: "discountGas91",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...discountGas91,
    },
    discountGas95: {
      name: "discountGas95",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...discountGas95,
    },
    discountGas97: {
      name: "discountGas97",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...discountGas97,
    },
    accuMovementDiesel: {
      disabled: true,
      inputDisabled: true,
      name: "accuMovementDiesel",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementDiesel,
    },
    accuMovementGas91: {
      disabled: true,
      inputDisabled: true,
      name: "accuMovementGas91",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas91,
    },
    accuMovementGas95: {
      disabled: true,
      inputDisabled: true,
      name: "accuMovementGas95",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas95,
    },
    accuMovementGas97: {
      disabled: true,
      inputDisabled: true,
      name: "accuMovementGas97",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas97,
    },
    accuSpecificDiesel: {
      disabled: true,
      inputDisabled: true,
      name: "accuSpecificDiesel",
      innerLabel: true,
      parseNumber: true,
      ...accuSpecificDiesel,
    },
    accuSpecificGas91: {
      disabled: true,
      inputDisabled: true,
      name: "accuSpecificGas91",
      innerLabel: true,
      parseNumber: true,
      ...accuSpecificGas91,
    },
    accuSpecificGas95: {
      disabled: true,
      inputDisabled: true,
      name: "accuSpecificGas95",
      innerLabel: true,
      parseNumber: true,
      ...accuSpecificGas95,
    },
    accuSpecificGas97: {
      disabled: true,
      inputDisabled: true,
      name: "accuSpecificGas97",
      innerLabel: true,
      parseNumber: true,
      ...accuSpecificGas97,
    },
    contractPriceDiesel: {
      disabled: true,
      inputDisabled: true,
      name: "contractPriceDiesel",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...contractPriceDiesel,
    },
    contractPriceGas91: {
      disabled: true,
      inputDisabled: true,
      name: "contractPriceGas91",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas91,
    },
    contractPriceGas95: {
      disabled: true,
      inputDisabled: true,
      name: "contractPriceGas95",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas95,
    },
    contractPriceGas97: {
      disabled: true,
      inputDisabled: true,
      name: "contractPriceGas97",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas97,
    },
    estimatedDieselVolume: {
      name: "estimatedDieselVolume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...estimatedDieselVolume,
    },
    estimatedGas91Volume: {
      name: "estimatedGas91Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas91Volume,
    },
    estimatedGas95Volume: {
      name: "estimatedGas95Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas95Volume,
    },
    estimatedGas97Volume: {
      name: "estimatedGas97Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas97Volume,
    },
    existingDieselVolume: {
      name: "existingDieselVolume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingDieselVolume,
    },

    existingGas91Volume: {
      name: "existingGas91Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas91Volume,
    },
    existingGas95Volume: {
      name: "existingGas95Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas95Volume,
    },
    existingGas97Volume: {
      name: "existingGas97Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas97Volume,
    },
    stationMovementDiesel: {
      name: "stationMovementDiesel",
      disabled: true,
      inputDisabled: true,
      ...stationMovementDiesel,
    },
    stationMovementGas91: {
      name: "stationMovementGas91",
      disabled: true,
      inputDisabled: true,
      ...stationMovementGas91,
    },
    stationMovementGas95: {
      name: "stationMovementGas95",
      disabled: true,
      inputDisabled: true,
      ...stationMovementGas95,
    },
    stationMovementGas97: {
      name: "stationMovementGas97",
      disabled: true,
      inputDisabled: true,
      ...stationMovementGas97,
    },
    sdumDiesel: {
      name: "sdumDiesel",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...sdumDiesel,
    },
    sdumGas91: {
      name: "sdumGas91",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...sdumGas91,
    },
    sdumGas95: {
      name: "sdumGas95",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...sdumGas95,
    },
    sdumGas97: {
      name: "sdumGas97",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...sdumGas97,
    },
    freightDiesel: {
      name: "freightDiesel",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...freightDiesel,
    },
    freightGas91: {
      name: "freightGas91",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...freightGas91,
    },
    freightGas95: {
      name: "freightGas95",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...freightGas95,
    },
    freightGas97: {
      name: "freightGas97",
      required: true,
      innerLabel: true,
      parseNumber: true,
      inputDisabled: true,
      ...freightGas97,
    },
  };
};
