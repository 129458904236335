const VoucherCampaignGroup = {
  SPIAllowance: "spi-allowance",
  SPIAllocation: "spi-allocation",
  SPIOBTravel: "spi-ob-travel",
  SPISuppliers: "spi-suppliers",
  SPIHonorarium: "spi-honorarium",
  Others: "others",
};

export default VoucherCampaignGroup;
