import { parseCSVFile } from "utils/object.utils";
import { isMobileNumberStrictValid } from "utils/text.utils";
import React from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
import { useCallback } from "react";

const ImportKYCModal = ({ refresh, resultModal, kycBatchRequest, ...importModal }) => {
  const validateFile = async ({ file }, onParse) => {
    const items = await parseCSVFile(file, [
      {
        text: "Mobile Number",
        key: "mobileNumber",
        validation: (text, row) => {
          const error = !isMobileNumberStrictValid(text);
          return {
            error,
            message: "Invalid mobile number",
          };
        },
      },
      {
        text: "Status",
        key: "status",
        validation: (text, row) => {
          const error = !["Approved", "Rejected"].includes(text);
          return {
            error,
            message: "Invalid status",
          };
        },
      },
      {
        text: "Rejection Message",
        key: "rejectionMessage",
        validation: (text, row) => {
          const rejectedError = row.status === "Rejected" && !text.trim();
          const approvedError = row.status === "Approved" && text.trim();
          return {
            error: approvedError || rejectedError,
            message: rejectedError
              ? "Rejected but no 'Rejection Message'"
              : "Approved but there is 'Rejection Message'",
          };
        },
      },
    ]);
    return items;
  };

  const showErrorModal = useCallback(
    ({ parsedFile, error }) => {
      importModal.close();
      const { data = {} } = error;
      const { errorRows = [], errorCustomerNotExists = [] } = data;
      const normalizeItems = {};
      parsedFile.passed.forEach((item) => {
        // if (!normalizeItems[item.mobileNumber]) {
        normalizeItems[item.mobileNumber] = item;
        // }
      });
      const rowErrors = [...errorRows, ...errorCustomerNotExists]
        .map((mobileNumber) => normalizeItems[mobileNumber].rowNumber)
        .sort();
      resultModal.show({
        title: locale.importFailed,
        content: (
          <ErrorImport
            otherError={parsedFile?.rowErrors.map((row) => `Row ${row}`)}
            doesNotExists={rowErrors.map((row) => `Row ${row}`)}
          />
        ),
      });
    },
    [resultModal, importModal]
  );

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          resultModal.close();
          refresh();
        }}
      >
        {resultModal.content}
        <ActionButton
          right={!resultModal.isSuccess}
          center={resultModal.isSuccess}
          items={
            resultModal.isSuccess
              ? [
                  {
                    text: locale.gotIt,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                    primary: true,
                  },
                ]
              : [
                  {
                    text: locale.gotIt,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                  },
                  {
                    text: locale.retry,
                    onClick: () => {
                      resultModal.close();
                      importModal.show();
                    },
                  },
                ]
          }
        />
      </Modal>
      <ImportModal
        {...importModal}
        loading={kycBatchRequest.loading}
        onSubmit={async (file) => {
          const parsedFile = await validateFile(file);
          if (!parsedFile?.rowErrors?.length) {
            try {
              const res = await kycBatchRequest.request({
                data: parsedFile.passed,
              });

              const isSuccess =
                !res?.errorRows?.length &&
                !res?.errorCustomerNotExists?.length &&
                !res &&
                !parsedFile.rowErrors.length;
              if (isSuccess) {
                resultModal.show({
                  title: locale.importSuccessful,
                  isSuccess: true,
                  content: (
                    <div
                      style={{
                        width: "400px",
                        textAlign: "center",
                      }}
                    >
                      <Text>{locale.detailHaveBeenSuccessfullyUpdated}</Text>
                    </div>
                  ),
                });
              } else {
                showErrorModal({
                  parsedFile,
                  error: {
                    data: {
                      errorRows: res?.errorRows || [],
                      errorCustomerNotExists: res?.errorCustomerNotExists || [],
                    },
                  },
                });
              }
              importModal.close();
            } catch (e) {
              showErrorModal({
                parsedFile,
                error: e,
              });
            }
          } else {
            importModal.close();
            resultModal.show({
              title: locale.importFailed,
              content: <ErrorImport otherError={parsedFile.rowErrors} />,
            });
          }
        }}
      />
    </div>
  );
};

const ErrorImport = ({ otherError = [], doesNotExists = [] }) => {
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div>
        <Text>{locale.fileUploadFailed}</Text>
      </div>
      {doesNotExists.length !== 0 && (
        <div
          className="flex"
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "250px",
            }}
          >
            <Text subtitle>{locale.customerDoesNotExist}</Text>
          </div>
          <div>
            <Text>
              {doesNotExists.slice(0, 5).join(", ")}
              {doesNotExists.length > 5 && `, and ${doesNotExists.length - 5} more others.`}
            </Text>
          </div>
        </div>
      )}
      {Boolean(otherError.length) && (
        <div
          className="flex"
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "250px",
            }}
          >
            <Text subtitle>{locale.errorInStatusMessage}</Text>
          </div>
          <div>
            <Text>
              {otherError.slice(0, 5).join(", ")}
              {otherError.length > 5 && `, and ${otherError.length - 5} more others.`}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportKYCModal;
