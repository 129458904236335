import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { Intro } from "components/commons";
import { MessagesContext } from "contexts";
import { useMount, useRouter } from "hooks";
import { locale } from "localization/en";
import ErrorPage from "pages/error.page";
import React, { useContext, useState } from "react";
import InboxContent from "./inbox-content";
import InboxItems from "./inbox-items";
import style from "./inbox.module.scss";

const InboxModule = () => {
  const { query = {}, history, location } = useRouter();
  const { messageId } = query;
  const [selectedMessage, setSelectedMessage] = useState(undefined);
  const { initializing, fetch, loading, data = [], setRead, error } = useContext(MessagesContext);
  const [searchKey, setSearchKey] = useState("");

  const items = data.filter((item) => {
    const { announcement: content } = item;
    const text = `${content.subject} ${content.contentPreview} ${content.content}`.toLowerCase();
    return text.includes(searchKey.toLowerCase().trim());
  });

  useMount(() => {
    if (messageId) {
      setSelectedMessage(messageId);
      setRead(messageId);
      history.push(location.pathname);
    }
  });

  return (
    <div className={classNames(style.container)}>
      <Intro title={locale.inbox} />
      {error && !loading ? (
        <ErrorPage />
      ) : (
        <Grid container className={style.gridContainer}>
          <InboxItems
            error={error}
            data={items}
            setSelectedMessage={setSelectedMessage}
            selectedMessage={selectedMessage}
            setRead={setRead}
            initializing={initializing}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            onSearch={(searchKey) => {
              fetch({
                searchKey,
              });
            }}
            loading={loading}
          />
          <Grid item xs={9}>
            {!items.length ? null : selectedMessage ? (
              <InboxContent data={items} selectedMessage={selectedMessage} />
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Intro
                  containerStyle={{
                    margin: "auto",
                  }}
                  style={{
                    margin: "auto",
                    textAlign: "center",
                  }}
                  title={locale.selectAnItemToRead}
                  subtitle={locale.noItemSelectedPleaseSelectAnItem}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default InboxModule;
