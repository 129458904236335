import locale from "localization";

export const nonFuelColumnsPLB = [
  {
    key: "paymentId",
    text: locale.locqPayId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "businessNameID",
    text: `${locale.businessName}/${locale.id}`,
    style: {
      minWidth: "150px",
    },
  },
  {
    key: "redemptionStation",
    text: `${locale.gasUpStation}/${locale.stationBusinessName}`,
    style: {
      minWidth: "150px",
    },
  },
  {
    key: "cardNumber",
    text: locale.cardNo,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "driver",
    text: `${locale.driversName}/${locale.id}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "plateNumber",
    text: `${locale.plateNumber}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "50px",
    },
  },
  {
    key: "transactionRemarks",
    text: locale.remarks,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "transactionDate",
    text: `${locale.dateOfTransaction}`,
    style: {
      minWidth: "100px",
    },
  },
  // {
  //   key: "transactionRemarks",
  //   text: locale.transactionRemarks,
  //   style: {
  //     minWidth: "100px",
  //   },
  // },
  {
    key: "amount",
    text: locale.finalAmountPaid,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "poNumber",
    text: locale.purchaseOrderNo,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus} / ${locale.settlementId}`,
    style: {
      minWidth: "130px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "50px",
    },
  },
];
