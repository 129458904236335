import React from "react";
import { Field, Title } from "components/commons";

import locale from "localization";

import styles from "./view-premium-payout.module.scss";
import { formatDate } from "utils";
import { DateTime } from "enums";

const ViewPremiumPayoutForm = ({ year, month }) => {
  return (
    <div>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <div
        style={{
          width: "700px",
        }}
      >
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.month}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {formatDate(month, DateTime.I)}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.year}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {year}
        </Field>
      </div>
    </div>
  );
};

export default ViewPremiumPayoutForm;
