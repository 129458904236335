import { Products } from "enums";
import StationType from "enums/station-type";

export const stationFilterState = () => ({
  startDate: null,
  endDate: null,
  perPage: 10,
  page: 1,
  stationType: [
    StationType.COCO,
    StationType.CODO,
    StationType.CODOX,
    StationType.COXO,
    StationType.WIBCO,
    StationType.WILCO,
    StationType.DODOX,
    StationType.WIDOX,
    StationType.DODO,
    StationType.XDODO,
    StationType.XWIDO,
    StationType.XDODO_W,
    StationType.XWIDO_W,
    StationType.UDODOX,
    StationType.CODOL,
    StationType.UWIDOX,
  ],
  plcStatus: "all",
  plbStatus: "all",
  vipStatus: "all",
  status: "all",
  productCode: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
});
