import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import React from "react";
import { prettifyVoucherOption } from "utils/pretty.utils";
import styles from "./voucher-campaign-filter.module.scss";
import locale from "localization";
import SelectCampaignCreators from "components/field/select-campaign-creators/select-campaign-creators";
import VoucherTypeOptions from "enums/voucher-type-options";
import { CampaignStatus } from "enums";
import { capitalize } from "utils";

const VoucherCampaignFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  getUserAccess,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className="flex items-center">
          <Filter
            submit={applyFilter}
            clear={applyClearFilter}
            close={applyResetFilter}
            filterCount={filterCount}
          >
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.dateCreated}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            {[
              {
                value: filterState.voucherType,
                name: "voucherType",
                label: locale.voucherType,
                hasAll: true,
                options: [
                  VoucherTypeOptions.PesoVoucherCodeOneTime,
                  VoucherTypeOptions.PesoVoucherCodeAutoSeed,
                  VoucherTypeOptions.PesoVoucherCodeMultiClaim,
                  VoucherTypeOptions.PesoDiscountCodeOneTime,
                  VoucherTypeOptions.PesoDiscountCodeAutoSeed,
                  VoucherTypeOptions.PesoDiscountCodeMultiClaim,
                ].map((option) => {
                  return {
                    value: option,
                    label: prettifyVoucherOption(option),
                  };
                }),
              },
              {
                value: filterState.status,
                name: "status",
                label: locale.status,
                hasAll: true,
                multiple: true,
                options: [
                  CampaignStatus.Upcoming,
                  CampaignStatus.Ongoing,
                  CampaignStatus.Cancelled,
                  CampaignStatus.Ended,
                ].map((status) => {
                  return {
                    value: status,
                    label: capitalize(status),
                  };
                }),
              },
            ].map(({ name, options, value, label, ...props }, key) => {
              return (
                <Field
                  key={key}
                  label={label}
                  labelPosition={"left"}
                  className={styles.fieldSelect}
                  childrenClassName={styles.fieldContent}
                >
                  <Autocomplete
                    name={name}
                    value={value?.value ? value.value : value}
                    // hasAll
                    options={options}
                    onChange={(name, { value, isSelectedAll }) => {
                      modifyFilter({ [name]: { value, isSelectedAll } });
                    }}
                    {...props}
                  />
                </Field>
              );
            })}
            <Field
              label={locale.campaignCreators}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <SelectCampaignCreators
                {...filterState.campaignCreators}
                onChange={(name, { value, label, isAll }) => {
                  modifyFilter({ campaignCreators: { value, label, isAll } });
                }}
                disableAllOption
                // placeholder={locale.allCampaignCreators}
              />
            </Field>
          </Filter>
          <Field className={styles.search}>
            <SearchField
              restrict={false}
              placeholder={[locale.voucherCampaignId, locale.name, locale.prefix].join(", ")}
              value={searchKey}
              onChange={(_, { value }) => modifySearch(value)}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          {(getUserAccess?.fullAccess || getUserAccess?.exportAccess) && (
            <Button
              startIcon={<span className="icon-download" />}
              exportButton
              onClick={exportReport}
              disabled={exporting}
              loading={exporting}
            >
              {locale.export}
            </Button>
          )}
        </Field>
      </div>
    </div>
  );
};

export default VoucherCampaignFilter;
