import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import CardListModule from "modules/card-management/card-list/card-list.module";

const CardManagementPage = () => {
  return (
    <Switch>
      <Route exact path={Path.CardManagement} component={CardListModule} />
      <Redirect to={Path.CardManagement} />
    </Switch>
  );
};

export default CardManagementPage;
