import locale from "localization";

export const cashInColumns = [
  { key: "cashInId", text: locale.cashInId, width: "10%" },
  { key: "referenceNumber", text: locale.referenceNumber, width: "15%" },
  { key: "businessNameId", text: locale.businessNameId, width: "10%" },
  { key: "amount", text: locale.cashInAmount, width: "12.5%" },
  { key: "cashInFeeLocq", text: locale.cashInFeeLocq, width: "10%" },
  { key: "cashInFeeCustomer", text: locale.cashInFeeCustomer, width: "10%" },
  { key: "cashInChannel", text: locale.cashInChannel, width: "17%" },
  { key: "remarks", text: locale.remarks, width: "10%" },
  { key: "statusCashInDate", text: locale.statusCashInDate, width: "20%" },
];
