import locale from "localization";

export const columns = [
  { key: "cashinId", text: locale.cashInId },
  { key: "referenceNumber", text: locale.referenceNumber },
  { key: "userDetails", text: locale.nameAndMobileNumber },
  { key: "amount", text: locale.cashInAmount },
  { key: "locqFee", text: locale.cashInFeeLocq },
  { key: "customerFee", text: locale.cashInFeeCustomer },
  { key: "cashInChannel", text: `${locale.cashinMethod} / ${locale.cashInChannel}` },
  { key: "discountCampaign", text: locale.discountCampaign },
  { key: "statusCashInDate", text: locale.statusCashInDate },
  { key: "requestId", text: locale.requestId },
  { key: "errorDetail", text: locale.errorDetails },
  { key: "authAmount", text: locale.authAmount },
];
