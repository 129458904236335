import { PLBStatus } from "enums";
import React from "react";
import { prettifyPLBStatus } from "utils/pretty.utils";
import Pill from "../pill/pill";

const PLBStatusPill = ({ value = PLBStatus.Active }) => {
  const obj = {
    sky: value === PLBStatus.NotOnboarded,
    grass: value === PLBStatus.Active,
    salsa: value === PLBStatus.Deactivated,
  };
  return <Pill {...obj}>{prettifyPLBStatus(value)}</Pill>;
};

export default PLBStatusPill;
