import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PanelButtons from "components/commons/panel-button/panel-buttons";
import locale from "localization";
import TransactionHistoryModule from "modules/vip-transaction-history/transaction-history/transaction-history.module";
import PayHistoryModule from "modules/vip-transaction-history/pay-with-points/pay-history.module";
import RedeemHistoryModule from "modules/vip-transaction-history/redeem-points/redeem-history.module";
import { useMount, useRouter } from "hooks";
import useProducts from "hooks/useProducts";
const TransactionHistoryPage = () => {
  const router = useRouter();

  const items = [
    {
      text: locale.awardPoints,
      path: Path.VIPHistoryAwardPoints,
    },
    {
      text: locale.payWithPoints,
      path: Path.VIPHistoryPayWithPoints,
    },
    {
      text: locale.redeemPoints,
      path: Path.VIPHistoryRedeemPoints,
    },
  ];

  return (
    <>
      {items.some((item) => item.path === router.pathname) && <PanelButtons items={items} />}
      <br />
      <br />
      <div>
        <Switch>
          <Route path={Path.VIPHistoryAwardPoints} component={AwardPointsPage} />
          <Route path={Path.VIPHistoryPayWithPoints} component={PayHistoryModule} />
          <Route path={Path.VIPHistoryRedeemPoints} component={RedeemHistoryModule} />
          <Redirect to={Path.VIPHistoryAwardPoints} />
        </Switch>
      </div>
    </>
  );
};

const AwardPointsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.VIPHistoryAwardPoints} component={TransactionHistoryModule} />
      {/* <Route
        path={Path.VIPTransactionHistoryManualPointsAwarding}
        component={ManualPointsAwardingModule}
      /> */}
      <Redirect to={Path.VIPTransactionHistory} />
    </Switch>
  );
};

const VipPage = () => {
  const { fetch: getProducts } = useProducts();
  useMount(async () => {
    await getProducts();
  });
  return (
    <Switch>
      <Route path={Path.VIPTransactionHistory} component={TransactionHistoryPage} />
      <Redirect to={Path.VIPTransactionHistory} />
    </Switch>
  );
};

export default VipPage;
