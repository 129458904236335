import { ActionButton, Modal } from "components/commons";
import locale from "localization";
import styles from "./confirm-cancel-voucher-modal.module.scss";
import React, { useCallback } from "react";

const ConfirmReactivateVoucherModal = ({ onSubmitReactivate, loading, name, ...modalState }) => {
  const { close, active } = modalState;

  const submit = useCallback(() => {
    return onSubmitReactivate();
  }, [onSubmitReactivate]);

  return (
    <Modal
      active={active}
      title={`${locale.reactivateCampaign}?`}
      close={close}
      className={styles.modal}
      titleStyle={styles.title}
      contentStyle={styles.contentBody}
    >
      <div className={styles.content}>
        <locale.Populate
          text={locale.youAreAboutToReactivateCampaign}
          items={[<strong>{name}</strong>, name]}
        />
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submit();
            },
            text: locale.yesReactivateIt,
            loading: loading,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmReactivateVoucherModal;
