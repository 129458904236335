const Role =  {
    PortalAdmin: 'portal-admin',
    LubeServ: 'lubeserv',
    Marketing: 'marketing',
    Operations: 'operations',
    RetailPricing: 'retail-pricing',
    Accounting: 'accounting',
    Finance: 'finance',
    Audit: 'audit',
    GEAD: 'gead'
}

export default Role;