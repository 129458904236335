import _ from "lodash";
import { parseNumber } from "utils";

export const getObjectValues = (data) => {
  let formValues = {};
  for (const [k, formValue] of Object.entries(data)) {
    formValues[k] = formValue.parseNumber
      ? parseNumber(formValue.value)
      : formValue.value;
  }
  return formValues;
};

export const getObjectDifference = (a, b) =>
  Object.fromEntries(
    Object.entries(b).filter(([key, val]) =>
      _.isArray(val)
        ? key in a &&
          !_.isEqual(
            typeof a[key] === "object" ? JSON.stringify(a[key]) : a[key],
            typeof val === "object" ? JSON.stringify(val) : val
          )
        : typeof val === "object"
        ? JSON.stringify(a[key]) !== JSON.stringify(val)
        : key in a && a[key] !== val
    )
  );
