import { Autocomplete, Field } from "components/commons";
import React from "react";
import locale from "localization";
import StationType from "enums/station-type";

const SelectStationType = ({
  onChange,
  name,
  value,
  className,
  label = locale.stationType,
  labelClassName,
  fieldClassName,
  labelPosition,
}) => {
  return (
    <Field
      className={className}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      childrenClassName={fieldClassName}
    >
      <Autocomplete
        value={value}
        name={name}
        placeholder={"Select"}
        multiple
        hasAll
        options={[
          StationType.COCO,
          StationType.CODO,
          StationType.CODOX,
          StationType.COXO,
          StationType.WIBCO,
          StationType.WILCO,
          StationType.WIDOX,
          StationType.DODO,
          StationType.DODOX,
          StationType.XDODO,
          StationType.XWIDO,
          StationType.XDODO_W,
          StationType.XWIDO_W,
          StationType.UDODOX,
          StationType.CODOL,
          StationType.UWIDOX,
        ].map((val) => ({
          label: val,
          value: val,
        }))}
        onChange={(_, { value }) => onChange({ [name]: value })}
      />
    </Field>
  );
};

export default SelectStationType;
