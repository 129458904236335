import { Provinces } from "assets";
import { Autocomplete } from "components/commons";
import { useEffect } from "react";
import { useMemo } from "react";
import { sortByKeyName } from "utils/object.utils";

const SelectProvince = ({
  value,
  province,
  name,
  multiple,
  hasAll = false,
  onChange,

  ...props
}) => {
  const options = useMemo(() => {
    const filteredCities = province
      ? Provinces.filter((data) => {
          return data.province === province;
        })
      : Provinces;
    const preparedData = filteredCities.map((p) => {
      return {
        label: p.name,
        value: p.name,
        key: p.key,
      };
    });
    return preparedData;
  }, [province]);

  useEffect(() => {
    if (!props.key && value) {
      const filtered = province
        ? Provinces.filter((data) => {
            return data.province === province;
          })
        : Provinces;
      const selectedValue = filtered.find((p) => {
        if (p.name === value) {
          return {
            label: p.name,
            value: p.name,
            key: p.key,
          };
        }
        return false;
      });
      if (selectedValue) {
        onChange(name, { value, key: selectedValue.key, isDirty: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, props.key]);

  return (
    <Autocomplete
      {...props}
      value={value}
      name={name}
      placeholder={"Select"}
      multiple={multiple}
      hasAll={hasAll}
      options={options.sort(sortByKeyName("label"))}
      onChange={(_, { value, key }) => onChange(name, { value, key, isDirty: true })}
    />
  );
};

export default SelectProvince;
