import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button } from "components/commons";
import locale from "localization";

function Progress(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

Progress.propTypes = {
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const Download = () => {
  return <Button outline>Download</Button>;
};

const ProgressBar = ({ progress, status }) => {
  const classes = useStyles();
  let progressMessage = null;
  switch (status) {
    case locale.cancelled:
      progressMessage = locale.cancelledText;
      break;
    case locale.failed:
      progressMessage = locale.failedText;
      break;
    case locale.done:
      progressMessage = <Download />;
      break;
    default:
      progressMessage = locale.LinearProgress;
  }
  return (
    <div className={classes.root}>
      {status === "inProgress" ? <Progress value={progress} /> : progressMessage}
    </div>
  );
};

export default ProgressBar;
