import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getPumpPrice = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.VirtualStation}/pump-price/fleet/${fleetId}`, query);
  return res.data;
};

export const getPriceHistory = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.VirtualStation}/price-history/fleet/${fleetId}`, query);
  return res.data;
};

export const getPriceChanges = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetPriceChanges}`, { ...query, orderBy: "fleetAuditTrailId" });
  return res.data;
};

export const batchUpdateVirtualStation = async ({ params }) => {
  let formData = new FormData();
  const file = params.fileInfo;

  formData.append("data", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    return await Post(`${ApiPath.PricingVirtualStation}/fleet/batch`, formData, config);
  } catch (error) {
    return error;
  }
};
