import React from "react";
import Product from "./product";
import { Products } from "enums";
import { prettifyProduct } from "utils/pretty.utils";

const ProductName = ({ product, textClassName }) => {
  return (
    <div className={textClassName}>
      <Product
        deepBlue={product === Products.Gas97}
        salsa={product === Products.Gas95}
        cheddar={product === Products.Diesel}
        grass={product === Products.Gas91}
      >
        {prettifyProduct(product)}
      </Product>
    </div>
  );
};

export default ProductName;
