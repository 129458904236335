import React, { useCallback } from "react";
import locale from "localization";
import styles from "./voucher-campaign-details.module.scss";
import { Field, TextField, Title, Button, Text, DateRange, Dropdown } from "components/commons";
import { InputNumber } from "components/field";
import {
  formatDate,
  formatAmount,
  prettifyVoucherCampaignCurrency,
  prettifyVoucherCampaignType,
} from "utils";
import { CampaignStatus } from "enums";
import moment from "moment";
import classNames from "classnames";

import { DateTime } from "enums";
const VoucherDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    modifyField,
    onDateRangeCb,
    loading,
    submitForm,
    handleSubmit,
    campaignStatus,
  } = stateForm;

  const onChangeTypeCb = useCallback(
    (value) => {
      modifyField(fields.voucherType.name, { value: value });
    },
    [fields.voucherType.name, modifyField]
  );

  const isOnGoing = (startDate) => {
    var dateToday = moment().format(DateTime.A);
    var startDateMoment = moment(startDate).format(DateTime.A);
    var dDiff = moment(dateToday).diff(moment(startDateMoment));
    if (dDiff < 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeCurrency = useCallback(
    (value) => {
      modifyField(fields.voucherCurrency.name, { value: value });
    },
    [fields.voucherCurrency.name, modifyField]
  );

  const endedCampaign = campaignStatus === CampaignStatus.Ended;

  return (
    <div className={styles.container}>
      <Title xsmall>{locale.basicInformation}</Title>
      <Field className={styles.fieldLabel} {...fields.campaignName}>
        {(viewMode || isOnGoing(fields.campaignDuration.value.startDate)) && !addMode ? (
          <Text className={styles.fieldText}>{fields.campaignName.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.campaignName} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.shortDescription}>
        {viewMode || endedCampaign ? (
          <Text className={styles.fieldText}>{fields.shortDescription.value}</Text>
        ) : (
          <TextField
            helperText={locale.populate(locale.ofCharacters, [
              fields.shortDescription.value.length,
              "50",
            ])}
            disabled={loading}
            {...fields.shortDescription}
            onChange={modifyField}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.longDescription}>
        {viewMode || endedCampaign ? (
          <Text className={classNames(styles.fieldText, styles.longDesc)}>
            {fields.longDescription.value}
          </Text>
        ) : (
          <TextField
            helperText={locale.populate(locale.ofCharacters, [
              fields.longDescription.value.length,
              "500",
            ])}
            disabled={loading}
            {...fields.longDescription}
            onChange={modifyField}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.voucherPrefix}>
        {campaignStatus && !addMode ? (
          <Text className={styles.fieldText}>{fields.voucherPrefix.value}</Text>
        ) : (
          <TextField
            disabled={loading}
            {...fields.voucherPrefix}
            upperCase
            onChange={modifyField}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.voucherCurrency}>
        {(viewMode || isOnGoing(fields.campaignDuration.value.startDate)) && !addMode ? (
          <Text className={styles.fieldText}>
            {prettifyVoucherCampaignCurrency(fields.voucherCurrency.value)}
          </Text>
        ) : (
          <Dropdown disabled={loading} onChange={onChangeCurrency} {...fields.voucherCurrency} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.voucherValue}>
        {(viewMode || isOnGoing(fields.campaignDuration.value.startDate)) && !addMode ? (
          <Text className={styles.fieldText}>{formatAmount(fields.voucherValue.value)}</Text>
        ) : (
          <InputNumber disabled={loading} {...fields.voucherValue} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.campaignImage}>
        {viewMode || endedCampaign ? (
          <Text className={styles.fieldText}>{fields.campaignImage.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.campaignImage} onChange={modifyField} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.campaignDuration}>
        {viewMode ? (
          <Text className={styles.fieldText}>
            {`${formatDate(fields.campaignDuration.value?.startDate)}`}
            <span className={styles.dateSeparator}> to </span>
            {`${formatDate(fields.campaignDuration.value?.endDate)}`}
          </Text>
        ) : (
          <DateRange
            className={styles.dateRange}
            {...fields.campaignDuration}
            value={{
              startDate: fields.campaignDuration.value?.startDate,
              endDate: fields.campaignDuration.value?.endDate,
            }}
            disableStartDate={endedCampaign || isOnGoing(fields.campaignDuration.value.startDate)}
            onChange={onDateRangeCb}
          />
        )}
      </Field>
      <Title xsmall>{locale.campaignRules}</Title>
      <Field className={styles.fieldLabel} {...fields.voucherType}>
        {(viewMode ||
          isOnGoing(fields.campaignDuration.value.startDate) ||
          campaignStatus === CampaignStatus.Upcoming) &&
        !addMode ? (
          <Text className={styles.fieldText}>
            {prettifyVoucherCampaignType(fields.voucherType.value)}
          </Text>
        ) : (
          <Dropdown disabled={loading} onChange={onChangeTypeCb} {...fields.voucherType} />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields.voucherQuantity}>
        {(viewMode ||
          isOnGoing(fields.campaignDuration.value.startDate) ||
          campaignStatus === CampaignStatus.Upcoming) &&
        !addMode ? (
          <Text className={styles.fieldText}>{fields.voucherQuantity.value}</Text>
        ) : (
          <InputNumber
            disabled={loading}
            decimalPlaces={0}
            onChange={modifyField}
            {...fields.voucherQuantity}
          />
        )}
      </Field>
      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!isFormSubmittable}
          onClick={() => {
            submitForm(handleSubmit);
          }}
        >
          {locale.create}
        </Button>
      )}
    </div>
  );
};

export default VoucherDetailsFormModule;
