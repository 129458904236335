import { useMemo } from "react";
import { useState, useCallback } from "react";

const useFilterV2 = ({ initialState, mapper }) => {
  const [initialFilterState] = useState(initialState);
  const [filterState, setFilterState] = useState({ ...initialState });
  const [submittedFilter, setSubmittedFilter] = useState({ ...initialState });

  const modifyFilter = useCallback(
    (fs) => {
      const mergedFilter = { ...filterState, ...fs };
      setFilterState(mergedFilter);

      return { ...mergedFilter };
    },
    [filterState]
  );

  const clearFilter = useCallback(() => {
    setFilterState(initialFilterState);
    setSubmittedFilter(initialFilterState);
  }, [initialFilterState, setFilterState]);

  const resetFilter = useCallback(() => {
    modifyFilter(submittedFilter);
  }, [submittedFilter, modifyFilter]);

  const mappedState = useMemo(() => {
    return mapper(filterState);
  }, [mapper, filterState]);

  const filterCount = useMemo(() => {
    let count = 0;

    const mappedInitialState = mapper(initialState);

    const submittedMappedState = mapper(submittedFilter);
    for (const [key, value] of Object.entries(submittedMappedState)) {
      const val = value || null;
      const state = mappedInitialState[key] || null;
      if (state !== val) {
        count++;
      }
    }

    if (mappedState.startDate && mappedState.endDate) {
      count--;
    }
    if (mappedState.startDateEffective && mappedState.endDateEffective) {
      count--;
    }
    if (mappedState.startDateRegistered && mappedState.endDateRegistered) {
      count--;
    }
    if (mappedState.startDateCreated && mappedState.endDateCreated) {
      count--;
    }

    if (mappedState.startDateUpdated && mappedState.endDateUpdated) {
      count--;
    }
    if (mappedState.reportType) {
      count--;
    }

    return count;
  }, [initialState, mappedState, mapper, submittedFilter]);

  const submitFilter = useCallback(() => {
    setSubmittedFilter(filterState);
  }, [filterState]);

  return {
    modifyFilter,
    clearFilter,
    filterState,
    filterCount,
    mappedFilterState: mappedState,
    submitFilter,
    resetFilter,
  };
};

export default useFilterV2;
