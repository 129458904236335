import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getPayments = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Payments}`, query);
  return res.data;
};

export const voidPayment = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Payments}/void`, body);
  return res.data;
};

export const updatePaymentRemarks = async ({ id, ...body }) => {
  const res = await Put(`${ApiPath.Payments}/${id}`, body);
  return res.data;
};
