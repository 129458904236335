export const priceBuildupFilterState = () => ({
  stationTypes: [
    "all",
    "COCO",
    "CODO",
    "CODOX",
    "COXO",
    "DODO",
    "DODOX",
    "WIBCO",
    "WIDOX",
    "WILCO",
  ],
  page: 1,
  perPage: 10,
});
