import { SettlementStatus } from "enums"

export const settlementHistoryFilterState = () => ({
  month: null,
  year: null,
  oilcoSettlementStatuses: [
    SettlementStatus.ForProcessing, 
    SettlementStatus.Processed,
  ],
  perPage: 10,
  page: 1
})