import locale from "localization";

export const pricingChangesColumns = [
  { key: "fleetAuditTrailId", text: locale.auditTrailId, width: "150px" },
  { key: "businessName", text: locale.businessNameId, width: "150px" },
  {
    key: "referenceStation",
    text: `${locale.referenceStation}/${locale.oldReferenceStation}`,
    width: "450px",
  },
  {
    key: "redemptionStation",
    text: `${locale.redemptionStation}/${locale.oldRedemptionStation}`,
    width: "100px",
  },
  {
    key: "depot",
    text: `${locale.depot}/${locale.oldDepot}`,
    width: "150px",
  },
  { key: "product", text: locale.product, width: "350px" },
  { key: "accountSpecificMovement", text: locale.accountSpecificMovement, width: "200px" },
  { key: "discount", text: `${locale.discount}/${locale.oldDiscount}`, width: "100px" },
  { key: "sdum", text: `${locale.sdum}/${locale.oldSdum}`, width: "200px" },
  { key: "freight", text: `${locale.freight}/${locale.oldFreight}`, width: "200px" },
  { key: "action", text: locale.actions, width: "800px" },
  { key: "updatedAt", text: locale.dateEdited, width: "400px" },
];
