import { VoucherCampaignCurrency, VoucherCampaignType } from "enums";
import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    campaignName = {
      value: "",
    },
    shortDescription = {
      value: "",
    },
    longDescription = {
      value: "",
    },
    voucherPrefix = {
      value: "",
    },
    voucherCurrency = {
      value: "none",
    },
    voucherValue = {
      value: "",
    },
    campaignImage = {
      value: "",
    },
    campaignDuration = {
      value: {
        startDate: null,
        endDate: null,
      },
    },
    voucherType = {
      value: "none",
    },
    voucherQuantity = {
      value: "",
    },
  } = state;

  return {
    campaignName: {
      name: "campaignName",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(150)],
      label: locale.campaignName,
      horizontal: true,
      ...campaignName,
    },
    shortDescription: {
      name: "shortDescription",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(50)],
      label: locale.shortDescription,
      horizontal: true,
      ...shortDescription,
    },
    longDescription: {
      name: "longDescription",
      multiline: true,
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(500)],
      label: locale.longDescription,
      horizontal: true,
      ...longDescription,
    },
    voucherPrefix: {
      name: "voucherPrefix",
      required: true,
      upperCase: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumeric(),
        Validation.maxlength(10),
      ],
      label: locale.voucherPrefix,
      horizontal: true,
      ...voucherPrefix,
    },

    voucherCurrency: {
      name: "voucherCurrency",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.voucherCurrency,
      horizontal: true,
      placeholder: locale.select,
      options: [
        {
          label: locale.peso,
          value: VoucherCampaignCurrency.Peso,
        },
        // {
        //   label: locale.liter,
        //   value: VoucherCampaignCurrency.Liter,
        // },
        // {
        //   label: locale.points,
        //   value: VoucherCampaignCurrency.Points,
        // },
      ],
      ...voucherCurrency,
    },

    campaignImage: {
      name: "campaignImage",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(50)],
      label: locale.campaignImage,
      horizontal: true,
      ...campaignImage,
    },
    campaignDuration: {
      name: "campaignDuration",
      minDate: new Date(),
      validations: [Validation.dateRangeRequired],
      required: true,
      label: locale.campaignDuration,
      horizontal: true,
      type: "date",
      ...campaignDuration,
    },

    voucherType: {
      name: "voucherType",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(50)],
      label: locale.voucherType,
      placeholder: locale.select,
      horizontal: true,
      options: [
        // {
        //   label: locale.generic,
        //   value: VoucherCampaignType.Generic,
        // },
        {
          label: locale.unique,
          value: VoucherCampaignType.Unique,
        },
      ],
      ...voucherType,
    },
    voucherValue: {
      name: "voucherValue",
      required: true,
      parseNumber: true,
      innerLabel: true,
      validations: [
        Validation.required({ noMessage: true }),
        // Validation.number(),
        Validation.min(1),
        Validation.max(100000, { isFormatted: true }),
      ],
      label: locale.voucherValue,
      horizontal: true,
      ...voucherValue,
    },
    voucherQuantity: {
      name: "voucherQuantity",
      required: true,
      parseNumber: true,
      validations: [
        Validation.required(),
        Validation.min(1),
        Validation.max(100000, { isFormatted: true }),
      ],
      label: locale.voucherQuantity,
      horizontal: true,
      ...voucherQuantity,
    },
  };
};
