import { useMemo } from "react";

const useBeforeMount = (callback) => {
  useMemo(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBeforeMount;
