import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { generatePricingReportv2, getSettlementHistory } from "apis";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { settlementHistoryFilterState } from "./settlement-history-filter.state";
import { mapFilterToRequest, mapDataToList } from "./settlement-history.mapper";
import { settlementHistoryColumns } from "./settlement-history-columns";
import SettlementHistoryFilter from "./settlement-history-filter";
import SettlementHistoryList from "./settlement-history.list";
import styles from "./settlement-history.module.scss";
import { useExport } from "hooks";
import { SuccessModal } from "components/modals";
import { UserContext } from "contexts";
import { UserAccess } from "enums";

const SettlementHistoryModule = () => {
  const history = useHistory();
  const { sessionId: connectionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.SettlementHistory;
  });

  const { filter, search, table } = useDataTable({
    api: { api: getSettlementHistory },
    filter: { initialState: settlementHistoryFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "settlements",
      mapper: (settlements) =>
        mapDataToList({
          settlements,
          history,
          getUserAccess,
        }),
      columns: settlementHistoryColumns,
    },
  });

  const exportReport = useExport({
    api: generatePricingReportv2,
    hasModal: true,
    reportType: "settlements",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      settlementType: "oilco",
      connectionId,
    },
  });

  return (
    <>
      <SuccessModal {...exportReport?.successModalComponent} />
      <Intro title={locale.oilcoSettlementHistory} subtitle={locale.viewTrackSettlement} />
      <SettlementHistoryFilter
        {...filter}
        {...search}
        {...exportReport}
        getUserAccess={getUserAccess}
      />
      <div className={styles.table}>
        <SettlementHistoryList table={table} />
      </div>
    </>
  );
};

export default SettlementHistoryModule;
