import locale from "localization";

export const columns = [
  {
    key: "voucherCampaignId",
    text: locale.voucherCampaignId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "name",
    text: locale.name,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "voucherPrefix",
    text: locale.voucherPrefix,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "campaignGroup",
    text: locale.campaignGroup,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "voucherType",
    text: locale.voucherType,
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "claimMethod",
    text: locale.claimMethod,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "totalValue",
    text: (
      <div>
        <div>{locale.totalValue}/</div>
        <div>{locale.numberOfVouchers}</div>
      </div>
    ),
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "totalClaimed",
    text: (
      <div>
        <div>{locale.totalClaimedValue}/</div>
        <div>{locale.vouchersClaimed}</div>
      </div>
    ),
    style: {
      minWidth: "120px",
    },
  },
  {
    key: "totalUsed",
    text: (
      <div>
        <div>{locale.totalUsedValue}/</div>
        <div>{locale.voucherUsed}</div>
      </div>
    ),
    style: {
      minWidth: "100px",
    },
  },

  {
    key: "status",
    text: (
      <div>
        <div>{locale.status}/</div>
        <div>{locale.dateOfLastUpdate}</div>
      </div>
    ),
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "date",
    text: (
      <div>
        <div>{locale.startDate}/</div>
        <div>{locale.endDate}</div>
      </div>
    ),
    style: {
      minWidth: "90px",
    },
  },
  {
    key: "campaignCreator",
    text: (
      <div>
        <div>{locale.campaignCreator}/</div>
        <div>{locale.campaignCreationDate}</div>
      </div>
    ),
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "actions",
    text: locale.actions,
    align: "right",
    style: {
      minWidth: "70px",
    },
  },
];
