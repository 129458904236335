import React, { useCallback } from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapApiRequest, mapDataToList, mapFilterToRequest } from "./voucher.mapper";
import { voucherCampaignFilterState } from "./voucher-campaign-filter.state";
import { columns } from "./voucher-campaign-columns";

import DataTableV2 from "components/commons/data-table/data-table-v2";

import { ConfirmModal, SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { useApi, useModal, useRouter } from "hooks";
import { Path, UserAccess } from "enums";
import VoucherCampaignFilter from "./voucher-campaign-filter";
import {
  getVoucherCampaignHistory,
  getVoucherCampaignsV2,
  updateVoucherCampaignStatus,
} from "apis/campaign-program.api";
import { UserContext } from "contexts";
import { useContext } from "react";

const VoucherCampaign = () => {
  const { sessionId: connectionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.VoucherCampaign;
  });
  const { history } = useRouter();
  const confirmModal = useModal();

  const updateVoucherRequest = useApi({
    api: updateVoucherCampaignStatus,
  });

  const getVoucherCampaignHistoryRequest = useApi({
    api: getVoucherCampaignHistory,
  });

  const exportVoucherClaim = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "voucher-claim",
    mappedFilterState: {
      connectionId,
    },
  });

  const exportSingleVoucher = useCallback(
    (value) => {
      exportVoucherClaim.mappedFilterState.voucherCampaignId = value;
      exportVoucherClaim.exportReport({
        fileType: undefined,
      });
    },
    [exportVoucherClaim]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getVoucherCampaignsV2, mapper: mapApiRequest },
    filter: { initialState: voucherCampaignFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "voucherCampaigns",
      hasColumnPicker: true,
      initialColumns: [
        "voucherCampaignId",
        "name",
        "voucherPrefix",
        "campaignGroup",
        "voucherType",
        "claimMethod",
        "totalValue",
        "totalClaimed",
        "totalUsed",
        "actions",
      ],
      mapper: (data) =>
        mapDataToList({
          history,
          data,
          updateVoucherRequest,
          confirmModal,
          getVoucherCampaignHistoryRequest,
          exportSingleVoucher,
          getUserAccess,
        }),
      columns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "voucher-campaign",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      rfidStatuses: filter?.mappedFilterState?.status,
      searchKey: search.searchKey,
      connectionId,
    },
  });

  return (
    <>
      <SuccessModal {...exportVoucherClaim?.successModalComponent} />
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal
        {...confirmModal}
        loading={updateVoucherRequest.loading || getVoucherCampaignHistoryRequest.loading}
        primary={
          confirmModal.primary
            ? {
                ...confirmModal.primary,
                onClick: async () => {
                  if (confirmModal.primary?.onClick) {
                    await confirmModal.primary?.onClick();
                    if (confirmModal.refresh) {
                      table.fetch({
                        page: 1,
                      });
                      confirmModal.close();
                    }
                  }
                },
              }
            : null
        }
      />
      <div>
        <Intro
          title={`${locale.voucher} ${locale.campaigns}`}
          subtitle={locale.viewAndCreateVoucherCampaigns}
          actionText={
            getUserAccess?.fullAccess || getUserAccess?.modifyAccess
              ? locale.createVoucherCampaign
              : ""
          }
          actionOnClick={() => {
            history.push(Path.AddVoucherCampaign);
          }}
        />
      </div>
      <VoucherCampaignFilter {...filter} {...search} {...exports} getUserAccess={getUserAccess} />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default VoucherCampaign;
