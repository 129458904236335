import React from "react";
import Image from "../image/image";
import Title from "../title/title";
import { GuyWithMagGlass } from "images";
import locale from "localization";
import styles from "./no-page.module.scss";
import { useLocation } from "react-router-dom";

const NoPage = () => {
  let location = useLocation();
  return (
    <div className={styles.noPage}>
      <Image src={GuyWithMagGlass} />
      <Title small>
        {locale.noPageAvailable} for {location.pathname}
      </Title>
    </div>
  );
};

export default NoPage;
