import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getVoucherCampaigns = async ({ ...query }) => {
  const res = await Get(`${ApiPath.CampaignVouchers}`, query);
  return res.data;
};

export const getVoucherCampaignsV2 = async ({ ...query }) => {
  const res = await Get(`${ApiPath.CampaignVouchers}/v2`, {
    ...query,
    orderBy: "voucherCampaignId",
  });

  return res.data;
};

export const createVoucherCampaign = async ({ ...body }) => {
  const res = await Post(`${ApiPath.CampaignVouchers}`, body);
  return res.data;
};

export const createVoucherCampaignV2 = async ({ isCsv, ...body }) => {
  if (isCsv) {
    let formData = new FormData();
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    const res = await Post(`${ApiPath.CampaignVouchers}/v2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } else {
    const res = await Post(`${ApiPath.CampaignVouchers}/v2`, body);
    return res.data;
  }
};

export const getDiscounts = async ({ ...query }) => {
  const res = await Get(`${ApiPath.CampaignDiscounts}`, query);
  return res.data;
};

export const getDiscount = async ({ discountCampaignId }) => {
  const res = await Get(`${ApiPath.CampaignDiscounts}/${discountCampaignId}`);
  return res.data;
};
export const getVoucher = async ({ voucherCampaignId }) => {
  const res = await Get(`${ApiPath.CampaignVouchers}/${voucherCampaignId}`);
  return res.data;
};

export const getVoucherV2 = async ({ voucherCampaignId }) => {
  const res = await Get(`${ApiPath.CampaignVouchers}/${voucherCampaignId}/v2`);
  return res.data;
};

export const getDiscountCampaignStations = async ({ discountCampaignId, ...query }) => {
  const res = await Get(`${ApiPath.CampaignDiscounts}/${discountCampaignId}/stations`, query);
  return res.data;
};

export const createDiscountCampaign = async ({ ...body }) => {
  const res = await Post(`${ApiPath.CampaignDiscounts}/v2`, body);
  return res.data;
};

export const updateDiscountCampaign = async ({ discountCampaignId, ...body }) => {
  const res = await Put(`${ApiPath.CampaignDiscounts}/${discountCampaignId}`, body);
  return res.data;
};

export const updateVoucherCampaign = async ({ voucherCampaignId, ...body }) => {
  const res = await Put(`${ApiPath.CampaignVouchers}/${voucherCampaignId}`, body);
  return res.data;
};

export const updateVoucherCampaignV2 = async ({ voucherCampaignId, ...body }) => {
  const res = await Put(`${ApiPath.CampaignVouchers}/${voucherCampaignId}/v2`, body);
  return res.data;
};

export const updateVoucherCampaignStatus = async ({ voucherCampaignId, ...body }) => {
  const res = await Put(`ms-fleet/voucher-campaign/${voucherCampaignId}/status`, body);
  return res.data;
};

export const getVoucherCampaignHistory = async ({ voucherCampaignId, ...body }) => {
  const res = await Get(`ms-fleet/voucher-campaign/${voucherCampaignId}/histories`, body);
  return res.data;
};
