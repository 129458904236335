import locale from "localization";
import styles from "./customer-list-column.module.scss";

export const customerColumns = [
  {
    key: "fullName",
    text: (
      <span className={styles.heading}>
        {locale.firstName}/ {locale.lastName}
      </span>
    ),
    width: "2%",
  },
  {
    key: "contact",
    text: (
      <span className={styles.heading}>
        {locale.mobileNumber}/{locale.email}
      </span>
    ),
    width: "2%",
  },
  {
    key: "vip",
    text: (
      <span className={styles.heading}>
        {locale.vipCardNumber}/{locale.cardType}
      </span>
    ),
    width: "5%",
  },
  {
    key: "loyaltyType",
    text: <span className={styles.heading}>{locale.loyaltyType}</span>,
    width: "5%",
  },
  {
    key: "station",
    text: <span className={styles.heading}>{locale.referenceStationSelected}</span>,
    width: "10%",
  },
  {
    key: "registered",
    text: <span className={styles.heading}>{locale.dateRegistered}</span>,
    width: "`0`%",
  },
  {
    key: "walletBalance",
    text: <span className={styles.heading}>{locale.locqpayBalance}</span>,
    width: "5%",
  },
  {
    key: "product",
    text: <span className={styles.heading}>{locale.product}</span>,
    width: "250px",
  },

  {
    key: "literBalance",
    text: <span className={styles.heading}>{locale.literBalance}</span>,
    width: "10%",
  },
  {
    key: "vipPointsBalance",
    text: <span className={styles.heading}>{locale.vipPointBalance}</span>,
    width: "10%",
  },
  {
    key: "status",
    text: (
      <span className={styles.heading}>
        {locale.status}/ {locale.dateOfLastUpdate}
      </span>
    ),
    width: "15%",
  },
  {
    key: "action",
    text: <span className={styles.heading}>{locale.action}</span>,
    width: "3%",
  },
];
