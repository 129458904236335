import { Autocomplete, Field } from "components/commons";
import React from "react";
import locale from "localization";
import { StationStatus } from "enums";
import { prettifyStationStatus } from "utils/pretty.utils";

const SelectStationStatus = ({
  onChange,
  name,
  value,
  className,
  label = locale.stationPortalStatus,
  labelClassName,
  fieldClassName,
  labelPosition,
  multiple,
  hasAll,
}) => {
  return (
    <Field
      className={className}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      childrenClassName={fieldClassName}
    >
      <Autocomplete
        value={value}
        name={name}
        placeholder={"Select"}
        multiple={multiple}
        hasAll={hasAll}
        options={[
          StationStatus.ForActivation,
          StationStatus.Pending,
          StationStatus.Active,
          StationStatus.Expired,
          StationStatus.Deactivated,
        ].map((val) => ({
          label: prettifyStationStatus(val),
          value: val,
        }))}
        onChange={(_, { value }) => onChange({ [name]: value })}
      />
    </Field>
  );
};

export default SelectStationStatus;
