import { StationStatus } from "enums";
import React from "react";
import { prettifyStationStatus } from "utils/pretty.utils";
import Pill from "../pill/pill";

const StationStatusPill = ({ value = StationStatus.Active }) => {
  const obj = {
    purple: value === StationStatus.ForActivation,
    cheddar: value === StationStatus.Pending,
    grass: value === StationStatus.Active,
    cement: value === StationStatus.Expired,
    salsa: value === StationStatus.Deactivated,
  };
  return <Pill {...obj}>{prettifyStationStatus(value)}</Pill>;
};

export default StationStatusPill;
