import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getCashIns = async ({ ...query }) => {
  const res = await Get(`${ApiPath.GetCashIns}`, query);
  return res.data;
};

export const createManualCashIn = async ({ ...query }) => {
  const res = await Post(`${ApiPath.ManualCashIn}`, query);
  return res.data;
};

export const batchManualCashIn = async ({ ...query }) => {
  const res = await Post(`${ApiPath.ManualCashIn}/batch`, query);
  return res.data;
};
