import { ApiPath } from "enums";
import { Get } from "utils";

export const getFuelBalance = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.FuelBalance}/${fleetId}`, query);
  return res.data;
};

export const getFuelBalanceByUserId = async ({ userId }) => {
  const res = await Get(`${ApiPath.GetFuelBalanceUser}/${userId}`, {});
  return res;
};
