import { ApiPath } from "enums";
import { Get, Post, Put, Delete } from "utils";

export const getAccountType = async ({ accountTypeId }) => {
  const res = await Get(`${ApiPath.AccountTypesList}/${accountTypeId}`);
  return res.data;
};
export const getAccountTypes = async ({ ...query }) => {
  const res = await Get(`${ApiPath.AccountTypesList}`, query);
  return res.data;
};

export const createAccountType = async ({ ...body }) => {
  const res = await Post(`${ApiPath.AccountTypesList}`, body);
  return res.data;
};

export const updateAccountType = async ({ accountTypeId, ...body }) => {
  const res = await Put(`${ApiPath.AccountTypesList}/${accountTypeId}`, body);
  return res.data;
};

export const deleteAccountType = async ({ accountTypeId }) => {
  const res = await Delete(`${ApiPath.AccountTypesList}/${accountTypeId}`);
  return res;
};

export const updateUserAccountType = async ({ userId, accountTypeId }) => {
  const res = await Put(`${ApiPath.GetUserProfile}/${userId}/account-type`, {
    accountTypeId,
  });
  return res;
};
