import { PLCStatus } from "enums";
import React from "react";
import { prettifyPLCStatus } from "utils/pretty.utils";
import Pill from "../pill/pill";

const PLCStatusPill = ({ value = PLCStatus.Active }) => {
  const obj = {
    sky: value === PLCStatus.NotOnboarded,
    cheddar: value === PLCStatus.Hidden,
    grass: value === PLCStatus.Active,
    salsa: value === PLCStatus.Deactivated,
  };
  return <Pill {...obj}>{prettifyPLCStatus(value)}</Pill>;
};

export default PLCStatusPill;
