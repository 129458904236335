import VoucherStatus from "enums/voucher-status";

export const voucherFilterState = () => {
  return {
    page: 1,
    perPage: 10,
    endDate: null,
    startDate: null,
    searchKey: "",
    statuses: [
      // VoucherStatus.Available,
      VoucherStatus.Unclaimed,
      VoucherStatus.Used,
      VoucherStatus.Expired,
      VoucherStatus.Void,
    ],
  };
};
