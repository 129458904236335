import { DateRange, Field, SearchField, Filter, Autocomplete, Button } from "components/commons";
import React from "react";

import styles from "./kyc-submission-filter.module.scss";
import locale from "localization";
import { useContext } from "react";
import { mapOptions } from "utils/object.utils";
import { prettifyKYCSubmissionStatus } from "utils/pretty.utils";
import KYCSubmissionStatus from "enums/kyc-submission-status";
import classNames from "classnames";
import { useApi, useModal } from "hooks";
import { batchKYCUpdate } from "apis/kyc-submission.api";
import ImportKYCModal from "./kyc-import";
import { FeatureFlagContext, UserContext } from "contexts";
import { Role, Portal } from "enums";

const KYCSubmissionFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filerCount,
  exportReport,
  exporting,
}) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const importModal = useModal();
  const resultModal = useModal();

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.OILCO;
  });

  const kycBatchRequest = useApi({
    api: batchKYCUpdate,
    handleOwnError: {
      badrequest: true,
    },
  });
  const { verificationStatuses } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter submit={applyFilter} close={resetFilter} clear={applyClearFilter}>
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.dateApplied}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(name, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          {[
            {
              label: locale.status,
              value: verificationStatuses,
              name: "verificationStatuses",
              options: mapOptions(
                [
                  KYCSubmissionStatus.Approved,
                  KYCSubmissionStatus.Pending,
                  KYCSubmissionStatus.Rejected,
                  KYCSubmissionStatus.Deleted,
                ],
                prettifyKYCSubmissionStatus
              ),
            },
          ].map(({ label, value, name, options }, key) => {
            return (
              <Field
                key={key}
                labelPosition={"left"}
                childrenClassName={styles.fieldContent}
                className={styles.fieldSelect}
                label={label}
              >
                <Autocomplete
                  capitalize={false}
                  name={name}
                  hasAll
                  multiple
                  value={value}
                  options={options}
                  onChange={(name, { value }) => {
                    modifyFilter({ [name]: value });
                  }}
                />
              </Field>
            );
          })}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={[
              locale.name,
              locale.mobileNumber,
              locale.driverLicenseIdID,
              locale.company,
              locale.kycId,
            ].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          {role === Role.Accounting ? (
            <div></div>
          ) : hasFeature("IMPORT_KYC") ? (
            <Button
              startIcon={<span className={classNames("icon-download", styles.import)} />}
              exportButton
              onClick={() => {
                importModal.show();
              }}
              loading={kycBatchRequest.loading}
            >
              {locale.import}
            </Button>
          ) : (
            <div></div>
          )}

          <Button
            className={styles.exportButton}
            startIcon={<span className={classNames("icon-download")} />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>

      <ImportKYCModal
        refresh={() => {
          // handleSubmitFilter(requestState);
        }}
        resultModal={resultModal}
        {...importModal}
        kycBatchRequest={kycBatchRequest}
      />
    </div>
  );
};

export default KYCSubmissionFilter;
