import { Intro, Text } from "components/commons";
import { UserContext } from "contexts";
import { useExport, useModal } from "hooks";
import React, { useContext } from "react";
import RedeemHistoryFilter from "./redeem-history-filter";
import { redeemHistoryState } from "./redeem-history-filter.state";
import { generateReportAdminLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { conversionHistoryType, mapDataToList, mapFilterToRequest } from "./redeem-history.mapper";
import { redeemHistoryColumns } from "./redeem-history-columns";
import { SuccessModal } from "components/modals";
import locale from "localization";
import { UserAccess, VipTransactionHistoryExports } from "enums";
import VoidVIPTransactionHistoryModal from "../transaction-history/void-vip-transaction-history-modal";
import { getTransactionHistoryRedeemPoints } from "apis/vip-transaction-history.api";

const RedeemHistoryModule = ({ title }) => {
  const { userAccess, sessionId } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.VIPTransactionHistory;
  });

  const voidModal = useModal();

  const handleOptionClickVoid = (transaction) => {
    const conversion = conversionHistoryType(transaction);
    voidModal.show({
      title: locale.voidPointsRedemption,
      content: (
        <Text style={{ width: "450px" }}>
          <locale.Populate
            text={locale.voidPointsRedemptionContentQuestion}
            items={[<b>{conversion?.name || `ID #${conversion?.pointsHistoryId}`}</b>]}
          />
        </Text>
      ),
      secondary: {
        text: "Cancel",
      },
      primary: {
        text: locale.yesProceed,
      },
      pointsHistoryId: transaction.pointsHistoryId,
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getTransactionHistoryRedeemPoints },
    filter: { initialState: redeemHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "pointsHistory",
      mapper: (conversionHistory) =>
        mapDataToList({
          conversionHistory,
          getUserAccess,
          onOptionClickVoid: handleOptionClickVoid,
        }),
      columns: redeemHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdminLoyalty,
    reportType: VipTransactionHistoryExports.VIP_REDEEM_POINTS,
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      connectionId: sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <VoidVIPTransactionHistoryModal {...voidModal} onSuccess={() => table.refetch()} />

      <Intro
        title={`${locale.transactionHistory}: ${locale.redeemPoints}`}
        subtitle={locale.viewRedeemedPoints}
      />
      <br />
      <RedeemHistoryFilter {...filter} {...search} {...exports} getUserAccess={getUserAccess} />
      <br />
      <DataTableV2 {...table} paginationV2={true} />
    </div>
  );
};

export default RedeemHistoryModule;
