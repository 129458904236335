import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getRedemptions = async ({ platformType, ...query }) => {
  const res = await Get(`${ApiPath.RedemptionNew}/${platformType}`, query);
  return res.data;
};

export const getFleetConsumption = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.Redemption}/consumption/fleet/${fleetId}`, query);
  return res.data;
};

export const voidRedemption = async ({ ...body }) => {
  const res = await Post(`ms-fleet/redemption/void`, body);
  return res.data;
};

export const updateRedemptionRemarks = async ({ id, ...body }) => {
  const res = await Put(`${ApiPath.FleetRedemption}/${id}/remarks`, body);
  return res.data;
};

export const exportRedemptionItems = async (body) => {
  const res = await Post(`${ApiPath.GenerateReportAdmin}`, body);
  return res.data;
};

export const manualRedemption = async ({ ...body }) => {
  const res = await Post(`${ApiPath.FleetRedemption}/manual`, body);
  return res.data;
};
