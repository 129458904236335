import { Intro } from "components/commons";
import locale from "localization";
import { generateReportAdmin, getCashIns } from "apis";
import { initialFilterState } from "./cashin-filter.state";
import CashInFilter from "./cashin-filter";
import CashInList from "./cashin-list";
import useDataTable from "hooks/useDataTable";
import { columns } from "./cashin-columns";
import { mapDataToList, mapFilterToRequest } from "./cashin.mapper";
import { useRouter } from "hooks";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { useContext } from "react";
import { UserContext } from "contexts";
import { UserAccess } from "enums";

const CashInModule = () => {
  const { sessionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PLCCashIn;
  });

  const { location } = useRouter();

  const { filter, search, table } = useDataTable({
    api: { api: getCashIns, params: { platformType: "plc" } },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: { key: "cashins", mapper: mapDataToList, columns },
    searchKey: location?.state?.searchKey,
  });

  const exportReport = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "cashin",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      platformType: "plc",
      searchKey: search?.searchKey,
      connectionId: sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro title={locale.plcCashIn} subtitle={locale.viewTrackCashActivities} />
      </div>
      <CashInFilter {...filter} {...search} {...exportReport} getUserAccess={getUserAccess} />
      <CashInList table={table} />
    </div>
  );
};

export default CashInModule;
