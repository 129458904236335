const { CashInChannel, CashInChannelType } = require("enums");

export const cashInChannelsData = [
  {
    key: CashInChannel.Cybersource,
    type: CashInChannelType.PaymentProcessor,
  },
  {
    key: CashInChannel.BDO,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.Unionbank,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.BPI,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.Billease,
    type: CashInChannelType.PaymentProcessor,
  },
  {
    key: CashInChannel.GCash,
    type: CashInChannelType.PaymentProcessor,
  },
  {
    key: CashInChannel.Seaoil,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.SevenEleven,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.ExpressPay,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.ECPay,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.HLhuillier,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.RDPawnshop,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.TrueMoney,
    type: CashInChannelType.Mode,
  },
  {
    key: CashInChannel.NewCustomerVoucher,
    type: CashInChannelType.Channel,
  },
  {
    key: CashInChannel.WithholdingTaxRefund,
    type: CashInChannelType.Channel,
  },
];

export const getChannelType = (channel) => {
  return (
    cashInChannelsData.find(({ key }) => {
      return key === channel;
    })?.type || CashInChannelType.PaymentProcessor
  );
};
