import locale from "localization";

export const columns = [
  { key: "redemptionItemIdAndId", text: locale.redemptionId, width: "100px" },
  { key: "businessNameId", text: locale.businessNameId, width: "100px" },
  {
    key: "redemptionStationAndStationBusinessName",
    text: `${locale.redemptionStation}/${locale.stationBusinessName}`,
    width: "250px",
  },
  {
    key: "driversNameandId",
    text: `${locale.driversName}/${locale.driversId}`,
    width: "250px",
  },
  { key: "dispensedToPlateNumber", text: locale.dispensedToPlateNumber, width: "200px" },
  { key: "product", text: locale.product, width: "150px" },
  { key: "fuelCodeAndDate", text: `${locale.fuelCode}/${locale.redemptionDate}`, width: "200px" },
  { key: "volumeCreditPrice", text: locale.volumeCreditPrice, width: "200px" },
  { key: "pumpPrice", text: locale.redemptionStationPumpPrice, width: "9%" },
  { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "200px" },
  { key: "pointsEarned", text: locale.pointsEarned, width: "200px" },
  { key: "orNumber", text: locale.orNumber, width: "200px" },
  { key: "status", text: locale.status, width: "200px" },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus}/${locale.settlementId}`,
    width: "500px",
  },
  { key: "remarks", text: locale.remarks, width: "200px" },
  { key: "options", text: locale.actions, width: "200px" },
];
