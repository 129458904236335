import Text from "../text/text";

const LabelAndCircle = ({ label, color = "#000000" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{ height: "10px", width: "10px", borderRadius: "20px", backgroundColor: color }}
      ></div>
      <div style={{ marginLeft: "10px" }}>
        <Text small>{label}</Text>
      </div>
    </div>
  );
};
export default LabelAndCircle;
