const PricingStationType = {
  COCO: "COCO",
  COXO: "COXO",
  CODO: "CODO",
  CODOX: "CODOX",
  DODO: "DODO",
  WIDOX: "WIDOX",
  DODOX: "DODOX",
  WIBCO: "WIBCO",
  WILCO: "WILCO",
  WILBO: "WILBO",
};

export default PricingStationType;
