import React from "react";
// import classNames from "classnames";
import locale from "localization";
import { BackLink, Field, Intro, Text, Title } from "components/commons";
import { Path } from "enums";
import { Skeleton } from "@material-ui/lab";

const VoucherSkeleton = ({ title = locale.createVoucherCampaign, fields = {}, loading, intro }) => {
  const {
    campaignName = {},
    shortDescription = {},
    longDescription = {},
    campaignImage = {},
    voucherType = {},
    campaignGroup = {},
    voucherPrefix = {},
    campaignDuration = {},
    voucherValue = {},
    claimMethod = {},
    recipients = {},
    voucherAmount = {},
    uploadCSV = {},
    claimLimit = {},
    voucherValueMultiClaim = {},
    voucherDiscountValue = {},
    discountCap = {},
    minimumSpend = {},
    requiredReferenceStation = {},
    voucherQuantity = {},
    quantity = {},
    billedTo = {},
    billedToOthers = {},
    remarks = {},
    employeeRemarks = {},
  } = fields || {};
  return (
    <div>
      <BackLink text={locale.voucherCampaigns} path={Path.VoucherCampaign} />
      <Intro title={title} {...intro} />
      {[
        {
          title: locale.basicInformation,
          fields: [
            campaignName,
            shortDescription,
            longDescription,
            campaignImage,
            voucherType,
            campaignGroup,
            voucherPrefix,
            employeeRemarks,
            campaignDuration,
            voucherDiscountValue,
            discountCap,
            minimumSpend,
            voucherQuantity,
            requiredReferenceStation,
            voucherValueMultiClaim,
            claimLimit,
            recipients,
            quantity,
            voucherValue,
            voucherAmount,
            uploadCSV,
            claimMethod,
            billedTo,
            billedToOthers,
            remarks,
          ],
        },
      ]
        .filter((group) => {
          const hasVisible = group.fields.find((item) => item.visible);
          return hasVisible;
        })
        .map((group, groupIndex) => {
          return (
            <div
              key={groupIndex}
              style={{
                maxWidth: "800px",
              }}
            >
              <Title xsmall>{group.title}</Title>
              <div>
                {group.fields
                  .filter((field) => field.visible)
                  .map((field, fieldIndex) => {
                    return (
                      <Field
                        childrenStyle={{
                          minWidth: "512px",
                          maxWidth: "512px",
                        }}
                        textStyle={{
                          paddingTop: "10px",
                        }}
                        key={fieldIndex}
                        labelPosition="left"
                        label={field.label}
                        error={field.error}
                        messages={field.messages}
                        labe
                        {...field}
                        style={{
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        {loading ? (
                          <Skeleton />
                        ) : typeof field.content === "string" ? (
                          <Text
                            strong
                            style={{
                              paddingTop: "10px",
                              wordBreak: "break-all",
                            }}
                          >
                            {field.content || "-"}
                          </Text>
                        ) : (
                          field.content || (
                            <Text
                              strong
                              style={{
                                paddingTop: "10px",
                                wordBreak: "break-all",
                              }}
                            >
                              {typeof field.value === "string" && field.value ? field.value : "-"}
                            </Text>
                          )
                        )}
                      </Field>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default VoucherSkeleton;
