import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PLBRedemptionModule from "modules/plb/redemptions/redemption.module";

const PLBRedemptionPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PLBRedemption} component={PLBRedemptionModule} />
      <Redirect to={Path.PLBRedemption} />
    </Switch>
  );
};

export default PLBRedemptionPage;
