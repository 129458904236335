import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./account-view.module.scss";
import { FormMode, UserAccess, Path } from "enums";
import { Intro } from "components/commons";
import AccountDetailsModule from "../account-details/account-details.module";
import { UserContext } from "contexts";
import { useRouter } from "hooks";

const ViewFleetAccountModule = (props) => {
  const { query } = useRouter();
  const history = useHistory();
  const fleetId = query?.id ?? "";

  const { userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.FleetAccounts;
  });

  return (
    <div>
      <div className={styles.intro}>
        <Intro
          title={locale.fleetAccountDetails}
          actionText={getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? locale.edit : ""}
          actionOnClick={() => {
            history.push(Path.EditFleetAccountById(fleetId));
          }}
          actionOutlined
          actionClassName={styles.primaryEditButton}
          actionStartIcon={<span className="icon-pencil" />}
        />
        <AccountDetailsModule pageMode={FormMode.View} fleetId={fleetId} />
      </div>
    </div>
  );
};

export default ViewFleetAccountModule;
