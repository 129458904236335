import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getTransactionHistoryAwardPoints = async ({ ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/award/history`, query);
  return res.data;
};

export const getTransactionHistoryPayWithPoints = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/pay/history`, query);
  return res.data;
};

export const getTransactionHistoryRedeemPoints = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/conversion/history`, query);
  return res.data;
};

export const manualPointsAwardingApi = async (query) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/award/manual`, query);
  return res.data;
};

export const voidTransactionHistoryApi = async (query) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/history/void`, query);
  return res.data;
};
