import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { formatNumber, formatDate, formatAmount } from "utils";

import { DateTime } from "enums";
import { getVoucherTypeAndSubType } from "enums/voucher-type-options";
import { Pill } from "components/commons";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherStatus,
  prettifyVoucherType,
  prettifyVoucherTypeSetting,
} from "utils/pretty.utils";
import VoucherStatus from "enums/voucher-status";
import moment from "moment";

export const mapDataToList = ({ data, history }) => {
  const {
    voucherCode,
    voucherCampaign = {},
    redemptions,
    quantity,
    claimedDate,
    status: voucherStatus,
    voucherClaimId,
    userDetail = {},
    usedAt,
    voucherValue = 0,
  } = data;
  const {
    campaignName,
    voucherCampaignGroup,
    voucherPrefix,
    voucherType,
    voucherSubType,
    claimMethod,
    createdByEmail,
    createdBy,
    startDate,
    endDate,
    billedTo,
  } = voucherCampaign || {};
  const { firstName = "", lastName = "", mobileNumber = "", userId: customerId } = userDetail || {};
  const status = voucherStatus.toString().toLowerCase();

  return {
    voucherCode: <div>{voucherCode}</div>,
    voucherId: voucherClaimId,
    name: (
      <LabelAndValue label={prettifyVoucherCampaignGroup(voucherCampaignGroup)}>
        {campaignName}
      </LabelAndValue>
    ),
    voucherPrefix,
    value: formatAmount(voucherValue),
    type: (
      <LabelAndValue label={prettifyVoucherClaimMethod(claimMethod)}>
        {`${prettifyVoucherType(voucherType)} ${
          voucherSubType ? `(${prettifyVoucherTypeSetting(voucherSubType)})` : ""
        }`.trim() || voucherType}
      </LabelAndValue>
    ),
    redemptions: formatNumber(redemptions),
    quantity: formatNumber(quantity),
    date: (
      <LabelAndValue label={formatDate(endDate, DateTime.G)}>
        {formatDate(startDate, DateTime.G)}
      </LabelAndValue>
    ),
    claimedDate:
      usedAt || claimedDate ? (
        <LabelAndValue label={usedAt ? formatDate(usedAt, DateTime.G) : ""}>
          {claimedDate ? formatDate(claimedDate, DateTime.G) : ""}
        </LabelAndValue>
      ) : (
        "-"
      ),
    campaignCreator: createdByEmail || createdBy || "-",
    status: (
      <Pill
        style={{
          marginBottom: "10px",
        }}
        grass={status === VoucherStatus.Claimed || status === VoucherStatus.Used}
        sky={[
          VoucherStatus.AvailableInApp,
          VoucherStatus.Available,
          VoucherStatus.Assigned,
        ].includes(status)}
        cement={status === VoucherStatus.Expired || (!usedAt && moment(endDate).isBefore(moment()))}
        deepRed={status === VoucherStatus.Void}
        cheddar={status === VoucherStatus.Unclaimed}
      >
        {prettifyVoucherStatus(status)}
      </Pill>
    ),
    claimedBy: (
      <LabelAndValue label={status === VoucherStatus.Assigned ? data.mobileNumber : mobileNumber}>
        {`${firstName} ${lastName}`.trim() || "-"}
      </LabelAndValue>
    ),
    customerId: customerId || "-",
    billedTo: billedTo || "--",
  };
};

export const mapFilterToRequest = (filterState) => {
  const { currency, status, type, campaignCreators, campaignGroup, ...fs } = filterState;
  return {
    ...fs,
    campaignCreators: campaignCreators.isAll
      ? ""
      : campaignCreators.value.map(({ value }) => value).join(","),
    status: !status.isSelectedAll ? status.value.join(",") : null,
    type: !type.isSelectedAll ? type.value.join(",") : null,
    voucherType: fs.voucherType === "all" ? "" : fs.voucherTpe,
    // currency: currency.join(","),
    campaignGroup: !campaignGroup.isSelectedAll ? campaignGroup.value.join(",") : null,
  };
};

export const mapApiRequest = ({ voucherType: type = [], ...param }) => {
  const voucher = getVoucherTypeAndSubType(type) || {};

  return {
    ...param,
    voucherType: voucher.type,
    voucherSubType: voucher.subType,
  };
};
