import { getVouchersCampaignCreators } from "apis/voucher.api";

import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { useApi } from "hooks";
import { locale } from "localization/en";

const SelectCampaignCreators = ({
  placeholder = "Campaign Creator",
  disableAllOption,
  params = {},
  ...props
}) => {
  const {
    request,
    loading,
    result = [],
  } = useApi({
    api: getVouchersCampaignCreators,
    params,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request();
    },
    [request]
  );
  const data = result
    ? result?.filter(function (item, pos, self) {
        const userIds = self.map((u) => u.userId).filter((_, index) => index !== pos);
        if (userIds.includes(item.userId)) {
          return item.email;
        }
        return true;
      })
    : [];

  return (
    <DropdownPaginated
      {...props}
      keepAll
      request={async (params) => {
        return await fetch(params);
      }}
      isMultiple
      total={data.length}
      loading={loading}
      label={locale.campaignCreators}
      allSelectedText={locale.allCampaignCreators}
      allLabel={locale.campaignCreators}
      selectAllLabel={locale.selectAll}
      formatRequest={(result) => {
        const data = result
          ? result?.filter(function (item, pos, self) {
              const userIds = self.map((u) => u.userId).filter((_, index) => index !== pos);
              if (userIds.includes(item.userId)) {
                return item.email;
              }
              return true;
            })
          : [];
        if (data.length > 0) {
          const options = data.map((d = {}) => {
            const { userId, email } = d;
            return {
              label: email || userId,
              value: userId,
            };
          });
          return options;
        }
        return [];
      }}
      placeholder={placeholder}
      {...(!disableAllOption
        ? {
            allOption: [
              {
                label: "All",
                value: null,
              },
            ],
          }
        : [])}
    />
  );
};

export default SelectCampaignCreators;
