import React from "react";
import { prettifyVIPStatus } from "utils/pretty.utils";
import Pill from "../pill/pill";
import VIPStatus from "enums/vip-status";

const VIPStatusPill = ({ value = VIPStatus.Active }) => {
  const obj = {
    sky: value === VIPStatus.NotOnboarded,
    cheddar: value === VIPStatus.Hidden,
    grass: value === VIPStatus.Active,
    salsa: value === VIPStatus.Deactivated,
  };
  return <Pill {...obj}>{prettifyVIPStatus(value)}</Pill>;
};

export default VIPStatusPill;
