import { Autocomplete, DateRange, Field, SearchField, Button, Filter } from "components/commons";
import { Products } from "enums";
import React from "react";
import { prettifyProduct } from "utils/pretty.utils";
import GroupCheckbox from "components/commons/group-checkbox/group-checkbox";
import styles from "./pay-with-locqpay-filter.module.scss";
import locale from "localization";
import { SelectStation } from "components/field";
import { payWithLOCQPayStatusOptions } from "data/select-options";
import classNames from "classnames";

const PayWithLOCQPayFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  getUserAccess,
  // onRedemptionStatusChangeCb,
}) => {
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        clear={applyClearFilter}
        close={applyResetFilter}
        filterCount={filterCount}
      >
        <Field
          className={styles.date}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          label={locale.transactionDate}
        >
          <DateRange
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDate,
                endDate,
              });
            }}
          />
        </Field>

        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.redemptionStation}
          label={locale.gasUpStation}
        >
          <SelectStation
            {...filterState?.redemptionStation}
            onChange={(_, { value, label }) => {
              modifyFilter({ redemptionStation: { value, label } });
            }}
          />
        </Field>

        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.status}
        >
          <Autocomplete
            capitalize={false}
            name={"paymentStatus"}
            hasAll
            multiple
            placeholder="Select Status"
            value={filterState.paymentStatus}
            options={payWithLOCQPayStatusOptions}
            onChange={(name, { value }) => {
              modifyFilter({ [name]: value });
            }}
          />
        </Field>
        {/* <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.settlementStatus}
        >
          <Autocomplete
            name={"settlementStatuses"}
            hasAll
            multiple
            placeholder="Select Settlement Status"
            value={filterState?.settlementStatuses}
            options={[
              {
                label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                value: SettlementStatus.ForSettlement,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                value: SettlementStatus.ForProcessing,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.Processed),
                value: SettlementStatus.Processed,
              },
            ]}
            onChange={(name, { value }) => {
              modifyFilter({ [name]: value });
            }}
          />
        </Field> */}

        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          name={"productCodes"}
          onChange={(name, { value }) => {
            modifyFilter({ [name]: value });
          }}
        />
      </Filter>
      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={locale.locqpayIdAndBusinessNameBusinessIdCardNumber}
          value={searchKey}
          onChange={(_, { value }) => modifySearch(value)}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>

      <Field className={styles.downloadButton}>
        {(getUserAccess.fullAccess || getUserAccess.exportAccess) && (
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        )}
      </Field>
    </div>
  );
};

export default PayWithLOCQPayFilter;
