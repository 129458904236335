import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NoPage } from "../components/commons";
const PricelocqPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Pricelocq} component={NoPage} />
      <Redirect to={Path.Pricelocq} />
    </Switch>
  );
};

export default PricelocqPage;
