import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import styles from "./pop-over.module.scss";
import classNames from "classnames";

const PopOver = ({
  children,
  content,
  simple,
  className,
  onClose,
  contentStyle,
  arrowStyle,
  backgroundStyle,
  closeOnClick,
  right,
  style,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(undefined);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <div className="pointer" onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={open ? "pop-over-content" : undefined}
        open={open}
        anchorEl={anchorEl}
        className={classNames(
          classNames(styles.popoverContainer, className, {
            [`${styles.left}`]: !right,
            [`${styles.right}`]: right,
          })
        )}
        onClose={handleClose}
        // keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: right ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: right ? "left" : "right",
        }}
      >
        {!simple && (
          <div
            className={classNames(styles.arrow, arrowStyle, backgroundStyle, {
              [`${styles.arrowRight}`]: right,
              [`${styles.arrowLeft}`]: !right,
            })}
          ></div>
        )}

        <div
          className={classNames(styles.content, contentStyle, backgroundStyle)}
          onClick={() => {
            if (closeOnClick) {
              handleClose();
            }
          }}
          style={style}
        >
          {content}
        </div>
      </Popover>
    </div>
  );
};

export default PopOver;
