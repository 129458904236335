import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getSettlementHistory = async ({ ...query }) => {
  const res = await Get(`${ApiPath.SettlementHistory}`, query);
  return res.data;
};

export const getSettlementHistoryById = async ({ id, ...query }) => {
  const res = await Get(`${ApiPath.SettlementHistory}/${id}`, query);
  return res.data;
};

export const getSettlementRedemption = async ({ ...query }) => {
  const res = await Get(`${ApiPath.SettlementRedemption}`, query);
  return res.data
};

export const updateSettlementHistory = async ({ settlementId, ...body }) => {
  const res = await Put(`${ApiPath.SettlementHistory}/${settlementId}`, body);
  return res.data;
};