import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import UniqueVouchers from "modules/voucher/unique-vouchers.module";
import { useContext } from "react";
import { FeatureFlagContext } from "contexts";
import UniqueVouchersModule from "modules/voucher/v2/unique-vouchers.module";

const VouchersPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route
        exact
        path={Path.Vouchers}
        component={hasFeature("VOUCHER_V2") ? UniqueVouchersModule : UniqueVouchers}
      />
      <Redirect to={Path.Vouchers} />
    </Switch>
  );
};

export default VouchersPage;
