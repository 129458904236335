import locale from "localization";

export const columns = [
  { key: "kycId", text: locale.kycId },
  { key: "mobileNumber", text: locale.mobileNumber },
  { key: "name", text: locale.name },
  { key: "driverInfo", text: locale.driverLicenseIdIDNumber },
  { key: "company", text: locale.company },
  { key: "riderId", text: locale.riderId },
  { key: "dateApplied", text: locale.dateApplied },
  { key: "statusAndDateUpdated", text: `${locale.status} /${locale.dateLastUpdated}` },
  { key: "remarks", text: locale.remarks },
  { key: "action", text: locale.action },
];
