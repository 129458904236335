import locale from "localization";
import { Product } from "components/commons";

export const wholesalePriceColumns = [
  { key: "batchId", text: `${locale.wppid}`, width: "10%" },
  { key: "depot", text: `${locale.depot}`, width: "15%" },
  { key: "created_date", text: `${locale.createdDate}`, width: "15%" },
  { key: "effective_date", text: `${locale.effectiveDate}`, width: "15%" },
  {
    key: "diesel",
    align: "center",
    text: (
      <div>
        <Product cheddar>{locale.diesel}</Product>
      </div>
    ),
    width: "10%",
  },
  {
    key: "gas91",
    align: "center",
    text: (
      <div>
        <Product grass>{locale.populate(locale.gasName, ["91"])}</Product>
      </div>
    ),
    width: "10%",
  },
  {
    key: "gas95",
    align: "center",
    text: (
      <div>
        <Product salsa>{locale.populate(locale.gasName, ["95"])}</Product>
      </div>
    ),
    width: "10%",
  },
  {
    key: "gas97",
    align: "center",
    text: (
      <div>
        <Product deepBlue>{locale.populate(locale.gasName, ["97"])}</Product>
      </div>
    ),
    width: "10%",
  },
];
