import { Intro, Text } from "components/commons";
import { UserContext } from "contexts";
import { useExport, useModal } from "hooks";
import React, { useContext } from "react";
import TransactionHistoryFilter from "./transaction-history-filter";
import { transactionHistoryState } from "./transaction-history-filter.state";
import { generateReportAdminLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { mapDataToList, mapFilterToRequest } from "./transaction-history.mapper";
import { transactionHistoryColumns } from "./transaction-history-columns";
import { SuccessModal } from "components/modals";
import { UserAccess, VipTransactionHistoryExports } from "enums";
import locale from "localization";
import VoidVIPTransactionHistoryModal from "./void-vip-transaction-history-modal";
import { getTransactionHistoryAwardPoints } from "apis/vip-transaction-history.api";

const TransactionHistoryModule = ({ title }) => {
  const { userAccess, sessionId } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.VIPTransactionHistory;
  });

  const voidModal = useModal();

  const handleOptionClickVoid = (transaction) => {
    voidModal.show({
      title: locale.awardVoidTitleQuestion,
      content: (
        <Text style={{ width: "450px" }}>
          <locale.Populate
            text={locale.awardVoidContentQuestion}
            items={[<b>{transaction?.name}</b>]}
          />
        </Text>
      ),
      secondary: {
        text: "Cancel",
      },
      primary: {
        text: locale.yesProceed,
      },
      pointsHistoryId: transaction.pointsHistoryId,
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getTransactionHistoryAwardPoints,
    },
    // value: mockTransaction,
    filter: { initialState: transactionHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "pointsHistory",
      mapper: (pointsHistory) =>
        mapDataToList({ pointsHistory, getUserAccess, onOptionClickVoid: handleOptionClickVoid }),
      columns: transactionHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdminLoyalty,
    reportType: VipTransactionHistoryExports.VIP_AWARD_POINTS,
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      connectionId: sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <VoidVIPTransactionHistoryModal {...voidModal} onSuccess={() => table.refetch()} />

      <Intro
        title={`${locale.transactionHistory}: ${locale.awardPoints}`}
        subtitle={locale.viewVipPointsAwarding}
        // actionText={locale.manualPointsAwarding}
        // actionOnClick={() => router.push(Path.VIPTransactionHistoryManualPointsAwarding)}
      />
      <br />
      <TransactionHistoryFilter
        {...filter}
        {...search}
        {...exports}
        getUserAccess={getUserAccess}
      />
      <br />
      <DataTableV2 {...table} paginationV2={true} />
    </div>
  );
};

export default TransactionHistoryModule;
