import React from "react";
import { Skeleton } from "@material-ui/lab";

import { Text, Field } from "components/commons";
import { InputLitre, InputAmount } from "components/field";
import { formatCurrency, formatCurrencyWithoutDecimal, formatVolume, parseLiter } from "utils";

import styles from "./pricing.module.scss";
import Big from "big.js";

const TableForm = ({
  gasType,
  modifyForm,
  fields,
  isLoading,
  isEdit = false,
  hasSpecificAmount = false,
  initialFormState,
  matchPumpPriceFields,
  currentInfo,
}) => {
  return (
    <div className={styles.horizontalInput}>
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`base${gasType}Price`]} className={styles.gasAmount}>
          <InputAmount
            {...fields[`base${gasType}Price`]}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value },
              });
            }}
            className={styles.gasAmount}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrency(fields[`base${gasType}Price`]?.value)}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`discount${gasType}`]} className={styles.gasAmount}>
          <InputAmount
            {...fields[`discount${gasType}`]}
            decimalPlaces={2}
            onChange={(name, { value }) => {
              if (parseFloat(value) !== parseFloat(initialFormState[`discount${gasType}`].value)) {
                modifyForm({
                  [name]: { value, isUpdate: true },
                  [fields?.[`contractPrice${gasType}`].name]: {
                    value: Number(
                      Big(initialFormState?.[`contractPrice${gasType}`].value || 0)
                        .add(matchPumpPriceFields?.[`matchAccountSpecific${gasType}`].value || 0)
                        .add(initialFormState?.[`discount${gasType}`].value || 0)
                        .minus(value || 0)
                    ),
                  },
                });
              } else if (
                parseFloat(value) === parseFloat(initialFormState[`discount${gasType}`].value)
              ) {
                modifyForm({
                  [name]: {
                    value: initialFormState?.[`discount${gasType}`].value,
                    isUpdate: false,
                  },
                  [fields?.[`contractPrice${gasType}`].name]: {
                    value: Number(
                      Big(initialFormState?.[`contractPrice${gasType}`].value).add(
                        matchPumpPriceFields?.[`matchAccountSpecific${gasType}`].value || 0
                      )
                    ),
                  },
                });
              }
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrency(fields[`discount${gasType}`]?.value)}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`accuMovement${gasType}`]} className={styles.gasAmount}>
          <InputAmount
            {...fields[`accuMovement${gasType}`]}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrency(fields[`accuMovement${gasType}`]?.value)}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field className={styles.gasAmount}>
          <InputAmount
            {...fields[`stationMovement${gasType}`]}
            innerLabel={true}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrency(fields[`stationMovement${gasType}`].value)}
        </Text>
      )}
      {hasSpecificAmount ? (
        isLoading ? (
          <Skeleton
            className={styles.gasAmount}
            style={{
              padding: `0px 10px`,
            }}
          />
        ) : isEdit ? (
          <Field {...fields[`accuSpecific${gasType}`]} className={styles.gasAmount}>
            <InputAmount
              {...fields[`accuSpecific${gasType}`]}
              innerLabel
              onChange={(name, { value }) => {
                modifyForm({
                  [name]: { value },
                });
              }}
            />
          </Field>
        ) : (
          <Text className={styles.gasAmount}>
            {formatCurrency(fields[`accuSpecific${gasType}`]?.value)}
          </Text>
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`contractPrice${gasType}`]} className={styles.gasAmount}>
          <InputAmount
            {...fields[`contractPrice${gasType}`]}
            innerLabel
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrency(Number(Big(fields[`contractPrice${gasType}`]?.value)))}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field
          {...{
            ...fields[`sdum${gasType}`],
            ...(!fields[`sdum${gasType}`]?.hasValidation ? { message: "" } : {}),
          }}
          className={styles.gasAmount}
        >
          <InputAmount
            // {...fields[`sdum${gasType}`]}
            {...{
              ...fields[`sdum${gasType}`],
              ...(!fields[`sdum${gasType}`]?.hasValidation ? { error: false } : {}),
            }}
            innerLabel
            decimalPlaces={3}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value, hasValidation: true },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>{formatCurrency(fields[`sdum${gasType}`]?.value)}</Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field
          {...{
            ...fields[`freight${gasType}`],
            ...(!fields[`freight${gasType}`]?.hasValidation ? { message: "" } : {}),
          }}
          className={styles.gasAmount}
        >
          <InputAmount
            {...{
              ...fields[`freight${gasType}`],
              ...(!fields[`freight${gasType}`]?.hasValidation ? { error: false } : {}),
            }}
            innerLabel
            decimalPlaces={4}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value, hasValidation: true },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatCurrencyWithoutDecimal(fields[`freight${gasType}`]?.value)}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`estimated${gasType}Volume`]} className={styles.gasAmount}>
          <InputLitre
            {...fields[`estimated${gasType}Volume`]}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value, isUpdate: true },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatVolume(parseLiter(fields[`estimated${gasType}Volume`]?.value ?? 0))}
        </Text>
      )}
      {isLoading ? (
        <Skeleton
          className={styles.gasAmount}
          style={{
            padding: `0px 10px`,
          }}
        />
      ) : isEdit ? (
        <Field {...fields[`existing${gasType}Volume`]} className={styles.gasAmount}>
          <InputLitre
            {...fields[`existing${gasType}Volume`]}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: { value, isUpdate: true },
              });
            }}
          />
        </Field>
      ) : (
        <Text className={styles.gasAmount}>
          {formatVolume(parseLiter(fields[`existing${gasType}Volume`]?.value ?? 0))}
        </Text>
      )}
    </div>
  );
};

export default TableForm;
