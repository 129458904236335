import VoucherType from "./voucher-type";
import VoucherTypeSetting from "./voucher-type-setting";

const VoucherTypeOptions = {
  PesoVoucherCodeOneTime: `${VoucherType.PesoVoucherCode} (${VoucherTypeSetting.OneTimeClaim})`,
  PesoVoucherCodeAutoSeed: `${VoucherType.PesoVoucherCode} (${VoucherTypeSetting.AutoSeed})`,
  PesoVoucherCodeMultiClaim: `${VoucherType.PesoVoucherCode} (${VoucherTypeSetting.MultiClaimGeneric})`,
  PesoDiscountCodeOneTime: `${VoucherType.PesoDiscount} (${VoucherTypeSetting.OneTimeClaim})`,
  PesoDiscountCodeAutoSeed: `${VoucherType.PesoDiscount} (${VoucherTypeSetting.AutoSeed})`,
  PesoDiscountCodeMultiClaim: `${VoucherType.PesoDiscount} (${VoucherTypeSetting.MultiClaimGeneric})`,
};

export const getVoucherTypeAndSubType = (type) => {
  return {
    [VoucherTypeOptions.PesoVoucherCodeOneTime]: {
      type: VoucherType.PesoVoucherCode,
      subType: VoucherTypeSetting.OneTimeClaim,
    },
    [VoucherTypeOptions.PesoVoucherCodeAutoSeed]: {
      type: VoucherType.PesoVoucherCode,
      subType: VoucherTypeSetting.AutoSeed,
    },
    [VoucherTypeOptions.PesoVoucherCodeMultiClaim]: {
      type: VoucherType.PesoVoucherCode,
      subType: VoucherTypeSetting.MultiClaimGeneric,
    },
    [VoucherTypeOptions.PesoDiscountCodeOneTime]: {
      type: VoucherType.PesoDiscount,
      subType: VoucherTypeSetting.OneTimeClaim,
    },
    [VoucherTypeOptions.PesoDiscountCodeAutoSeed]: {
      type: VoucherType.PesoDiscount,
      subType: VoucherTypeSetting.AutoSeed,
    },
    [VoucherTypeOptions.PesoDiscountCodeMultiClaim]: {
      type: VoucherType.PesoDiscount,
      subType: VoucherTypeSetting.MultiClaimGeneric,
    },
  }[type];
};

export default VoucherTypeOptions;
