import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Text } from "components/commons";
import styles from "./back-link.module.scss";
import { NavLink } from "react-router-dom";

const BackLink = ({ text, path }) => {
  return (
    <NavLink to={path}>
      <div className={styles.backlink}>
        <span className={styles.backlinkContent}>
          <ChevronLeftIcon className={styles.icon} />
          <Text className={styles.text}>{text}</Text>
        </span>

      </div>
    </NavLink>
  );
};

export default BackLink;
