import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import VoucherStatus from "enums/voucher-status";
import localization from "localization";
import React from "react";
import { prettifyVoucherStatus } from "utils/pretty.utils";
import styles from "./voucher-filter.module.scss";

const VoucherFilter = ({
  filterState,
  submitFilter,
  resetFilter,
  clearFilter,
  filterCount,
  filters,
  modifyFilters,
  onExport,
  exporting,
  isExportDisbaled,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Filter
          submit={submitFilter}
          close={resetFilter}
          clear={clearFilter}
          filterCount={filterCount}
          filterState={filterState}
          disabled={!filterState.statuses.length}
        >
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            {[
              {
                name: "dateClaimed",
                content: (
                  <Field
                    className={styles.filterLabel}
                    label={localization.dateClaimed}
                    labelPosition={"left"}
                    childrenClassName={styles.fieldContent}
                    l
                  >
                    <DateRange
                      value={{
                        startDate: filterState.startDate,
                        endDate: filterState.endDate,
                      }}
                      onChange={(name, { value }) => {
                        const { startDate, endDate } = value || {};
                        modifyFilters({
                          startDate,
                          endDate,
                        });
                      }}
                    />
                  </Field>
                ),
              },
              {
                name: "status",
                content: (
                  <Field
                    className={styles.filterInput}
                    label={localization.status}
                    labelPosition={"left"}
                    childrenClassName={styles.fieldContent}
                  >
                    <Autocomplete
                      name="status"
                      value={filterState.statuses}
                      hasAll
                      placeholder={localization.selectStatus}
                      options={[
                        VoucherStatus.Unclaimed,
                        VoucherStatus.Used,
                        VoucherStatus.Expired,
                        VoucherStatus.Void,
                      ].map((item) => ({
                        label: prettifyVoucherStatus(item),
                        value: item,
                      }))}
                      onChange={(name, { value }) => {
                        modifyFilters({
                          statuses: value,
                        });
                      }}
                      multiple
                    />
                  </Field>
                ),
              },
            ]
              .filter((item) => filters.includes(item.name))
              .map((item, index) => (
                <div key={index}>{item.content}</div>
              ))}
          </div>
        </Filter>
        <Field
          className={styles.search}
          style={{
            marginLeft: "10px",
          }}
        >
          <SearchField
            placeholder={`${localization.campaignName}, ${localization.mobileNumber}, ${localization.name}, ${localization.voucherCode}`}
            value={filterState.searchKey}
            onChange={(name, { value }) => {
              modifyFilters({
                searchKey: value,
              });
            }}
            onEnter={() => {
              submitFilter();
            }}
            onClear={() => {
              submitFilter({ searchKey: "" });
            }}
            restrict={false}
          />
        </Field>
      </div>
      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={() => {
          onExport();
        }}
        disabled={exporting || isExportDisbaled}
        loading={exporting}
      >
        {localization.export}
      </Button>
    </div>
  );
};

export default VoucherFilter;
