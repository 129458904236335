import React, { useCallback } from "react";
import styles from "./pagination.module.scss";
import Dropdown from "../dropdown/dropdown";
import classNames from "classnames";
import Icon, { Icons } from "../icon/icon";
import { Size } from "enums/size";

const NextPrevPagination = ({
  page,
  perPage = 10,
  onChange,
  className,
  disablePerPage = false,
  style,
  currentItemCount,
  isReverse,
  setIsLastPage,
  isLastPage,
}) => {
  const onChangePage = useCallback(
    (val, isReverse = false) => {
      if (onChange) {
        const obj = { page: val, perPage };
        obj.isReverse = isReverse;
        return onChange(obj);
      }
    },
    [perPage, onChange]
  );

  const onChangePageSize = useCallback(
    (val) => {
      if (onChange) {
        return onChange({ page: 1, perPage: val, isReverse: false });
      }
    },
    [onChange]
  );

  return (
    <div className={classNames(className, "flex")} style={style}>
      <div
        className={classNames("flex")}
        style={{
          marginRight: "20px",
        }}
      >
        <div
          className={classNames(styles.buttonNav, {
            [`${styles.disabled}`]: page === 1 && !isReverse,
          })}
          onClick={() => {
            onChangePage(1, false);
            setIsLastPage(false);
          }}
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <Icon icon={Icons.Skip} size={Size.Large} />
        </div>
        <div
          className={classNames(styles.buttonNav, {
            [`${styles.disabled}`]: (page === 1 && !isReverse) || !page,
          })}
          onClick={() => {
            const disabled = (page === 1 && !isReverse) || !page;
            if (!disabled) {
              onChangePage(isReverse ? page + 1 : page - 1, isReverse);
            }
          }}
        >
          <Icon icon={Icons.Previous} size={Size.Large} />
        </div>
        <div
          className={classNames(styles.buttonNav, {
            [`${styles.disabled}`]:
              currentItemCount !== perPage || (page === 1 && isReverse) || isLastPage,
          })}
          onClick={() => {
            if (currentItemCount === perPage || (page !== 1 && isReverse)) {
              onChangePage(isReverse ? page - 1 : page + 1, isReverse);
            }
          }}
        >
          <Icon icon={Icons.Next} size={Size.Large} />
        </div>
        <div
          className={classNames(styles.buttonNav, {
            [`${styles.disabled}`]:
              (page === 1 && isReverse) || currentItemCount !== perPage || isLastPage,
          })}
          onClick={() => {
            onChangePage(1, true);
          }}
        >
          <Icon icon={Icons.Skip} size={Size.Large} />
        </div>
      </div>
      {!disablePerPage && (
        <div className={styles.pageSize}>
          <Dropdown
            className={styles.perPage}
            value={perPage}
            dropdownClassName={styles.dropdown}
            onChange={onChangePageSize}
            options={[
              { label: "10 / page", value: 10 },
              { label: "20 / page", value: 20 },
              { label: "30 / page", value: 30 },
              { label: "40 / page", value: 40 },
              { label: "50 / page", value: 50 },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default NextPrevPagination;
