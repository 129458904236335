import { Modal, Text, ActionButton, TextField, Field } from "components/commons";
import React from "react";
import locale from "localization";
import {
  getAccountStationByCode,
  getNearestStation,
  updateStationStatus,
} from "apis/account-stations";
import { useApi } from "hooks";
import { useEffect } from "react";
import { PLCStatus } from "enums";
import useForm from "hooks/useForm.v2";
import { useState } from "react";
import { useCallback } from "react";
import { debounced } from "utils";

const DeactivatePLCModal = ({ refresh, stationId, ...modal }) => {
  const [submittedStationCode, setSubmittedStationCode] = useState("");
  const [validStationCode, setValidStationCode] = useState("");
  const form = useForm({
    form: {
      stationCode: {
        label: locale.stationCode,
        visible: true,
        maxLength: 10,
        type: "input",
        validations: {
          isRequired: true,
          isAlphaNumeric: true,
          custom: [
            (value, field) => {
              const { errorCode } = field;
              const errorMessage = {
                S1002: locale.sorryThisStationCodeDoesNotExist,
                "invalid-station": "Invalid station",
              };
              return {
                error: Boolean(errorCode),
                message: errorMessage[errorCode],
              };
            },
          ],
        },
      },
      stationId: {},
      name: {},
    },
  });

  const { fields, modifyForm } = form;
  const { stationCode, name, stationId: stationIdValue } = fields;

  const nearestRequest = useApi({
    api: getNearestStation,
  });

  const stationCodeRequest = useApi({
    api: getAccountStationByCode,
    handleOwnError: true,
  });

  const updateStatusRequest = useApi({
    api: updateStationStatus,
  });

  const fetchNearestStation = useCallback(
    async (stationId) => {
      const res = await nearestRequest.request({
        stationId,
      });
      setSubmittedStationCode(res?.stationCode);
      setValidStationCode(res?.stationCode);
      form.initializeForm(
        {
          stationCode: { value: res?.stationCode },
          name: { value: res?.name },
          stationId: { value: res?.stationId },
        },
        true
      );
    },
    [form, nearestRequest]
  );

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stationId]);

  useEffect(() => {
    if (!modal.active) {
      form.initializeForm({}, true);
    } else {
      if (stationId) {
        fetchNearestStation(stationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.active]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounced(async () => {
      const stationCodeValue = stationCode?.value || "";
      if (
        stationCodeValue.length >= 4 &&
        validStationCode !== submittedStationCode &&
        submittedStationCode !== stationCodeValue
      ) {
        setSubmittedStationCode(stationCodeValue);
        if (stationCodeValue === nearestRequest?.result?.stationCode?.toString()) {
          setValidStationCode(stationCodeValue);
          modifyForm({
            stationCode: { value: stationCodeValue },
            name: { value: nearestRequest?.result?.name },
            stationId: { value: nearestRequest?.result?.stationId },
          });
        } else {
          try {
            const res = await stationCodeRequest.request({
              stationCode: stationCodeValue,
            });
            if ([PLCStatus.Active, PLCStatus.Hidden].includes(res.plcStatus)) {
              const { name, stationId: newStationId } = res || {};

              if (newStationId === stationId) {
                modifyForm({
                  stationCode: {
                    value: stationCodeValue,
                    visible: true,
                    errorCode: "invalid-station",
                  },
                  name: {
                    value: name,
                    visible: true,
                  },
                });
              } else {
                setValidStationCode(stationCodeValue);
                form.initializeForm(
                  {
                    stationCode: {
                      value: stationCodeValue,
                      visible: true,
                    },
                    name: {
                      value: name,
                      visible: true,
                    },
                    stationId: {
                      value: newStationId,
                    },
                  },
                  true
                );
              }
            } else {
              stationCode.onChange(stationCode.name, {
                errorCode: "S1002",
                value: stationCodeValue,
              });
            }
          } catch (e) {
            const { data = {} } = e || {};
            const { errorCode } = data;
            const errorMessage = ["S1002", "S1012"];

            if (errorMessage.includes(errorCode?.toString())) {
              stationCode.onChange(stationCode.name, {
                errorCode: "S1002",
                value: stationCodeValue,
              });
            } else {
              e.showError();
            }
          }
        }
      }
    }, 1000),
    [stationCode, stationId]
  );

  const isValidStationCode =
    submittedStationCode &&
    validStationCode &&
    submittedStationCode === validStationCode &&
    stationCode.value === validStationCode;

  return (
    <Modal
      {...modal}
      title={locale.deactivateStationInPLC}
      style={{
        width: "400px",
      }}
    >
      <Text className="text-center">{locale.onceDeactivatedTheStation}</Text>

      <Text
        style={{
          margin: "30px 0",
        }}
      >
        {locale.nearestStation}:
      </Text>

      <Field
        {...stationCode}
        label={locale.stationCode}
        labelPosition="left"
        childrenStyle={{
          width: "300px",
        }}
        loading={nearestRequest.loading}
      >
        <TextField
          {...stationCode}
          loading={stationCodeRequest.loading}
          onChange={async (name, field) => {
            // stationCode.onChange(name, field);
            setSubmittedStationCode("");
            stationCode.onChange(name, {
              ...field,
              errorCode: null,
            });
          }}
        />
      </Field>
      <Field
        label={locale.stationName}
        labelPosition="left"
        childrenStyle={{
          width: "300px",
        }}
        loading={nearestRequest.loading}
      >
        <TextField value={name?.value} disabled onChange={() => {}} />
      </Field>

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modal.close();
            },
          },
          {
            loading: updateStatusRequest.loading,
            disabled: nearestRequest.loading || !isValidStationCode || stationCodeRequest.loading,
            text: locale.yesDeactivateIt,
            onClick: async () => {
              if (isValidStationCode) {
                await updateStatusRequest.request({
                  stationId,
                  plcStatus: PLCStatus.Deactivated,
                  newStationId: stationIdValue.value,
                });
                refresh();
                modal.close();
              }
            },
          },
        ]}
      />
    </Modal>
  );
};

// newStationId

export default DeactivatePLCModal;
